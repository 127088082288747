// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import { createCustomAction } from 'typesafe-actions';
import { Dispatch } from 'redux';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import {
    create,
    get,
    loadOnScroll,
    paginateList,
    remove,
    update,
} from 'common/actions/actionsWrapper';
import { IPaginateApiResponse } from 'common/api/resources/Response';
import { IAppState } from 'admin/core/store';
import * as types from 'admin/limitGroup/constants/types';
import {
    ILimitGroupRequest,
    ILimitGroupResponse,
    limitGroups,
} from 'common/api/resources/LimitGroup';

export const setLimitGroupList = createCustomAction(
    types.SET_LIMIT_GROUP_LIST,
    (data: IPaginateApiResponse<ILimitGroupResponse[]>) => ({ payload: data })
);
export const appendLimitGroups = createCustomAction(
    types.APPEND_LIMIT_GROUPS,
    (data: IPaginateApiResponse<ILimitGroupResponse[]>) => ({ payload: data })
);
export const setLimitGroupItem = createCustomAction(
    types.SET_LIMIT_GROUP_ITEM,
    (data: ILimitGroupResponse) => ({ payload: data })
);
export const unsetLimitGroupItem = createCustomAction(types.UNSET_LIMIT_GROUP_ITEM);
export const addLimitGroupItem = createCustomAction(
    types.ADD_LIMIT_GROUP_ITEM,
    (data: ILimitGroupResponse) => ({ payload: data })
);
export const updateLimitGroupItem = createCustomAction(
    types.UPDATE_LIMIT_GROUP_ITEM,
    (data: ILimitGroupResponse) => ({ payload: data })
);
export const removeLimitGroupItem = createCustomAction(
    types.REMOVE_LIMIT_GROUP_ITEM,
    (id: number) => ({ payload: id })
);
export const setLimitGroupItemIsDeleting = createCustomAction(
    types.SET_LIMIT_GROUP_ITEM_IS_DELETING,
    (id: number, isDeleting: boolean) => ({ payload: { id, isDeleting } })
);

export const getLimitGroups = () => async (dispatch: Dispatch) => await paginateList({
    dispatch,
    loadingFlag: LOADING_FLAGS.LIMIT_GROUP_LIST,
    action: setLimitGroupList,
    apiCall: limitGroups.list,
});

export const createLimitGroup = (data: ILimitGroupRequest) => async (dispatch: Dispatch) => await create({
    data,
    dispatch,
    loadingFlag: LOADING_FLAGS.SAVE_LIMIT_GROUP_ITEM,
    action: addLimitGroupItem,
    apiCall: limitGroups.create,
    translations: {
        success: 'limitGroup.toasts.limitGroupSaved',
    },
});

export const getLimitGroup = (id: number) => async (dispatch: Dispatch) => await get(id, {
    dispatch,
    apiCall: limitGroups.item,
    action: setLimitGroupItem,
    loadingFlag: LOADING_FLAGS.LIMIT_GROUP_ITEM,
});

export const updateLimitGroup = (id: number, data: ILimitGroupRequest) => async (dispatch: Dispatch) => await update(id, {
    data,
    dispatch,
    apiCall: limitGroups.update,
    action: updateLimitGroupItem,
    loadingFlag: LOADING_FLAGS.SAVE_LIMIT_GROUP_ITEM,
    translations: {
        success: 'limitGroup.toasts.limitGroupSaved',
    },
});

export const removeLimitGroup = (id: number) => async (dispatch: Dispatch) => await remove(id, {
    dispatch,
    apiCall: limitGroups.remove,
    setLoadingAction: setLimitGroupItemIsDeleting,
    action: removeLimitGroupItem,
    loadingFlag: LOADING_FLAGS.REMOVE_LIMIT_GROUP_ITEM,
    translations: {
        success: 'limitGroup.toasts.limitGroupDeleted',
    },
});

export const loadLimitGroupsOnScroll = () => async(dispatch: Dispatch, getState: () => IAppState) => {
    const state = getState();
    const nextPage = state.limitGroup.list.links.next;
    const isLoading = state.app.loadingFlags.has(LOADING_FLAGS.LIMIT_GROUP_LIST);

    return await loadOnScroll({
        nextPage,
        isLoading,
        dispatch,
        action: appendLimitGroups,
        loadingFlag: LOADING_FLAGS.LIMIT_GROUP_LIST,
    });
};
