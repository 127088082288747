// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import { Button } from '@plesk/ui-library';
import { INTENT_TYPE } from 'common/constants';
import * as responseErrorActions from 'common/modules/app/responseError/actions';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { connect } from 'react-redux';
import { RootState } from 'client/core/store';
import ConfirmationInputPopover from 'common/components/ConfirmationInputPopover/ConfirmationInputPopover';

export const TEST = {
    INPUT_CONFIRM_POPOVER: 'input-confirm-popover',
};

interface IButtonWithInputConfirmationProps extends React.HTMLProps<HTMLDivElement> {
    onClose?: () => void;
    disabled?: boolean;
    icon?: string | React.ReactNode;
    ghost?: boolean;
    isLoading?: boolean;
    handleConfirm: (force?: boolean) => void;
    confirmation: string;
    translations: {
        confirmationButton: React.ReactNode;
        label: React.ReactNode;
        forceLabel?: React.ReactNode;
        button?: React.ReactNode;
        text?: React.ReactNode;
        title?: React.ReactNode;
        tooltip?: React.ReactNode;
    };
    'data-cy'?: string;
    buttonSize?: string;
    buttonIntent?: INTENT_TYPE | null;
    confirmationButtonIntent?: INTENT_TYPE | null;
    placement?: string;
    withForceCheckbox?: boolean;
}

export type ButtonWithInputConfirmationProps =
    IButtonWithInputConfirmationProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const ButtonWithInputConfirmation: React.FC<ButtonWithInputConfirmationProps> = ({
    responseErrorActions: { clearResponseError },
    onClose,
    error,
    ghost = true,
    icon,
    handleConfirm,
    confirmation,
    isLoading = false,
    disabled,
    translations: {
        button,
        confirmationButton,
        label,
        forceLabel,
        text,
        title,
        tooltip,
    },
    'data-cy': dataCy = TEST.INPUT_CONFIRM_POPOVER,
    buttonSize,
    buttonIntent,
    confirmationButtonIntent = INTENT_TYPE.DANGER,
    placement,
    withForceCheckbox,
}) => {
    const [isPopoverOpened, setPopoverOpened] = React.useState(false);

    const handleClose = () => {
        setPopoverOpened(false);
        clearResponseError();

        onClose?.();
    };

    const handleOpenPopover = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        clearResponseError();
        setPopoverOpened(true);
    };

    const handleClickConfirm = async (force?: boolean) => {
        await handleConfirm(force);

        if (!error.error) {
            setPopoverOpened(false);
        }
    };

    return (
        <ConfirmationInputPopover
            opened={isPopoverOpened}
            translations={{
                button: confirmationButton,
                label,
                forceLabel,
                text,
                title,
            }}
            isLoading={isLoading}
            disabled={disabled}
            error={error.error}
            intent={error.error ? INTENT_TYPE.DANGER : null}
            onClose={handleClose}
            placement={placement}
            buttonIntent={confirmationButtonIntent}
            confirmation={confirmation}
            withForceCheckbox={withForceCheckbox}
            handleConfirm={handleClickConfirm}
        >
            <Button
                intent={buttonIntent}
                ghost={ghost}
                size={buttonSize}
                state={isLoading ? 'loading' : undefined}
                disabled={disabled}
                onClick={handleOpenPopover}
                icon={icon}
                tooltip={tooltip}
                data-cy={dataCy}
            >
                {button}
            </Button>
        </ConfirmationInputPopover>
    );
};

const mapStateToProps = (state: RootState) => ({
    error: state.app.responseError,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    responseErrorActions: bindActionCreators(responseErrorActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ButtonWithInputConfirmation);
