// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import styled from 'styled-components';

interface IContainerProps {
    alignRight?: boolean;
}

export const Container = styled.div<IContainerProps>`
    display: flex;
    flex: 1;
    ${props => props.alignRight ? 'justify-content: flex-end' : ''}
    margin-top: 10px;
    margin-bottom: 8px;
    
    div.pul-section-item {
        padding-right: 10px !important;
        min-width: 20%;
    }

    .pul-section-item--vertical {
        margin-bottom: 0 !important;
    }
`;
