// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import { createCustomAction } from 'typesafe-actions';
import * as types from 'common/modules/app/language/types';
import { ILanguageResponse } from 'common/api/resources/Language';

export const setLanguage = createCustomAction(
    types.SET_LANGUAGE,
    (language: ILanguageResponse) => ({ payload: language })
);
