// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import styled from 'styled-components';
import { Popover as UIPopover } from '@plesk/ui-library';

export const IFrame = styled.iframe`
  width: 100%;
  height: 100%;
`;

export const Popover = styled(UIPopover)`
  padding: 0 !important;

  .pul-popover__content {
    height: 100%;
  }

  .pul-popover__content-inner {
    height: 100%;
    padding: 0 !important;
  }
`;
