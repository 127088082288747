// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import { AuthLayoutContainer } from 'common/modules/auth/layouts/AuthLayout/Styles';

interface IProps extends React.HTMLProps<HTMLElement> {}

const AuthLayout: React.FC<IProps> = ({
    children,
}) => (
    <AuthLayoutContainer>
        {children}
    </AuthLayoutContainer>
);

export default AuthLayout;
