// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import {
    UsageDetail,
    UsageItem,
    UsageProgress,
    UsageProgressHalf,
} from 'admin/computeResource/page/components/UsageTab/UsageBlock/Styles';
import { Tooltip } from 'common/components';

export interface IUsageBlockProps {
    header: string | React.ReactNode;
    body: string;
    footer: string | React.ReactNode;
    progress: number;
    tooltip?: React.ReactNode;
}

export const UsageBlock: React.FC<IUsageBlockProps> = ({
    header,
    body,
    footer,
    progress,
    tooltip,
}) => {
    const angle = 3.6 * progress;

    return (
        <Tooltip
            shown={tooltip !== undefined}
            title={tooltip}
        >
            <UsageItem>
                <UsageDetail>
                    {header}
                    <div>
                        {body}
                    </div>
                    {footer}
                </UsageDetail>
                <UsageProgress moreThanHalf={angle > 180}>
                    <UsageProgressHalf angle={angle <= 180 ? angle : 180} />
                    {angle > 180 && <UsageProgressHalf angle={angle} />}
                </UsageProgress>
            </UsageItem>
        </Tooltip>
    );
};
