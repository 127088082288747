// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import {
    Drawer,
    Translate,
    Icon,
    Button,
    Checkbox,
} from '@plesk/ui-library';
import {
    SSHKeysContainer,
    InputContainer,
    SshList,
} from 'common/components/ServerSSHKeys/Styles';
import { Footer } from 'common/components/styles/DrawerFooter';
import { ISshKeyResponse } from 'common/api/resources/SshKey';
import { INTENT_TYPE } from 'common/constants';

export interface IServerSSHKeysDrawerProps {
    isOpen: boolean;
    selected: number[];
    sshKeys: ISshKeyResponse[];
    onClose: () => void;
    onKeysAdd: (ids: number[]) => void;
}

export const ServerSSHKeysDrawer: React.FC<IServerSSHKeysDrawerProps> = ({
    onClose,
    isOpen,
    sshKeys,
    onKeysAdd,
    selected,
}) => {
    const [filter, setFilter] = React.useState('');
    const [selectedKeys, setSelectedKeys] = React.useState([...selected]);

    const handleSearch = (e: React.FormEvent<HTMLInputElement>) => setFilter(e.currentTarget.value);
    const handleSelect = (id: number ) => () => {
        setSelectedKeys(selectedKeys.includes(id) ? selectedKeys.filter((item) => item !== id) : [...selectedKeys, id]);
    };
    const handleConfirmSelected = () => {
        onKeysAdd(selectedKeys);
    };
    const handleClose = () => {
        setSelectedKeys([...selected]);
        onClose();
    };

    React.useEffect(() => {
        setSelectedKeys([...selected]);
        setFilter('');
    }, [selected]);

    const keyList = React.useMemo(() => {
        const filteredKeys = sshKeys.filter(key => key.name.includes(filter));

        return filteredKeys.map((sshKey, key) => (
            <Checkbox
                onChange={handleSelect(sshKey.id)}
                checked={selectedKeys.includes(sshKey.id)}
                key={key}
            >
                {sshKey.name}
            </Checkbox>
        ));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sshKeys, filter, selectedKeys]);


    return (
        <Drawer
            onClose={handleClose}
            title={<Translate content="servers.create.ssh.sectionTitle" />}
            isOpen={isOpen}
            size="xs"
        >
            <SSHKeysContainer>
                <InputContainer>
                    <Icon className="input-icon" name="search" />
                    <input onChange={handleSearch} placeholder="Name" />
                </InputContainer>
                <SshList>
                    {keyList}
                </SshList>
                <Footer>
                    <Button onClick={handleConfirmSelected} intent={INTENT_TYPE.PRIMARY}>
                        <Translate content="servers.create.ssh.addSelectedBtn" />
                    </Button>
                </Footer>
            </SSHKeysContainer>
        </Drawer>
    );
};
