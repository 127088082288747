// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

export const USERS = {
    TABLE: 'users-table',
};

export const TABLE_ACTIONS = {
    EDIT: 'edit',
    REMOVE: 'remove',
    BATCH_REMOVE: 'batch-remove',
};
