// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import {
    IPaginateApiResponse,
    paginateInitialState,
} from 'common/api/resources/Response';
import {
    ActionType,
    getType,
} from 'typesafe-actions';
import * as backupNodeActions from 'admin/backupNode/actions';
import { combineReducers } from 'redux';
import {
    BackupNodeType,
    IBackupNodeResponse,
    IShortBackupNodeResponse,
} from 'common/api/resources/BackupNode';
import { addToPaginated } from 'common/reducers/list';

export interface IBackupNodeReducer {
    list: IPaginateApiResponse<IBackupNodeResponse[]>;
    item: IBackupNodeResponse;
}

export type BackupNodeActions = ActionType<typeof backupNodeActions>;
export type BackupNodeState = Readonly<IBackupNodeReducer>;

export const initialShortBackupNodeState: IShortBackupNodeResponse = {
    id: 0,
    name: '',
};

export const initialBackupNodeState: IBackupNodeResponse = {
    id: 0,
    name: '',
    type: BackupNodeType.SSH_RSYNC,
    credentials: {
        port: 22,
        login: 'root',
        key: '',
        storage_path: '/root',
        host: 'foo.bar',
    },
    compute_resources_count: 0,
    backups_count: 0,
    total_backups_size: 0,
    compute_resources: [],
    can_delete: true,

    is_deleting: false,
};

export default combineReducers<BackupNodeState, BackupNodeActions>({
    list: (state = paginateInitialState, action) => {
        switch (action.type) {
        case getType(backupNodeActions.setBackupNodeList):
            return action.payload;

        case getType(backupNodeActions.addBackupNodeItem):
            return addToPaginated(state, action.payload);

        case getType(backupNodeActions.appendBackupNodeList):
            return {
                links: action.payload.links,
                data: [...state.data, ...action.payload.data],
                meta: action.payload.meta,
            };

        case getType(backupNodeActions.removeBackupNodeItem):
            return {
                ...state,
                data: state.data.filter(item => item.id !== action.payload),
            };

        case getType(backupNodeActions.updateBackupNodeItem):
            return {
                ...state,
                data: state.data.map(item => {
                    if (item.id === action.payload.id) {
                        return action.payload;
                    }

                    return item;
                }),
            };
        case getType(backupNodeActions.setBackupNodeItemIsDeleting):
            return {
                ...state, data: [...state.data.map(item => {
                    if (item.id === action.payload.id) {
                        return { ...item, is_deleting: action.payload.isDeleting };
                    }

                    return item;
                })],
            };
        default:
            return state;
        }
    },
    item: (state = { ...initialBackupNodeState }, action) => {
        switch (action.type) {
        case getType(backupNodeActions.setBackupNodeItem):
            return action.payload;

        case getType(backupNodeActions.unsetBackupNodeItem):
            return { ...initialBackupNodeState };

        default:
            return state;
        }
    },
});
