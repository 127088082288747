// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import { IUserResponse } from 'common/api/resources/User';
import { IBackupNodeResponse } from 'common/api/resources/BackupNode';
import { IPaginated } from 'common/api/resources/Request/request';

// Should be synced with `backend/api/v1/ManagementNodeBackup/Enums/ManagementNodeBackupCreationMethod.php`.
export enum ManagementNodeBackupCreationMethod {
    AUTO = 'auto',
    MANUAL = 'manual',
}

// Should be synced with `backend/api/v1/ManagementNodeBackup/Enums/ManagementNodeBackupStatus.php`
export enum ManagementNodeBackupStatus {
    CREATING = 'creating',
    RESTORING = 'restoring',
    CREATED = 'created',
    FAILED = 'failed',
}

export interface IManagementNodeBackupResponse {
    id: number;
    status: ManagementNodeBackupStatus;
    version: string;
    created_at: string;
    creation_method: ManagementNodeBackupCreationMethod;
    backup_progress?: number;
    size: number;
    backup_node?: IBackupNodeResponse;
    creator?: IUserResponse;
    backup_fail_reason: string;
    is_deleting: boolean;
}

export interface IUpdateManagementNodeBackupResource {
    backup: IManagementNodeBackupResponse;
}

export interface IManagementNodeListRequest extends IPaginated {}
