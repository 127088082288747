// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import {
    Form,
    FormFieldCheckbox,
    FormFieldText,
    Paragraph,
    StatusMessage,
    Translate,
} from '@plesk/ui-library';
import {
    INTENT_TYPE,
    SIZE,
} from 'common/constants';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { connect } from 'react-redux';
import * as formErrorsActions from 'common/modules/app/formErrors/actions';
import { RootState } from 'client/core/store';
import { StyledPopover } from 'common/components/ConfirmationInputPopover/Styles';

export interface IConfirmationInputTranslation {
    button: React.ReactNode;
    label: React.ReactNode;
    forceLabel?: React.ReactNode;
    text?: React.ReactNode;
    title?: React.ReactNode;
}

export interface IConfirmationFormValues {
    confirmation: string;
    force?: boolean;
}

export interface IConfirmationInputPopoverProps extends Omit<React.HTMLProps<HTMLDivElement>, 'target'> {
    opened?: boolean;
    children: React.ReactElement;
    translations: IConfirmationInputTranslation;
    error?: string;
    disabled?: boolean;
    isLoading?: boolean;
    'data-cy'?: string;
    intent?: INTENT_TYPE | null;
    confirmation: string;
    handleConfirm: (force?: boolean) => void;
    buttonIntent?: INTENT_TYPE | null;
    onClose: () => void;
    placement?: string;
    withForceCheckbox?: boolean;
}

export type ConfirmationInputPopoverProps = IConfirmationInputPopoverProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const ConfirmationInputPopover: React.FC<ConfirmationInputPopoverProps> = ({
    opened,
    translations: {
        button,
        text,
        title,
        label,
        forceLabel,
    },
    error,
    children,
    isLoading = false,
    disabled,
    'data-cy': dataCy = 'confirmation-popover',
    intent,
    onClose,
    buttonIntent = INTENT_TYPE.DANGER,
    placement = 'right-bottom',
    confirmation,
    handleConfirm,
    withForceCheckbox,
    formErrors,
    formErrorsActions: { setFormErrors, clearFormErrors },
}) => {
    const [values] = React.useState<IConfirmationFormValues>({ confirmation: '' });
    const handleOnClone = () => {
        clearFormErrors();
        onClose();
    };
    const handleSubmit = ({ confirmation: userConfirmation, force }: IConfirmationFormValues) => {
        clearFormErrors();

        if (confirmation !== userConfirmation.trim()) {
            setFormErrors({
                confirmation: {
                    notConfirmed: <Translate content="computeResource.servers.confirmationPopover.notConfirmed" />,
                },
            });
            return;
        }

        handleConfirm(force);
    };

    return (
        <StyledPopover
            title={title}
            data-cy={dataCy}
            onClose={handleOnClone}
            intent={intent}
            visible={opened}
            target={children}
            placement={placement}
        >
            {
                error
                    ? (<StatusMessage intent={INTENT_TYPE.DANGER}>{error}</StatusMessage>)
                    : (
                        <Form
                            id="confirmationForm"
                            errors={formErrors}
                            values={values}
                            onSubmit={handleSubmit}
                            applyButton={false}
                            submitButton={{
                                id: 'confirm-button',
                                children: button,
                                intent: buttonIntent,
                                state: isLoading ? 'loading' : undefined,
                                disabled,
                            }}
                            cancelButton={{
                                children: <Translate content="computeResource.servers.confirmationPopover.cancel" />,
                                onClick: handleOnClone,
                                disabled: isLoading || disabled,
                            }}
                        >
                            {text && (<Paragraph>{text}</Paragraph>)}
                            {withForceCheckbox && (
                                <FormFieldCheckbox
                                    label={forceLabel}
                                    name="force"
                                />
                            )}
                            <FormFieldText
                                label={label}
                                size={SIZE.FILL}
                                name="confirmation"
                                required={true}
                            />
                        </Form>
                    )
            }
        </StyledPopover>
    );
};

const mapStateToProps = (state: RootState) => ({
    formErrors: state.app.formErrors,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    formErrorsActions: bindActionCreators(formErrorsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationInputPopover);
