// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import styled from 'styled-components';
import { ADMIN } from 'common/styles/variables';

export const NavItemsContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    color: #fff;

    background-color: ${ADMIN.SIDEBAR_COLOR};
`;

export const NavItemsGroupBottom = styled.div`
    margin-top: auto;
`;

export const Logo = styled.div`
    display: flex;
    align-items: center;
    margin-left: 18px;
    min-height: 60px;
    max-width: 190px;
    color: #ffffff;

    svg {
      fill: #ffffff;
    }
`;
