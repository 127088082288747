// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import styled from 'styled-components';

export const StyledCheckBox = styled.div`
  display: flex;

  input[type=checkbox] {
    display: none;

    &[type=checkbox] {
      + label {
        #checkmark {
          opacity: 0;
        }
      }
    }
    &[type=checkbox]:checked {
      + label {
        #background {
          filter: none;
          fill: #28aade;
        }
        #border {
          opacity: 0;
        }
        #checkmark {
          opacity: 1;
        }
      }
    }
  }
`;

export const Label = styled.label`
  display: block;
  height: 20px;
  user-select: none;

  &:hover {
    cursor: pointer;
  }

  svg {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }

  span {
    line-height: 20px;
    font-size: 14px;
    vertical-align: -1px;
  }
`;
