// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import * as snapshotActions from 'common/modules/snapshot/actions';
import { CardItem } from 'client/common/components/Card';
import ButtonWithConfirmation from 'common/components/ButtonWithConfirmation';
import { RootState } from 'client/core/store';
import {
    RouteComponentProps,
    withRouter,
} from 'react-router';
import { ISnapshotResponse } from 'common/api/resources/Snapshot';
import { INTENT_TYPE } from 'common/constants';
import moment from 'moment';
import {
    IconWrap,
    Label,
} from 'common/components/ServerTabs/SnapshotTab/Styles';
import {
    Icon,
    Status,
    Toolbar,
    ToolbarExpander,
    Translate,
    Popover,
} from '@plesk/ui-library';
import {
    SNAPSHOT_STATUS_AVAILABLE,
    SNAPSHOT_STATUS_FAILED,
    SNAPSHOT_STATUS_PROCESSING,
} from 'common/modules/project/constants';
import { HTTP_CODES } from 'common/api/constants';

export interface ISnapshotCardProps extends RouteComponentProps {
    snapshot: ISnapshotResponse;
}

export type SnapshotCardProps =
    ISnapshotCardProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const SnapshotCard: React.FC<SnapshotCardProps> = ({
    snapshotsActions: {
        revertSnapshot,
        removeSnapshot,
    },
    server,
    snapshot,
}) => {
    const [error, setError] = React.useState();

    const handleCloseErrorPopover = () => setError(undefined);

    const handleRevert = async() => {
        try {
            await revertSnapshot(snapshot);
        } catch (e) {
            if (e.response.status === HTTP_CODES.BAD_REQUEST) {
                setError(e.response.data.message);
            }
        }
    };

    const handleRemove = () => removeSnapshot(snapshot);

    return (
        <CardItem
            isLoading={snapshot.isLoading}
            icon={
                <IconWrap>
                    <Icon size="32" name="camera"/>
                </IconWrap>
            }
            cardTitle={snapshot.name}
            item={
                <Toolbar>
                    <Label>
                        <Icon intent="inactive" name="floppy-disk" size="16"/>
                        {snapshot.size} GiB
                    </Label>
                    <Label>
                        <Icon intent="inactive" name="calendar" size="16"/>
                        {moment(snapshot.created_at).format('DD/MM/YYYY HH:mm')}
                    </Label>
                </Toolbar>
            }
            footer={
                <Toolbar>
                    <Status
                        intent={snapshot.status !== SNAPSHOT_STATUS_FAILED ? INTENT_TYPE.SUCCESS : INTENT_TYPE.DANGER}
                        progress={snapshot.status === SNAPSHOT_STATUS_PROCESSING}
                        compact={snapshot.status !== SNAPSHOT_STATUS_PROCESSING}
                    >
                        {snapshot.status !== SNAPSHOT_STATUS_PROCESSING && snapshot.status}
                    </Status>
                    <ToolbarExpander/>
                    {snapshot.status !== SNAPSHOT_STATUS_PROCESSING && (
                        <>
                            {snapshot.status === SNAPSHOT_STATUS_AVAILABLE && !server.is_suspended && (
                                <Popover
                                    placement="bottom-right"
                                    visible={!!error}
                                    intent="danger"
                                    onClose={handleCloseErrorPopover}
                                    target={
                                        <ButtonWithConfirmation
                                            icon="transfer"
                                            translations={{
                                                text: (
                                                    <Translate content="snapshots.confirmationRevert.text" />
                                                ),
                                                button: (
                                                    <Translate content="snapshots.confirmationRevert.button" />
                                                ),
                                                tooltip: (
                                                    <Translate content="snapshots.confirmationRevert.button" />
                                                ),
                                            }}
                                            handleConfirm={handleRevert}
                                        />
                                    }
                                >
                                    {error}
                                </Popover>
                            )}
                            <ButtonWithConfirmation
                                translations={{
                                    text: (
                                        <Translate content="snapshots.confirmationRemove.text" />
                                    ),
                                    button: (
                                        <Translate content="snapshots.confirmationRemove.button" />
                                    ),
                                    tooltip: (
                                        <Translate content="snapshots.confirmationRemove.button" />
                                    ),
                                }}
                                handleConfirm={handleRemove}
                                icon="recycle"
                            />
                        </>
                    )}
                </Toolbar>
            }
        />
    );
};

const mapStateToProps = (state: RootState) => ({
    server: state.project.servers.item,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    snapshotsActions: bindActionCreators(snapshotActions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SnapshotCard));
