// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import { Dispatch } from 'redux';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { createCustomAction } from 'typesafe-actions';
import * as types from 'common/modules/project/constants/types';
import { IPaginateApiResponse } from 'common/api/resources/Response';
import {
    setIsLoading,
    unsetIsLoading,
} from 'common/modules/app/loadingFlags/actions';
import { HTTP_CODES } from 'common/api/constants';
import moment from 'moment';
import { RESET_INTERVAL } from 'client/project/containers/projectItem/tabs/ProjectMembersTab/TimeoutLink';
import {
    IProjectMemberRequest,
    IProjectMemberResponse,
    projects,
} from 'common/api/resources/Project';
import { IPaginatedWithSearch } from 'common/api/resources/Request/request';
import { CancelTokenSource } from 'axios';

export const setProjectMembers = createCustomAction(
    types.SET_PROJECT_MEMBERS_LIST,
    (data: IPaginateApiResponse<IProjectMemberResponse[]>) => ({ payload: data })
);
export const updateMemberItem = createCustomAction(
    types.UPDATE_MEMBER_ITEM,
    (data: IProjectMemberResponse) => ({ payload: data })
);
export const addMember = createCustomAction(
    types.ADD_MEMBER,
    (data: IProjectMemberResponse) => ({ payload: data })
);
export const removeMemberItem = createCustomAction(
    types.REMOVE_MEMBER_ITEM,
    (id: number) => ({ payload: id })
);
export const setItemIsLoading = createCustomAction(
    types.SET_IS_LOADING,
    (id: number) => ({ payload: id })
);
export const unsetItemIsLoading = createCustomAction(
    types.UNSET_IS_LOADING,
    (id: number) => ({ payload: id })
);

export const leaveProject = (data: IProjectMemberResponse, projectId: number) => async (dispatch: Dispatch) => {
    dispatch(setItemIsLoading(data.id));

    try {
        const result = await projects.leave(projectId);

        if (result.status === HTTP_CODES.NO_CONTENT) {
            dispatch(removeMemberItem(data.id));
        }

        return result;
    } finally {
        dispatch(unsetItemIsLoading(data.id));
    }
};

export const getProjectMembers = (id: number, params?: IPaginatedWithSearch, cancelToken?: CancelTokenSource) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.PROJECT_MEMBERS_LIST));

    try {
        const result = await projects.members.list(id, params, cancelToken);
        if (result.status === HTTP_CODES.OK) {
            dispatch(setProjectMembers({
                ...result.data,
                data: result.data.data.map(item => ({
                    ...item,
                    isEdit: false,
                    isLoading: false,
                })),
            }));
        }

        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.PROJECT_MEMBERS_LIST));
    }
};

export const createMember = (projectId: number, data: IProjectMemberRequest) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.PROJECT_MEMBERS_ITEM));

    try {
        const result = await projects.members.create(projectId, { email: data.email });

        if (result.status === HTTP_CODES.CREATED) {
            dispatch(addMember({
                ...result.data.data,
                isEdit: false,
            }));
        }

        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.PROJECT_MEMBERS_ITEM));
    }
};

export const removeProjectMember = (projectId: number, data: IProjectMemberResponse) => async (dispatch: Dispatch) => {
    dispatch(setItemIsLoading(data.id));

    try {
        const result = await projects.members.remove(projectId, data.id);

        if (result.status === HTTP_CODES.NO_CONTENT) {
            dispatch(removeMemberItem(data.id));
        }

        return result;
    } catch (e) {
        if (e.response.status === HTTP_CODES.NOT_FOUND) {
            dispatch(removeMemberItem(data.id));
        }

        throw e;
    } finally {
        dispatch(unsetItemIsLoading(data.id));
    }
};

export const resendInvite = (projectId: number, member: IProjectMemberResponse) => async (dispatch: Dispatch) => {
    dispatch(setItemIsLoading(member.id));

    try {
        const result = await projects.members.resendInvite(projectId, member.id);

        if (result.status === HTTP_CODES.OK) {
            dispatch(updateMemberItem({
                ...result.data.data,
                invite_sent_at: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
            }));
        }

        return result;
    } catch (e) {
        if (e.response.status === HTTP_CODES.TOO_MANY_REQUESTS) {
            const retryAfter = RESET_INTERVAL - parseInt(e.response.headers['retry-after'], 10);

            dispatch(updateMemberItem({
                ...member,
                invite_sent_at: moment().subtract(retryAfter, 'seconds').utc().format('YYYY-MM-DD HH:mm:ss'),
            }));
        }

        throw e;
    } finally {
        dispatch(unsetItemIsLoading(member.id));
    }
};

export const joinProject = (id: number, token: string) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.PROJECT_MEMBERS_JOIN));

    try {
        return await projects.join(id, { token });
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.PROJECT_MEMBERS_JOIN));
    }
};
