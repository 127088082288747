// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import styled from 'styled-components';
import { COLORS } from 'admin/core/theme';

export const NotificationItemContainer = styled.div`
    display: flex;

    min-height: 88px;
    padding: 16px 0;
    margin: 0 20px;
    border-bottom: 1px solid ${COLORS.GREY_30};
`;

export const NotificationItemContent = styled.div`
    flex: 1;
`;

export const NotificationItemHeader = styled.div`
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 4px;
`;

export const NotificationItemDescription = styled.div`
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 9px;
`;

export const NotificationItemToolbar = styled.div`
    display: flex;
`;

export const IconContainer = styled.div`
    padding-right: 8px;
`;
