// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import { BackupNodeType } from 'common/api/resources/BackupNode';

const humanTypeMap = {
    [BackupNodeType.SSH_RSYNC]: 'SSH+rsync',
    [BackupNodeType.HETZNER_STORAGE_BOX]: 'Hetzner Storage Box',
};

export const humanizeType = (type: BackupNodeType): string => humanTypeMap[type];
