// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import {
    getStatus,
    IComputeResourceResponse,
    NetworkType,
} from 'common/api/resources/ComputeResource';
import { Status } from 'admin/computeResource/common/Status/Status';
import {
    TitleContainer,
    MainTitleContainer,
    MainTitle,
    SubTitleContainer,
    SubTitleSection,
    SubTitleSectionLabel,
    ActionsContainer,
} from 'admin/computeResource/page/components/PageHeader/Styles';
import { Header } from 'admin/common/components/PageHeader/Styles';
import CopyText from 'common/containers/CopyText/CopyText';
import LocationCell from 'common/components/IconCell/LocationCell';
import IPv6AvailableCell from 'common/components/IconCell/IPv6AvailableCell';
import { ILocationResponse } from 'common/api/resources/Location';
import { dataCySelector } from 'common/tests/selectors';
import {
    Button,
    Icon,
    Translate,
} from '@plesk/ui-library';
import { COMPUTE_RESOURCE } from 'admin/computeResource/constants/tests';

export interface IPageHeaderProps {
    computeResource: IComputeResourceResponse;
    onSettingsClick: () => void;
    onEditClick: () => void;
}

const renderLocations = (locations: ILocationResponse[]) => {
    if (locations.length === 0) {
        return null;
    }

    return locations.map((location) => <LocationCell key={location.id} location={location}/>);
};

const PageHeader: React.FC<IPageHeaderProps> = ({
    computeResource,
    onSettingsClick,
    onEditClick,
}) => (
    <Header>
        <TitleContainer>
            <MainTitleContainer>
                <MainTitle content="computeResource.page.title" params={{ name: computeResource.name }}/>
                <Status status={getStatus(computeResource.status)} withoutButton={true}/>
            </MainTitleContainer>
            <SubTitleContainer>
                <SubTitleSection>
                    <SubTitleSectionLabel>
                        <Translate content="computeResource.list.host" />
                    </SubTitleSectionLabel>
                    <CopyText>
                        {computeResource.host}
                    </CopyText>
                </SubTitleSection>
                <SubTitleSection>
                    <SubTitleSectionLabel>
                        <Translate content="computeResource.list.port" />
                    </SubTitleSectionLabel>
                    {computeResource.agent_port}
                </SubTitleSection>
                {computeResource.settings.network.type === NetworkType.ROUTED &&
                    <SubTitleSection>
                        <IPv6AvailableCell available={computeResource.metrics.network.ipv6_available} />
                    </SubTitleSection>
                }
                <SubTitleSection
                    data-cy={dataCySelector(computeResource.id, 'locations')}
                >
                    {computeResource.locations && renderLocations(computeResource.locations)}
                </SubTitleSection>
            </SubTitleContainer>
        </TitleContainer>
        <ActionsContainer>
            <Button
                ghost={true}
                icon={<Icon name="gear" size="24"/>}
                onClick={onSettingsClick}
                data-cy={COMPUTE_RESOURCE.SETTINGS}
            />
            <Button
                ghost={true}
                icon={<Icon name="pencil" size="24"/>}
                onClick={onEditClick}
                data-cy={COMPUTE_RESOURCE.EDIT}
            />
        </ActionsContainer>
    </Header>
);

export default PageHeader;
