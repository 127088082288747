// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

export const SET_COMPUTE_RESOURCE_VMS = 'compute-resource-vm/SET_COMPUTE_RESOURCE_VMS';
export const SET_COMPUTE_RESOURCE_VM = 'compute-resource-vm/SET_COMPUTE_RESOURCE_VM';
export const UNSET_COMPUTE_RESOURCE_VM = 'compute-resource-vm/UNSET_COMPUTE_RESOURCE_VM';
export const UPDATE_COMPUTE_RESOURCE_VM = 'compute-resource-vm/UPDATE_COMPUTE_RESOURCE_VM';
export const UPDATE_COMPUTE_RESOURCE_VM_PROGRESS = 'compute-resource-vm/UPDATE_COMPUTE_RESOURCE_VM_PROGRESS';
export const UPDATE_COMPUTE_RESOURCE_VM_DISK_USAGE = 'compute-resource-vm/UPDATE_COMPUTE_RESOURCE_VM_DISK_USAGE';
export const MOVE_COMPUTE_RESOURCE_VM = 'compute-resource-vm/MOVE_COMPUTE_RESOURCE_VM';
export const ADD_COMPUTE_RESOURCE_VM = 'compute-resource-vm/ADD_COMPUTE_RESOURCE_VM';
export const DELETE_COMPUTE_RESOURCE_VM = 'compute-resource-vm/DELETE_COMPUTE_RESOURCE_VM';
export const DELETE_COMPUTE_RESOURCE_VMS = 'compute-resource-vm/DELETE_COMPUTE_RESOURCE_VMS';
export const SET_VM_IS_LOADING = 'compute-resource-vm/SET_VM_IS_LOADING';
export const UNSET_VM_IS_LOADING = 'compute-resource-vm/UNSET_VM_IS_LOADING';
export const SET_COMPUTE_RESOURCE_VM_ITEM_IS_DELETING = 'compute-resource-vm/SET_COMPUTE_RESOURCE_VM_ITEM_IS_DELETING';
export const ADD_COMPUTE_RESOURCE_VM_IPV6_REVERSE_DNS = 'compute-resource-vm/ADD_COMPUTE_RESOURCE_VM_IPV6_REVERSE_DNS';
export const DELETE_COMPUTE_RESOURCE_VM_IPV6_REVERSE_DNS = 'compute-resource-vm/DELETE_COMPUTE_RESOURCE_VM_IPV6_REVERSE_DNS';
export const UPDATE_COMPUTE_RESOURCE_VM_REVERSE_DNS = 'compute-resource-vm/UPDATE_COMPUTE_RESOURCE_VM_REVERSE_DNS';
export const ADD_COMPUTE_RESOURCE_VM_ADDITIONAL_IP = 'compute-resource-vm/ADD_COMPUTE_RESOURCE_VM_ADDITIONAL_IP';
export const DELETE_COMPUTE_RESOURCE_VM_ADDITIONAL_IP = 'compute-resource-vm/DELETE_COMPUTE_RESOURCE_VM_ADDITIONAL_IP';
export const COMPUTE_RESOURCE_VM_CHANGE_PRIMARY_IP = 'compute-resource-vm/COMPUTE_RESOURCE_VM_CHANGE_PRIMARY_IP';
