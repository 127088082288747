// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import {
    Form,
    FormFieldPassword,
    FormFieldSelect,
    FormFieldText,
    Section,
    setIn,
    StatusMessage,
    Translate,
    useTranslate,
} from '@plesk/ui-library';
import * as React from 'react';
import { connect } from 'react-redux';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import * as externalIntegrationActions from 'admin/externalIntegration/actions';
import * as formErrorsActions from 'common/modules/app/formErrors/actions';
import { Button } from 'admin/common/components/Button/Button';
import {
    DefaultCPanelGroupId,
    DefaultCPanelPackageId,
    DefaultPleskProductConstant,
    ExternalIntegrationType,
    ICPanelCredentialsData,
    IExternalIntegrationCreateRequest,
    TranslationExternalIntegrationTypeMap,
} from 'common/api/resources/ExternalIntegration';
import {
    INTENT_TYPE,
    SIZE,
} from 'common/constants';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import {
    IValidationRules,
    requiredRule,
    validate,
} from 'common/validator';
import { RootState } from 'admin/core/store';
import { nestStringProperties } from 'common/modules/app/formErrors/selectors';
import { HTTP_CODES } from 'common/api/constants';

interface IExternalIntegrationFormProps {
    onSubmit: () => void;
}

export type ExternalIntegrationFormProps =
    IExternalIntegrationFormProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const ExternalIntegrationForm: React.FC<ExternalIntegrationFormProps> = ({
    item,
    isItemSaving,
    onSubmit,
    responseError,
    formErrors,
    formErrorsActions: {
        setFormErrors,
        clearFormErrors,
    },
    externalIntegrationActions: {
        createExternalIntegration,
        updateExternalIntegration,
        unsetExternalIntegrationItem,
    },
}) => {
    const translate = useTranslate();
    const [submitValues, setSubmitValues] = React.useState<IExternalIntegrationCreateRequest>({ ...item });
    const isCreating = !item.id;

    React.useEffect(() => () => {
        clearFormErrors();
        unsetExternalIntegrationItem();
    }, [clearFormErrors, unsetExternalIntegrationItem]);

    const externalIntegrationTypeOptions = React.useMemo(() => Object.values(ExternalIntegrationType).map((value) => (
        <option key={value} value={value}>
            {translate(TranslationExternalIntegrationTypeMap[value])}
        </option>
    )), [translate]);

    const onFieldChange = (key: string, value: string) => setSubmitValues(setIn(submitValues, key, value));

    const handleSubmit = async (values: IExternalIntegrationCreateRequest) => {

        let rules: IValidationRules  = {
            name: requiredRule(<Translate content="validate.fieldRequired" />),
            type: requiredRule(<Translate content="validate.fieldRequired" />),
        };

        switch (values.type) {
        case ExternalIntegrationType.PLESK:
            rules = {
                ...rules,
                'credentials.username': requiredRule(<Translate content="validate.fieldRequired" />),
                'credentials.product_constant': requiredRule(<Translate content="validate.fieldRequired" />),
            };

            if (isCreating) {
                rules = {
                    ...rules,
                    'credentials.password': requiredRule(<Translate content="validate.fieldRequired" />),
                };
            } else {
                if (values.credentials.password !== undefined && !values.credentials.password.length) {
                    delete values.credentials.password;
                }
            }

            break;
        case ExternalIntegrationType.CPANEL:
            rules = {
                ...rules,
                'credentials.package_id': requiredRule(<Translate content="validate.fieldRequired" />),
                'credentials.group_id': requiredRule(<Translate content="validate.fieldRequired" />),
            };

            if (isCreating) {
                rules = {
                    ...rules,
                    'credentials.user': requiredRule(<Translate content="validate.fieldRequired" />),
                    'credentials.password': requiredRule(<Translate content="validate.fieldRequired" />),
                };
            } else {
                const credentials = values.credentials as ICPanelCredentialsData;
                if (credentials.user !== undefined && !credentials.user.length) {
                    delete credentials.user;
                }
                if (credentials.password !== undefined && !credentials.password.length) {
                    delete credentials.password;
                }
            }

            break;
        }

        const errors = validate<IExternalIntegrationCreateRequest>(values, rules);

        if (Object.keys(errors).length) {
            setFormErrors(errors);
            return;
        }

        try {
            item.id ? await updateExternalIntegration(item.id, values) : await createExternalIntegration(values);
            onSubmit();
        } catch (e) {
            throw e;
        }
    };

    const renderPleskExternalIntegrationCredentials = () => (
        <>
            <FormFieldText
                size={SIZE.FILL}
                name="credentials[username]"
                label={<Translate content="externalIntegration.form.credentials.username" />}
                required={true}
            />
            <FormFieldPassword
                name="credentials[password]"
                size={SIZE.FILL}
                required={true}
                label={<Translate content="externalIntegration.form.credentials.password"/>}
                hideGenerateButton={true}
                hidePasswordMeter={true}
            />
            <FormFieldText
                size={SIZE.FILL}
                name="credentials[product_constant]"
                label={<Translate content="externalIntegration.form.credentials.plesk.productConstant" />}
                required={true}
                placeholder={DefaultPleskProductConstant}
                fullDescription={(
                    <Translate
                        content="externalIntegration.form.credentials.plesk.productConstantDescription"
                        params={{
                            url: (
                                <a
                                    href="https://docs.plesk.com/en-US/obsidian/partner-api-3.0/partner-api-30-constants.77835"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <Translate content="externalIntegration.form.credentials.plesk.seeAllConstants" />
                                </a>
                            ),
                        }}
                    />
                )}
            />
        </>
    );

    const renderCPanelExternalIntegrationCredentials = () => (
        <>
            <FormFieldText
                size={SIZE.FILL}
                name="credentials[user]"
                label={<Translate content="externalIntegration.form.credentials.username" />}
                required={isCreating}
            />
            <FormFieldPassword
                name="credentials[password]"
                size={SIZE.FILL}
                required={isCreating}
                label={<Translate content="externalIntegration.form.credentials.password"/>}
                hideGenerateButton={true}
                hidePasswordMeter={true}
            />
            <FormFieldText
                size={SIZE.FILL}
                name="credentials[package_id]"
                label={<Translate content="externalIntegration.form.credentials.cPanel.packageId" />}
                required={true}
                placeholder={DefaultCPanelPackageId}
                fullDescription={(
                    <Translate
                        content="externalIntegration.form.credentials.cPanel.packageIdDescription"
                        params={{
                            url: (
                                <a
                                    href="https://docs.cpanel.net/manage2/packages/list-packages/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <Translate content="externalIntegration.form.credentials.cPanel.packageIdLc" />
                                </a>
                            ),
                        }}
                    />
                )}
            />
            <FormFieldText
                size={SIZE.FILL}
                name="credentials[group_id]"
                label={<Translate content="externalIntegration.form.credentials.cPanel.groupId" />}
                required={true}
                placeholder={DefaultCPanelGroupId}
                fullDescription={(
                    <Translate
                        content="externalIntegration.form.credentials.cPanel.groupIdDescription"
                        params={{
                            url: (
                                <a
                                    href="https://docs.cpanel.net/manage2/groups/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <Translate content="externalIntegration.form.credentials.cPanel.groupIdLc" />
                                </a>
                            ),
                        }}
                    />
                )}
            />
        </>
    );

    return (
        <>
            <Form
                id="externalIntegrationForm"
                footerClassName="hidden"
                onSubmit={handleSubmit}
                onFieldChange={onFieldChange}
                values={submitValues}
                errors={formErrors}
                hideRequiredLegend={true}
                submitButton={false}
                cancelButton={false}
                applyButton={false}
                vertical={true}
            >
                <Section>
                    {responseError.code === HTTP_CODES.BAD_REQUEST && (
                        <StatusMessage intent={INTENT_TYPE.DANGER}>
                            {responseError.error}
                        </StatusMessage>
                    )}
                    <FormFieldText
                        size={SIZE.FILL}
                        name="name"
                        label={<Translate content="externalIntegration.form.name" />}
                        required={true}
                    />
                    <FormFieldSelect
                        size={SIZE.FILL}
                        name="type"
                        required={true}
                        disabled={!!item.id}
                        label={<Translate content="externalIntegration.form.type" />}
                    >
                        {externalIntegrationTypeOptions}
                    </FormFieldSelect>
                    {submitValues.type === ExternalIntegrationType.PLESK && renderPleskExternalIntegrationCredentials()}
                    {submitValues.type === ExternalIntegrationType.CPANEL && renderCPanelExternalIntegrationCredentials()}
                </Section>
            </Form>
            <Button
                type="submit"
                form="externalIntegrationForm"
                fill={true}
                intent={INTENT_TYPE.PRIMARY}
                size={SIZE.LG}
                isLoading={isItemSaving}
            >
                <Translate content="externalIntegration.form.saveBtn" />
            </Button>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    item: state.externalIntegration.item,
    isItemSaving: state.app.loadingFlags.has(LOADING_FLAGS.EXTERNAL_INTEGRATION_SAVE),
    formErrors: nestStringProperties(state),
    responseError: state.app.responseError,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    externalIntegrationActions: bindActionCreators(externalIntegrationActions, dispatch),
    formErrorsActions: bindActionCreators(formErrorsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExternalIntegrationForm);
