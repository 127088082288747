// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import styled from 'styled-components';
import {
    ItemList as UILibraryItemList,
    Section,
    Switch,
} from '@plesk/ui-library';

export const IpContainer = styled.div`
    padding: 10px 0;
    background: #F5F5F5;
    border-radius: 1px;
    display: flex;
    justify-content: center;
    font-weight: 600;
    font-size: 14px;
`;

export const NetworkingHeader = styled.div`
  display: flex !important;
  justify-content: space-between;
  width: 100%;
`;

export const AdditionalIpDialogText = styled.div`
  margin-top: 16px;
  margin-bottom: 34px;
`;

export const AdditionalIpDialogSection = styled(Section)`
  margin-top: -18px;
`;

export const AdditionalIpDialogSwitch = styled(Switch)`
  justify-content: left;
  display: flex;
  flex-direction: row-reverse;

  .pul-switch__label {
    margin-right: 0;
    margin-left: 5px;
  }
`;

export const ItemList = styled(UILibraryItemList)`
  /*
    when we extract <Item /> in separate component
    <ItemList /> adds extra margins idk why ¯\_(ツ)_/¯
  */
  margin-left: 0 !important;
  margin-right: 0 !important;
`;
