// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import { SubInputs } from 'admin/common/styles/Styles';
import { FormFieldNumber } from 'common/components/Form/FormFieldNumber/FormFieldNumber';
import { SIZE } from 'common/constants';
import {
    Column,
    Columns,
    FormFieldCheckbox,
    Section,
    Translate,
} from '@plesk/ui-library';
import { FORM } from 'admin/plan/constants/tests';
import { Unit } from 'common/helpers/units';
import PlanLimit from 'common/components/plan/components/PlanLimit';
import {
    IPlanBackupSettings,
    IPlanLimit,
} from 'common/api/resources/Plan';
import { StorageType } from 'common/api/resources/StorageType';
import { Tooltip } from 'common/components/index';
import { FIELDS } from 'common/components/plan/PlanForm';

export interface IAdditionalOffersSectionProps {
    is_backup_available: boolean;
    is_additional_ips_available: boolean;
    isIncrementalBackupsAvailable: boolean;
    storageType: StorageType;
    backupsNumber: IPlanLimit;
    backupSettings: IPlanBackupSettings;
    isBillingIntegrationEnabled: boolean;
    fields?: string[];
}

const AdditionalOffersSection: React.FC<IAdditionalOffersSectionProps> = ({
    is_backup_available,
    is_additional_ips_available,
    isIncrementalBackupsAvailable,
    storageType,
    backupsNumber,
    backupSettings,
    isBillingIntegrationEnabled,
    fields,
}) => {
    const additionalOffersFields = [
        FIELDS.IS_BACKUP_AVAILABLE,
        FIELDS.BACKUP_PRICE,
        FIELDS.BACKUP_LIMIT,
        FIELDS.INCREMENTAL_BACKUP,
        FIELDS.IS_ADDITIONAL_IP_AVAILABLE,
        FIELDS.ADDITIONAL_IP_TOKENS,
    ];

    if (!fields?.some(item => additionalOffersFields.includes(item))) {
        return null;
    }

    const isIncrementalBackupEnabled = is_backup_available && backupSettings.is_incremental_backup_enabled;
    const isIncrementalBackupCanBeEnabled = storageType === StorageType.THIN_LVM && backupsNumber.is_enabled && isIncrementalBackupsAvailable;

    return (
        <Section title={<Translate content="plan.actionDialog.additionalOffers" />}>
            {fields.includes(FIELDS.IS_BACKUP_AVAILABLE) && (
                <FormFieldCheckbox
                    name="is_backup_available"
                    label={<Translate content="plan.actionDialog.isBackupAvailable" />}
                    data-cy={FORM.BACKUP_AVAILABILITY}

                />
            )}
            <SubInputs>
                {isBillingIntegrationEnabled && fields.includes(FIELDS.BACKUP_PRICE) && (
                    <FormFieldNumber
                        name="backup_price"
                        label={<Translate content="plan.actionDialog.backupPrice" />}
                        fullDescription={<Translate content="plan.actionDialog.backupPriceDescription" />}
                        data-cy={FORM.BACKUP_PRICE}
                        disabled={!is_backup_available}
                        min={0}
                        max={100}
                    />
                )}
                {fields.includes(FIELDS.BACKUP_LIMIT) && (
                    <PlanLimit
                        data-cy={FORM.BACKUP_LIMITS.NUMBER}
                        limit={backupsNumber}
                        limitName="backups_number"
                        limitOnly={isIncrementalBackupEnabled}
                        limitOnlyTooltip={<Translate content="plan.actionDialog.backupLimitOnly" />}
                        units={Unit}
                        label={<Translate content="plan.actionDialog.limits.backupsNumber.label" />}
                        fullDescription={<Translate content="plan.actionDialog.limits.backupsNumber.description" />}
                        disabled={!is_backup_available}
                    />
                )}
                {isIncrementalBackupsAvailable && fields.includes(FIELDS.INCREMENTAL_BACKUP) &&(
                    <>
                        <Tooltip
                            shown={!isIncrementalBackupCanBeEnabled}
                            title={<Translate content="plan.actionDialog.incrementalBackupCantBeEnabled" />}
                        >
                            <FormFieldCheckbox
                                name="backup_settings[is_incremental_backup_enabled]"
                                label={<Translate content="plan.actionDialog.isIncrementalBackupEnabled" />}
                                disabled={!is_backup_available || !isIncrementalBackupCanBeEnabled}
                            />
                        </Tooltip>
                        <FormFieldNumber
                            label={<Translate content="plan.actionDialog.incrementalCount" />}
                            name={'backup_settings[incremental_backups_limit]'}
                            min={1}
                            disabled={!isIncrementalBackupEnabled}
                        />
                    </>
                )}
            </SubInputs>
            {fields.includes(FIELDS.IS_ADDITIONAL_IP_AVAILABLE) && (
                <FormFieldCheckbox
                    name="is_additional_ips_available"
                    label={<Translate content="plan.actionDialog.isAdditionalIpsAvailable"/>}
                    data-cy={FORM.ADDITIONAL_IP_AVAILABILITY}
                />
            )}
            {isBillingIntegrationEnabled && fields.includes(FIELDS.ADDITIONAL_IP_TOKENS) && (
                <SubInputs>
                    <Columns gap={SIZE.LG} vertical={false}>
                        <Column width={150}>
                            <FormFieldNumber
                                name="ip_tokens_per_hour"
                                label={<Translate content="plan.actionDialog.ipTokensPerHour" />}
                                fullDescription={<Translate content="plan.actionDialog.planTokenPerHourDescription" />}
                                vertical
                                min={0}
                                disabled={!is_additional_ips_available}
                            />
                        </Column>
                        <Column width={150}>
                            <FormFieldNumber
                                name="ip_tokens_per_month"
                                label={<Translate content="plan.actionDialog.ipTokensPerMonth" />}
                                fullDescription={<Translate content="plan.actionDialog.planTokenPerMonthDescription" />}
                                vertical
                                min={0}
                                disabled={!is_additional_ips_available}
                            />
                        </Column>
                    </Columns>
                </SubInputs>
            )}
        </Section>
    );
};

export default AdditionalOffersSection;
