// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import styled from 'styled-components';
import arrowUp from 'common/assets/icons/arrow_up.svg';
import arrowDown from 'common/assets/icons/arrow_down.svg';

export const StyledSelect = styled.div`
    display: inline-block;
    position: relative;

    select {
        appearance: none;
        height: 28px!important;
        background: #ececec url(${arrowDown}) no-repeat 97% 54%;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.28);
        border-radius: 2px;
        line-height: 20px;
        border: 0!important;

        &:active, :focus {
            background: #ccc url(${arrowUp}) no-repeat 97% 54%;
            border-color: unset;
        }

        option {
            background: #fff;
        }
    }
`;
