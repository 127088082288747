// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import { RootState } from 'admin/core/store';
import { connect } from 'react-redux';
import {
    ProgressStep,
    Translate,
} from '@plesk/ui-library';
import { SIZE } from 'common/constants';
import { Dialog } from 'admin/common/components/Dialog/Dialog';

interface IClusterImportStatusDialogProps {
    isOpen: boolean;
    handleClose: () => void;
}

export type ClusterImportStatusDialogProps =
    IClusterImportStatusDialogProps &
    ReturnType<typeof mapStateToProps>;

export const ClusterImportStatusDialog: React.FC<ClusterImportStatusDialogProps> = ({
    item,
    isOpen,
    handleClose,
}) => (
    <Dialog
        heading={<Translate content="clusterImport.list.failReason" params={{
            name: item.name,
        }}/>}
        closeHandler={handleClose}
        isOpen={isOpen}
        size={SIZE.XS}
    >
        <ProgressStep icon="gear" status="error">
            {item.fail_reason}
        </ProgressStep>
    </Dialog>
);


const mapStateToProps = (state: RootState) => ({
    item: state.clusterImport.item,
});

export default connect(mapStateToProps)(ClusterImportStatusDialog);