// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import { connect } from 'react-redux';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { ResizeTab } from 'common/components/ServerTabs/ResizeTab/ResizeTab';
import { RootState } from 'client/core/store';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import {
    getProjectServerPlans,
    loadPlansOnScroll,
} from 'common/modules/plan/actions';
import { isFeatureEnabled } from 'common/selectors';
import { FEATURE } from 'common/api/resources/Settings';
import { resizeComputeResourceVm } from 'common/modules/computeResourceVm/actions';

const mapStateToProps = (state: RootState) => ({
    hasNextPage: !!state.plan.list.links.next,
    plans: state.plan.list.data.filter(plan => {
        const { plan: serverPlan, specifications } = state.project.servers.item;

        return plan.id !== serverPlan.id && plan.params.disk >= specifications.disk;
    }),
    server: state.project.servers.item,
    isLoading: state.app.loadingFlags.has(LOADING_FLAGS.PLAN_LIST),
    isResizing: state.app.loadingFlags.has(LOADING_FLAGS.COMPUTE_RESOURCE_VM_RESIZE),
    hidePlanName: isFeatureEnabled(FEATURE.HIDE_PLAN_NAME, state),
    withPriceInfo: true,
    canManageBackups: false,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getPlans: bindActionCreators(getProjectServerPlans, dispatch),
    resize: bindActionCreators(resizeComputeResourceVm, dispatch),
    loadOnScroll: bindActionCreators(loadPlansOnScroll, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResizeTab);
