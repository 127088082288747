// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import { Icon } from '@plesk/ui-library';
import {
    Description,
    Title,
} from 'client/auth/components/AlertMessage/Styles';

export interface IAlertMessageProps {
    message: string | React.ReactNode;
    title: string | React.ReactNode;
}

export const AlertMessage: React.FC<IAlertMessageProps> = ({ title, message }) => (
    <div>
        <Title>
            <Icon
                style={{ color: '#D02D4B' }}
                size="24"
                name="exclamation-mark-circle-filled"
            />
            <span style={{ paddingLeft: '8px' }}>{title}</span>
        </Title>
        <Description>
            {message}
        </Description>
    </div>
);
