// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';
import {
    ADMIN,
    CLASS_COLUMN_FIXED_RIGHT,
} from 'common/styles/variables';
import { COLORS } from 'admin/core/theme';

export const GlobalStyle = createGlobalStyle`
    ${reset}
    body {
      background-color: #fff !important;
      font-family: Open Sans,sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
    }

    h1 {
        font-weight: 600!important;
        font-size: 32px!important;
        line-height: 40px!important;
        margin-bottom: 8px!important;
    }

    h3 {
        font-weight: 600!important;
        font-size: 20px!important;
        line-height: 24px!important;
        margin-bottom: 8px!important;
    }

    h4 {
        font-weight: 600;
        font-size: 20px;
        line-height: 26px;
        margin-bottom: 8px!important;
    }

    h5 {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 4px!important;
    }
    
    p {
        margin-bottom: 10px !important;
    }

    .pul-layout__main {
       position: relative;
       background: #fff !important;
    }

    #root {
        height: 100%;
    }

    /** UI library    */
    .toaster {
        z-index: 9999!important;
    }
    
    .pul-button {
        min-width: auto !important;
    }
    
    .hidden {
       display: none;
    }

    .pul-drawer__body {
        & > div {
            height: 100%;

            .pul-drawer__inner-body {
                padding: 0;
                height: 100%;
                form.pul-form {
                    padding: 20px 16px;
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    overflow: auto;
                }
            }
        }
    }

    .pul-layout__sidebar {
        /*
            Fix scroll bug in Safari.

            If we don't set this color we may got some artifacts when we scroll
            content in sidebar. For about one second we see parts with different
            colors.
         */
        background-color: ${ADMIN.SIDEBAR_COLOR} !important;
    }

    .pul-layout__sidebar-inner {
        /*
            Fix scroll bug in Safari.

            If we don't set this color we will see different color for part of
            sidebar after scrolling to bottom.
         */
        background-color: ${ADMIN.SIDEBAR_COLOR} !important;
    }
    
    .admin-menu .ReactCollapse--collapse:first-child {
        /*
            Fix scroll bug in Safari.
    
            If we are not setting the height to first child element,
            scrollbar does not appears
        */
        height: auto !important;
    }

    .pul-layout__header-inner {
        background-color: #36404C !important;
        border-bottom: 1px solid #36404C !important;
    }

    .pul-layout__sidebar-content {
        box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.05);
        /* Apply scrollbar styles for Firefox. */
        scrollbar-width: thin;

        &::-webkit-scrollbar {
            background-color: #ccc !important;
        }
        
        &::-webkit-scrollbar-thumb {
            background-color: #999 !important;
        }
    }

    .pul-layout__sidebar-content-inner {
        height: inherit;
        display: flex;
        flex-direction: column;
    }

    .cell-icons {
        padding: 6px 4px!important;
    }

    .cell-bold {
        font-weight: 600;
    }

    .cell-default {
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;

        span {
            vertical-align: -3px;
            margin-left: 5px;
        }
    }

    .pul-form-field__error {
        display: flex!important;
        align-items: center;
    }

    .pul-form-field--error > .pul-section-item__value div {
        & > .checkmark {
            & + div {
                .policy-text {
                    color: #D00000;
                }
            }
            #border {
                stroke: #D02D4B;
            }
        }
    }
    
    .pul-form-field__error-icon > svg {
        width: 12px;
    }

    .pul-popover {
        background: #FFFFFF;
        box-shadow: 0 0 8px rgba(34, 34, 34, 0.33);
        border-radius: 2px;
        padding: 5px;

        .pul-popover__close {
            .pul-icon {
                color: #737373!important;
            }
        }

        .pul-popover__content {
            font-size: 14px;
            line-height: 20px;
            color: #222;

            span {
                white-space: pre-line;
            }
        }
        
        .pul-popover__content-inner {
            min-width: 280px;
            padding: 12px 16px 12px;
            padding-right: 12px !important;
        }
     
        /* 
          Override info intent for popovers, cause 
          we don't have blue intent in our palette 
        */
        &--info {
          background-color: #ffffff !important;
        }
        
        &__arrow{
           box-shadow: none !important;
        
           &::after {
              background-color: #ffffff !important;
           }
        }
    }
    
    div.pul-section-item__title span:first-child {
        color: #737373;
        font-size: 12px;
        line-height: 16px;
    }
    
    .pul-toolbar {
       margin-bottom: 0 !important;
    }

    .pul-toolbar__group-item {
        &:not(:last-child) {
            margin-right: 10px!important;
        }
    }
    
    .pul-extended-status-message__container {
      display: none;
    }

    
    .pul-layout__main-inner {
        padding: 12px 40px 40px !important;
        display: flex;
        flex-direction: column;
    }

    @media (max-width: 480px) {
        .pul-layout__main-inner {
            padding: 12px 20px 20px !important;
        }
    }
    
    .pul-list__table-thead {
       color: #737373;
       font-size: 12px;
    }
    
    .pul-list th.${CLASS_COLUMN_FIXED_RIGHT} {
        background-color: ${COLORS.WHITE};
    }

    /*
      Customize UI library <Dropdown> styles.
    */
    .pul-dropdown__menu {
        padding-top: 6px !important;

        .pul-menu__base-item {
            line-height: 24px!important;

            &:hover {
                background-color: rgba(34, 34, 34, .05) !important;
            }
        }

        .pul-menu__base-item-icon {
            padding-right: 4px;
        }

        .pul-menu__base-item-content {
            color: ${COLORS.GREY_100}!important;
            font-size: 12px!important;
        }
    }

    .pul-label--inactive {
        color: ${COLORS.GREY_100}!important;
        background-color: ${COLORS.GREY_40}!important;
    }

    .pul-badge__value--dot.pul-label {
        top: 2px!important;
        right: 2px!important;
    }

    .pul-input__input {
        box-shadow: unset!important;
    }

    .pul-progress-step-progress-bar {
        background-color: ${COLORS.PRIMARY};
    }

    /* Specific styles for Language dropdown selector. */
    .dropdown-language-menu {
        max-height: 335px; /* Enough to show 10 languages without scrollbars. */
        overflow-y: auto;
    }
`;
