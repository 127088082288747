// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import {
    Tab,
    TabList,
    TabPanel,
    Tabs,
} from 'react-tabs';
import { Translate } from '@plesk/ui-library';
import { TabsHeader } from 'admin/computeResource/page/components/PageTabs/Styles';
import { INTENT_TYPE } from 'common/constants';
import { Button } from 'admin/common/components/Button/Button';
import {
    COMPUTE_RESOURCE,
    TABS,
} from 'admin/computeResource/constants/tests';

export interface IPageTabProps {
    hasComputeResourceVms: boolean;

    computeResourceVmTabContent: React.ReactNode;
    storageTabContent: React.ReactNode;
    tasksTabContent: React.ReactNode;
    usageAndLimitsTabContent: React.ReactNode;

    onComputeResourceVmButtonClick: () => void;
    onStorageButtonClick: () => void;
}

interface ITabButtonState {
    title?: React.ReactNode;
    icon?: string;
    shown: boolean;
    onClick?: () => void;
}

const COMPUTE_RESOURCE_VM_TAB = 0;
const STORAGE_TAB = 1;

const PageTabs: React.FC<IPageTabProps> = ({
    hasComputeResourceVms,
    computeResourceVmTabContent,
    storageTabContent,
    tasksTabContent,
    usageAndLimitsTabContent,
    onComputeResourceVmButtonClick,
    onStorageButtonClick,
}) => {
    const [selectedTab, setSelectedTab] = React.useState(0);

    const tabBtn = (() => {
        let state: ITabButtonState = {
            shown: false,
        };

        switch (selectedTab) {
        case COMPUTE_RESOURCE_VM_TAB:
            state = {
                title: <Translate content="computeResource.servers.addBtn" />,
                icon: 'screen',
                onClick: onComputeResourceVmButtonClick,
                shown: hasComputeResourceVms,
            };
            break;

        case STORAGE_TAB:
            state = {
                title: <Translate content="computeResource.storages.addBtn" />,
                icon: 'storage',
                onClick: onStorageButtonClick,
                shown: true,
            };
            break;
        }

        return state;
    })();

    const handleTabSelect = (idx: number) => { setSelectedTab(idx); };

    return (
        <Tabs
            selectedIndex={selectedTab}
            onSelect={handleTabSelect}
        >
            <TabsHeader>
                <TabList>
                    <Tab data-cy={TABS.SERVERS}><Translate content="computeResource.page.tabs.servers" /></Tab>
                    <Tab data-cy={TABS.STORAGES}><Translate content="computeResource.page.tabs.storages" /></Tab>
                    <Tab data-cy={TABS.TASKS}><Translate content="computeResource.page.tabs.tasks" /></Tab>
                    <Tab data-cy={TABS.USAGE}><Translate content="computeResource.page.tabs.usageAndLimits" /></Tab>
                </TabList>
                <div>
                    {tabBtn.shown &&
                        <Button
                            onClick={tabBtn.onClick}
                            size="lg"
                            intent={INTENT_TYPE.PRIMARY}
                            icon={tabBtn.icon}
                            data-cy={COMPUTE_RESOURCE.ADD}
                        >
                            {tabBtn.title}
                        </Button>
                    }
                </div>
            </TabsHeader>

            <TabPanel>
                {computeResourceVmTabContent}
            </TabPanel>
            <TabPanel>
                {storageTabContent}
            </TabPanel>
            <TabPanel>
                {tasksTabContent}
            </TabPanel>
            <TabPanel>
                {usageAndLimitsTabContent}
            </TabPanel>
        </Tabs>
    );
};

export default PageTabs;
