// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'admin/core/store';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import isEmpty from 'validator/lib/isEmpty';
import * as formErrorsActions from 'common/modules/app/formErrors/actions';
import * as sshKeyActions from 'common/modules/sshKey/actions';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { validate } from 'common/validator';
import {
    Form,
    FormFieldText,
    Section,
    Translate,
} from '@plesk/ui-library';
import { ISshKeyRequest } from 'common/api/resources/SshKey';
import { INTENT_TYPE } from 'common/constants';
import { Button } from 'admin/common/components/Button/Button';
import { DialogHeadingContainer } from 'admin/sshKey/containers/styles';
import SSHGenerateButton from 'common/components/SSHGenerateButton/SSHGenerateButton';
import { Dialog } from 'admin/common/components/Dialog/Dialog';
import { FixedMultilineFormText } from 'common/components/styles/FixedMultilineFromText';
import { nestStringProperties } from 'common/modules/app/formErrors/selectors';

interface ISshKeyDialogProps {
    userEmail: string;
    isOpened: boolean;
    onCreated: () => void;
    onClose: () => void;
}

export type SshKeyDialogProps =
    ISshKeyDialogProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

const defaultValues: ISshKeyRequest = Object.freeze({
    name: '',
    body: '',
});

export const SshKeyDialog: React.FC<SshKeyDialogProps> = ({
    userEmail,
    isOpened,
    onCreated,
    onClose,
    isItemCreating,
    formErrors,
    sshKeyActions: {
        createSshKey,
    },
    formErrorsActions: {
        setFormErrors,
        clearFormErrors,
    },
}) => {
    const [submitValues, setSubmitValues] = React.useState({ ...defaultValues });

    React.useEffect(() => () => {
        clearFormErrors();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onBodyChanges = (val: string) => {
        const match = val.match(/[^@<\s]+@[^@\s>]+/g);

        setSubmitValues({ body: val, name: match && match.length ? match[0] : submitValues.name });
    };

    const handleCreate = async (values: ISshKeyRequest) => {
        const errors = validate<ISshKeyRequest>(values, {
            name: {
                validator: isEmpty,
                message: <Translate content="validate.fieldRequired" />,
                comparison: true,
            },
            body: {
                validator: isEmpty,
                message: <Translate content="validate.fieldRequired" />,
                comparison: true,
            },
        });

        if (Object.keys(errors).length) {
            setFormErrors(errors);
            return;
        }

        try {
            await createSshKey(values);
            setSubmitValues({ ...defaultValues });
            onCreated();
        } catch (e) {
            throw e;
        }
    };

    const handleClose = () => {
        setSubmitValues({ ...defaultValues });
        onClose();
    };

    const handleSSHKeyGenerateSuccess = (publicKey: string) => {
        const match = publicKey.match(/[^@<\s]+@[^@\s>]+/g);

        setSubmitValues({ body: publicKey, name: match && match.length ? match[0] : submitValues.name });
    };

    return (
        <Dialog
            heading={(
                <DialogHeadingContainer>
                    <Translate content="sshKey.form.titleAdd"/>
                    <SSHGenerateButton
                        tooltip={<Translate content="sshKey.form.generate.tooltip"/>}
                        email={userEmail}
                        onSuccessfulGenerate={handleSSHKeyGenerateSuccess}
                    >
                        <Translate content="sshKey.form.generate.title"/>
                    </SSHGenerateButton>
                </DialogHeadingContainer>
            )}
            closeHandler={handleClose}
            isOpen={isOpened}
            size="xs"
        >
            <Form
                id="sshKeyForm"
                footerClassName="hidden"
                onSubmit={handleCreate}
                values={submitValues}
                errors={formErrors}
                hideRequiredLegend={true}
                submitButton={false}
                cancelButton={false}
                applyButton={false}
                vertical={true}
            >
                <Section>
                    <FixedMultilineFormText height="250px">
                        <FormFieldText
                            onChange={onBodyChanges}
                            spellCheck={false}
                            size="fill"
                            name="body"
                            label={<Translate content="sshKey.form.body" />}
                            required={true}
                            multiline={true}
                        />
                    </FixedMultilineFormText>
                    <FormFieldText
                        name="name"
                        size="fill"
                        label={<Translate content="sshKey.form.name" />}
                        required={true}
                    />
                </Section>
            </Form>
            <Button
                type="submit"
                form="sshKeyForm"
                fill={true}
                intent={INTENT_TYPE.PRIMARY}
                size="lg"
                isLoading={isItemCreating}
            >
                <Translate content="sshKey.form.saveBtn" />
            </Button>
        </Dialog>
    );
};

const mapStateToProps = (state: RootState) => ({
    formErrors: nestStringProperties(state),
    isItemCreating: state.app.loadingFlags.has(LOADING_FLAGS.SAVE_SSH_KEY_ITEM),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    sshKeyActions: bindActionCreators(sshKeyActions, dispatch),
    formErrorsActions: bindActionCreators(formErrorsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SshKeyDialog);
