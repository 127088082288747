// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import { StyledCardList } from 'client/common/components/Card/Styles';
import {
    Grid as UIGrid,
    Pagination,
} from '@plesk/ui-library';
import ListHeader from 'common/components/List/ListHeader';
import {
    IMeta,
    IPaginateApiResponse,
} from 'common/api/resources/Response';
import {
    AxiosResponse,
    CancelTokenSource,
} from 'axios';

export interface IPaginatedCardListProps<T> {
    isLoading: boolean;
    items: T[];
    meta: IMeta;
    component: (item: T, key: number) => JSX.Element;
    loadPaginated: (page: number, cancelToken?: CancelTokenSource) => Promise<AxiosResponse<IPaginateApiResponse<T[]>>>;
}

const Grid: React.FC<React.Props<UIGrid>> = ({ children }) => (
    <UIGrid minColumnWidth={380}  gap="lg" style={{ paddingTop: 5, paddingLeft: 3 }}>
        {children}
    </UIGrid>
);

export function PaginatedCardList<T>(props: IPaginatedCardListProps<T>) {
    const {
        isLoading,
        items,
        meta,
        component,
        loadPaginated,
    } = props;

    return (
        <StyledCardList>
            <ListHeader
                meta={meta}
                loadItems={loadPaginated}
                isLoading={isLoading}
            />
            <Grid>
                {items.map(component)}
            </Grid>
            {meta.last_page > 1 && (
                <Pagination
                    loading={isLoading}
                    current={meta.current_page}
                    total={meta.last_page}
                    onSelect={loadPaginated}
                />
            )}
        </StyledCardList>
    );
}
