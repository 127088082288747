// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import {
    Container,
    Title,
} from 'common/modules/auth/components/LogoBox/Styles';
import { RootState } from 'client/core/store';
import {
    RouteComponentProps,
    withRouter,
} from 'react-router';
import { connect } from 'react-redux';
import { isAdminSite } from 'common/helpers/core';
import { ReactComponent as Logo } from 'common/assets/solusvm2.svg';

export type LogoBoxProps =
    RouteComponentProps &
    ReturnType<typeof mapStateToProps>;

export const LogoBox: React.FC<LogoBoxProps> = ({ settings }) => {
    if (isAdminSite()) {
        return (
            <Container>
                <Logo />
            </Container>
        );
    }

    if (settings.theme.logo) {
        return (
            <Container>
                <img src={settings.theme.logo} alt="" />
            </Container>
        );
    }

    return (
        <Container>
            <Title>
                {settings.theme.brand_name}
            </Title>
        </Container>
    );
};

const mapStateToProps = (state: RootState) => ({
    settings: state.settings,
});

export default withRouter(connect(mapStateToProps)(LogoBox));
