// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import styled from 'styled-components';
import { COLORS } from 'common/theme';

interface ICardItemProps {
    isSelected?: boolean;
    isDisabled?: boolean;
}

export const CardItem = styled.div<ICardItemProps>`
    display: flex;
    flex-basis: 190px;
    box-sizing: border-box;
    background: ${props => !props.isSelected ? !props.isDisabled ? COLORS.WHITE_0 : COLORS.GREY_20 : props.theme.primary_color};
    border: 1px solid ${props => !props.isDisabled ? COLORS.GREY_40 : COLORS.GREY_30};
    border-radius: 2px;
    cursor: pointer;
    user-select: none;

    &:hover {
        background: ${(props) =>  !props.isSelected ? COLORS.GREY_20 : props.theme.primary_color};
    }

    ${props => props.isSelected && `
        *:not(ul *) {
            color: ${COLORS.WHITE_0};
        }
    `}
    ${props => props.isDisabled && `
        *:not(ul *) {
            color: ${COLORS.GREY_40};
        }
        img {
            opacity: 0.3;
        }
    `}
`;
