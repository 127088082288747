// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import { createCustomAction } from 'typesafe-actions';
import * as types from 'admin/license/solusIO/constants/types';
import {
    ILicenseActivateRequest,
    ILicenseResponse,
    license,
} from 'common/api/resources/License/solusIO';
import { Dispatch } from 'redux';
import {
    setIsLoading,
    unsetIsLoading,
} from 'common/modules/app/loadingFlags/actions';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { bakeForegroundToast } from 'common/modules/app/toaster/actions';
import { INTENT_TYPE } from 'common/constants';
import { HTTP_CODES } from 'common/api/constants';
import { setLicenseIsActive } from 'common/modules/app/meta/actions';
import { CancelTokenSource } from 'axios';

export const setLicenseExist = createCustomAction(
    types.SET_LICENSE_EXIST,
    (exist: boolean) => ({ payload: exist })
);
export const setLicenseItem = createCustomAction(
    types.SET_LICENSE_ITEM,
    (data: ILicenseResponse) => ({ payload: data })
);

export const getLicense = (cancelToken?: CancelTokenSource) => async(dispatch: Dispatch) =>  {
    dispatch(setIsLoading(LOADING_FLAGS.LICENSE_ITEM));

    try {
        const result = await license.item(cancelToken);

        if (result.status === HTTP_CODES.OK) {
            dispatch(setLicenseExist(true));
            dispatch(setLicenseItem(result.data.data));
        }

        return result;
    } catch (e) {
        if (e.response?.status === HTTP_CODES.NOT_FOUND) {
            return;
        }

        throw e;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.LICENSE_ITEM));
    }
};

export const activateLicense = (data: ILicenseActivateRequest) => async(dispatch: Dispatch) =>  {
    dispatch(setIsLoading(LOADING_FLAGS.LICENSE_ACTIVATION));

    try {
        const result = await license.activate(data);

        if (result.status === HTTP_CODES.OK) {
            dispatch(setLicenseExist(true));
            dispatch(setLicenseItem(result.data.data));
            dispatch(setLicenseIsActive(true));
            bakeForegroundToast(INTENT_TYPE.SUCCESS, 'license.toasts.activationSuccess')(dispatch);
        }

        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.LICENSE_ACTIVATION));
    }
};

export const refreshLicense = () => async(dispatch: Dispatch) =>  {
    dispatch(setIsLoading(LOADING_FLAGS.LICENSE_REFRESH));

    try {
        const result = await license.refresh();

        if (result.status === HTTP_CODES.OK) {
            dispatch(setLicenseExist(true));
            dispatch(setLicenseIsActive(true));
            dispatch(setLicenseItem(result.data.data));
            bakeForegroundToast(INTENT_TYPE.SUCCESS, 'license.toasts.refreshSuccess')(dispatch);
        }

        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.LICENSE_REFRESH));
    }
};
