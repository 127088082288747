// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import { RootState } from 'client/core/store';
import { connect } from 'react-redux';
import {
    AdditionalPrice,
    PriceInfo,
    PriceItem,
    PriceTaxes,
    TermsAndConditions,
    TotalPrice,
} from 'client/common/components/PlanPrice/Styles';
import {
    Link,
    Translate,
} from '@plesk/ui-library';

export interface IPlanPriceProps {
    planId?: number;
    isBackupEnabled: boolean;
    hasAdditionalIps: boolean;
}

export type PlanPriceProps =
    IPlanPriceProps &
    ReturnType<typeof mapStateToProps>;

export const PlanPrice: React.FC<PlanPriceProps> = ({
    planId,
    plans,
    termsAndConditionsUrl,
    isBackupEnabled,
    hasAdditionalIps,
}) => {
    const plan = plans.data.find(item => item.id === planId);

    if (!plan?.price) {
        return (<></>);
    }

    const taxesText = plan.price.taxes_inclusive ? 'plan.price.taxesInclusive' : 'plan.price.taxesExclusive';

    const additionalPrice = (
        <>
            | <PriceItem>
                <span>
                    {plan.price.per_month}
                </span>
                <Translate content="plan.price.server"/>
            </PriceItem>
            {isBackupEnabled && <>
                + <PriceItem>
                    <span>
                        {plan.price.backup_price}
                    </span>
                    <Translate content="plan.price.backup"/>
                </PriceItem>
            </>}
            {hasAdditionalIps && plan.is_additional_ips_available && <>
                + <PriceItem>
                    <span>
                        {plan.price.additional_ips_per_month}
                    </span>
                    <Translate content="plan.price.additionalIps"/>
                </PriceItem>
            </>}
        </>
    );

    return (
        <PriceInfo>
            <TotalPrice>
                <Translate content="plan.price.total" params={{
                    price: isBackupEnabled ? plan.price.total_price : plan.price.total_price_without_backups,
                }}/><AdditionalPrice>{additionalPrice}</AdditionalPrice>
            </TotalPrice>
            {plan.price.taxes.length > 0 && <PriceTaxes>
                <Translate content={taxesText} params={{
                    taxes: plan.price.taxes.map(tax => tax.rate + '% ' + tax.label).join(', '),
                }}/>
            </PriceTaxes>}
            {termsAndConditionsUrl && <TermsAndConditions>
                <Translate content="plan.price.termsAndConditions.text" params={{
                    link: <Link href={termsAndConditionsUrl} target="blank">
                        <Translate content="plan.price.termsAndConditions.link"/>
                    </Link>,
                }}/>
            </TermsAndConditions>}
        </PriceInfo>
    );
};

const mapStateToProps = (state: RootState) => ({
    plans: state.plan.list,
    termsAndConditionsUrl: state.settings.theme.terms_and_conditions_url,
});

export default connect(mapStateToProps)(PlanPrice);