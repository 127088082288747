// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { RootState } from 'admin/core/store';
import * as licenseActions from 'admin/license/solusIO/actions';
import { PageHeader } from 'admin/common/components/PageHeader/PageHeader';
import { EmptyView } from 'common/components/EmptyView/EmptyView';
import { Dialog } from 'admin/common/components/Dialog/Dialog';
import LicenseForm from 'admin/license/solusIO/containers/LicenseForm';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { Loader } from 'common/components';
import { Button } from 'admin/common/components/Button/Button';
import { ICONS } from 'common/constants';
import moment,
{ MomentInput } from 'moment';
import {
    StatusWrapper,
    Table,
} from 'admin/license/solusIO/containers/SolusIOLicense/Styles';
import {
    ExtendedStatusMessageActions,
    Status,
    Translate,
} from '@plesk/ui-library';
import CopyText from 'common/containers/CopyText/CopyText';
import Usage from 'admin/common/components/Usage/Usage';

export type SolusIOLicenseProps =
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const SolusIOLicense: React.FC<SolusIOLicenseProps> = ({
    license: { item, exist },
    licenseActions: { refreshLicense, getLicense },
    loadingFlags: { isLicenseLoading, isLicenseRefreshing },
}) => {
    React.useEffect(() => {
        if (!exist) {
            getLicense();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [dialogOpened, setDialogOpened] = React.useState(false);

    const handleClose = () => setDialogOpened(false);
    const handleActivate = () => setDialogOpened(true);
    const convertDate = (date: MomentInput) => moment(date).format('DD-MM-YYYY');

    const licenseStatus = () => {
        if (!exist) {
            return null;
        }

        if (item.is_active) {
            return (
                <Status intent="success">
                    <Translate content="license.statuses.active"/>
                </Status>
            );
        }

        return (
            <Status intent="danger">
                <Translate content="license.statuses.expired"/>
            </Status>
        );
    };

    const progress = item.cpu_cores ? (100 * item.cpu_cores_in_use) / item.cpu_cores : 0;

    return (
        <>
            <PageHeader
                isButtonShown={exist}
                title={
                    <StatusWrapper>
                        <Translate content="license.title"/>
                        {licenseStatus()}
                    </StatusWrapper>
                }
                buttonText="license.activateBtn"
                buttonIcon={ICONS.CARD_RIBBON}
                onButtonClick={handleActivate}
            />
            <Loader isLoading={isLicenseLoading}>
                {!exist && (
                    <EmptyView
                        title="license.emptyView.title"
                        description="license.emptyView.description"
                        buttonText="license.emptyView.buttonText"
                        onButtonClick={handleActivate}
                        icon={ICONS.CARD_RIBBON}
                    />
                )}
                {exist && (
                    <div>
                        <Table>
                            <tbody>
                                <tr>
                                    <td><Translate content="license.cpuCores"/></td>
                                    <td>
                                        <Usage
                                            progress={progress}
                                            title={
                                                <Translate
                                                    content="license.cpuCoresProgress"
                                                    params={{
                                                        current: item.cpu_cores_in_use,
                                                        available: item.cpu_cores,
                                                    }}
                                                />
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Translate content="license.key"/>
                                    </td>
                                    <td>
                                        <CopyText>{item.key}</CopyText>
                                    </td>
                                </tr>
                                <tr>
                                    <td><Translate content="license.expirationDate"/></td>
                                    <td>{convertDate(item.expiration_date)}</td>
                                </tr>
                                <tr>
                                    <td><Translate content="license.nextUpdateDate"/></td>
                                    <td>{convertDate(item.update_date)}</td>
                                </tr>
                            </tbody>
                        </Table>
                        <ExtendedStatusMessageActions>
                            <Button
                                isLoading={isLicenseRefreshing}
                                icon={ICONS.REFRESH}
                                onClick={refreshLicense}
                            >
                                <Translate content="license.refreshBtn" />
                            </Button>
                        </ExtendedStatusMessageActions>
                    </div>
                )}
            </Loader>
            <Dialog
                heading={<Translate content="license.form.title" />}
                closeHandler={handleClose}
                isOpen={dialogOpened}
                size="xs"
            >
                <LicenseForm onSubmit={handleClose} />
            </Dialog>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    license: state.solusIOLicense,
    loadingFlags: {
        isLicenseLoading: state.app.loadingFlags.has(LOADING_FLAGS.LICENSE_ITEM),
        isLicenseRefreshing: state.app.loadingFlags.has(LOADING_FLAGS.LICENSE_REFRESH),
    },
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    licenseActions: bindActionCreators(licenseActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SolusIOLicense);
