// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import styled from 'styled-components';
import { COLORS } from 'admin/core/theme';

export const Title = styled.div`
    flex: 1;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 24px;
    color: ${COLORS.GREY_100};
`;

export const Footer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 20px 40px;
    margin-left: -40px;
    position: fixed;
    bottom: 0;
    width: 100%;
    background: ${COLORS.WHITE};
    box-shadow: 0 -2px 4px rgba(34, 34, 34, 0.15);
    z-index: 10;
    
    
    @media (max-width: 560px) {
      margin-left: -16px;
    }
`;

export const FooterHelp = styled.div`
  margin-left: 20px;
`;
