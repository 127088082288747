// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import {
    ISelectOption,
    Loader,
} from 'common/components';
import { RootState } from 'admin/core/store';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { connect } from 'react-redux';
import { hasPermission } from 'common/modules/permission/selectors';
import { PERMISSION_LIST } from 'common/modules/permission/constants';
import {
    Form,
    FormField,
    FormFieldText,
    Section,
    setIn,
    Translate,
} from '@plesk/ui-library';
import {
    INTENT_TYPE,
    SIZE,
} from 'common/constants';
import { Button } from 'admin/common/components/Button/Button';
import AsyncSelectInput from 'common/components/Select/AsyncSelectInput';
import {
    IUserRequest,
    IUserResponse,
    UserStatus,
} from 'common/api/resources/User';
import {
    limitGroupToOption,
    loadLimitGroupsOptions,
    loadRolesOptions,
    rolesToArrayOfIds,
    roleToOption,
} from 'admin/user/components/helpers';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import * as formErrorsActions from 'common/modules/app/formErrors/actions';
import * as userActions from 'admin/user/actions';
import {
    requiredRule,
    validate,
} from 'common/validator';
import FormFieldPassword from 'common/components/Form/FormFieldPassword/FormFieldPassword';
import FormFieldTags from 'common/components/Form/FormFieldTags/FormFieldTags';
import { nestStringProperties } from 'common/modules/app/formErrors/selectors';
import SelectInput from 'common/components/Select/SelectInput';
import { statusMappings } from 'admin/user/constants/translation';
import LanguageSelector from 'common/containers/LanguageSelector/LanguageSelector';
import { BillingType } from 'common/api/resources/Settings';
import { IFormProps } from 'common/components/Form/types';
import { FormFieldEmail } from 'common/components/Form/FormFieldEmail/FormFieldEmail';

interface IUserFormProps {
    onSubmit: () => void;
}

export type UserFormProps =
    IUserFormProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

const convertUserToRequest = (user: IUserResponse): IUserRequest => ({
    email: user.email,
    status: user.status,
    language_id: user.language.id,
    password: '',
    limit_group_id: user.limit_group ? user.limit_group.id : null,
    roles: rolesToArrayOfIds(user.roles),
    billing_user_id: user.billing_user_id,
    billing_token: user.billing_token,
    allowed_ips: user.allowed_ips,
});

const statusOptions: ISelectOption[] = Object.values(UserStatus).map(status => ({
    label: statusMappings[status],
    value: status,
}));

export const UserForm: React.FC<UserFormProps> = props => {
    const {
        onSubmit,
        user,
        formErrors,
        languages,
        isBillingIntegrationEnabled,
        formErrorsActions: { setFormErrors, clearFormErrors },
        permissions: {
            hasRolePermission,
            hasLimitGroupsPermission,
        },
        loadingFlags: {
            isSaving,
            isLoadingItem,
        },
        userActions: { createUser, updateUser },
    } = props;
    const [formValues, setFormValues] = React.useState(convertUserToRequest(user));
    const [selectedStatus, setSelectedStatus] = React.useState<ISelectOption | undefined>();
    const [selectedRoles, setSelectedRoles] = React.useState<ISelectOption[]>([]);
    const [selectedLimitGroup, setSelectedLimitGroup] = React.useState<ISelectOption | undefined>();

    React.useEffect(() => {
        clearFormErrors();
        setFormValues(convertUserToRequest(user));
        setSelectedRoles(user.roles.map(roleToOption));
        setSelectedStatus(statusOptions.find(option => option.value === user.status));
        if (user.limit_group) {
            setSelectedLimitGroup(limitGroupToOption(user.limit_group));
        } else {
            setSelectedLimitGroup(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleStatusChange = (option: any) => {
        setFormValues((state) => ({
            ...state,
            status: option ? option.value : null,
        }));
        setSelectedStatus(option);
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleRoleChange = (options: any) => {
        setFormValues((state) => ({
            ...state,
            roles: options !== null ? options.map((option: ISelectOption) => option.value) : [],
        }));
        setSelectedRoles(options !== null ? [...options] : []);
    };

    const handleLanguageChange = (lang: number) => setFormValues(values => ({ ...values, language_id: lang }));

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleLimitGroupChange = (option: any) => {
        setFormValues((state) => ({
            ...state,
            limit_group_id: option ? option.value : null,
        }));
        setSelectedLimitGroup(option);
    };

    const handleSubmit = async (newValues: IUserRequest) => {
        const errors = validate<IUserRequest>(newValues, {
            email: requiredRule(<Translate content="validate.fieldRequired" />),
            status: requiredRule(<Translate content="validate.fieldRequired" />),
        });

        if (Object.keys(errors).length) {
            setFormErrors(errors);
            return;
        }

        if (!user.id) {
            await createUser(newValues);
        } else {
            if (!newValues?.password?.length) {
                delete newValues.password;
            }

            await updateUser(user.id, newValues);
        }

        clearFormErrors();
        onSubmit();
    };

    const handleFieldChange = (field: string, value: string) => {
        setFormValues(setIn(formValues, field, value));
    };

    const renderBillingFields = () => {
        if (isBillingIntegrationEnabled) {
            return (
                <>
                    <FormFieldText
                        size="fill"
                        name="billing_user_id"
                        label={<Translate content="user.form.billingUserId" />}
                    />
                    <FormFieldText
                        size="fill"
                        name="billing_token"
                        label={<Translate content="user.form.billingToken" />}
                    />
                </>
            );
        }

        return null;
    };

    return (
        <Loader isLoading={isLoadingItem}>
            <Form
                id="userForm"
                footerClassName="hidden"
                onSubmit={handleSubmit}
                onFieldChange={handleFieldChange}
                values={formValues}
                errors={formErrors}
                hideRequiredLegend={true}
                submitButton={false}
                cancelButton={false}
                applyButton={false}
                vertical={true}
            >
                <Section>
                    <FormFieldEmail
                        size={SIZE.FILL}
                        name="email"
                        label={<Translate content="user.form.email" />}
                        required={true}
                    />
                    <FormFieldPassword
                        size={SIZE.FILL}
                        name="password"
                        label={<Translate content="user.form.password"/>}
                        description={<Translate content="user.form.passwordDescription"/>}
                        required={false}
                    />
                    <FormField
                        size={SIZE.XL}
                        name="status"
                        required={true}
                        label={<Translate content="user.form.status"/>}
                    >
                        {({ getId }: IFormProps<unknown>) => (
                            <SelectInput<ISelectOption>
                                inputId={getId()}
                                value={selectedStatus}
                                options={statusOptions}
                                onChange={handleStatusChange}
                                menuPosition="fixed"
                            />
                        )}
                    </FormField>
                    {languages.length > 1 && (
                        <FormField
                            name="language_id"
                            label={<Translate content="user.form.language"/>}
                        >
                            {({ getId }: IFormProps<unknown>) => (
                                <LanguageSelector
                                    languages={languages}
                                    inputId={getId()}
                                    selected={user.language}
                                    onChange={handleLanguageChange}
                                />
                            )}
                        </FormField>
                    )}
                    {renderBillingFields()}
                    {hasRolePermission && (
                        <FormField
                            name="roles"
                            label={<Translate content="user.form.roles" />}
                        >
                            {({ getId }: IFormProps<unknown>) => (
                                <AsyncSelectInput
                                    inputId={getId()}
                                    value={selectedRoles}
                                    isMulti={true}
                                    loadOptions={loadRolesOptions}
                                    onChange={handleRoleChange}
                                    debounceTimeout={1000}
                                    additional={{ page: 1 }}
                                    menuPosition="fixed"
                                />
                            )}
                        </FormField>
                    )}
                    {hasLimitGroupsPermission && (
                        <FormField
                            name="limit_group_id"
                            label={<Translate content="user.form.limitGroup" />}
                        >
                            {({ getId }: IFormProps<unknown>) => (
                                <AsyncSelectInput
                                    inputId={getId()}
                                    value={selectedLimitGroup}
                                    placeholder={<Translate content="user.form.limitGroupUnlimited" />}
                                    loadOptions={loadLimitGroupsOptions}
                                    onChange={handleLimitGroupChange}
                                    debounceTimeout={1000}
                                    additional={{ page: 1 }}
                                    menuPosition="fixed"
                                    isClearable={true}
                                />
                            )}
                        </FormField>
                    )}
                    <FormFieldTags
                        vertical
                        size={SIZE.FILL}
                        name="allowed_ips"
                        label={<Translate content="user.form.allowedIPs" />}
                        delimiters={[',', 'Enter', ' ']}
                    />
                </Section>
            </Form>
            <Button
                type="submit"
                form="userForm"
                fill={true}
                intent={INTENT_TYPE.PRIMARY}
                size="lg"
                isLoading={isSaving}
            >
                <Translate content="user.form.saveBtn" />
            </Button>
        </Loader>
    );
};


const mapStateToProps = (state: RootState) => ({
    isBillingIntegrationEnabled: state.settings.billing_integration.type !== BillingType.NULL,
    permissions: {
        hasRolePermission: hasPermission(state, PERMISSION_LIST.MANAGE_ROLES),
        hasLimitGroupsPermission: hasPermission(state, PERMISSION_LIST.MANAGE_LIMIT_GROUPS),
    },
    user: state.user.item,
    languages: state.language.list.data,
    formErrors: nestStringProperties(state),
    loadingFlags: {
        isLoadingItem: state.app.loadingFlags.has(LOADING_FLAGS.USER_ITEM),
        isSaving: state.app.loadingFlags.has(LOADING_FLAGS.SAVE_USER_ITEM),
    },
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    userActions: bindActionCreators(userActions, dispatch),
    formErrorsActions: bindActionCreators(formErrorsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserForm);
