// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import React from 'react';
import { INotificationResponse } from 'common/api/resources/Notification';
import {
    Icon,
    Translate,
} from '@plesk/ui-library';
import NotificationItem from 'admin/notification/containers/Items/NotificationItem';
import moment from 'moment';

export interface IVersionUpdatedItemProps {
    item: INotificationResponse;
}

export const VersionUpdatedItem: React.FC<IVersionUpdatedItemProps> = ({
    item,
}) => {
    const date = moment(item.data.date);

    return (
        <NotificationItem
            item={item}
            icon={<Icon name="check-mark-circle-filled" size="16" intent="success" />}
            header={(
                <Translate
                    content="notification.item.versionUpdated.title"
                    params={{ version: item.data.version }}
                />
            )}
            description={(
                <Translate
                    content="notification.item.versionUpdated.description"
                    params={{
                        date: date.format('D-M-Y'),
                        time: date.format('HH:mm:ss'),
                    }}
                />
            )}
        />
    );
};

export default VersionUpdatedItem;
