// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import {
    api,
    IApiResponse,
    IPaginateApiResponse,
} from 'common/api/resources/Response';
import { IProjectResponse } from 'common/api/resources/Project';
import {
    IUserListRequest,
    IUserProjectsListRequest,
    IUserRequest,
    IUserResponse,
} from 'common/api/resources/User/model';
import { QueryBuilder } from 'common/api/resources/Request/QueryBuilder';
import { ILoginResponse } from 'common/api/resources/Auth';
import { CancelTokenSource } from 'axios';

export const users = {
    list: (params?: IUserListRequest, cancelToken?: CancelTokenSource) => api.get<IPaginateApiResponse<IUserResponse[]>>('users', {
        params: new QueryBuilder<IUserListRequest>(params).build(),
        cancelToken: cancelToken?.token,
    }),
    create: (data: IUserRequest) => api.post<IApiResponse<IUserResponse>>('users', data),
    item: (id: number) => api.get<IApiResponse<IUserResponse>>(`users/${id}`),
    update: (id: number, data: IUserRequest) => api.put<IApiResponse<IUserResponse|ILoginResponse>>(`users/${id}`, data),
    remove: (id: number, force?: boolean) => api.delete(`users/${id}`, { data: { force } }),
    removeBatch: (ids: number[], force?: boolean) => api.delete('users', { data: { ids, force } }),

    projects: {
        list: (id: number, params?: IUserProjectsListRequest) => api.get<IPaginateApiResponse<IProjectResponse[]>>(`users/${id}/projects`, {
            params: new QueryBuilder(params).build(),
        }),
    },
};
