// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import { initEchoConnection } from 'common/services/EchoService';
import {
    GraphContainer,
    IXY,
    IXYZ,
} from 'common/components/ServerTabs/GraphsTab/GraphContainer';
import { Area } from 'recharts';
import {
    Grid,
    Translate,
} from '@plesk/ui-library';
import { Loader } from 'common/components';
import { IUpdateChartItem } from 'common/api/resources/Graph';
import { IVmResponse } from 'common/api/resources/ComputeResourceVm';
import { bindedActionCreators } from 'common/redux/types';
import * as graphActions from 'common/modules/graph/actions';
import { debounce } from 'ts-debounce';

interface IChartsData {
    cpu: IXY[];
    network: IXYZ[];
    disks: IXYZ[];
    memory: IXY[];
}

export interface IGraphsTabProps {
    server: IVmResponse;
    echoCredentials: string;
    chartsData: IChartsData;
    isLoading: boolean;
    graphActions: bindedActionCreators<typeof graphActions>;
}

export const GraphsTab: React.FC<IGraphsTabProps> = ({
    server,
    echoCredentials,
    chartsData: { cpu, network, disks, memory },
    isLoading,
    graphActions: {
        loadChartData,
        updateChart,
    },
}) => {
    React.useEffect(() => {
        const echo = initEchoConnection(echoCredentials);

        if (server.uuid) {
            loadChartData(server.uuid);

            const channel = echo.private(`usage.${server.uuid}`);
            const updater = debounce(updateChart, 100);

            channel.listen('.chart.updated', (data: IUpdateChartItem) => updater(data));
        }

        return () => echo.disconnect();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [server]);

    return (
        <Loader isLoading={isLoading}>
            <Grid gap="lg">
                <GraphContainer
                    icon="cpu"
                    title={
                        <Translate content="servers.tabs.graph.cpu"/>
                    }
                    data={cpu}
                >
                    <Area
                        dot={false}
                        isAnimationActive={false}
                        name="Usage (%)"
                        type="linear"
                        dataKey="y"
                        stroke="#28AADE"
                        fill="#28AADE"
                    />
                </GraphContainer>
                <GraphContainer
                    icon="web"
                    title={
                        <Translate content="servers.tabs.graph.network"/>
                    }
                    data={network}
                >
                    <Area
                        dot={false}
                        isAnimationActive={false}
                        type="linear"
                        name="Read KiB"
                        dataKey="y"
                        stroke="#28AADE"
                        fill="#28AADE"
                    />
                    <Area
                        dot={false}
                        isAnimationActive={false}
                        type="linear"
                        name="Write KiB"
                        dataKey="z"
                        stroke="#80AF26"
                        fill="#80AF26"
                    />
                </GraphContainer>
                <GraphContainer
                    icon="card-row"
                    title={
                        <Translate content="servers.tabs.graph.diskThroughput"/>
                    }
                    data={disks}
                >
                    <Area
                        dot={false}
                        isAnimationActive={false}
                        type="linear"
                        name="Read KiB"
                        dataKey="y"
                        stroke="#28AADE"
                        fill="#28AADE"
                    />
                    <Area
                        dot={false}
                        isAnimationActive={false}
                        type="linear"
                        name="Write KiB"
                        dataKey="z"
                        stroke="#80AF26"
                        fill="#80AF26"
                    />
                </GraphContainer>
                <GraphContainer
                    icon="sliders"
                    title={
                        <Translate content="servers.tabs.graph.memory"/>
                    }
                    data={memory}
                >
                    <Area
                        dot={false}
                        isAnimationActive={false}
                        type="linear"
                        name="Usage MiB"
                        dataKey="y"
                        stroke="#28AADE"
                        fill="#28AADE"
                    />
                </GraphContainer>
            </Grid>
        </Loader>
    );
};
