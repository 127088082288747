// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import { COLORS } from 'admin/core/theme';
import { ISettingsTheme } from 'common/api/resources/Settings';

export const ADMIN = {
    SIDEBAR_COLOR: COLORS.PRIMARY,
    THEME: {
        primary_color: COLORS.PRIMARY,
        secondary_color: COLORS.WHITE,
    },
};

export type Theme = (typeof ADMIN.THEME) | ISettingsTheme;

export const CLASS_COLUMN_FIXED_RIGHT = 'col--fixed-right';
