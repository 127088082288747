// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import { beforeAppLoading } from 'common/services/AppLoadingService';
import AdminApp from 'admin/core/App';
import ClientApp from 'client/core/App';
import {
    isAdminSite,
    isProduction,
} from 'common/helpers/core';
import { store as AdminStore } from 'admin/core/store';
import { store as ClientStore } from 'client/core/store';
import { setUpInterceptors } from 'common/services/NetworkService';

const renderApp = () => {
    isAdminSite() ? setUpInterceptors(AdminStore) : setUpInterceptors(ClientStore);
    ReactDOM.render(
        isAdminSite() ? <AdminApp /> : <ClientApp />,
        document.getElementById('root')
    );
};

if (isProduction()) {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        ignoreErrors: [
            /getBoundingClientRect+.*/,
            /Request failed with status code 401+.*/,
            /Request failed with status code 404+.*/,
            /Request failed with status code 422+.*/,
        ],
    });
}

// We should wait until version is updated 'cause we get race condition when user try to use auth_by_link route
// (SVM-2622)
beforeAppLoading().then(renderApp);
