// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import { connect } from 'react-redux';
import { RootState } from 'admin/core/store';
import NetworkingTab from 'common/components/ServerTabs/NetworkingTab/NetworkingTab';
import { Translate } from '@plesk/ui-library';
import { ClusterImportProductTitles } from 'admin/clusterImport/constants';

const mapStateToProps = (state: RootState) => {
    const server = state.computeResourceVm.item;

    return {
        server,
        tooltip: (
            <Translate content="computeResource.servers.form.tooltip" params={{
                product: ClusterImportProductTitles[server.product],
            }}/>
        ),
    };
};

export default connect(mapStateToProps)(NetworkingTab);
