// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import { createCustomAction } from 'typesafe-actions';
import { Dispatch } from 'redux';
import * as types from 'common/modules/project/constants/types';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { IPaginateApiResponse } from 'common/api/resources/Response';
import { HTTP_CODES } from 'common/api/constants';
import {
    setIsLoading,
    unsetIsLoading,
} from 'common/modules/app/loadingFlags/actions';
import {
    IProjectRequest,
    IProjectResponse,
    projects,
} from 'common/api/resources/Project';
import {
    ISshKeyResponse,
    ISshKeyRequest,
} from 'common/api/resources/SshKey';

export const setProjectList = createCustomAction(
    types.SET_PROJECT_LIST,
    (data: IPaginateApiResponse<IProjectResponse[]>) => ({ payload: data })
);
export const setProjectItem = createCustomAction(
    types.SET_PROJECT_ITEM,
    (data: IProjectResponse) => ({ payload: data })
);
export const addProject = createCustomAction(
    types.ADD_PROJECT,
    (data: IProjectResponse) => ({ payload: data })
);
export const removeProjectItem = createCustomAction(
    types.REMOVE_PROJECT_ITEM,
    (id: number) => ({ payload: id })
);
export const updateProjectItem = createCustomAction(
    types.UPDATE_PROJECT_ITEM,
    (data: IProjectResponse) => ({ payload: data })
);
export const setItemIsLoading = createCustomAction(
    types.SET_IS_LOADING,
    (id: number) => ({ payload: id })
);
export const unsetItemIsLoading = createCustomAction(
    types.UNSET_IS_LOADING,
    (id: number) => ({ payload: id })
);
export const setProjectSshKeys = createCustomAction(
    types.SET_PROJECT_SSH_KEYS_LIST,
    (id: number, data: ISshKeyResponse[]) => ({ payload: { id, data } })
);
export const appendProjectSshKeyItem = createCustomAction(
    types.APPEND_PROJECT_SSH_KEY_ITEM,
    (id: number, data: ISshKeyResponse) => ({ payload: { id, data } })
);

export const removeProject = (data: IProjectResponse) => async (dispatch: Dispatch) => {
    dispatch(setItemIsLoading(data.id));

    try {
        const result = await projects.remove(data.id);

        if (result.status === HTTP_CODES.NO_CONTENT) {
            dispatch(removeProjectItem(data.id));
        }

        return result;
    } finally {
        dispatch(unsetItemIsLoading(data.id));
    }
};

export const getProject = (id: number) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.PROJECT_ITEM));

    try {
        const result = await projects.item(id);

        if (result.status === HTTP_CODES.OK) {
            dispatch(setProjectItem(result.data.data));
        }

        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.PROJECT_ITEM));
    }
};

export const getProjects = () => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.PROJECT_LIST));

    try {
        const result = await projects.list();

        if (result.status === HTTP_CODES.OK) {
            dispatch(setProjectList({
                ...result.data,
                data: result.data.data.map(item => ({
                    ...item,
                    isEdit: false,
                    isLoading: false,
                })),
            }));
        }

        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.PROJECT_LIST));
    }
};

export const saveProject = (data: IProjectRequest, id?: number) => async (dispatch: Dispatch) => {
    if (id) {
        dispatch(setItemIsLoading(id));
    }
    dispatch(setIsLoading(LOADING_FLAGS.PROJECT_ITEM));

    try {
        const result = id ? await projects.update(id, data) : await projects.create(data);

        if ([HTTP_CODES.CREATED, HTTP_CODES.OK].includes(result.status)) {
            if (id) {
                dispatch(updateProjectItem({
                    ...result.data.data,
                }));
            } else {
                dispatch(addProject({
                    ...result.data.data,
                    isEdit: false,
                    isLoading: false,
                }));
            }
        }

        return result;
    } finally {
        if (id) {
            dispatch(unsetItemIsLoading(id));
        }
        dispatch(unsetIsLoading(LOADING_FLAGS.PROJECT_ITEM));
    }
};

export const getProjectSshKeys = (id: number) => async(dispatch: Dispatch) => {
    const result = await projects.sshKeys.list(id);
    if (result.status === HTTP_CODES.OK) {
        dispatch(setProjectSshKeys(id, result.data.data));
    }

    return result;
};

export const createProjectSshKey = (id: number, data: ISshKeyRequest) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.SAVE_SSH_KEY_ITEM));

    try {
        const result = await projects.sshKeys.create(id, data);

        if (result.status === HTTP_CODES.CREATED) {
            dispatch(appendProjectSshKeyItem(id, result.data.data));
        }

        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.SAVE_SSH_KEY_ITEM));
    }
};
