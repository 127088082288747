// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import * as settingsActions from 'common/modules/settings/actions';
import { ISettingsResponse } from 'common/api/resources/Settings';
import FormFieldPassword from 'common/components/Form/FormFieldPassword/FormFieldPassword';
import {
    requiredRule,
    validate,
} from 'common/validator';
import * as formErrorsActions from 'common/modules/app/formErrors/actions';
import SettingsWrapper from 'admin/settings/containers/Settings/SettingsWrapper';
import {
    FormFieldCheckbox,
    FormFieldText,
    Section,
    Translate,
} from '@plesk/ui-library';

export type MailSettingsProps =
    ReturnType<typeof mapDispatchToProps>;

export const MailSettings: React.FC<MailSettingsProps> = ({
    settingsActions: { saveSettings },
    formErrorsActions: { setFormErrors },
}) => {
    const handleSubmit = (values: ISettingsResponse) => {
        const formErrors = validate<ISettingsResponse>(values, {
            'mail.host': requiredRule(<Translate content="validate.fieldRequired" />),
            'mail.port': requiredRule(<Translate content="validate.fieldRequired" />),
            'mail.password': requiredRule(<Translate content="validate.fieldRequired" />),
            'mail.username': requiredRule(<Translate content="validate.fieldRequired" />),
            'mail.from_email': requiredRule(<Translate content="validate.fieldRequired" />),
            'mail.from_name': requiredRule(<Translate content="validate.fieldRequired" />),
        });

        if (Object.keys(formErrors).length) {
            setFormErrors(formErrors);
            return;
        }

        saveSettings({
            mail: values.mail,
        });
    };

    return (
        <SettingsWrapper
            title="settings.titles.mail"
            onSubmit={handleSubmit}
        >
            <Section>
                <FormFieldText
                    size="xl"
                    name="mail[host]"
                    label={<Translate content="settings.mail.host" />}
                    required={true}
                />
                <FormFieldText
                    size="sm"
                    name="mail[port]"
                    label={<Translate content="settings.mail.port" />}
                    required={true}
                />
                <FormFieldText
                    size="xl"
                    name="mail[username]"
                    label={<Translate content="settings.mail.username" />}
                    required={true}
                />
                <FormFieldPassword
                    size="xl"
                    name="mail[password]"
                    label={<Translate content="settings.mail.password" />}
                    required={true}
                />
                <FormFieldText
                    size="xl"
                    name="mail[from_email]"
                    label={<Translate content="settings.mail.from.email" />}
                    required={true}
                />
                <FormFieldText
                    size="xl"
                    name="mail[from_name]"
                    label={<Translate content="settings.mail.from.name" />}
                    required={true}
                />
                <FormFieldCheckbox
                    name="mail[encryption]"
                    label={<Translate content="settings.mail.encryption"/>}
                />
                <FormFieldCheckbox
                    name="mail[send_test_mail]"
                    label={<Translate content="settings.mail.testMail"/>}
                />
                <FormFieldText
                    placeholder="Enter test email here..."
                    name="mail[test_mail]"
                />
            </Section>
        </SettingsWrapper>
    );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    settingsActions: bindActionCreators(settingsActions, dispatch),
    formErrorsActions: bindActionCreators(formErrorsActions, dispatch),
});

export default connect(null, mapDispatchToProps)(MailSettings);
