// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import {
    IVmSettings,
    VirtualServerSettingsRequest,
    VZNetfilterStatus,
} from 'common/api/resources/ComputeResourceVm';
import { Translate } from '@plesk/ui-library';
import { Grid } from 'common/components/ServerTabs/SettingsTab/Styles';
import VZNetfilterStatusSegmentedControl from 'common/components/VZNetfilterStatusSegmentedControl';
import VZTUNTAPSegmentedControl from 'common/components/VZTUNTAPSegmentedControl';
import VZPPPSegmentedControl from 'common/components/VZPPPSegmentedControl';
import { ICommonState } from 'common/store';
import { hasPermission } from 'common/modules/permission/selectors';
import { PERMISSION_LIST } from 'common/modules/permission/constants';
import SettingsPropertyCard from 'common/components/ServerTabs/SettingsTab/SettingsPropertyCard';
import { SettingsPropertyCardDataCy } from 'common/components/ServerTabs/SettingsTab/constants';

export interface IVZSettingsTabProps {
    serverSettings: IVmSettings;
    isUpdating: boolean;
    updateSettings: (settings: VirtualServerSettingsRequest) => void;
}

export type VZSettingsTabProps =
    IVZSettingsTabProps &
    ReturnType<typeof mapStateToProps>;

export const VZSettingsTab: React.FC<VZSettingsTabProps> = ({
    serverSettings,
    isUpdating,
    updateSettings,
    canManageServers,
}) => {
    const netfilterChange = (value: VZNetfilterStatus) => {
        updateSettings({ netfilter: value });
    };

    const tunTAPChange = (value: boolean) => {
        updateSettings({ tun_tap: value });
    };

    const pppChange = (value: boolean) => {
        updateSettings({ ppp: value });
    };

    const isEditable = (key: string) => canManageServers || (serverSettings[key]?.is_editable ?? false);

    return (
        <Grid
            xs={1}
            sm={1}
            md={2}
            lg={2}
            xl={2}
            gap="md"
        >
            <SettingsPropertyCard
                title="Netfilter"
                text={<Translate content="servers.tabs.settings.netfilter.text" />}
                data-cy={SettingsPropertyCardDataCy.NETFILTER}
            >
                <VZNetfilterStatusSegmentedControl
                    withConfirmation={true}
                    selected={serverSettings.netfilter?.value ?? VZNetfilterStatus.STATELESS}
                    disabled={isUpdating || !isEditable('netfilter')}
                    onChange={netfilterChange}
                />
            </SettingsPropertyCard>
            <SettingsPropertyCard
                title="TUN/TAP"
                text={<Translate content="servers.tabs.settings.tunTAP.text" />}
                data-cy={SettingsPropertyCardDataCy.TUN_TAP}
            >
                <VZTUNTAPSegmentedControl
                    withConfirmation={true}
                    selected={serverSettings.tun_tap?.value ?? false}
                    disabled={isUpdating || !isEditable('tun_tap')}
                    onChange={tunTAPChange}
                />
            </SettingsPropertyCard>
            <SettingsPropertyCard
                title="PPP"
                text={<Translate content="servers.tabs.settings.ppp.text" />}
                data-cy={SettingsPropertyCardDataCy.PPP}
            >
                <VZPPPSegmentedControl
                    withConfirmation={true}
                    selected={serverSettings.ppp?.value ?? false}
                    disabled={isUpdating || !isEditable('ppp')}
                    onChange={pppChange}
                />
            </SettingsPropertyCard>
        </Grid>
    );
};

const mapStateToProps = (state: ICommonState) => ({
    canManageServers: hasPermission(state, PERMISSION_LIST.MANAGE_SERVERS),
});

export default connect(mapStateToProps)(VZSettingsTab);
