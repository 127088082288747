// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import styled from 'styled-components';

export const Title = styled.span` 
  font-size: 20px; 
  line-height: 26px; 
  font-weight: 600;
  color: #222222;
`;

export const Description = styled.div`
  margin-top: 20px;
`;
