// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import { ILocationResponse } from 'common/api/resources/Location';
import { PROJECT_SERVER_PAGE } from 'client/project/constants/tests';
import { Icon } from '@plesk/ui-library';
import { COLORS } from 'common/theme';
import { ServerIcon } from 'common/components/ServerIcon/ServerIcon';
import { CutTitle } from 'common/components';
import {
    ICONS,
    INTENT_TYPE,
} from 'common/constants';
import {
    ContentName,
    DescriptionText,
    ItemContent,
    LocationItem,
    LogoContainer,
} from 'common/components/location/Styles';

type IProjectServerCardProps = {
    location: ILocationResponse;
    isSelected: boolean;
    onItemClicked: (id: number) => void;
    disabled?: boolean;
    showInternalTooltips?: boolean;
} & React.HTMLProps<HTMLDivElement>;

export const LocationCard: React.FC<IProjectServerCardProps> = ({
    location,
    isSelected,
    onItemClicked,
    disabled,
    showInternalTooltips= true,
}) => {
    const renderIcon = () => {
        if (isSelected) {
            return <Icon name={ICONS.LOCATION} />;
        }

        if (!location.icon) {
            return (
                <Icon
                    intent={INTENT_TYPE.INACTIVE}
                    name={ICONS.GLOBE}
                />
            );
        }

        return <ServerIcon icon={location.icon} />;
    };

    const handleClick = (id: number) => () => !disabled && onItemClicked(id);

    return (
        <LocationItem isSelected={isSelected} onClick={handleClick(location.id)} isDisabled={disabled}>
            <div>
                <LogoContainer>
                    {renderIcon()}
                </LogoContainer>
            </div>
            <ItemContent>
                <ContentName data-cy={PROJECT_SERVER_PAGE.LOCATION_CARD}>
                    <CutTitle title={location.name} withTooltip={showInternalTooltips} />
                </ContentName>
                <DescriptionText className="location-description">
                    <CutTitle title={location.description} withTooltip={showInternalTooltips} />
                </DescriptionText>
            </ItemContent>
            {!location.is_visible && (
                <Icon name="eye-closed" size="16" style={{ color: isSelected ? COLORS.WHITE_0 : COLORS.GREY_60 }}/>
            )}
        </LocationItem>
    );
};
