// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import styled from 'styled-components';

export const ResetLink = styled.div`
    text-align: center;
    
    span {
       color: ${props => props.theme.primary_color} !important;
    }
`;

export const SocialitePanel = styled.div`
  padding-top: 10px;
  
  a {
    display: flex; 
    align-items: center;
    
    img {
        height: 35px;
        padding-right: 10px;
    }
  }
`;
