// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import {
    DiskCacheMode,
    IVmSettings,
    VirtualServerSettingsRequest,
} from 'common/api/resources/ComputeResourceVm';
import { Translate } from '@plesk/ui-library';
import { ValueType } from 'react-select';
import SelectWithConfirmation from 'common/components/SelectWithConfirmation/SelectWithConfirmation';
import { ISelectOption } from 'common/components/SelectInput';

export interface IKVMSettingsTabProps {
    serverSettings: IVmSettings;
    isUpdating: boolean;
    updateSettings: (settings: VirtualServerSettingsRequest) => void;
}

export const KVMSettingsTab: React.FC<IKVMSettingsTabProps> = ({
    serverSettings,
    isUpdating,
    updateSettings,
}) => {
    const diskCacheModeOptions: ISelectOption[] = Object.values(DiskCacheMode).map(mode => ({
        value: mode,
        label: mode,
    }));
    const getDiskCacheModeValue = (value?: DiskCacheMode) =>
        diskCacheModeOptions.find((option) => option.value === value);

    const handleChange = (option: ValueType<ISelectOption>): void => {
        updateSettings({ disk_cache_mode: (option as ISelectOption).value as DiskCacheMode });
    };

    return (
        <>
            <h3 id="disk-cache-mode-label">
                <Translate content="servers.tabs.settings.diskCacheMode.label" />
            </h3>
            <SelectWithConfirmation
                aria-labelledby="disk-cache-mode-label"
                options={diskCacheModeOptions}
                value={getDiskCacheModeValue(serverSettings.disk_cache_mode)}
                minHeight="40px"
                width="350px"
                onChange={handleChange}
                isDisabled={isUpdating}
                isLoading={isUpdating}
                dialog={{
                    title: <Translate content="servers.tabs.settings.diskCacheMode.confirmationDialog.title"/>,
                    text: <Translate content="servers.tabs.settings.diskCacheMode.confirmationDialog.content"/>,
                    button: <Translate content="servers.tabs.settings.diskCacheMode.confirmationDialog.button"/>,
                }}
            />
        </>
    );
};

export default KVMSettingsTab;
