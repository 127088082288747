// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as apiTokenActions from 'common/modules/apiToken/actions';
import { combineReducers } from 'redux';
import {
    ActionType,
    getType,
} from 'typesafe-actions';
import { IApiTokenResponse } from 'common/api/resources/ApiToken';
import { initialUserState } from 'admin/user/reducer';

interface IApiTokens {
    list: IApiTokenResponse[];
}

export type ApiTokenAction = ActionType<typeof apiTokenActions>;
export type ApiTokenState = Readonly<IApiTokens>;

export const initialApiTokenState: IApiTokenResponse = {
    id: '1',
    name: 'test',
    user: initialUserState,
    isLoading: false,
    created_at: '',
};

export default combineReducers<ApiTokenState, ApiTokenAction>({
    list: (state = [], action: ApiTokenAction) => {
        switch (action.type) {
        case getType(apiTokenActions.appendApiTokenItem):
            return [...state, action.payload];
        case getType(apiTokenActions.removeApiTokenItem):
            return [...state.filter(item => item.id !== action.payload)];
        case getType(apiTokenActions.setApiTokens):
            return action.payload;
        case getType(apiTokenActions.setItemIsLoading):
            return [...state.map(item => {
                if (item.id === action.payload) {
                    return { ...item, isLoading: true };
                }

                return item;
            })];
        case getType(apiTokenActions.unsetItemIsLoading):
            return [...state.map(item => {
                if (item.id === action.payload) {
                    return { ...item, isLoading: false };
                }

                return item;
            })];
        default:
            return state;
        }
    },
});
