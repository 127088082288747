// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import styled from 'styled-components';
import { COLORS } from 'admin/core/theme';

export const ServerInfo = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    margin-top: 12px;
`;

export const ServerInfoBlockWrapper = styled.div`
    margin: 0 60px 12px 0;
`;

export const ServerInfoTitle = styled.div`
    font-size: 12px;
    line-height: 16px;
    color: ${COLORS.GREY_80};
    margin-bottom: 4px;
`;

export const ServerInfoDescription = styled.div`
    font-size: 14px;
    line-height: 20px;
    color: ${COLORS.GREY_100};
`;
