// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import { IEmailNotification } from 'common/api/resources/Notification/model';
import {
    api,
    IApiResponse,
} from 'common/api/resources/Response';

export const notifications = {
    defaults: (event: string) => api.get<IApiResponse<IEmailNotification>>(`notifications/defaults?event=${event}`),
    remove: (id: number) => api.delete(`notifications/${id}`),
};
