// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import {
    IVmResponse,
    VZNetfilterStatus,
} from 'common/api/resources/ComputeResourceVm';
import {
    ImageFormat,
    StorageType,
} from 'common/api/resources/StorageType';
import {
    IPaginated,
    IRequest,
    ISearchable,
} from 'common/api/resources/Request/request';
import {
    BandwidthUnit,
    convertToDataUnit,
    DataUnit,
    DiskBandwidthUnit,
    IOpsUnit,
    Unit,
} from 'common/helpers/units';
import { VirtualizationType } from 'common/api/resources/ComputeResource';
import { IShortLocationResponse } from 'common/api/resources/Location';
import { IShortApplicationResponse } from 'common/api/resources/Application';
import { IShortOsImageVersionResponse } from 'common/api/resources/OsImageVersion';

interface IPlanParams {
    disk: number;
    ram: number;
    vcpu: number;
    vcpu_units?: number;
    vcpu_limit?: number;
    io_priority?: number;
    swap?: number;
}

export type Units = BandwidthUnit | DataUnit | IOpsUnit | DiskBandwidthUnit | Unit;

export interface IPlanLimit<T = Units> {
    limit: number;
    unit: T;
    is_enabled: boolean;
}

export function limitFactory<T>(unit: T, limit: number = 1, is_enabled: boolean = false): IPlanLimit<T> {
    return {
        unit,
        limit,
        is_enabled,
    };
}

export const areNetworkLimitsExceeded = (server: IVmResponse) => server.usage.network.incoming.is_exceeded || server.usage.network.outgoing.is_exceeded;
export const isLimitExceeded = (currentUsage: number, limit: IPlanLimit<DataUnit>): boolean =>
    limit.is_enabled && convertToDataUnit(currentUsage, limit.unit) >= limit.limit;

export enum ResetLimitPolicy {
    Never = 'never',
    FirstDayOfMonth = 'first_day_of_month',
    VmCreatedDay = 'vm_created_day',
}

export enum NetworkTrafficLimitTypes {
    Separate = 'separate',
    Total = 'total',
}

export interface IPlanLimits {
    disk_iops: IPlanLimit<IOpsUnit>;
    disk_bandwidth: IPlanLimit<DiskBandwidthUnit>;
    network_incoming_bandwidth: IPlanLimit<BandwidthUnit>;
    network_outgoing_bandwidth: IPlanLimit<BandwidthUnit>;
    network_incoming_traffic: IPlanLimit<DataUnit>;
    network_outgoing_traffic: IPlanLimit<DataUnit>;
    network_total_traffic: IPlanLimit<DataUnit>;
    network_reduce_bandwidth: IPlanLimit<BandwidthUnit>;
    backups_number: IPlanLimit<Unit>;
}

export interface IPlanUpdateLimits {
    network_incoming_bandwidth: IPlanLimit<BandwidthUnit>;
    network_outgoing_bandwidth: IPlanLimit<BandwidthUnit>;
    network_incoming_traffic: IPlanLimit<DataUnit>;
    network_outgoing_traffic: IPlanLimit<DataUnit>;
    network_total_traffic: IPlanLimit<DataUnit>;
    network_reduce_bandwidth: IPlanLimit<BandwidthUnit>;
    backups_number: IPlanLimit<Unit>;
}

export interface IPlanCreateRequest {
    name: string;
    params: IPlanParams;
    virtualization_type: VirtualizationType;
    storage_type: StorageType;
    image_format: ImageFormat;
    tokens_per_hour: number;
    tokens_per_month: number;
    is_backup_available: boolean;
    backup_price: number;
    is_visible: boolean;
    is_snapshots_enabled: boolean;
    limits: IPlanLimits;
    is_additional_ips_available: boolean;
    ip_tokens_per_month: number;
    ip_tokens_per_hour: number;
    reset_limit_policy: ResetLimitPolicy;
    network_traffic_limit_type: NetworkTrafficLimitTypes;
    backup_settings: IPlanBackupSettings;
    available_locations: number[];
    available_os_image_versions: number[];
    available_applications: number[];
    netfilter?: {
        value: VZNetfilterStatus;
        is_editable: boolean;
    };
    tun_tap?: {
        value: boolean;
        is_editable: boolean;
    };
    ppp?: {
        value: boolean;
        is_editable: boolean;
    };
}

export type PlanUpdateRequest = IKVMPlanUpdateRequest | IVZPlanUpdateRequest;

export interface IKVMPlanUpdateRequest extends ICommonPlanUpdateRequest {}

export interface IVZPlanUpdateRequest extends ICommonPlanUpdateRequest {
    netfilter: {
        value: VZNetfilterStatus;
        is_editable: boolean;
    };
    tun_tap: {
        value: boolean;
        is_editable: boolean;
    };
    ppp: {
        value: boolean;
        is_editable: boolean;
    };
}

interface ICommonPlanUpdateRequest {
    name: string;
    tokens_per_hour: number;
    tokens_per_month: number;
    is_backup_available: boolean;
    is_snapshots_enabled: boolean;
    is_visible: boolean;
    backup_price: number;
    is_additional_ips_available: boolean;
    ip_tokens_per_month: number;
    ip_tokens_per_hour: number;
    limits: IPlanUpdateLimits;
    network_traffic_limit_type: NetworkTrafficLimitTypes;
    backup_settings: IPlanBackupSettings;
    available_locations: number[];
    available_os_image_versions: number[];
    available_applications: number[];
}

export interface IPlanPatchRequest {
    is_default?: boolean;
    is_visible?: boolean;
    position?: number;
}

export interface IPrice {
    currency_code: string;
    per_hour: string;
    per_month: string;
    taxes_inclusive: boolean;
    taxes: ITax[];
    total_price: string;
    total_price_without_backups: string;
    backup_price: string;
    additional_ips_per_month: string;
    additional_ips_per_hour: string;
}

export interface ITax {
    label: string;
    rate: number;
}

export interface IPlanBackupSettings {
    incremental_backups_limit: number;
    is_incremental_backup_enabled: boolean;
}

export interface IPlanResponse {
    id: number;
    name: string;
    virtualization_type: VirtualizationType;
    tokens_per_hour: number;
    tokens_per_month: number;
    params: IPlanParams;
    price?: IPrice;
    storage_type: StorageType;
    image_format: ImageFormat;
    reset_limit_policy: ResetLimitPolicy;
    is_snapshot_available: boolean;
    is_snapshots_enabled: boolean;
    is_default: boolean;
    is_loading: boolean;
    is_deleting: boolean;
    limits: IPlanLimits;
    is_backup_available: boolean;
    backup_price: number;
    backup_settings: IPlanBackupSettings;
    is_visible: boolean;
    is_custom: boolean;
    position: number;
    is_additional_ips_available: boolean;
    ip_tokens_per_month: number;
    ip_tokens_per_hour: number;
    network_traffic_limit_type: NetworkTrafficLimitTypes;
    available_locations?: IShortLocationResponse[];
    available_os_image_versions?: IShortOsImageVersionResponse[];
    available_applications?: IShortApplicationResponse[];
    netfilter?: {
        value: VZNetfilterStatus;
        is_editable: boolean;
    };
    tun_tap?: {
        value: boolean;
        is_editable: boolean;
    };
    ppp?: {
        value: boolean;
        is_editable: boolean;
    };
}

export interface IShortPlanResponse {
    id: number;
    name: string;
}

export interface IPlanListFilters {
    storage_type?: StorageType;
    image_format?: ImageFormat;
    disk?: string[];
    search?: string;
    location_id?: number;
    virtualization_type?: VirtualizationType;
}

export interface IPlanListAdditionalParameters {
    additional_ips_count?: number;
}

export interface IPlanListRequest extends IRequest<IPlanListFilters>, IPaginated, ISearchable, IPlanListAdditionalParameters {}
