// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import {
    ComputeResourceVmStatus,
    IVmSettings,
    VirtualServerSettingsRequest,
} from 'common/api/resources/ComputeResourceVm';
import { VirtualizationType } from 'common/api/resources/ComputeResource';
import KvmSettingsTab from 'common/components/ServerTabs/SettingsTab/KVMSettingsTab';
import VzSettingsTab from 'common/components/ServerTabs/SettingsTab/VZSettingsTab';
import { ICommonState } from 'common/store';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import * as computeResourceVmActions from 'common/modules/computeResourceVm/actions';
import { connect } from 'react-redux';

export interface ISettingsTabProps {
    serverId: number;
    serverSettings: IVmSettings;
    serverStatus: ComputeResourceVmStatus;
    serverVirtualizationType: VirtualizationType;
}

export type SettingsTabProps =
    ISettingsTabProps &
    ReturnType<typeof mapDispatchToProps> &
    ReturnType<typeof mapStateToProps>;

export const SettingsTab: React.FC<SettingsTabProps> = ({
    serverId,
    serverSettings,
    serverStatus,
    serverVirtualizationType,
    isUpdating,
    updateComputeResourceVmSettings,
}) => {
    isUpdating = isUpdating || (serverStatus === ComputeResourceVmStatus.UPDATING);

    const updateSettings = (settings: VirtualServerSettingsRequest) => {
        updateComputeResourceVmSettings(serverId, settings);
    };

    switch (serverVirtualizationType) {
    case VirtualizationType.KVM:
        return (<KvmSettingsTab
            serverSettings={serverSettings}
            isUpdating={isUpdating}
            updateSettings={updateSettings}
        />);
    case VirtualizationType.VZ:
        return (<VzSettingsTab
            serverSettings={serverSettings}
            isUpdating={isUpdating}
            updateSettings={updateSettings}
        />);
    default:
        return null;
    }
};

const mapStateToProps = (state: ICommonState) => ({
    isUpdating: state.app.loadingFlags.has(LOADING_FLAGS.COMPUTE_RESOURCE_VM_UPDATE_SETTINGS),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    updateComputeResourceVmSettings: bindActionCreators(computeResourceVmActions.updateComputeResourceVmSettings, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsTab);
