// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import * as settingsActions from 'common/modules/settings/actions';
import SettingsWrapper from 'admin/settings/containers/Settings/SettingsWrapper';
import { ISettingsResponse } from 'common/api/resources/Settings';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import {
    FormFieldCheckbox,
    FormFieldText,
    Translate,
} from '@plesk/ui-library';

export type GeneralSettingsProps =
    ReturnType<typeof mapDispatchToProps>;

export const GeneralSettings: React.FC<GeneralSettingsProps> = ({
    settingsActions: { saveSettings },
}) => {
    const handleSubmit = (values: ISettingsResponse) => {
        saveSettings({
            hostname: values.hostname,
            send_statistic: values.send_statistic,
        });
    };

    return (
        <SettingsWrapper title="settings.title" onSubmit={handleSubmit}>
            <FormFieldText
                name="hostname"
                size="xl"
                label={<Translate content="settings.hostname" />}
                description={<Translate content="settings.hostnameDescription" />}
                required={true}
            />
            <FormFieldCheckbox
                name="send_statistic"
                label={<Translate content="settings.sendStatistic"/>}
            />
        </SettingsWrapper>
    );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    settingsActions: bindActionCreators(settingsActions, dispatch),
});

export default connect(null, mapDispatchToProps)(GeneralSettings);
