// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import styled from 'styled-components';
import { Dialog as UiLibDialog } from '@plesk/ui-library';

export const StyledDialog = styled(UiLibDialog)`
  background: transparent;
  
  .pul-dialog__content {
    background: #fbfbfb;
    padding: 30px 40px;
    overflow-y: auto;
    
    .pul-button--lg {
      z-index: 1;
    }
    
    &::-webkit-scrollbar {
      width: 8px;
    }
        
    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: #C4C4C4 !important;
    }
  }
`;

export const CloseLink = styled.div`
  position: absolute;
  right: 15px;
  top: 15px;
  
  a {
    color: #737373;
  }
`;
