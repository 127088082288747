// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import { IUserResponse } from 'common/api/resources/User';

export const BEARER_TOKEN_TYPE = 'Bearer';

export interface ILoginRequest {
    email: string;
    password: string;
}

export interface IRegisterRequest {
    email: string;
    password: string;
    language_id?: number;
    accepts_policy?: boolean;
    token?: string | null;
}

export interface IUserCredentials {
    access_token: string;
    token_type: string;
}

export interface ILoginResponse {
    credentials: IUserCredentials;
    user: IUserResponse;
}

export interface IResetPasswordRequest {
    email: string;
}

export interface IVerifyEmailRequest {
    token: string | null;
}

export interface IAuthLinkRequest {
    email: string;
    token: string;
}

export interface IUpdatePasswordRequest {
    email?: string;
    token: string | null;
    password: string;
}
