// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import { IconType } from 'common/api/resources/Icon';
import { Translate } from '@plesk/ui-library';

export interface IIconTypeTextProps {
    type: IconType;
}

export const IconTypeText: React.FC<IIconTypeTextProps> = ({
    type,
}) => {
    const map = {
        [IconType.OS]: 'icon.types.os',
        [IconType.APPLICATION]: 'icon.types.application',
        [IconType.FLAGS]: 'icon.types.location',
    };

    return <Translate content={map[type]} />;
};
