// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import { IPermissionResponse } from 'common/api/resources/Permission';
import { StyledList } from 'admin/role/components/PermissionList/Styles';
import { Translate } from '@plesk/ui-library';
import { CheckBox } from 'common/components/CheckBox/CheckBox';
import { getActionColumnProps } from 'common/helpers/list';
import { dataCySelector } from 'common/tests/selectors';

export interface IPermissionListProps {
    permissions: IPermissionResponse[];
    selected: string[];
    onSelectionChange: (selection: string[]) => void;
}

const columns = [{
    key: 'colName',
    title: <Translate content="role.form.permissions.name" />,
}, getActionColumnProps(),
];

const PermissionList: React.FC<IPermissionListProps> = ({
    permissions,
    selected,
    onSelectionChange,
}) => {
    const createCheckboxChangeHandler = (id: string) => (ev: React.ChangeEvent<HTMLInputElement>) => {
        if (ev.target.checked) {
            onSelectionChange([...selected, id]);
        } else {
            onSelectionChange(selected.filter((curId) => curId !== id));
        }
    };

    const listData = permissions.map((permission) => {
        const curId = permission.id.toString();

        return {
            colName: permission.name,
            colActions: (
                <CheckBox
                    id={curId}
                    data-cy={dataCySelector('role', curId)}
                    onChange={createCheckboxChangeHandler(curId)}
                    checked={selected.some((id) => curId === id)}
                />
            ),
        };
    });

    return (
        <StyledList
            columns={columns}
            data={listData}
            rowKey="colName"
            vertical={false}
        />
    );
};

export default PermissionList;
