// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import { connect } from 'react-redux';
import { RootState } from 'client/core/store';
import NetworkingTab from 'common/components/ServerTabs/NetworkingTab/NetworkingTab';
import { Translate } from '@plesk/ui-library';

const mapStateToProps = (state: RootState) => ({
    server: state.project.servers.item,
    tooltip: (
        <Translate content="projects.server.tooltipForImported" />
    ),
});

export default connect(mapStateToProps)(NetworkingTab);
