// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import styled from 'styled-components';

interface IIconWrapperProps {
    color?: string;
}

export const IconCellContainer = styled.div`
    display: flex;
    align-items: center;
    *:first-child {
        margin-right: 6px;
    }
`;

export const IconWrapper = styled.span<IIconWrapperProps>`
    margin-right: 6px;
    display: flex;
    align-items: center;
    color: ${props => props.color ? props.color : 'currentColor'};
`;
