// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import { INTENT_TYPE } from 'common/constants';
import { COMPUTE_RESOURCE_STATUS } from 'admin/computeResource/constants';
import {
    Button,
    Translate,
    IntentType,
} from '@plesk/ui-library';
import { ButtonContainer } from 'admin/computeResource/common/Status/Styles';
import { Status as StyledStatus } from 'common/components/styles/Status';
import { COMPUTE_RESOURCE } from 'admin/computeResource/constants/tests';


export interface IStatusProps {
    status: COMPUTE_RESOURCE_STATUS;
    onDetailsClick?: () => void;
    compact?: boolean;
    withoutButton?: boolean;
}

export const statusTranslationMap: Record<COMPUTE_RESOURCE_STATUS, React.ReactNode> = {
    [COMPUTE_RESOURCE_STATUS.FAILED]: <Translate content="computeResource.statuses.failed" />,
    [COMPUTE_RESOURCE_STATUS.COMMISSIONING]: <Translate content="computeResource.statuses.commissioning" />,
    [COMPUTE_RESOURCE_STATUS.CONFIGURE_NETWORK]: <Translate content="computeResource.statuses.configureNetwork" />,
    [COMPUTE_RESOURCE_STATUS.CONFIGURING_NETWORK]: <Translate content="computeResource.statuses.configuringNetwork" />,
    [COMPUTE_RESOURCE_STATUS.UNAVAILABLE]: <Translate content="computeResource.statuses.unavailable" />,
    [COMPUTE_RESOURCE_STATUS.ACTIVE]: <Translate content="computeResource.statuses.active" />,
};

export const statusIntentMap: Partial<Record<COMPUTE_RESOURCE_STATUS, IntentType>> = {
    [COMPUTE_RESOURCE_STATUS.FAILED]: INTENT_TYPE.DANGER,
    [COMPUTE_RESOURCE_STATUS.COMMISSIONING]: INTENT_TYPE.WARNING,
    [COMPUTE_RESOURCE_STATUS.CONFIGURE_NETWORK]: INTENT_TYPE.WARNING,
    [COMPUTE_RESOURCE_STATUS.UNAVAILABLE]: INTENT_TYPE.WARNING,
    [COMPUTE_RESOURCE_STATUS.ACTIVE]: INTENT_TYPE.SUCCESS,
};

export const statusesWithDetails = [
    COMPUTE_RESOURCE_STATUS.COMMISSIONING,
    COMPUTE_RESOURCE_STATUS.FAILED,
    COMPUTE_RESOURCE_STATUS.CONFIGURE_NETWORK,
];

export const processingStatues = {
    [COMPUTE_RESOURCE_STATUS.CONFIGURING_NETWORK]: INTENT_TYPE.WARNING,
};

export const Status: React.FC<IStatusProps> = ({
    status,
    onDetailsClick,
    compact,
    withoutButton,
}) => {
    if (!statusTranslationMap.hasOwnProperty(status)) {
        return null;
    }

    const isProcessing = processingStatues.hasOwnProperty(status);

    return (
        <>
            <StyledStatus
                intent={statusIntentMap[status]}
                compact={!!compact && !isProcessing}
                progress={isProcessing}
                data-cy={COMPUTE_RESOURCE.STATUS}
            >
                {statusTranslationMap[status]}
            </StyledStatus>
            {!withoutButton && statusesWithDetails.includes(status) && (
                <ButtonContainer>
                    <Button
                        icon="box-diagonal-top-in"
                        ghost={true}
                        tooltip={<Translate content="computeResource.tooltip.details" />}
                        onClick={onDetailsClick}
                    />
                </ButtonContainer>
            )}
        </>
    );
};
