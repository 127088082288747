// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import { Middleware } from 'redux';
import { api } from 'common/api/resources/Response';
import { ICommonState } from 'common/store';

export const tokenMiddleware: Middleware = state => next => action =>  {
    const appState: ICommonState = state.getState();

    if (appState.auth.authData.token_type && appState.auth.authData.access_token) {
        api.defaults.headers.Authorization = `${appState.auth.authData.token_type} ${appState.auth.authData.access_token}`;
    }

    next(action);
};
