// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

export const SET_COMPUTE_RESOURCE_LIST = 'compute-resource/SET_COMPUTE_RESOURCE_LIST';
export const APPEND_COMPUTE_RESOURCES = 'compute-resource/APPEND_COMPUTE_RESOURCES';
export const ADD_COMPUTE_RESOURCE_ITEM = 'compute-resource/ADD_COMPUTE_RESOURCE_ITEM';
export const SET_COMPUTE_RESOURCE_INSTALL_STEPS = 'compute-resource/SET_COMPUTE_RESOURCE_INSTALL_STEPS';
export const UPDATE_COMPUTE_RESOURCE_INSTALL_STEP = 'compute-resource/UPDATE_COMPUTE_RESOURCE_INSTALL_STEP';
export const SET_COMPUTE_RESOURCE_NETWORKS = 'compute-resource/SET_COMPUTE_RESOURCE_NETWORKS';
export const SET_COMPUTE_RESOURCE_IP_BLOCKS = 'compute-resource/SET_COMPUTE_RESOURCE_IP_BLOCKS';
export const SET_COMPUTE_RESOURCE_METRICS = 'compute-resource/SET_COMPUTE_RESOURCE_METRICS';
export const SET_COMPUTE_RESOURCE_PHYSICAL_VOLUMES = 'compute-resource/SET_COMPUTE_RESOURCE_PHYSICAL_VOLUMES';
export const SET_COMPUTE_RESOURCE_THIN_POOLS = 'compute-resource/SET_COMPUTE_RESOURCE_THIN_POOLS';
export const SET_COMPUTE_RESOURCE_ITEM = 'compute-resource/SET_COMPUTE_RESOURCE_ITEM';
export const UPDATE_COMPUTE_RESOURCE_ITEM = 'compute-resource/UPDATE_COMPUTE_RESOURCE_ITEM';
export const SET_COMPUTE_RESOURCE_SETTINGS = 'compute-resource/SET_COMPUTE_RESOURCE_SETTINGS';
export const SET_COMPUTE_RESOURCE_STORAGES = 'compute-resource/SET_COMPUTE_RESOURCE_STORAGES';
export const UNSET_COMPUTE_RESOURCE_ITEM = 'compute-resource/UNSET_COMPUTE_RESOURCE_ITEM';
export const ADD_COMPUTE_RESOURCE_STORAGE = 'compute-resource/ADD_COMPUTE_RESOURCE_STORAGE';
export const PATCH_COMPUTE_RESOURCE_STORAGE = 'compute-resource/PATCH_COMPUTE_RESOURCE_STORAGE';
export const SET_COMPUTE_RESOURCE_ITEM_ID = 'compute-resource/SET_COMPUTE_RESOURCE_ITEM_ID';
export const DELETE_COMPUTE_RESOURCE = 'compute-resource/DELETE_COMPUTE_RESOURCE';
export const DELETE_COMPUTE_RESOURCE_STORAGE = 'compute-resource/DELETE_COMPUTE_RESOURCE_STORAGE';
export const SET_COMPUTE_RESOURCE_ITEM_IS_CONFIGURING_NETWORK = 'compute-resource/SET_COMPUTE_RESOURCE_ITEM_IS_CONFIGURING_NETWORK';
export const UNSET_COMPUTE_RESOURCE_ITEM_IS_CONFIGURING_NETWORK = 'compute-resource/UNSET_COMPUTE_RESOURCE_ITEM_IS_CONFIGURING_NETWORK';
export const SET_COMPUTE_RESOURCE_ITEM_IS_DELETING = 'compute-resource/SET_COMPUTE_RESOURCE_ITEM_IS_DELETING';
export const SET_COMPUTE_RESOURCE_STORAGE_ITEM_IS_DELETING = 'compute-resource/SET_COMPUTE_RESOURCE_STORAGE_ITEM_IS_DELETING';
export const SET_COMPUTE_RESOURCE_USAGE = 'compute-resource/SET_COMPUTE_RESOURCE_USAGE';
