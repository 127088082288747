// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import styled from 'styled-components';

export const ResetPasswordHeader = styled.div`
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    color: #222;
    width: 100%;
    margin-bottom: 20px;
`;
