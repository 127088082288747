// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import { EmptyView } from 'common/components/EmptyView/EmptyView';

export interface IErrorBoundaryState {
    hasError: boolean;
}

export class ErrorBoundary extends React.Component<Record<string, unknown>, IErrorBoundaryState> {
    state: IErrorBoundaryState = {
        hasError: false,
    };

    componentDidCatch() {
        this.setState({ hasError: true });
        return false;
    }

    refreshPage() {
        window.location.reload();
    }

    render() {
        if (this.state.hasError) {
            return (
                <EmptyView
                    title="error.title"
                    description="error.description"
                    buttonText="error.buttonText"
                    onButtonClick={this.refreshPage}
                    icon="bug"
                />
            );
        }

        return this.props.children;
    }
}

