// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import { Button } from 'admin/common/components/Button/Button';
import { Dialog } from 'admin/common/components/Dialog/Dialog';
import {
    Icon,
    Paragraph,
} from 'common/components/ServerTabs/RescueTab/Styles';
import {
    Grid,
    GridCol,
    Item,
    ItemList,
    Translate,
} from '@plesk/ui-library';
import {
    INTENT_TYPE,
    SIZE,
} from 'common/constants';
import { RESET_PASSWORD_BUTTON } from 'common/components/ServerTabs/RescueTab/constants/test';
import {
    BootMode,
    IVmResetPasswordRequest,
    IVmResponse,
} from 'common/api/resources/ComputeResourceVm';
import { Loader } from 'common/components';
import { VirtualizationType } from 'common/api/resources/ComputeResource';
import { ClusterImportProductType } from 'common/api/resources/ClusterImport';

export interface IRescueTabProps {
    isSwitchingBootMode: boolean;
    isResettingPassword: boolean;
    server: IVmResponse;
    resetPassword: (id: number, data?: IVmResetPasswordRequest) => void;
    updateBootMode: (id: number, bootMode: BootMode) => void;
    authId: number;
    tooltip: React.ReactNode;
}

interface IBootOption {
    title: string;
    value: BootMode;
    icon: string;
    description: string;
}

const bootOptions: IBootOption[] = [
    {
        title: 'servers.tabs.rescue.rescue.disk.title',
        value: BootMode.DISK,
        icon: 'hard-drive',
        description: 'servers.tabs.rescue.rescue.disk.description',
    },
    {
        title: 'servers.tabs.rescue.rescue.iso.title',
        value: BootMode.RESCUE,
        icon: 'cd',
        description: 'servers.tabs.rescue.rescue.iso.description',
    },
];

export const RescueTab: React.FC<IRescueTabProps> = ({
    server,
    isResettingPassword,
    isSwitchingBootMode,
    resetPassword,
    updateBootMode,
    authId,
    tooltip,
}) => {
    const [isOpen, setIsOpen] = React.useState(false);

    const isResetPasswordDisabled = React.useMemo(
        () => server.product === ClusterImportProductType.SOLUS_VM_1
            && server.virtualization_type === VirtualizationType.KVM,
        [server]
    );
    const handlePasswordReset = () => {
        resetPassword(server.id);
        setIsOpen(false);
    };

    const handleOpen = () => setIsOpen(true);
    const handleClose = () => setIsOpen(false);
    const handleUpdateBootMode = (bootMode: BootMode) => {
        if (bootMode === server.boot_mode) {
            return;
        }

        updateBootMode(server.id, bootMode);
    };

    const renderRescueSection = () => (
        <Grid gap="md">
            <GridCol xs={12} sm={12} md={12} xl={7} lg={7}>
                <Item
                    title={
                        <h3>
                            <Translate content="servers.tabs.rescue.rescue.title" />
                        </h3>
                    }
                    view="card"
                >
                    <Paragraph>
                        <Translate content="servers.tabs.rescue.rescue.description1" />
                    </Paragraph>
                    <ItemList
                        md="auto"
                        onSelect={handleUpdateBootMode}
                        selectable={true}
                        gap="md"
                        value={server.boot_mode}
                    >
                        {bootOptions.map((item, key) => (
                            <Item
                                data-cy={item.value}
                                key={key}
                                disabled={isSwitchingBootMode}
                                icon={
                                    <Loader isLoading={item.value === server.boot_mode && isSwitchingBootMode}>
                                        <Icon size={32} name={item.icon} />
                                    </Loader>
                                }
                                value={item.value}
                                title={
                                    <h3>
                                        <Translate content={item.title} />
                                    </h3>
                                }
                            >
                                <Translate content={item.description} />
                            </Item>
                        ))}
                    </ItemList>
                    <Paragraph>
                        <Translate content="servers.tabs.rescue.rescue.description2" />
                    </Paragraph>
                    <Paragraph>
                        <Translate content="servers.tabs.rescue.rescue.description3" />
                    </Paragraph>
                    <Paragraph>
                        <Translate content="servers.tabs.rescue.rescue.description4" />
                    </Paragraph>
                </Item>
            </GridCol>
        </Grid>
    );

    return (
        <>
            {server.virtualization_type === VirtualizationType.KVM && renderRescueSection()}
            <Grid gap="md">
                <GridCol xs={12} sm={12} md={12} xl={7} lg={7}>
                    <Item
                        title={
                            <h3>
                                <Translate content="servers.tabs.rescue.resetPassword.title" />
                            </h3>
                        }
                        view="card"
                    >
                        <Paragraph>
                            <Translate content="servers.tabs.rescue.resetPassword.content" />
                        </Paragraph>
                        <Button
                            onClick={handleOpen}
                            size={SIZE.LG}
                            intent={INTENT_TYPE.PRIMARY}
                            isLoading={isResettingPassword}
                            disabled={isResetPasswordDisabled}
                            tooltip={isResetPasswordDisabled && tooltip}
                        >
                            <Translate content="servers.tabs.rescue.resetPassword.button" />
                        </Button>
                    </Item>
                </GridCol>
            </Grid>
            <Dialog
                heading={
                    <Translate content="servers.tabs.rescue.resetPassword.dialog.title" />
                }
                closeHandler={handleClose}
                isOpen={isOpen}
                size={SIZE.XS}
            >
                <Paragraph>
                    {server.user.id === authId
                        ? <Translate content="servers.tabs.rescue.resetPassword.dialog.content" />
                        : <Translate content="servers.tabs.rescue.resetPassword.dialog.contentForNotOwner" />
                    }
                </Paragraph>
                <Button
                    data-cy={RESET_PASSWORD_BUTTON}
                    fill={true}
                    onClick={handlePasswordReset}
                    size={SIZE.LG}
                    disabled={isResettingPassword}
                    intent={INTENT_TYPE.PRIMARY}
                >
                    <Translate content="servers.tabs.rescue.resetPassword.dialog.button" />
                </Button>
            </Dialog>
        </>
    );
};
