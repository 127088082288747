// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import {
    api,
    IApiResponse,
} from 'common/api/resources/Response';
import {
    ILicenseActivateRequest,
    ILicenseResponse,
} from 'common/api/resources/License/solusIO/model';
import { CancelTokenSource } from 'axios';

export const license = {
    activate: (data: ILicenseActivateRequest) => api.post<IApiResponse<ILicenseResponse>>('license/activate', data),
    refresh: () => api.post<IApiResponse<ILicenseResponse>>('license/refresh'),
    item: (cancelToken?: CancelTokenSource) => api.get<IApiResponse<ILicenseResponse>>('license', {
        cancelToken: cancelToken?.token,
    }),
};
