// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import { IRoleResponse } from 'common/api/resources/Role';
import { ILimitGroupResponse } from 'common/api/resources/LimitGroup';
import { ILanguageResponse } from 'common/api/resources/Language';
import {
    IPaginated,
    IRequest,
} from 'common/api/resources/Request/request';

export enum UserStatus {
    ACTIVE = 'active',
    LOCKED = 'locked',
    SUSPENDED = 'suspended'
}

export interface IUserListFilters {
    status: string;
    limit_group_id: number;
    role_id: number;
}

export interface IUserProjectsListFilters {
    owner_id: number;
}

export interface IUserListRequest extends IRequest<IUserListFilters>, IPaginated {}
export interface IUserProjectsListRequest extends IRequest<IUserProjectsListFilters>, IPaginated {}

export interface IUserRequest {
    email: string;
    password?: string;
    status: UserStatus;
    language_id: number | null;
    limit_group_id: number | null;
    billing_user_id: number | null;
    billing_token: string | null;
    roles: number[];
    allowed_ips: string[] | null;
}

export interface IUserResponse {
    id: number;
    email: string;
    limit_group: ILimitGroupResponse | null;
    limit_usage: {
        servers: number;
        running_servers: number;
        additional_ips: number;
    };
    has_verified_email: boolean;
    email_verified_at: string | null;
    created_at: string;
    roles: IRoleResponse[];
    status: UserStatus;
    is_deleting: boolean;
    language: ILanguageResponse;
    billing_user_id: number | null;
    billing_token: string | null;
    allowed_ips: string[] | null;
}

export interface IShortUserResponse {
    id: number;
    email: string;
}

