// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import {
    api,
    IApiResponse,
    IPaginateApiResponse,
} from 'common/api/resources/Response';
import {
    IOsImageCreateRequest,
    IOsImageResponse,
    IOsImageUpdateRequest,
} from 'common/api/resources/OsImage/model';
import {
    IOsImageVersionRequest,
    IOsImageVersionResponse,
} from 'common/api/resources/OsImageVersion';
import { QueryBuilder } from 'common/api/resources/Request/QueryBuilder';
import { IPaginatedWithSearch } from 'common/api/resources/Request/request';

export const osImages = {
    list: (params?: IPaginatedWithSearch) => api.get<IPaginateApiResponse<IOsImageResponse[]>>('os_images', {
        params: new QueryBuilder(params).build(),
    }),
    item: (id: number) => api.get<IApiResponse<IOsImageResponse>>(`os_images/${id}`),
    remove: (id: number) => api.delete(`os_images/${id}`),
    removeBatch: (ids: number[]) => api.delete('os_images', { data: { ids } }),
    create: (data: IOsImageCreateRequest) => api.post<IApiResponse<IOsImageResponse>>('os_images', data),
    update: (id: number, data: IOsImageCreateRequest) => api.put<IApiResponse<IOsImageResponse>>(`os_images/${id}`, data),
    patch: (id: number, data: IOsImageUpdateRequest) => api.patch<IApiResponse<IOsImageResponse>>(`os_images/${id}`, data),

    versions: {
        create: (id: number, data: IOsImageVersionRequest) => api.post<IApiResponse<IOsImageVersionResponse>>(`os_images/${id}/versions`, data),
    },
};
