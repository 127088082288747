// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import {
    Dispatch,
    bindActionCreators,
} from 'redux';
import { RootState } from 'admin/core/store';
import * as userActions from 'admin/user/actions';
import * as settingsActions from 'common/modules/settings/actions';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { PageHeader } from 'admin/common/components/PageHeader/PageHeader';
import {
    Action,
    Icon,
    IntentType,
    Text,
    Toolbar,
    ToolbarGroup,
    Tooltip,
    Translate,
} from '@plesk/ui-library';
import List from 'common/components/List/List';
import {
    TABLE_ACTIONS,
    USERS,
} from 'admin/user/constants/tests';
import { EmptyView } from 'common/components/EmptyView/EmptyView';
import Filters,
{ ISelectOption } from 'admin/user/components/Filters';
import { ValueType } from 'react-select';
import Roles from 'admin/user/components/Roles/Roles';
import {
    IRoleResponse,
    roles,
} from 'common/api/resources/Role';
import { limitGroups } from 'common/api/resources/LimitGroup';
import RoleInfo from 'admin/user/components/RoleInfo';
import { UserStatus } from 'common/api/resources/User';
import { Status } from 'admin/user/containers/Users/Styles';
import Usage from 'admin/common/components/Usage/Usage';
import { Dialog } from 'admin/common/components/Dialog/Dialog';
import UserForm from 'admin/user/containers/UserForm';
import { StyledActions } from 'common/components/Actions/Styles';
import { dataCySelector } from 'common/tests/selectors';
import { statusMappings } from 'admin/user/constants/translation';
import queryString from 'query-string';
import {
    ICONS,
    INTENT_TYPE,
    SIZE,
} from 'common/constants';
import { LanguageName } from 'common/components/LanguageName';
import { formatTableDate } from 'common/date';
import { UsageContainer } from 'admin/common/components/Usage/Styles';
import ButtonWithInputConfirmation
    from 'common/components/ButtonWithInputConfirmation/ButtonWithInputConfirmation';
import CopyText from 'common/containers/CopyText/CopyText';
import {
    getActionColumnProps,
    reloadListData,
} from 'common/helpers/list';
import { ILimit } from 'common/api/resources/model';
import { RouteComponentProps } from 'react-router';
import { useRequestCancellationEffect } from 'common/hooks/useRequestCancellationEffect';
import { hasPermission } from 'common/modules/permission/selectors';
import { PERMISSION_LIST } from 'common/modules/permission/constants';

export const DELETE_CONFIRMATION: string = 'delete';

interface IFilters {
    status?: string;
    role_id?: string;
    limit_group_id?: string;
}

interface IQueryParams {
    role_id?: string;
    limit_group_id?: string;
}

const columns = [{
    width: '1%',
    key: 'colId',
    title: <Translate content="user.list.id" />,
}, {
    width: '10%',
    key: 'colLanguage',
    title: <Translate content="user.list.language" />,
    cellProps: {
        className: 'cell-bold',
    },
}, {
    width: '1%',
    key: 'colVerified',
    title: <Translate content="user.list.verified" />,
    cellProps: {
        style: { textAlign: 'center' },
    },
}, {
    width: '15%',
    key: 'colEmail',
    title: <Translate content="user.list.email" />,
    cellProps: {
        className: 'cell-bold',
    },
}, {
    width: '10%',
    key: 'colStatus',
    title: <Translate content="user.list.status" />,
}, {
    width: '15%',
    key: 'colRole',
    title: <Translate content="user.list.role" />,
}, {
    width: '15%',
    key: 'colLimitGroup',
    title: <Translate content="user.list.limitGroup" />,
}, {
    width: '90px',
    key: 'colServers',
    title: <Translate content="user.list.servers" />,
}, {
    width: '90px',
    key: 'colRunning',
    title: <Translate content="user.list.running" />,
}, {
    width: '90px',
    key: 'colAdditionalIps',
    title: <Translate content="user.list.additionalIps" />,
}, {
    key: 'colCreated',
    title: <Translate content="user.list.created" />,
}, getActionColumnProps(),
];

const statusColor: {
    [key in UserStatus]: IntentType;
} = {
    [UserStatus.ACTIVE]: INTENT_TYPE.SUCCESS,
    [UserStatus.LOCKED]: INTENT_TYPE.DANGER,
    [UserStatus.SUSPENDED]: INTENT_TYPE.WARNING,
};

const USAGE_START_COLOR = '#00ff00';
const USAGE_END_COLOR = '#ff0000';

export type UsersProps =
    RouteComponentProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const Users: React.FC<UsersProps> = ({
    location,
    users,
    user,
    isLoadingList,
    authId,
    canManageUsers,
    isRemoving,
    userActions: {
        getUsers,
        getUser,
        removeUser,
        removeUsers,
        unsetUserItem,
    },
    settingsActions: {
        getSettings,
    },
}) => {
    const query: IQueryParams = queryString.parse(location.search);

    const [filters, setFilters] = React.useState<IFilters>({
        role_id: query.role_id,
        limit_group_id: query.limit_group_id,
    });

    const [selectedRole, setSelectedRole] = React.useState<ISelectOption>();
    const [selectedLimitGroup, setSelectedLimitGroup] = React.useState<ISelectOption>();
    const [selection, setSelection] = React.useState<string[]>([]);
    const [isDialogOpened, setIsDialogOpened] = React.useState(false);
    const [role, setRole] = React.useState<IRoleResponse | null>(null);

    const handleStatusFilterChange = (option: ValueType<ISelectOption>) => {
        setFilters(values => ({
            ...values,
            status: option ? (option as ISelectOption).value  : undefined,
        }));
    };

    const handleRoleFilterChange = (option: ValueType<ISelectOption>) => {
        const typedOption = option as ISelectOption;

        setSelectedRole(typedOption);

        setFilters(values => ({
            ...values,
            role_id: typedOption?.value,
        }));
    };

    const handleLimitGroupFilterChange = (option: ValueType<ISelectOption>) => {
        const typedOption = option as ISelectOption;

        setSelectedLimitGroup(typedOption);

        setFilters(values => ({
            ...values,
            limit_group_id: typedOption?.value,
        }));
    };

    const filtered = filters !== undefined && Object.values(filters).some(filter => !!filter);

    const handleSelectionChange = canManageUsers ? (indexes: string[]) => setSelection(indexes) : undefined;

    const closeDialog = () => {
        setIsDialogOpened(false);
    };

    const loadPaginated = (page: number) => getUsers({ filters, page });

    const isFirstLoading = useRequestCancellationEffect(
        token => getUsers({ filters }, token),
        [filters]
    );

    const handleCreate = () => {
        unsetUserItem();
        setIsDialogOpened(true);
    };

    const handleEdit = (id: number) => () => {
        getUser(id);
        setIsDialogOpened(true);
    };

    const handleDelete = (id: number) => async () => {
        await removeUser(id, true);
        reloadListData(users, loadPaginated);
    };

    const handleBatchDelete = async (force?: boolean) => {
        const result = await removeUsers(selection.map(id => parseInt(id, 10)), force);
        setSelection([]);
        reloadListData(users, loadPaginated, result.length);
    };

    const getProgress = (current: number, max: number) => (current < max) ? (current * 100) / max : 100;

    const getLimit = (usage: number, limit?: ILimit) => {
        if (limit !== undefined && limit.is_enabled) {
            return (
                <Usage
                    progress={getProgress(usage, limit.limit)}
                    title={<Translate
                        content="user.usage"
                        params={{
                            max: limit.limit,
                            current: usage,
                        }}/>
                    }
                    startColor={USAGE_START_COLOR}
                    endColor={USAGE_END_COLOR}
                />
            );
        }

        return (
            <UsageContainer>
                <Translate
                    content='user.usageUnlimited'
                    params={{ current: usage }}
                />
            </UsageContainer>
        );
    };

    const vsCountOfSelectedUsers = selection.reduce((vsCount, selectedId) => {
        const selectedUser = users.data.find(item => item.id === parseInt(selectedId, 10));
        return selectedUser ? vsCount + selectedUser.limit_usage.servers : vsCount;
    }, 0);

    const userWithServersCount = selection.reduce((userCount, selectedId) => {
        const selectedUser = users.data.find(item => item.id === parseInt(selectedId, 10));
        return selectedUser && selectedUser.limit_usage.servers > 0 ? userCount + 1 : userCount;
    }, 0);

    const data = users.data.map(item => ({
        colId: item.id,
        colLanguage: <LanguageName language={item.language}/>,
        colVerified: item.has_verified_email && (<Icon name="check-mark" />),
        colEmail: item.email,
        colStatus: (
            <Status intent={statusColor[item.status]} compact={true}>
                {statusMappings[item.status]}
            </Status>
        ),
        colRole: (
            <Roles
                roles={item.roles}
                onClick={setRole}
            />
        ),
        colActions: canManageUsers ? (
            <StyledActions>
                <Tooltip title={<Translate content="user.tooltips.edit"/>}>
                    <Action
                        icon="pencil"
                        className="action-icon"
                        onClick={handleEdit(item.id)}
                        data-cy={dataCySelector(item.id, TABLE_ACTIONS.EDIT)}
                    />
                </Tooltip>
                <ButtonWithInputConfirmation
                    disabled={item.id === authId}
                    isLoading={item.is_deleting}
                    confirmation={item.email}
                    translations={{
                        title: (
                            <Translate content="user.buttonWithConfirmation.title" />
                        ),
                        text: (
                            <Translate
                                content="user.buttonWithConfirmation.confirmationText"
                                params={{
                                    email: (
                                        <CopyText isInline={true}>
                                            <Text bold>{item.email}</Text>
                                        </CopyText>
                                    ),
                                    count: (
                                        <Text bold>
                                            {item.limit_usage.servers}
                                        </Text>
                                    ),
                                }}
                            />
                        ),
                        confirmationButton: (
                            <Translate content="user.buttonWithConfirmation.button" />
                        ),
                        label: (
                            <Translate content="user.buttonWithConfirmation.label" />
                        ),
                        tooltip: (<Translate content="user.buttonWithConfirmation.tooltips.enabled" />),
                    }}
                    handleConfirm={handleDelete(item.id)}
                    data-cy={dataCySelector(item.id, TABLE_ACTIONS.REMOVE)}
                    icon={ICONS.RECYCLE}
                />
            </StyledActions>
        ) : null,
        colLimitGroup: item.limit_group && (<>{item.limit_group.name}</>),
        colServers: getLimit(item.limit_usage.servers, item.limit_group?.vms),
        colRunning: getLimit(item.limit_usage.running_servers, item.limit_group?.running_vms),
        colAdditionalIps: getLimit(item.limit_usage.additional_ips, item.limit_group?.additional_ips),
        colCreated: formatTableDate(item.created_at),
        key: item.id.toString(),
        disabled: item.id === authId,
    }));

    React.useEffect(() => {
        getSettings();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        // Load necessary data for role and limit group filters.
        if (query.role_id) {
            (async () => {
                const { data: { data: { id, name } } } = await roles.item(parseInt(query.role_id!, 10));
                setSelectedRole({ value: id.toString(), label: name });
            })();
        }


        if (query.limit_group_id) {
            (async () => {
                const { data: { data: { id, name } } } = await limitGroups.item(parseInt(query.limit_group_id!, 10));
                setSelectedLimitGroup({ value: id.toString(), label: name });
            })();
        }
    }, [query.role_id, query.limit_group_id]);

    return (
        <>
            <PageHeader
                title={<Translate content="user.title"/>}
                buttonText="user.addBtn"
                buttonIcon="user"
                onButtonClick={handleCreate}
                isButtonShown={canManageUsers}
            />
            <List
                emptyView={
                    <EmptyView
                        title="user.emptyView.title"
                        description="user.emptyView.description"
                        buttonText="user.emptyView.buttonText"
                        onButtonClick={handleCreate}
                        icon="user"
                    />
                }
                toolbar={
                    <Toolbar>
                        {canManageUsers &&
                            <ToolbarGroup title="remove-users">
                                <ButtonWithInputConfirmation
                                    disabled={selection.length === 0}
                                    isLoading={isRemoving}
                                    ghost={false}
                                    confirmation={DELETE_CONFIRMATION}
                                    withForceCheckbox={userWithServersCount > 0}
                                    translations={{
                                        button: (
                                            <Translate content="user.batchDelete"/>
                                        ),
                                        tooltip: (
                                            <Translate content="user.confirmationBatchRemove.tooltip"/>
                                        ),
                                        title: (
                                            <Translate content="user.confirmationBatchRemove.title"/>
                                        ),
                                        text: (
                                            <Translate
                                                content={'user.confirmationBatchRemove.text'}
                                                params={{
                                                    userCount: <Text bold>{selection.length - userWithServersCount}</Text>,
                                                }}
                                            />
                                        ),
                                        label: (
                                            <Translate
                                                content="user.confirmationBatchRemove.label"
                                                params={{ confirmation: <CopyText isInline={true}>{DELETE_CONFIRMATION}</CopyText> }}
                                            />
                                        ),
                                        forceLabel: userWithServersCount > 0 ? (
                                            <Translate
                                                content="user.confirmationBatchRemove.forceLabel"
                                                params={{
                                                    userCount: <Text bold>{selection.length}</Text>,
                                                    serverCount: <Text bold>{vsCountOfSelectedUsers}</Text>,
                                                }}
                                            />
                                        ) : undefined,
                                        confirmationButton: (
                                            <Translate content="user.confirmationBatchRemove.button"/>
                                        ),
                                    }}
                                    handleConfirm={handleBatchDelete}
                                    icon={ICONS.RECYCLE}
                                    data-cy={TABLE_ACTIONS.BATCH_REMOVE}
                                />
                            </ToolbarGroup>
                        }
                        <Filters
                            onStatusFilterChange={handleStatusFilterChange}
                            selectedRole={selectedRole}
                            onRoleFilterChange={handleRoleFilterChange}
                            selectedLimitGroup={selectedLimitGroup}
                            onLimitGroupFilterChange={handleLimitGroupFilterChange}
                        />
                    </Toolbar>
                }
                loadItems={loadPaginated}
                meta={users.meta}
                isFirstLoading={isFirstLoading}
                isLoading={isLoadingList}
                columns={columns}
                data={data}
                filtered={filtered || isLoadingList}
                selection={selection}
                onSelectionChange={handleSelectionChange}
                data-cy={USERS.TABLE}
            />
            <RoleInfo
                role={role}
                onClose={() => setRole(null)}
            />
            <Dialog
                heading={
                    <Translate
                        content={user.id ? 'user.form.titleEdit' : 'user.form.titleAdd'}
                        params={user.id ? { user: user.email } : {}}
                    />
                }
                closeHandler={closeDialog}
                isOpen={isDialogOpened}
                size={SIZE.XS}
            >
                <UserForm onSubmit={closeDialog} />
            </Dialog>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    users: state.user.list,
    user: state.user.item,
    isLoadingList: state.app.loadingFlags.has(LOADING_FLAGS.USER_LIST),
    isRemoving: state.app.loadingFlags.has(LOADING_FLAGS.REMOVE_USER_ITEM),
    authId: state.auth.user.id,
    canManageUsers: hasPermission(state, PERMISSION_LIST.MANAGE_USERS),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    userActions: bindActionCreators(userActions, dispatch),
    settingsActions: bindActionCreators(settingsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Users);
