// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import { IIconResponse } from 'common/api/resources/Icon';
import { Icon } from 'admin/icon/components/CellIcon/Styles';

export interface ICellIconProps {
    icon: IIconResponse | null;
    name: string;
}

const CellIcon: React.FC<ICellIconProps> = ({
    icon,
    name,
}) => (
    <>
        {icon && (
            <Icon
                width={16}
                src={icon.url}
                alt={icon.name}
            />
        )}
        {name}
    </>
);

export default CellIcon;
