// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import {
    api,
    IApiResponse,
} from 'common/api/resources/Response';
import {
    ICpuItem,
    IDisksItem,
    IGraphResponse,
    IMemoryItem,
    INetworkItem,
} from 'common/api/resources/Graph/model';

export const graphs = {
    cpu: (uuid: string) => api.get<IApiResponse<IGraphResponse<ICpuItem[]>>>(`usage/cpu/${uuid}`),
    network: (uuid: string) => api.get<IApiResponse<IGraphResponse<INetworkItem[]>>>(`usage/network/${uuid}`),
    disks: (uuid: string) => api.get<IApiResponse<IGraphResponse<IDisksItem[]>>>(`usage/disks/${uuid}`),
    memory: (uuid: string) => api.get<IApiResponse<IGraphResponse<IMemoryItem[]>>>(`usage/memory/${uuid}`),
};
