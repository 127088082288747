// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import {
    Footer,
    FooterHelp,
    Title,
} from 'admin/application/components/FormLayout/Styles';
import {
    Grid,
    GridCol,
} from '@plesk/ui-library';
import { Button } from 'admin/common/components/Button/Button';
import {
    INTENT_TYPE,
    SIZE,
} from 'common/constants';
import { Loader } from 'common/components';

export interface IFormLayoutProps {
    title: React.ReactNode;
    children: React.ReactNode;
    isSaving: boolean;
    isLoading: boolean;
    submitBtn: {
        text: React.ReactNode;
        disabled: boolean;
        help?: React.ReactNode;
    };
}

export const FormLayout: React.FC<IFormLayoutProps> = ({
    title,
    children,
    isLoading,
    isSaving,
    submitBtn,
}) => (
    <Loader isLoading={isLoading}>
        <Title>
            {title}
        </Title>
        <Grid minColumnWidth={300} maxColumnsNumber={2} gap={SIZE.MD}>
            <GridCol>
                {children}
            </GridCol>
        </Grid>
        <Footer>
            <Button
                isLoading={isSaving}
                type="submit"
                form="form"
                intent={INTENT_TYPE.PRIMARY}
                size={SIZE.LG}
                disabled={submitBtn.disabled}
            >
                {submitBtn.text}
            </Button>
            {submitBtn && (
                <FooterHelp>
                    {submitBtn.help}
                </FooterHelp>
            )}
        </Footer>
    </Loader>
);
