// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import {
    BackupNodeType,
    IBackupNodeCreateRequest,
    IBackupNodeResponse,
} from 'common/api/resources/BackupNode';
import { RootState } from 'admin/core/store';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import * as backupNodesActions from 'admin/backupNode/actions';
import * as formErrorsActions from 'common/modules/app/formErrors/actions';
import {
    Form,
    FormField,
    FormFieldText,
    Section,
    Translate,
} from '@plesk/ui-library';
import { Button } from 'admin/common/components/Button/Button';
import { INTENT_TYPE } from 'common/constants';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import {
    FormErrorsState,
    IFormErrors,
} from 'common/modules/app/formErrors/reducer';
import AsyncSelectInput from 'common/components/Select/AsyncSelectInput';
import { createOptionsLoader } from 'common/components/Select/helpers';
import { connect } from 'react-redux';
import { ISelectOption } from 'common/components';
import {
    IValidationRules,
    numericRule,
    requiredRule,
    validate,
} from 'common/validator';
import {
    computeResources,
    IComputeResourceResponse,
} from 'common/api/resources/ComputeResource';
import { SegmentedControl } from 'common/components/SegmentedControl/SegmentedControl';
import { humanizeType } from 'admin/backupNode/constants';
import { nestStringProperties } from 'common/modules/app/formErrors/selectors';
import FormErrors from 'common/components/Form/FormErrors/FormErrors';
import { FORM } from 'admin/backupNode/constants/tests';

interface IBackupNodeFormProps {
    onSubmit: () => void;
}

export type BackupNodeFormProps =
    IBackupNodeFormProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

const typeOptions = Object.values(BackupNodeType).map((type) => ({
    value: type.toString(),
    title: humanizeType(type),
}));

const convertNodeToRequest = (node: IBackupNodeResponse): IBackupNodeCreateRequest => ({
    name: node.name,
    type: node.type,
    credentials: { ...node.credentials },
    compute_resources: node.compute_resources ? node.compute_resources.map((cr) => cr.id) : [],
});

const computeResourceToOption = (cr: IComputeResourceResponse) => ({
    label: cr.name,
    value: cr.id.toString(),
});

const loadComputeResourceOptions = createOptionsLoader(
    computeResources.list,
    computeResourceToOption
);

const renderNotAccessibleErrors = (formErrors: FormErrorsState) => {
    const errors: IFormErrors = formErrors;

    if (!errors?.notAccessible) {
        return null;
    }

    return (
        <FormErrors
            title={<Translate content="backupNode.nodeNotAccessible"/>}
            errors={errors.notAccessible}
        />
    );
};

export const BackupNodeForm: React.FC<BackupNodeFormProps> = ({
    item,
    formErrors,
    isCreating,
    onSubmit,
    backupNodesActions: {
        createBackupNode,
        updateBackupNode,
    },
    formErrorsActions: {
        setFormErrors,
    },
}) => {
    const [ submitValues, setSubmitValues ] = React.useState(convertNodeToRequest(item));
    const [ selectedCRs, setSelectedCRs ] = React.useState<ISelectOption[]>(item.compute_resources.map(computeResourceToOption));
    const isUpdating = !!item.id;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const createOnPropertyChange = (property: string) => (value: any) => {
        setSubmitValues((state) => ({
            ...state,
            [property]: value,
        }));
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const createOnCredentialsPropertyChange = (property: string) => (value: any) => {
        setSubmitValues((state) => ({
            ...state,
            credentials: {
                ...state.credentials,
                [property]: value,
            },
        }));
    };

    const renderCredentials = (type: BackupNodeType) => {
        switch (type) {
        case BackupNodeType.SSH_RSYNC:
            return (
                <>
                    <FormFieldText
                        name="credentials[host]"
                        size="fill"
                        label={<Translate content="backupNode.form.host" />}
                        required={true}
                        onChange={createOnCredentialsPropertyChange('host')}
                    />
                    <FormFieldText
                        name="credentials[port]"
                        size="fill"
                        label={<Translate content="backupNode.form.port" />}
                        required={true}
                        onChange={createOnCredentialsPropertyChange('port')}
                    />
                    <FormFieldText
                        name="credentials[login]"
                        size="fill"
                        label={<Translate content="backupNode.form.login" />}
                        required={true}
                        onChange={createOnCredentialsPropertyChange('login')}
                    />
                    <FormFieldText
                        name="credentials[key]"
                        size="fill"
                        label={<Translate content="backupNode.form.key" />}
                        required={!isUpdating}
                        multiline={true}
                        onChange={createOnCredentialsPropertyChange('key')}
                    />
                    <FormFieldText
                        name="credentials[storage_path]"
                        size="fill"
                        label={<Translate content="backupNode.form.storagePath" />}
                        required={true}
                        onChange={createOnCredentialsPropertyChange('storage_path')}
                    />
                </>
            );
        case BackupNodeType.HETZNER_STORAGE_BOX:
            return (
                <>
                    <FormFieldText
                        name="credentials[host]"
                        size="fill"
                        label={<Translate content="backupNode.form.host" />}
                        required={true}
                        onChange={createOnCredentialsPropertyChange('host')}
                    />
                    <FormFieldText
                        name="credentials[login]"
                        size="fill"
                        label={<Translate content="backupNode.form.login" />}
                        required={true}
                        onChange={createOnCredentialsPropertyChange('login')}
                    />
                    <FormFieldText
                        name="credentials[key]"
                        size="fill"
                        label={<Translate content="backupNode.form.key" />}
                        required={!isUpdating}
                        multiline={true}
                        onChange={createOnCredentialsPropertyChange('key')}
                    />
                </>
            );
        }

        return null;
    };

    const handleSubmit = async (data: IBackupNodeCreateRequest) => {
        let rules: IValidationRules = {
            name: requiredRule(<Translate content="validate.fieldRequired" />),
            type: requiredRule(<Translate content="validate.fieldRequired" />),
        };

        switch (data.type) {
        case BackupNodeType.SSH_RSYNC:
            rules = {
                ...rules,
                'credentials.host': requiredRule(<Translate content="validate.fieldRequired"/>),
                'credentials.port': numericRule(<Translate content="validate.fieldNumeric"/>),
                'credentials.login': requiredRule(<Translate content="validate.fieldRequired"/>),
                'credentials.storage_path': requiredRule(<Translate content="validate.fieldRequired"/>),
            };

            if (!isUpdating) {
                rules['credentials.key'] = requiredRule(<Translate content="validate.fieldRequired" />);
            }
            break;

        case BackupNodeType.HETZNER_STORAGE_BOX:
            rules = {
                ...rules,
                'credentials.host': requiredRule(<Translate content="validate.fieldRequired"/>),
                'credentials.login': requiredRule(<Translate content="validate.fieldRequired"/>),
            };

            if (!isUpdating) {
                rules['credentials.key'] = requiredRule(<Translate content="validate.fieldRequired" />);
            }
            break;
        }

        const errors = validate<IBackupNodeCreateRequest>(data, rules);

        if (Object.keys(errors).length) {
            setFormErrors(errors);
            return;
        }

        if (isUpdating) {
            await updateBackupNode(item.id, data);
        } else {
            await createBackupNode(data);
        }
        onSubmit();
    };

    const handleCRsChange = (crs: ISelectOption[]) => {
        if (!crs) {
            crs = [];
        }
        setSelectedCRs(crs);
        setSubmitValues((state) => ({
            ...state,
            compute_resources: crs.map((opt) => opt.value as number),
        }));
    };

    return (
        <>
            <Form
                id="backupNodeForm"
                footerClassName="hidden"
                onSubmit={handleSubmit}
                values={submitValues}
                errors={formErrors}
                hideRequiredLegend={true}
                submitButton={false}
                cancelButton={false}
                applyButton={false}
                vertical={true}
            >
                <Section>
                    <FormFieldText
                        name="name"
                        size="fill"
                        label={<Translate content="backupNode.form.name" />}
                        required={true}
                        onChange={createOnPropertyChange('name')}
                    />
                    <FormField
                        name="type"
                        required={true}
                        value={submitValues.type}
                        label={<Translate content="backupNode.form.type" />}
                    >
                        <SegmentedControl
                            buttons={typeOptions}
                            selected={submitValues.type}
                            onChange={createOnPropertyChange('type')}
                            data-cy={FORM.TYPE_SELECTOR}
                        />
                    </FormField>
                    <FormField
                        name="compute_resources"
                        label={<Translate content="backupNode.form.computeResources" />}
                    >
                        <AsyncSelectInput
                            value={selectedCRs}
                            loadOptions={loadComputeResourceOptions}
                            onChange={handleCRsChange}
                            debounceTimeout={1000}
                            additional={{ page: 1 }}
                            menuPosition="fixed"
                            isMulti={true}
                        />
                    </FormField>
                    {renderCredentials(submitValues.type)}
                </Section>
            </Form>
            {
                renderNotAccessibleErrors(formErrors)
            }
            <Button
                type="submit"
                form="backupNodeForm"
                fill={true}
                intent={INTENT_TYPE.PRIMARY}
                size="lg"
                isLoading={isCreating}
            >
                <Translate content="backupNode.saveBtn" />
            </Button>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    item: state.backupNode.item,
    formErrors: nestStringProperties(state),
    isCreating: state.app.loadingFlags.has(LOADING_FLAGS.BACKUP_NODE_SAVE),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    backupNodesActions: bindActionCreators(backupNodesActions, dispatch),
    formErrorsActions: bindActionCreators(formErrorsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(BackupNodeForm);
