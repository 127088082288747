// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import {
    UserDataButton,
    UserDataContainer,
} from 'common/components/ServerUserData/Styles';
import {
    Button,
    CodeEditor,
    Drawer,
    Form,
    FormField,
    ToolbarGroup,
    Translate,
} from '@plesk/ui-library';
import isEmpty from 'validator/lib/isEmpty';
import UserDataHeader from 'common/components/ServerUserData/UserDataHeader/UserDataHeader';
import { validate } from 'common/validator';
import { PageSubHeader } from 'client/common/components';
import { Footer } from 'common/components/styles/DrawerFooter';
import { OS_TYPES } from 'common/constants';

export interface IUserDataProps {
    isSelected: boolean;
    isLinux: boolean;
    onSetUserData: (data: string | undefined) => void;
    userData?: string;
    disabled?: boolean;
}

interface IUserDataFormValues {
    user_data: string;
}

/* eslint-disable no-useless-escape */
const PLACEHOLDERS = {
    linux: `#cloud-config
runcmd:
  - echo "echo \"Hello from User Data\"" > /root/hello-world-user-data.sh
  - [ chmod, +x, "/root/hello-world-user-data.sh" ]
    `,
    windows: `#cloud-config
set_timezone: America/New York
write_files:
  - encoding: gzip+base64
    content: H4sIAAAAAAAA/0tNzshX8EjNycnnAgBnCE3RCwAAAA==
    path: c:\\cmd-hello-world.cmd
    permissions: '0o466'

  - encoding: gzip+base64
    content: H4sIAAAAAAAA/ysvyixJ1c3ILy5R8EjNyckHAKTHjIgQAAAA
    path: c:\\Program Files\\Cloudbase Solutions\\Cloudbase-Init\\LocalScripts\\power-shell-hello-world.ps1
    permissions: '0o466'
    `,
};
/* eslint-enable no-useless-escape */

export const UserData: React.FunctionComponent<IUserDataProps> = ({
    isSelected,
    isLinux,
    onSetUserData,
    userData,
    disabled,
}) => {
    const [submitValues, setSubmitValues] = React.useState({
        user_data: userData !== undefined ? userData : '',
    });
    const [isDrawerOpened, setPopoverDrawer] = React.useState(false);
    const [formErrors, setFormErrors] = React.useState({});

    React.useEffect(() => {
        if (submitValues.user_data === '') {
            setSubmitValues({ user_data: PLACEHOLDERS[isLinux ? OS_TYPES.LINUX : OS_TYPES.WINDOWS] });
        }
    }, [isLinux, submitValues.user_data]);

    const toggleDrawer = () => setPopoverDrawer(!isDrawerOpened);
    const handleClear = () => {
        toggleDrawer();
        setSubmitValues({ user_data: PLACEHOLDERS[isLinux ? OS_TYPES.LINUX : OS_TYPES.WINDOWS] });
        onSetUserData(undefined);
    };
    const handleSubmit = async (values: IUserDataFormValues) => {
        const errors = validate(values, {
            user_data: {
                validator: isEmpty,
                message: <Translate content="validate.fieldRequired" />,
                comparison: true,
            },
        });

        if (Object.keys(errors).length) {
            setFormErrors(errors);
            return;
        }

        toggleDrawer();
        onSetUserData(values.user_data);
    };
    const handleEditorChange = (data: string) => setSubmitValues({ user_data: data });

    return (
        <>
            <PageSubHeader title="servers.create.userData.sectionTitle" />
            <UserDataButton
                isSelected={isSelected}
                disabled={disabled}
                onClick={toggleDrawer}
            >
                <div>User Data</div>
            </UserDataButton>
            <Drawer
                onClose={toggleDrawer}
                title={<Translate content="servers.create.userData.drawerTitle" />}
                isOpen={isDrawerOpened}
                size="md"
            >
                <UserDataContainer>
                    <Form
                        id="ServerUserDataForm"
                        errors={formErrors}
                        values={submitValues}
                        onSubmit={handleSubmit}
                        hideRequiredLegend={true}
                        submitButton={false}
                        cancelButton={false}
                        applyButton={false}
                        vertical={true}
                    >
                        <UserDataHeader isLinux={isLinux} />
                        <FormField name="user_data">
                            <CodeEditor
                                onChange={handleEditorChange}
                                mode="yaml"
                            >
                                {submitValues.user_data}
                            </CodeEditor>
                        </FormField>
                    </Form>
                    <Footer>
                        <ToolbarGroup title="actions">
                            <Button type="submit" form="ServerUserDataForm" intent="primary">
                                <Translate content="servers.create.userData.setBtn" />
                            </Button>
                            <Button onClick={handleClear} intent="primary" ghost={true}>
                                <Translate content="servers.create.userData.clearBtn" />
                            </Button>
                        </ToolbarGroup>
                    </Footer>
                </UserDataContainer>
            </Drawer>
        </>
    );
};

export default UserData;
