// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import React from 'react';
import { connect } from 'react-redux';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import * as taskActions from 'admin/task/actions';
import TaskTable from 'admin/task/containers/TaskTable';
import { useRequestCancellationEffect } from 'common/hooks/useRequestCancellationEffect';

interface IVmTasksTabProps {
    id: number;
}

export type VmTasksTabProps =
    IVmTasksTabProps &
    ReturnType<typeof mapDispatchToProps>;

export const VmTasksTab: React.FC<VmTasksTabProps> = ({
    id,
    taskActions: {
        getTaskList,
    },
}) => {
    const filters = {
        compute_resource_vm_id: id,
    };

    const isFirstLoading = useRequestCancellationEffect(
        token => getTaskList({ filters }, token),
        []
    );

    return (
        <TaskTable
            isFirstLoading={isFirstLoading}
            borderless={true}
            filters={filters}
        />
    );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    taskActions: bindActionCreators(taskActions, dispatch),
});

export default connect(null, mapDispatchToProps)(VmTasksTab);
