// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import { CardWithSwitch } from 'common/components/CardWithSwitch/CardWithSwitch';
import {
    Icon,
    Link,
    Popover,
    Translate,
} from '@plesk/ui-library';
import { RootState } from 'admin/core/store';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { connect } from 'react-redux';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import * as settingsActions from 'common/modules/settings/actions';
import React from 'react';
import {
    ICONS,
    SIZE,
} from 'common/constants';
import {
    CardWithSwitchLink,
    CardWithSwitchLinkIcon,
} from 'common/components/CardWithSwitch/Styles';
import { Dialog } from 'admin/common/components/Dialog/Dialog';
import { formatTableDate } from 'common/date';
import { MANAGEMENT_NODE_BACKUP_SCHEDULE_CARD } from 'admin/managementNodeBackup/constants/tests';
import ManagementNodeBackupSettingsForm
    from 'admin/managementNodeBackup/containers/SettingsForm/SettingsForm';
import * as managementNodeBackupActions from 'admin/managementNodeBackup/actions';
import { hasPermission } from 'common/modules/permission/selectors';
import { PERMISSION_LIST } from 'common/modules/permission/constants';

export type SettingsCardProps =
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const SettingsCard: React.FC<SettingsCardProps> = ({
    backupSettings,
    isSwitching,
    nextScheduledDate,
    settingsActions: { saveSettings },
    canManageBackups,
    managementNodeBackupActions: {
        getNextScheduledDate,
    },
}) => {
    const [isDialogOpened, setIsDialogOpened] = React.useState(false);

    const handleSettingsFormSubmit = () => {
        setIsDialogOpened(false);
        getNextScheduledDate();
    };

    const handleBackupsEnabledChange = async (isEnabled: boolean) => {
        await saveSettings({
            management_node_backup: {
                ...backupSettings,
                is_enabled: isEnabled,
            },
        });
    };

    const renderSwitchTooltip = () => {
        if (!canManageBackups) {
            return undefined;
        }

        if (!backupSettings.backup_node_id) {
            return (<Translate content="managementNodeBackup.settingsCard.content.backupNodeNotSpecified" />);
        }

        if (backupSettings.is_enabled) {
            return (<Translate content="managementNodeBackup.settingsCard.content.safeDisable" />);
        }

        return undefined;
    };

    return (
        <>
            <CardWithSwitch
                title={<Translate content="managementNodeBackup.settingsCard.title" />}
                isEnabled={backupSettings.is_enabled}
                isLoading={isSwitching}
                handleChange={handleBackupsEnabledChange}
                isDisabled={!backupSettings.backup_node_id || !canManageBackups}
                tooltip={renderSwitchTooltip()}
                switch-data-cy={MANAGEMENT_NODE_BACKUP_SCHEDULE_CARD.SWITCH}
            >
                <>
                    {backupSettings.is_enabled && nextScheduledDate && (
                        <div>
                            <Translate
                                content="managementNodeBackup.settingsCard.content.enabled"
                                params={{
                                    schedule_date: formatTableDate(nextScheduledDate),
                                }}
                            />
                        </div>
                    )}
                    {backupSettings.limit.is_enabled ? (
                        <Translate content="managementNodeBackup.settingsCard.content.limit.limited" params={{
                            limit: backupSettings.limit.limit,
                        }}/>
                    ) : (
                        <Translate content="managementNodeBackup.settingsCard.content.limit.unlimited"/>
                    )}
                    <Popover
                        target={(
                            <Icon name={ICONS.INFO_CIRCLE} size="12" className="pul-form-field__full-description"/>
                        )}>
                        <Translate content="managementNodeBackup.settingsCard.content.limit.description" />
                    </Popover>
                    <CardWithSwitchLink>
                        <CardWithSwitchLinkIcon name="calendar-clock"/>
                        <Link onClick={() => setIsDialogOpened(true)} disabled={!canManageBackups}>
                            <Translate content="managementNodeBackup.settingsCard.content.settings"/>
                        </Link>
                    </CardWithSwitchLink>
                </>
            </CardWithSwitch>
            <Dialog
                heading={<Translate content="managementNodeBackup.settingsCard.settingsTitle"/>}
                closeHandler={() => setIsDialogOpened(false)}
                isOpen={isDialogOpened}
                size={SIZE.XS}
            >
                <ManagementNodeBackupSettingsForm onSubmit={handleSettingsFormSubmit} />
            </Dialog>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    backupSettings: state.settings.management_node_backup,
    isSwitching: state.app.loadingFlags.has(LOADING_FLAGS.SAVE_APP_SETTINGS),
    nextScheduledDate: state.managementNodeBackup.nextScheduledDate,
    canManageBackups: hasPermission(state, PERMISSION_LIST.MANAGE_MANAGEMENT_NODE_BACKUPS),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    settingsActions: bindActionCreators(settingsActions, dispatch),
    managementNodeBackupActions: bindActionCreators(managementNodeBackupActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsCard);
