// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import { Button } from 'common/components/styles/Button';
import { generateSSHKeypair } from 'common/services/SSHKeyGenerator';

export interface ISSHGenerateButton extends React.HTMLProps<HTMLButtonElement> {
    tooltip: React.ReactNode;
    email: string;
    onSuccessfulGenerate: (publicKey: string, privateKey: string) => void;
}

const SSHGenerateButton: React.FC<ISSHGenerateButton> = ({
    tooltip,
    email,
    onSuccessfulGenerate,
    children,
    ...btnProps
}) => {
    const [isGeneratingSshKey, setGeneratingSshKey] = React.useState(false);

    const download = (filename: string, text: string) => {
        const element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', filename);

        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    };

    const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

    const generateSshKeyPairAndDownload = async () => {
        setGeneratingSshKey(true);
        const pair = await generateSSHKeypair(email);

        download('id_rsa.pub', pair.public);
        // This sleep allow to download multiple files in Safari ...
        await sleep(1);
        download('id_rsa.priv', pair.private);
        onSuccessfulGenerate(pair.public, pair.private);
        setGeneratingSshKey(false);
    };

    return (
        // Ignore is required 'cause we do not have proper props type for button and
        // when we user spread operator on remain part of SSHGenerateButton props we
        // got an error.
        // fixme add proper type for button props.
        // @ts-ignore
        <Button
            tooltip={tooltip}
            state={isGeneratingSshKey ? 'loading' : null}
            onClick={generateSshKeyPairAndDownload}
            ghost={true}
            icon="plus"
            {...btnProps}
        >
            {children}
        </Button>
    );
};

export default SSHGenerateButton;
