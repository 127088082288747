// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import { Wrapper } from 'common/components/TopBar/Styles';
import { Button } from '@plesk/ui-library';
import { INTENT_TYPE } from 'common/constants';

export interface ITopBarProps extends React.HTMLProps<HTMLElement> {
    linkText?: React.ReactNode;
    onLinkClick?: () => void;
    intent?: INTENT_TYPE;
    color?: string;
    backgroundColor?: string;
    dataCy?: string;
}

export const TopBar: React.FC<ITopBarProps> = ({
    children,
    onLinkClick,
    linkText,
    color,
    backgroundColor,
    dataCy,
}) => (
    <Wrapper color={color} backgroundColor={backgroundColor} data-cy={dataCy}>
        {children}
        {onLinkClick && (
            <Button ghost={true} intent="primary" onClick={onLinkClick}>
                {linkText}
            </Button>
        )}
    </Wrapper>
);

export default TopBar;
