// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import styled from 'styled-components';
import { COLORS } from 'admin/core/theme';

export const IpDash = styled.div`
  color: ${COLORS.GREY_80};
  font-size: 16px;
  margin-top: 30px;
  text-align: center;
`;
