// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import {
    Button,
    Icon,
    List,
    Translate,
} from '@plesk/ui-library';
import SettingsWrapper from 'admin/settings/containers/Settings/SettingsWrapper';
import * as settingsActions from 'common/modules/settings/actions';
import { connect } from 'react-redux';
import {
    Dispatch,
    bindActionCreators,
} from 'redux';
import { NotificationEvent } from 'common/api/resources/Settings';
import { RootState } from 'admin/core/store';
import { StyledActions } from 'common/components/Actions/Styles';
import {
    ICONS,
    SIZE,
} from 'common/constants';
import { StyledTable } from 'common/components/styles/StyledTable';
import { Dialog } from 'admin/common/components/Dialog/Dialog';
import NotificationSettingsEditDialog from 'admin/settings/containers/Settings/NotificationSettingsEditDialog';
import { getActionColumnProps } from 'common/helpers/list';
import { dataCySelector } from 'common/tests/selectors';
import { NOTIFICATION_SETTINGS } from 'admin/settings/constants/tests';
import { FormFieldSwitch } from 'common/components/Form/FormFieldSwitch/FormFieldSwitch';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';

export const NotificationTranslationMap = {
    [NotificationEvent.SERVER_CREATE]: 'settings.notifications.serverCreate',
    [NotificationEvent.SERVER_RESET_PASSWORD]: 'settings.notifications.serverResetPassword',
    [NotificationEvent.USER_RESET_PASSWORD]: 'settings.notifications.userResetPassword',
    [NotificationEvent.USER_VERIFY_EMAIL]: 'settings.notifications.userVerifyEmail',
    [NotificationEvent.PROJECT_USER_INVITE]: 'settings.notifications.projectUserInvite',
    [NotificationEvent.PROJECT_USER_LEFT]: 'settings.notifications.projectUserLeft',
    [NotificationEvent.SERVER_INCOMING_TRAFFIC_EXCEEDED]: 'settings.notifications.serverIncomingTrafficExceeded',
    [NotificationEvent.SERVER_OUTGOING_TRAFFIC_EXCEEDED]: 'settings.notifications.serverOutgoingTrafficExceeded',
};

export type NotificationsSettingsProps =
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

const columns = [
    {
        key: 'colEvent',
        title: <Translate content="settings.notifications.table.event" />,
    }, {
        key: 'colSend',
        title: <Translate content="settings.notifications.table.send" />,
        align: 'center',
    },
    getActionColumnProps(),
];

export const NotificationsSettings: React.FC<NotificationsSettingsProps> = ({
    notifications,
    isSavingSettings,
    settingsActions: { saveSettings },
}) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const [savingEvent, setSavingEvent] = React.useState(NotificationEvent.SERVER_CREATE);
    const [editEvent, setEditEvent] = React.useState<string>(NotificationEvent.SERVER_CREATE);

    const handleOpenEditDialog = (event: string) => () => {
        setIsOpen(true);
        setEditEvent(event);
    };
    const handleCloseEditDialog = () => setIsOpen(false);

    const handleSwitchEvent = React.useCallback((event: string) => () => {
        setSavingEvent(event as NotificationEvent);
        saveSettings({
            notifications: {
                ...notifications,
                [event]: {
                    ...notifications[event],
                    enabled: !notifications[event].enabled,
                },
            },
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notifications]);


    const data = React.useMemo(() => {
        let rows = [];

        for (const event in notifications) {
            if (Object.prototype.hasOwnProperty.call(notifications, event)) {
                rows.push({
                    colEvent: <Translate content={NotificationTranslationMap[event]} />,
                    colSend: (
                        <FormFieldSwitch
                            loading={isSavingSettings && savingEvent === event}
                            disabled={isSavingSettings}
                            onChange={handleSwitchEvent(event)}
                            name={`notifications[${event}][enabled]`}
                            label={<></>}
                            data-cy={dataCySelector(event, NOTIFICATION_SETTINGS.ENABLED)}
                        />
                    ),
                    colActions: (
                        <StyledActions>
                            <Button
                                ghost={true}
                                className="action-icon"
                                onClick={handleOpenEditDialog(event)}
                                icon={<Icon name={ICONS.PENCIL_DIALOG} />}
                                data-cy={dataCySelector(event, NOTIFICATION_SETTINGS.ACTION_EDIT)}
                            />
                        </StyledActions>
                    ),
                    key: event,
                });
            }
        }

        return rows;
    }, [notifications, handleSwitchEvent, isSavingSettings, savingEvent]);

    return (
        <>
            <SettingsWrapper title="settings.titles.notifications">
                <StyledTable>
                    <List
                        columns={columns}
                        data={data}
                    />
                </StyledTable>
            </SettingsWrapper>
            <Dialog
                heading={(
                    <Translate content={'settings.notifications.editNotification'} />
                )}
                closeHandler={handleCloseEditDialog}
                isOpen={isOpen}
                size={SIZE.MD}
                data-cy={NOTIFICATION_SETTINGS.EDIT_DIALOG}
            >
                <NotificationSettingsEditDialog
                    event={editEvent}
                />
            </Dialog>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    notifications: state.settings.notifications,
    isSavingSettings: state.app.loadingFlags.has(LOADING_FLAGS.SAVE_APP_SETTINGS),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    settingsActions: bindActionCreators(settingsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsSettings);
