// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import styled from 'styled-components';

export const StyledActions = styled.div`
  display: flex;
  justify-content: flex-end;

  .pul-button {
    color: #1d6a90;
  }

  .pul-action__icon {
    color: #737373;
    padding: 6px;
    vertical-align: -3px;

    &:hover {
        color: #222;
        background: #ececec;
        border-radius: 2px;
    }
  }
`;
