// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import moment,
{ Moment } from 'moment';

export const mockDate = (): void => {
    Date.now = jest.fn(() => (new Date('2021-01-02 11:22:33').getTime()));
};

export const formatTableDate = (date: Moment | Date | string | number): string =>
    moment(date).format('YYYY-MM-DD HH:mm:ss');

export const formatUpdateDate = (date: string, utc: boolean = false): string => {
    const now = utc ? moment(date).utc() : moment(date);

    return now.format('D MMMM YYYY HH:mm:ss');
};
