// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import * as iconActions from 'admin/icon/actions';
import * as formErrorsActions from 'common/modules/app/formErrors/actions';
import { connect } from 'react-redux';
import { RootState } from 'admin/core/store';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { PageHeader } from 'admin/common/components/PageHeader/PageHeader';
import { StyledTable } from 'common/components/styles/StyledTable';
import {
    Action,
    List,
    Tooltip,
    Translate,
} from '@plesk/ui-library';
import InfiniteScroll from 'common/components/InfinityScroll/InfinityScroll';
import {
    ISelectOption,
    Loader,
} from 'common/components';
import { EmptyView } from 'common/components/EmptyView/EmptyView';
import { StyledActions } from 'common/components/Actions/Styles';
import { Dialog } from 'admin/common/components/Dialog/Dialog';
import IconForm from 'admin/icon/containers/IconForm';
import ButtonWithConfirmation from 'common/components/ButtonWithConfirmation';
import { dataCySelector } from 'common/tests/selectors';
import { TABLE_ACTIONS } from 'admin/icon/constants/tests';
import { ICONS } from 'common/constants';
import { IconTypeText } from 'admin/icon/components/IconTypeText';
import { ValueType } from 'react-select';
import IconFilter from 'admin/common/components/Filters/IconFilter/IconFilter';
import { getActionColumnProps } from 'common/helpers/list';

export type IconsProps =
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export enum IconTableColumns {
    ID = 'colId',
    NAME = 'colName',
    ICON = 'colIcon',
    URL = 'colUrl',
    TYPE = 'colType',
    ACTIONS = 'colActions',
}

const columns = [{
    width: '1%',
    key: IconTableColumns.ID,
    title: <Translate content="icon.list.id" />,
}, {
    width: '1%',
    key: IconTableColumns.ICON,
    cellProps: {
        className: 'cell-icons',
    },
    title: '',
}, {
    width: '15%',
    key: IconTableColumns.NAME,
    title: <Translate content="icon.list.name" />,
}, {
    width: '15%',
    key: IconTableColumns.TYPE,
    title: <Translate content="icon.list.type" />,
}, getActionColumnProps(),
];

export const Icons: React.FC<IconsProps> = ({
    item,
    list,
    isLoadingList,
    isLoadingItem,
    iconsActions: {
        getIcons,
        getIcon,
        loadIconsOnScroll,
        removeIcon,
    },
    formErrorsActions: {
        clearFormErrors,
    },
    unsetIconItem: unsetItem,
}) => {
    const [isDialogOpen, setDialogOpen] = React.useState(false);

    React.useEffect(() => {
        getIcons();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleRemove = (id: number) => async () => await removeIcon(id);
    const handleEdit = (id: number) => () => {
        getIcon(id);
        setDialogOpen(true);
    };

    const handleTypeFilterChange = (option: ValueType<ISelectOption>) => {
        const type = option as ISelectOption;
        type ? getIcons({
            filters: {
                type: type.value,
            },
        }) : getIcons();
    };

    const data = list.data.map((icon) => {
        const actions = (
            <StyledActions>
                <Tooltip title={<Translate content="icon.tooltip.edit"/>}>
                    <Action
                        icon="pencil"
                        className="action-icon"
                        onClick={handleEdit(icon.id)}
                        data-cy={dataCySelector(icon.id, TABLE_ACTIONS.EDIT)}
                    />
                </Tooltip>
                <ButtonWithConfirmation
                    isLoading={icon.is_deleting}
                    translations={{
                        title: (
                            <Translate content="icon.removePopover.title" />
                        ),
                        button: (
                            <Translate content="icon.removePopover.button" />
                        ),
                        tooltip: (
                            <Translate content="icon.removePopover.tooltip" />
                        ),
                    }}
                    handleConfirm={handleRemove(icon.id)}
                    data-cy={dataCySelector(icon.id, TABLE_ACTIONS.REMOVE)}
                    icon="recycle"
                />
            </StyledActions>
        );

        return {
            [IconTableColumns.ID]: icon.id,
            [IconTableColumns.ICON]: (
                <img width={25} src={icon.url} alt={icon.name} />
            ),
            [IconTableColumns.NAME]: icon.name,
            [IconTableColumns.TYPE]: <IconTypeText type={icon.type} />,
            [IconTableColumns.ACTIONS]: actions,
            key: icon.id.toString(),
        };
    });

    const openCreateDialog = () => {
        unsetItem();
        setDialogOpen(true);
    };
    const closeDialog = () => {
        setDialogOpen(false);
        clearFormErrors();
    };

    return (
        <>
            <PageHeader
                isButtonShown={list.data.length > 0}
                title={<Translate content="icon.title"/>}
                buttonText="icon.addBtn"
                buttonIcon={ICONS.SMILE}
                onButtonClick={openCreateDialog}
            />
            <IconFilter onTypeFilterChange={handleTypeFilterChange}/>
            <StyledTable>
                <InfiniteScroll
                    loadMore={loadIconsOnScroll}
                    hasMore={!!list.links.next}
                >
                    <Loader isLoading={isLoadingList}>
                        <List
                            emptyView={
                                <EmptyView
                                    title="icon.emptyView.title"
                                    description="icon.emptyView.description"
                                    buttonText="icon.emptyView.buttonText"
                                    onButtonClick={openCreateDialog}
                                    icon={ICONS.SMILE}
                                />
                            }
                            columns={columns}
                            data={data}
                        />
                    </Loader>
                </InfiniteScroll>
            </StyledTable>
            <Dialog
                heading={<Translate content={item.id ? 'icon.dialog.editTitle' : 'icon.dialog.createTitle'} />}
                closeHandler={closeDialog}
                isOpen={isDialogOpen}
                size="xs"
            >
                <Loader isLoading={isLoadingItem} center={false}>
                    <IconForm onSubmit={closeDialog}/>
                </Loader>
            </Dialog>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    item: state.icon.item,
    list: state.icon.list,
    isLoadingList: state.app.loadingFlags.has(LOADING_FLAGS.ICON_LIST),
    isLoadingItem: state.app.loadingFlags.has(LOADING_FLAGS.ICON_ITEM),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    iconsActions: bindActionCreators(iconActions, dispatch),
    formErrorsActions: bindActionCreators(formErrorsActions, dispatch),
    unsetIconItem: bindActionCreators(iconActions.unsetIconItem, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Icons);
