// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

export const FILTERS = {
    COMPUTE_RESOURCE: 'compute-resource-filter',
    COMPUTE_RESOURCE_VM: 'compute-resource-vm-filter',
    COMPUTE_RESOURCE_VM_STATUS: 'compute-resource-vm-status-filter',
    BACKUP_NODE: 'backup-node-filter',
    USER: 'user-filter',
    VIRTUALIZATION_TYPE: 'virtualization-type-filter',
};

export const HEADER = {
    LANGUAGE_DROPDOWN: 'language-dropdown',
    LICENSE_TOP_BAR: 'license-top-bar',
};
