// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import { Loader } from 'common/components';
import { IVmResponse } from 'common/api/resources/ComputeResourceVm';
import { RouteComponentProps } from 'react-router';
import { VncDialog as VncDialogComponent } from 'common/components/VncDialog/VncDialog';

export type VncDialogProps =
    RouteComponentProps<{ id: string }> &
    {
        vm: IVmResponse;
        isSettingVncUp: boolean;
        isLoadingVm: boolean;
        getComputeResourceVm: (id: number) => void;
    };

const VncDialog: React.FC<VncDialogProps> = ({
    match: { params },
    vm,
    isLoadingVm,
    isSettingVncUp,
    getComputeResourceVm,
}) => {
    React.useLayoutEffect(() => {
        if (!vm.id) {
            getComputeResourceVm(parseInt(params.id, 10));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vm]);

    return (
        <Loader isLoading={isLoadingVm}>
            {vm.id && (
                <VncDialogComponent
                    vm={vm}
                    isSettingVncUp={isSettingVncUp}
                />
            )}
        </Loader>
    );
};

export default VncDialog;
