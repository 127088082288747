// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as snapshotActions from 'common/modules/snapshot/actions';
import { combineReducers } from 'redux';
import {
    ActionType,
    getType,
} from 'typesafe-actions';
import {
    IPaginateApiResponse,
    paginateInitialState,
} from 'common/api/resources/Response';
import {
    SNAPSHOT_STATUS_PROCESSING,
    SNAPSHOT_STATUS_AVAILABLE,
} from 'common/modules/project/constants';
import { ISnapshotResponse } from 'common/api/resources/Snapshot';

interface ISnapshots {
    list: IPaginateApiResponse<ISnapshotResponse[]>;
}

export type SnapshotAction = ActionType<typeof snapshotActions>;
export type SnapshotState = Readonly<ISnapshots>;

export default combineReducers<SnapshotState, SnapshotAction>({
    list: (state = paginateInitialState, action: SnapshotAction) => {
        switch (action.type) {
        case getType(snapshotActions.setSnapshots):
            return action.payload;
        case getType(snapshotActions.removeSnapshotItem):
            return {
                ...state,
                data: state.data.filter(item => item.id !== action.payload),
            };
        case getType(snapshotActions.updateSnapshotItemById):
            return {
                ...state, data: [...state.data.map(item => {
                    if (item.id === action.payload.id) {
                        return { ...action.payload };
                    }

                    return item;
                })],
            };
        case getType(snapshotActions.setSnapshotItemIsProcessing):
            return {
                ...state, data: [...state.data.map(item => {
                    if (item.id === action.payload) {
                        return { ...item, status: SNAPSHOT_STATUS_PROCESSING };
                    }

                    return item;
                })],
            };
        case getType(snapshotActions.unsetSnapshotItemIsProcessing):
            return {
                ...state, data: [...state.data.map(item => {
                    if (item.id === action.payload) {
                        return { ...item, status: SNAPSHOT_STATUS_AVAILABLE };
                    }

                    return item;
                })],
            };
        case getType(snapshotActions.addSnapshot):
            return { ...state, data: [action.payload, ...state.data] };
        case getType(snapshotActions.setItemIsLoading):
            return {
                ...state, data: [...state.data.map(item => {
                    if (item.id === action.payload) {
                        return { ...item, isLoading: true };
                    }

                    return item;
                })],
            };
        case getType(snapshotActions.unsetItemIsLoading):
            return {
                ...state, data: [...state.data.map(item => {
                    if (item.id === action.payload) {
                        return { ...item, isLoading: false };
                    }

                    return item;
                })],
            };
        default:
            return state;
        }
    },
});
