// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

export const ELEMENTS = {
    STATUS: 'status',
    COMPUTE_RESOURCE: 'link-to-compute-resource',
    COMPUTE_RESOURCE_VM: 'link-to-compute-resource-vm',
};

export const ACTIONS = {
    RESTORE: 'restore',
    DELETE: 'delete',
    NEXT_BACKUP_AT: 'next-backup-at',
    CREATE_BUTTON: 'backup-create-button',
    ENABLE_SWITCH: 'backup-enable-switch',
};

export const BACKUPS = {
    TABLE: 'backups-table',
};
