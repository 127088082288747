// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import styled from 'styled-components';

export const ComputeResourceVmNameContainer = styled.div`
  display: flex;
  align-items: center;

  img {
    height: 14px;
    margin-right: 7px;
  }

  span {
    font-weight: bold;
  }
`;

export const MigrationFromTitle = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const MigrationFormTitleLink = styled.a`
  font-size: 12px;
  line-height: 16px;

  .pul-icon {
    margin-bottom: 1px;
  }
`;
