// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import styled from 'styled-components';
import { Icon } from '@plesk/ui-library';
import { COLORS } from 'common/theme';

export const CardWithSwitchContainer = styled.div`
    margin-top: 30px;
`;

export const CardWithSwitchBody = styled.div`
    margin-top: 8px;
`;

export const CardWithSwitchLink = styled.div`
    margin-top: 6px;
`;

export const CardWithSwitchLinkIcon = styled(Icon)`
    margin-right: 8px;
`;

export interface ICardProps {
    size?: string | number;
}

export const Card = styled.div<ICardProps>`
    flex-basis: ${({ size }) => size ? size : '726px'};
    padding: 20px;
    background: ${COLORS.WHITE_0};
    box-shadow: 0 0 4px rgba(34, 34, 34, 0.24);
    border-radius: 2px;
`;

export const CardsContainer = styled.div`
    display: flex;
`;

export const CardRow = styled.div`
    display: flex;
    flex-direction: row;
`;

export interface ICardRowColumnProps {
    size?: string | number;
}

export const CardRowColumn = styled.div<ICardRowColumnProps>`
    flex-basis: ${({ size }) => size ? size : '100%'};
    padding: 4px;
`;
