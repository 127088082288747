// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import { Translate } from '@plesk/ui-library';
import SelectInput,
{ ISelectInputProps } from 'common/components/Select/SelectInput';
import { ISelectOption } from 'common/components/SelectInput';

const options = [
    { value: 1, label: <Translate content="weekday.monday" /> },
    { value: 2, label: <Translate content="weekday.tuesday" /> },
    { value: 3, label: <Translate content="weekday.wednesday" /> },
    { value: 4, label: <Translate content="weekday.thursday" /> },
    { value: 5, label: <Translate content="weekday.friday" /> },
    { value: 6, label: <Translate content="weekday.saturday" /> },
    { value: 7, label: <Translate content="weekday.sunday" /> },
];

export interface IWeekdaysSelectorProps extends Omit<ISelectInputProps<ISelectOption>, 'options' | 'value' > {
    value: number[];
}

const WeekdaysSelector: React.FC<IWeekdaysSelectorProps> = ({ value, ...remain }) => (
    <SelectInput
        {...remain}
        value={value ? value.map(i => options[i - 1]) : []}
        options={options}
    />
);

export default WeekdaysSelector;
