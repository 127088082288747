// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import { Status } from 'common/components/ServerTabs/components/TabStatus/Styles';

export interface ITabStatusProps  {
    intent: 'danger' | 'warning' | 'success' | 'info' | 'muted' | 'inactive';
}

export const TabStatus: React.FC<ITabStatusProps> = ({ intent, ...props }) => (
    <Status
        intent={intent}
        compact
        {...props}
    />
);

export default TabStatus;
