// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import { createCustomAction } from 'typesafe-actions';
import * as types from 'common/modules/permission/constants/types';
import { Dispatch } from 'redux';
import { HTTP_CODES } from 'common/api/constants';
import {
    IPermissionResponse,
    permissions,
} from 'common/api/resources/Permission';

export const setPermissionList = createCustomAction(
    types.SET_PERMISSION_LIST,
    (data: IPermissionResponse[]) => ({ payload: data })
);
export const getPermissions = () => (dispatch: Dispatch) => permissions.list().then(result => {
    if (result.status === HTTP_CODES.OK) {
        dispatch(setPermissionList(result.data.data));
    }

    return result;
});
