// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import { IPaginatedWithSearch } from 'common/api/resources/Request/request';
import { CancelTokenSource } from 'axios';
import {
    api,
    IApiResponse,
    IPaginateApiResponse,
} from 'common/api/resources/Response';
import { QueryBuilder } from 'common/api/resources/Request/QueryBuilder';
import {
    IClusterImportComputeResourceResource,
    IClusterImportRequest,
    IClusterImportResponse,
} from 'common/api/resources/ClusterImport/model';

export const clusterImports = {
    item: (id: number) => api.get<IApiResponse<IClusterImportResponse>>(`cluster_imports/${id}`),
    list: (params?: IPaginatedWithSearch, cancelToken?: CancelTokenSource) => api.get<IPaginateApiResponse<IClusterImportResponse[]>>('cluster_imports', {
        params: new QueryBuilder(params).build(),
        cancelToken: cancelToken?.token,
    }),
    create: (data: IClusterImportRequest) => api.post<IApiResponse<IClusterImportResponse>>('cluster_imports', data),
    prepare: (id: number, data: IClusterImportRequest) => api.post<IApiResponse<IClusterImportResponse>>(`cluster_imports/${id}/prepare`, data),
    remove: (id: number) => api.delete(`cluster_imports/${id}`),
    run: (id: number, ids: number[]) => api.post(`cluster_imports/${id}/run`, { ids }),
    getComputeResources: (id: number, params?: IPaginatedWithSearch, cancelToken?: CancelTokenSource) =>
        api.get<IPaginateApiResponse<IClusterImportComputeResourceResource[]>>(`cluster_imports/${id}/compute_resources`, {
            params: new QueryBuilder(params).build(),
            cancelToken: cancelToken?.token,
        }),
    refresh: (id: number) => api.post(`cluster_imports/${id}/compute_resources/refresh`),
};
