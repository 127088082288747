// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import {
    Button,
    Popover,
    Translate,
} from '@plesk/ui-library';
import { ButtonWrapper } from 'admin/application/components/LoginLinkContentPopover/Styles';
import CopyText from 'common/containers/CopyText/CopyText';

const prefilledProps = [
    {
        title: 'email',
        about: <Translate content="application.prefilledProperties.email"/>,
    },
    {
        title: 'domain',
        about: <Translate content="application.prefilledProperties.domain"/>,
    },
    {
        title: 'passwd',
        about: <Translate content="application.prefilledProperties.password"/>,
    },
];

export const PrefilledPropertiesPopover = () => (
    <Popover
        target={(
            <ButtonWrapper>
                <Button
                    ghost={true}
                    intent="primary"
                    icon="info-circle"
                >
                    <Translate content="application.prefilledProperties.btnInfo" />
                </Button>
            </ButtonWrapper>
        )}
        placement="right"
    >
        <h3>
            <Translate content="application.prefilledProperties.title" />
        </h3>
        <p>
            <Translate content="application.prefilledProperties.description" />
        </p>
        {prefilledProps.map((prop) => (
            <p key={prop.title}>
                <div style={{ float: 'left' }}>
                    <CopyText>{`"${prop.title}"`}</CopyText>
                </div>
                {prop.about && (
                    <>
                        &nbsp;— {prop.about}
                    </>
                )}
            </p>
        ))}
    </Popover>
);
