// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import React from 'react';
import Form, {
    FormProps,
    ISubmitEvent as ISubmitEventOrigin,
} from '@rjsf/core';

type FormData = Record<string, unknown>;

export interface ISubmitEvent extends ISubmitEventOrigin<FormData>{}

export interface IJSONSchemaForm {
    isValid(): boolean;
    scrollTo(): void;
}

interface IJSONSchemaFormProps extends React.PropsWithChildren<FormProps<FormData>> {}

export default React.forwardRef<IJSONSchemaForm, IJSONSchemaFormProps>((props, ref) => {
    const formRef = React.useRef<Form<FormData> & { formElement: HTMLFormElement }>(null);

    React.useImperativeHandle(ref, () => ({
        isValid: () => {
            if (!formRef.current) {
                return false;
            }
            const form = formRef.current;

            // SIO-2601 & SIO-3637
            // We have only this way to make sure that JSON form is valid ...

            // Trigger displaying of errors on page.
            form.submit();

            // Call validation.
            // This code was copied from inside of JSONForm.
            return form.validate((form.state as { formData: FormData }).formData).errors.length === 0;
        },
        scrollTo: () => {
            formRef.current?.formElement.scrollIntoView();
        },
    }));

    return (
        <Form
            {...props}
            ref={formRef}
        />
    );
});
