// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import SelectInput,
{ ISelectInputProps } from 'common/components/Select/SelectInput';
import { ISelectOption } from 'common/components/SelectInput';
import { ValueType } from 'react-select';
import * as React from 'react';
import { Dialog } from 'admin/common/components/Dialog/Dialog';
import {
    INTENT_TYPE,
    SIZE,
} from 'common/constants';
import { Paragraph } from 'common/components/ServerTabs/RescueTab/Styles';
import { Button } from 'admin/common/components/Button/Button';

export interface ISelectWithConfirmationProps extends ISelectInputProps<ISelectOption> {
    dialog: {
        title: React.ReactNode;
        text: React.ReactNode;
        button: React.ReactNode;
    };
    onChange: (value: ValueType<ISelectOption>) => void;
}

export const SelectWithConfirmation: React.FC<ISelectWithConfirmationProps> = ({
    dialog,
    onChange,
    ...selectInputProps
}) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const [valueForConfirm, setValueForConfirm] = React.useState<ValueType<ISelectOption>>(null);

    const handleDialogClose = () => setIsOpen(false);
    const handleSelectChange = (option: ValueType<ISelectOption>): void => {
        setValueForConfirm(option);
        setIsOpen(true);
    };
    const handleButtonClick = () => {
        setIsOpen(false);
        onChange(valueForConfirm);
    };

    return (
        <>
            <SelectInput {...selectInputProps}
                onChange={handleSelectChange}
            />
            <Dialog
                heading={dialog.title}
                closeHandler={handleDialogClose}
                isOpen={isOpen}
                size={SIZE.XS}
            >
                <Paragraph>{dialog.text}</Paragraph>
                <Button
                    fill={true}
                    onClick={handleButtonClick}
                    size={SIZE.LG}
                    intent={INTENT_TYPE.PRIMARY}
                >
                    {dialog.button}
                </Button>
            </Dialog>
        </>
    );
};

export default SelectWithConfirmation;
