// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import {
    ActionType,
    createCustomAction,
} from 'typesafe-actions';
import * as types from 'common/modules/app/viewType/constants';
import { VIEW_TYPE } from 'common/constants';

export interface IViewTypeActions {
    setResponseError(data: VIEW_TYPE): ActionType<typeof setViewType>;
}

export const setViewType = createCustomAction(
    types.SET_VIEW_TYPE,
    (data: VIEW_TYPE) => ({ payload: data })
);
