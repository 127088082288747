// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import {
    Form,
    FormField,
    FormFieldCheckbox,
    setIn,
    Translate,
} from '@plesk/ui-library';
import { IVmResponse } from 'common/api/resources/ComputeResourceVm';
import MigrationFormMetric from 'admin/computeResourceVm/containers/MigrationForm/MigrationFormMetric';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import * as serversMigrationActions from 'admin/serversMigration/actions';
import { connect } from 'react-redux';
import { RootState } from 'admin/core/store';
import { nestStringProperties } from 'common/modules/app/formErrors/selectors';
import * as formErrorsActions from 'common/modules/app/formErrors/actions';
import { IServerMigrationRequest } from 'common/api/resources/ServersMigration';
import { Button } from 'admin/common/components/Button/Button';
import { INTENT_TYPE } from 'common/constants';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import AsyncSelectInput from 'common/components/Select/AsyncSelectInput';
import {
    createOptionsLoader,
    filteredOptionsLoader,
} from 'common/components/Select/helpers';
import {
    computeResources,
    IComputeResourceResponse,
} from 'common/api/resources/ComputeResource';
import { ISelectOption } from 'common/components';
import { validate } from 'common/validator';
import FormErrors from 'common/components/Form/FormErrors/FormErrors';
import { TEST } from 'admin/computeResourceVm/constants';
import isInt from 'validator/lib/isInt';
import {
    convertToDataUnit,
    DataUnit,
} from 'common/helpers/units';
import { IFormProps } from 'common/components/Form/types';

interface IMigrationFormProps {
    servers: IVmResponse[];
    onSubmit?: () => void;
}

interface IFormErrors {
    migration: string[];
}

export type MigrationFormProps =
    IMigrationFormProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const MigrationForm: React.FC<MigrationFormProps> = ({
    onSubmit,
    servers,
    isCreating,
    formErrors,
    serversMigrationActions: {
        createServersMigration,
    },
    formErrorsActions: {
        clearFormErrors,
        setFormErrors,
    },
}) => {
    const sourceIds = React.useMemo(() => servers.map((vm: IVmResponse) => vm.compute_resource!.id), [servers]);
    const [submitValues, setSubmitValues] = React.useState<IServerMigrationRequest>({
        is_live: true,
        preserve_ips: true,
        destination_compute_resource_id: 0,
        servers: servers.map(vm => vm.id),
    });
    const [ selectedDst, setSelectedDst ] = React.useState<ISelectOption|null>();
    React.useEffect(() => () => { clearFormErrors(); }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSubmit = async (values: IServerMigrationRequest) => {
        const rules = {
            destination_compute_resource_id: ({
                validator: (value: string) => isInt(value, { gt: 0 }),
                message: <Translate content="validate.fieldRequired" />,
                comparison: false,
            }),
        };

        const errors = validate(values, rules);

        if (Object.keys(errors).length) {
            setFormErrors(errors);
            return;
        }

        await createServersMigration(values);
        onSubmit?.();
    };

    const handleFieldChange = (field: string, value: string|boolean) => {
        let tmp = setIn(submitValues, field, value);
        if (field === 'is_live' && value) {
            tmp = setIn(tmp, 'preserve_ips', true);
        }
        setSubmitValues(tmp);
    };

    const handleDestinationChange = (opt: ISelectOption) => {
        setSubmitValues(state => ({
            ...state,
            destination_compute_resource_id: parseInt(opt.value as string, 10),
        }));
        setSelectedDst(opt);
    };

    const loadComputeResourceOptions = filteredOptionsLoader(
        createOptionsLoader(
            computeResources.list,
            (cr: IComputeResourceResponse) => ({
                label: cr.name,
                value: cr.id.toString(),
            })
        ),
        (item) => !sourceIds.includes(parseInt(item.value as string, 10))
    );

    const totalMemory = servers.reduce((acc, vm) => acc + vm.specifications.ram, 0);
    const totalDisk = servers.reduce((acc, vm) => acc + (
        vm.usage.disk === undefined ? 0 : vm.usage.disk.actual_size
    ), 0);

    return (
        <>
            <MigrationFormMetric
                data-cy={TEST.SERVERS_MIGRATION_FORM.METRICS.VM}
                title={<Translate content="computeResource.servers.migrationForm.totalServers" />}
                value={servers.length}
            />&nbsp;
            <MigrationFormMetric
                data-cy={TEST.SERVERS_MIGRATION_FORM.METRICS.MEMORY}
                title={<Translate content="computeResource.servers.migrationForm.totalMemory" />}
                value={`${convertToDataUnit(totalMemory, DataUnit.MiB)} MiB`}
            />&nbsp;
            <MigrationFormMetric
                data-cy={TEST.SERVERS_MIGRATION_FORM.METRICS.DISK}
                title={<Translate content="computeResource.servers.migrationForm.totalDisk" />}
                value={`${convertToDataUnit(totalDisk, DataUnit.GiB)} GiB`}
            />
            <Form
                id="serversMigrationForm"
                footerClassName="hidden"
                onFieldChange={handleFieldChange}
                onSubmit={handleSubmit}
                values={submitValues}
                errors={formErrors}
                hideRequiredLegend={true}
                submitButton={false}
                cancelButton={false}
                applyButton={false}
                vertical={true}
            >
                <FormField
                    name="destination_compute_resource_id"
                    label={<Translate content="computeResource.servers.migrationForm.destination" />}
                >
                    {({ getId }: IFormProps<unknown>) => (
                        <AsyncSelectInput
                            value={selectedDst}
                            loadOptions={loadComputeResourceOptions}
                            onChange={handleDestinationChange}
                            debounceTimeout={1000}
                            additional={{ page: 1 }}
                            menuPosition="fixed"
                            isMulti={false}
                            data-cy={TEST.SERVERS_MIGRATION_FORM.DESTINATION}
                            inputId={getId()}
                        />
                    )}
                </FormField>
                <FormFieldCheckbox
                    name="is_live"
                    label={<Translate content="computeResource.servers.migrationForm.live"/>}
                />
                <FormFieldCheckbox
                    name="preserve_ips"
                    label={<Translate content="computeResource.servers.migrationForm.preserveIPs"/>}
                    disabled={submitValues.is_live}
                />
                {
                    (formErrors as IFormErrors)?.migration &&
                        <FormErrors
                            title={<Translate content="computeResource.servers.migrationForm.errors"/>}
                            errors={(formErrors as IFormErrors).migration}
                        />
                }
                <Button
                    type="submit"
                    form="serversMigrationForm"
                    fill={true}
                    intent={INTENT_TYPE.PRIMARY}
                    size="lg"
                    isLoading={isCreating}
                >
                    <Translate content="computeResource.servers.migrationForm.submit" />
                </Button>
            </Form>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    formErrors: nestStringProperties(state),
    isCreating: state.app.loadingFlags.has(LOADING_FLAGS.SERVERS_MIGRATION_CREATE),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    serversMigrationActions: bindActionCreators(serversMigrationActions, dispatch),
    formErrorsActions: bindActionCreators(formErrorsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(MigrationForm);
