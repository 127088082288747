// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import { Loader } from 'common/components/Loader/Loader';
import { StyledTable } from 'common/components/styles/StyledTable';
import {
    Pagination,
    IList,
    List as UIList,
} from '@plesk/ui-library';
import { IMeta } from 'common/api/resources/Response';
import { EmptyView } from 'common/components/EmptyView/EmptyView';

export interface ICustomListProps extends IList<React.ReactNode | string> {
    isLoading?: boolean;
    isFirstLoading?: boolean;
    borderless?: boolean;
    meta?: IMeta;
    loadItems?: (page: number) => void;
}

const List: React.FC<ICustomListProps> = ({
    isLoading,
    isFirstLoading,
    borderless,
    meta,
    loadItems,
    emptyView,
    filtered,
    ...props
}) => {
    if (filtered) {
        emptyView = (
            <EmptyView
                title="list.emptyViewOfFilters.title"
                description="list.emptyViewOfFilters.description"
                icon="filter"
            />
        );
    }

    return (
        <StyledTable borderless={borderless}>
            {isFirstLoading && isLoading ? (
                <Loader isLoading={isLoading}/>
            ) : (
                <UIList
                    {...props}
                    emptyView={emptyView}
                    filtered={filtered}
                    loading={isLoading}
                    totalRows={meta?.total}
                    pagination={(
                        // We add fragment to avoid default UI library behavior where paginator is wrapped in popover
                        <>
                            <Pagination
                                current={meta?.current_page}
                                total={meta?.last_page}
                                onSelect={loadItems}
                                loading={isLoading}
                            />
                        </>
                    )}
                />
            )}
        </StyledTable>
    );
};

export default List;
