// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import { createCustomAction } from 'typesafe-actions';
import {
    SET_IS_AUTH,
    SET_USER,
    SET_USER_CREDENTIALS,
    UPDATE_USER,
} from 'common/modules/auth/constants';
import { Dispatch } from 'redux';
import { HTTP_CODES } from 'common/api/constants';
import * as formErrorsActions from 'common/modules/app/formErrors/actions';
import {
    setIsLoading,
    unsetIsLoading,
} from 'common/modules/app/loadingFlags/actions';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { setResponseError } from 'common/modules/app/responseError/actions';
import {
    accounts,
    IAccountUpdateRequest,
} from 'common/api/resources/Account';
import {
    auth,
    BEARER_TOKEN_TYPE,
    IAuthLinkRequest,
    ILoginRequest,
    IRegisterRequest,
    IResetPasswordRequest,
    IUpdatePasswordRequest,
    IUserCredentials,
    IVerifyEmailRequest,
} from 'common/api/resources/Auth';
import { IUserResponse } from 'common/api/resources/User';
import { AUTH_PERSIST_KEY } from 'common/redux/constants';
import { api } from 'common/api/resources/Response';
import { resetState } from 'common/modules/root/actions';
import { setLanguage } from 'common/modules/app/language/actions';
import { ICommonState } from 'common/store';
import { setSettings } from 'common/modules/settings/actions';
import { CancelTokenSource } from 'axios';

export const setUserCredentials = createCustomAction(
    SET_USER_CREDENTIALS,
    (data: IUserCredentials) => ({ payload: data })
);
export const setUser = createCustomAction(
    SET_USER,
    (data: IUserResponse) => ({ payload: data })
);
export const updateUser = createCustomAction(
    UPDATE_USER,
    (data: IUserResponse) => ({ payload: data })
);
export const setIsAuth = createCustomAction(
    SET_IS_AUTH,
    (isAuth: boolean) => ({ payload: isAuth })
);

export const login = (data: ILoginRequest) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.LOGIN));
    dispatch(formErrorsActions.clearFormErrors());

    try {
        const result = await auth.login(data);

        if (result.status === HTTP_CODES.OK) {
            dispatch(setUserCredentials(result.data.data.credentials));
            dispatch(setUser(result.data.data.user));
            dispatch(setIsAuth(true));
        }

        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.LOGIN));
    }
};

export const logout = () => async (dispatch: Dispatch, getState: () => ICommonState) => {
    const state = getState();

    localStorage.removeItem(`persist:${AUTH_PERSIST_KEY}`);
    dispatch(resetState());
    dispatch(setLanguage(state.auth.user.language));
    dispatch(setSettings(state.settings));
};

export const register = (data: IRegisterRequest) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.REGISTER));

    try {
        const result = await auth.register(data);

        if (result.status === HTTP_CODES.OK) {
            dispatch(setUserCredentials(result.data.data.credentials));
            dispatch(setUser(result.data.data.user));
            dispatch(setIsAuth(true));
        }

        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.REGISTER));
    }
};

export const getUser = (cancelToken?: CancelTokenSource) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.USER));
    try {
        const result = await accounts.item(cancelToken);

        if (result.status === HTTP_CODES.OK) {
            dispatch(formErrorsActions.clearFormErrors());
            dispatch(setUser(result.data.data));
        }

        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.USER));
    }
};

export const updateUserSettings = (data: IAccountUpdateRequest) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.UPDATE_USER));
    dispatch(formErrorsActions.clearFormErrors());

    try {
        const result = await accounts.update(data);

        if (result.status === HTTP_CODES.OK) {
            // ILoginResponse is returned only when user changes password for himself
            if ('credentials' in result.data.data) {
                dispatch(setUserCredentials(result.data.data.credentials));
                dispatch(setUser(result.data.data.user));
                dispatch(setIsAuth(true));
            }

            // IUserResponse returned otherwise
            if ('id' in result.data.data) {
                dispatch(setUser(result.data.data));
            }
        }

        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.UPDATE_USER));
    }
};

export const verifyEmail = (id: number, data: IVerifyEmailRequest) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.VERIFY_EMAIL));
    try {
        const result = await auth.verify(id, data);

        if (result.status === HTTP_CODES.OK) {
            dispatch(setUserCredentials(result.data.data.credentials));
            dispatch(setUser(result.data.data.user));
            dispatch(setIsAuth(true));
        }

        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.VERIFY_EMAIL));
    }
};

export const authByToken = (token: string) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.AUTH_BY_TOKEN));

    try {
        const { data: { access_token, token_type } } = await api.post('account/tokens', {}, {
            headers: {
                Authorization: `${BEARER_TOKEN_TYPE} ${token}`,
            },
        });

        dispatch(setUserCredentials({ token_type, access_token }));

        await getUser()(dispatch);
        const result = await getUser()(dispatch);

        if (result.status === HTTP_CODES.OK) {
            dispatch(setIsAuth(true));
        }
    } catch (e) {
        throw e;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.AUTH_BY_TOKEN));
    }
};

export const authByLink = (data: IAuthLinkRequest) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.AUTH_BY_LINK));
    try {
        const result = await auth.byLink(data);

        if (result.status === HTTP_CODES.OK) {
            dispatch(setUserCredentials(result.data.data.credentials));
            dispatch(setUser(result.data.data.user));
            dispatch(setIsAuth(true));
        }

        return result;
    } catch (e) {
        dispatch(setResponseError({
            code: HTTP_CODES.AUTH_LINK_INVALID,
            error: e,
        }));
        throw e;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.AUTH_BY_LINK));
    }
};

export const resetPassword = (data: IResetPasswordRequest) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.PASSWORD_RESET_LINK));
    try {
        const result = await auth.resetPassword(data);

        if (result.status === HTTP_CODES.OK) {
            dispatch(formErrorsActions.clearFormErrors());
        }

        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.PASSWORD_RESET_LINK));
    }
};

export const updatePassword = (data: IUpdatePasswordRequest) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.UPDATE_PASSWORD));
    dispatch(formErrorsActions.clearFormErrors());

    try {
        const result = await auth.updatePassword(data);

        if (result.status === HTTP_CODES.OK) {
            dispatch(setUserCredentials(result.data.data.credentials));
            dispatch(setUser(result.data.data.user));
            dispatch(setIsAuth(true));
        }

        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.UPDATE_PASSWORD));
    }
};
