// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as vmActions from 'common/modules/computeResourceVm/actions';
import { combineReducers } from 'redux';
import { initialPlanState } from 'common/modules/plan/reducer';
import { initialLocationState } from 'common/modules/location/reducer';
import { initialUserState } from 'admin/user/reducer';
import { initialProjectState } from 'common/modules/project/reducer';
import { initialShortComputeResourceState } from 'admin/computeResource/reducers';
import {
    IPaginateApiResponse,
    paginateInitialState,
} from 'common/api/resources/Response';
import {
    ActionType,
    getType,
} from 'typesafe-actions';
import {
    addToPaginated,
    deleteFromPaginated,
    updateInPaginated,
} from 'common/reducers/list';
import {
    BootMode,
    ComputeResourceVmCustomPlan,
    ComputeResourceVmStatus,
    defaultBackupSettings,
    DiskImageType,
    IIpV4,
    IUpdateComputeResourceVmEvent,
    IVmResponse,
    IShortVmResponse,
} from 'common/api/resources/ComputeResourceVm';
import { IReverseDnsResponse } from 'common/api/resources/ReverseDns';
import { VirtualizationType } from 'common/api/resources/ComputeResource';
import {
    ImageFormat,
    StorageType,
} from 'common/api/resources/StorageType';
import {
    limitFactory,
    NetworkTrafficLimitTypes,
    ResetLimitPolicy,
} from 'common/api/resources/Plan';
import {
    BandwidthUnit,
    DataUnit,
    DiskBandwidthUnit,
    GiB,
    IOpsUnit,
    Unit,
} from 'common/helpers/units';
import { ClusterImportProductType } from 'common/api/resources/ClusterImport';

export interface IVmReducer {
    list: IPaginateApiResponse<IVmResponse[]>;
    item: IVmResponse;
}

export type VmActions = ActionType<typeof vmActions>;
export type VmState = Readonly<IVmReducer>;

export const initialShortComputeResourceVmState: IShortVmResponse = {
    id: 0,
    name: '',
    compute_resource: initialShortComputeResourceState,
};

export const initialComputeResourceVmState: IVmResponse = {
    id: 0,
    name: '',
    uuid: '',
    description: '',
    status: ComputeResourceVmStatus.NO_STATE,
    virtualization_type: VirtualizationType.KVM,
    real_status: ComputeResourceVmStatus.NO_STATE,
    boot_mode: BootMode.DISK,
    is_processing: false,
    progress: 0,
    plan: initialPlanState,
    location: initialLocationState,
    has_incremental_backups: false,
    ips: [],
    ip_addresses: {
        ipv4: [],
        ipv6: {
            id: 0,
            range: '',
            is_reverse_dns_enabled: false,
            reverse_dns: [],
        },
    },
    specifications: {
        ram: 0,
        disk: 0,
        vcpu: 0,
    },
    settings: {
        os_image: {
            icon: '',
            url: '',
            name: '',
            type: DiskImageType.OS_IMAGE,
            cloud_init_version: '',
        },
        user: '',
        mac_address: '',
        vnc_password: '',
    },
    user: initialUserState,
    project: initialProjectState,
    usage: {
        cpu: 0,
        network: {
            incoming: {
                value: 0,
                is_exceeded: false,
            },
            outgoing: {
                value: 0,
                is_exceeded: false,
            },
        },
        disk: {
            actual_size: 0,
        },
    },
    compute_resource: initialShortComputeResourceState,
    is_suspended: false,
    is_backup_available: false,
    backup_settings: defaultBackupSettings,
    next_scheduled_backup_at: null,
    is_deleting: false,
    ssh_keys: [],
    created_at: '',
    vnc_url: 'example.com:8080/vnc-console/kvm/uuid',
    fqdns: [],
    product: ClusterImportProductType.SOLUS_VM_2,
};

export const initialComputeResourceVmEventState: IUpdateComputeResourceVmEvent = {
    id: 0,
    status: ComputeResourceVmStatus.NO_STATE,
    real_status: ComputeResourceVmStatus.NO_STATE,
    is_processing: false,
    plan: initialPlanState,
    specifications: {
        ram: 0,
        disk: 0,
        vcpu: 0,
    },
    usage: initialComputeResourceVmState.usage,
    is_suspended: false,
    progress: 100,
    backup_settings: defaultBackupSettings,
    has_incremental_backups: false,
    ip_addresses: {
        ipv4: [],
        ipv6: {
            id: 0,
            range: '',
            is_reverse_dns_enabled: false,
            reverse_dns: [],
        },
    },
};

export const initialCustomPlanState: ComputeResourceVmCustomPlan = {
    params: {
        disk: 1,
        ram: GiB,
        vcpu: 1,
        vcpu_units: 1000,
        vcpu_limit: 100,
        io_priority: 4,
        swap: GiB,
    },
    virtualization_type: VirtualizationType.KVM,
    storage_type: StorageType.FB,
    image_format: ImageFormat.QCOW2,
    is_snapshots_enabled: false,
    reset_limit_policy: ResetLimitPolicy.Never,
    network_traffic_limit_type: NetworkTrafficLimitTypes.Separate,
    limits: {
        network_outgoing_traffic: limitFactory(DataUnit.GiB),
        network_incoming_traffic: limitFactory(DataUnit.GiB),
        network_total_traffic: limitFactory(DataUnit.GiB),
        network_reduce_bandwidth: limitFactory(BandwidthUnit.Kbps),
        network_outgoing_bandwidth: limitFactory(BandwidthUnit.Mbps),
        network_incoming_bandwidth: limitFactory(BandwidthUnit.Mbps),
        disk_iops: limitFactory(IOpsUnit.iops),
        disk_bandwidth: limitFactory(DiskBandwidthUnit.Bps),
        backups_number: limitFactory(Unit.UNITS, 7, true),
    },
    is_backup_available: false,
    backup_settings: {
        is_incremental_backup_enabled: false,
        incremental_backups_limit: 3,
    },
    is_additional_ips_available: false,
};

export default combineReducers<VmState, VmActions>({
    list: (state = paginateInitialState, action) => {
        switch (action.type) {
        case getType(vmActions.updateVm):
            // todo https://jira.plesk.ru/browse/SIO-2993
            return ({
                ...state,
                data: state.data.map(item => {
                    if (item.id === action.payload.id) {
                        return {
                            ...item,
                            ...action.payload,
                            usage: {
                                ...item.usage,
                                ...action.payload.usage,
                            },
                        };
                    }

                    return item;
                }),
            });
        case getType(vmActions.updateVmDiskUsage):
            return ({
                ...state,
                data: state.data.map(item => {
                    if (item.id === action.payload.id) {
                        return {
                            ...item,
                            usage: {
                                ...item.usage,
                                disk: {
                                    ...item.usage.disk,
                                    actual_size: action.payload.size,
                                },
                            },
                        };
                    }

                    return item;
                }),
            });
        case getType(vmActions.updateVmProgress):
            return updateInPaginated(state, {
                id: action.payload.id,
                progress: action.payload.progress,
            });
        case getType(vmActions.addVm):
            return addToPaginated(state, action.payload);
        case getType(vmActions.setVms):
            return action.payload;
        case getType(vmActions.setVm):
            return updateInPaginated(state, action.payload);
        case getType(vmActions.deleteVm):
            return deleteFromPaginated(state, action.payload);
        case getType(vmActions.setVmIsLoading):
            return {
                ...state,
                data: [...state.data.map(item => {
                    if (item.id === action.payload) {
                        return { ...item, isLoading: true };
                    }
                    return item;
                })],
            };
        case getType(vmActions.unsetVmIsLoading):
            return {
                ...state,
                data: [...state.data.map(item => {
                    if (item.id === action.payload) {
                        return { ...item, isLoading: false };
                    }
                    return item;
                })],
            };
        case getType(vmActions.setVmItemIsDeleting):
            return {
                ...state,
                data: [...state.data.map(item => {
                    if (item.id === action.payload.id) {
                        return { ...item, is_deleting: action.payload.isDeleting };
                    }

                    return item;
                })],
            };
        case getType(vmActions.moveVm):
            return {
                ...state,
                data: [...state.data.map(item => {
                    if (item.id === action.payload.id) {
                        return {
                            ...item,
                            compute_resource: action.payload.dst,
                            ips: action.payload.ips,
                        };
                    }

                    return item;
                })],
            };
        default:
            return state;
        }
    },
    item: (state = { ...initialComputeResourceVmState }, action) => {
        switch (action.type) {
        case getType(vmActions.setVm):
            return action.payload;
        case getType(vmActions.unsetVm):
            return { ...initialComputeResourceVmState };
        case getType(vmActions.addVmIpV6ReverseDns):
            return {
                ...state,
                ip_addresses: {
                    ...state.ip_addresses,
                    ipv6: state.ip_addresses.ipv6 ? {
                        ...state.ip_addresses.ipv6,
                        reverse_dns: [...state.ip_addresses.ipv6.reverse_dns, action.payload],
                    } : null,
                },
            };
        case getType(vmActions.deleteVmIpV6ReverseDns):
            return {
                ...state,
                ip_addresses: {
                    ipv6: state.ip_addresses.ipv6 ? {
                        ...state.ip_addresses.ipv6,
                        reverse_dns: state.ip_addresses.ipv6.reverse_dns.filter(item => item.id !== action.payload),
                    } : null,
                    ipv4: state.ip_addresses.ipv4,
                },
            };
        case getType(vmActions.updateVmReverseDns):
            return {
                ...state,
                ip_addresses: {
                    ipv6: state.ip_addresses.ipv6 ? {
                        ...state.ip_addresses.ipv6,
                        reverse_dns: state.ip_addresses.ipv6.reverse_dns.map((item: IReverseDnsResponse) => {
                            if (item.id === action.payload.id) {
                                return { ...item, ...action.payload.domain };
                            }

                            return item;
                        }),
                    } : null,
                    ipv4: state.ip_addresses.ipv4.map((item: IIpV4) => {
                        if (item.reverse_dns.id === action.payload.id) {
                            return {
                                ...item,
                                reverse_dns: {
                                    ...item.reverse_dns,
                                    ...action.payload.domain,
                                },
                            };
                        }

                        return item;
                    }),
                },
            };
        case getType(vmActions.updateVm):
            if (action.payload.id === state.id) {
                // todo https://jira.plesk.ru/browse/SIO-2993
                return {
                    ...state,
                    ...action.payload,
                    usage: {
                        ...state.usage,
                        ...action.payload.usage,
                    },
                };
            }
            return state;
        case getType(vmActions.updateVmDiskUsage):
            if (action.payload.id === state.id) {
                return ({
                    ...state,
                    usage: {
                        ...state.usage,
                        disk: {
                            ...state.usage.disk,
                            actual_size: action.payload.size,
                        },
                    },
                });
            }
            return state;
        case getType(vmActions.updateVmProgress):
            if (action.payload.id === state.id) {
                return {
                    ...state,
                    progress: action.payload.progress,
                };
            }
            return state;
        case getType(vmActions.moveVm):
            if (state.id !== action.payload.id) {
                return state;
            }
            return {
                ...state,
                compute_resource: action.payload.dst,
            };
        case getType(vmActions.createVmAdditionalIp):
            // Don't attach the same IP more than once to the list.
            if (state.ip_addresses.ipv4.some(item => item.id === action.payload.id)) {
                return state;
            }

            return {
                ...state,
                ip_addresses: {
                    ipv6: state.ip_addresses.ipv6,
                    ipv4: [
                        ...state.ip_addresses.ipv4,
                        action.payload,
                    ],
                },
            };
        case getType(vmActions.deleteVmAdditionalIp):
            return {
                ...state,
                ip_addresses: {
                    ipv6: state.ip_addresses.ipv6,
                    ipv4: state.ip_addresses.ipv4.filter(item => item.id !== action.payload),
                },
            };
        case getType(vmActions.updatePrimaryIp):
            return {
                ...state,
                ip_addresses: {
                    ...state.ip_addresses,
                    ipv4: state.ip_addresses.ipv4.map(item => ({
                        ...item,
                        is_primary: item.id === action.payload,
                    })),
                },
            };
        default:
            return state;
        }
    },
});
