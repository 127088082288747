// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { RootState } from 'admin/core/store';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { RouteComponentProps } from 'react-router';
import {
    Translate,
    Switch,
    Toolbar,
    List,
    ToolbarGroup,
} from '@plesk/ui-library';
import {
    IIpResponse,
    IpIssuedFor,
} from 'common/api/resources/Ip';
import { getActionColumnProps } from 'common/helpers/list';
import * as ipBlockActions from 'admin/ipBlock/actions';
import { StyledActions } from 'common/components/Actions/Styles';
import { dataCySelector } from 'common/tests/selectors';
import { IpBlockListType } from 'common/api/resources/IpBlock';
import ButtonWithConfirmation from 'common/components/ButtonWithConfirmation';
import { ICONS } from 'common/constants';
import { PageHeader } from 'admin/common/components/PageHeader/PageHeader';
import { StyledTable } from 'common/components/styles/StyledTable';
import {
    IP_BLOCKS,
    IPS,
} from 'admin/ipBlock/constants/tests';
import InfiniteScroll from 'common/components/InfinityScroll/InfinityScroll';
import { Loader } from 'common/components';
import { EmptyView } from 'common/components/EmptyView/EmptyView';
import IpAddDialog from 'admin/ipBlock/containers/IpAddDialog';

export type IpTableProps =
    RouteComponentProps<{ id: string }> &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

const columns = [{
    width: '1%',
    key: 'colId',
    title: <Translate content="ip.list.id" />,
}, {
    key: 'colIp',
    title: <Translate content="ip.list.ip" />,
    cellProps: {
        className: 'cell-bold',
    },
}, {
    key: 'colServer',
    title: <Translate content="ip.list.server" />,
}, {
    key: 'colUser',
    title: <Translate content="ip.list.user" />,
}, {
    key: 'colIssuedFor',
    title: <Translate content="ip.list.issuedFor" />,
}, {
    key: 'colReserved',
    title: <Translate content="ip.list.reserved" />,
}, {
    key: 'colComment',
    title: <Translate content="ip.list.comment" />,
}, getActionColumnProps(),
];

const issuedForTranslations = {
    [IpIssuedFor.VM]: <Translate content="ip.issuedFor.vm" />,
    [IpIssuedFor.USER]: <Translate content="ip.issuedFor.user" />,
    [IpIssuedFor.GATEWAY]: <Translate content="ip.issuedFor.gateway" />,
    [IpIssuedFor.SERVERS_MIGRATION]: <Translate content="ip.issuedFor.serversMigration" />,
};

const canBeRemoved = (ip: IIpResponse): boolean => !ip.server && ip.issued_for !== IpIssuedFor.GATEWAY;

export const IpTable: React.FC<IpTableProps> = ({
    ipBlock: {
        ipList,
        item: ipBlock,
    },
    match: { params },
    loadingFlags: {
        isLoading,
        isLoadingList,
    },
    ipBlockActions: {
        getIpBlock,
        getIpBlockIps,
        removeIp,
        removeIps,
        patchIp,
        loadIpBlockIpsOnScroll,
    },
}) => {
    const [selection, setSelection] = React.useState<string[]>([]);
    const [isDialogOpened, setDialogOpened] = React.useState(false);

    React.useEffect(() => {
        const ipBlockId = parseInt(params.id, 10);

        getIpBlock(ipBlockId);
        getIpBlockIps(ipBlockId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSelectionChange = (items: string[]) => setSelection(items);

    const remove = async (ids: number | number[]) => {
        try {
            Array.isArray(ids) ? await removeIps(ids) : await removeIp(ids);
            setSelection([]);
        } catch (e) {
            throw e;
        }
    };

    const handleItemRemove = (item: IIpResponse) => () => remove(item.id);

    const handleRemoveSelected = () => remove(selection.map((str: string): number => parseInt(str, 10)));

    const handleToggleDialog = () => setDialogOpened(prev => !prev);

    const handleToggleReserved = (item: IIpResponse) => () => {
        patchIp(item.id, {
            is_reserved: !item.is_reserved,
        });
    };

    const removeText = ipBlock.list_type === IpBlockListType.RANGE ? (
        <Translate content="ip.batchButtonWithConfirmation.textRange" />
    ) : (
        <Translate content="ip.batchButtonWithConfirmation.textSet" />
    );

    const generateIpData = () => ipList.data.map(item => {
        const actions = (
            <StyledActions>
                <ButtonWithConfirmation
                    disabled={!canBeRemoved(item)}
                    isLoading={item.is_loading}
                    translations={{
                        title: <Translate content="ip.buttonWithConfirmation.title" />,
                        button: <Translate content="ip.buttonWithConfirmation.button" />,
                        tooltip: <Translate content="ip.buttonWithConfirmation.tooltip" />,
                        text: removeText,
                    }}
                    handleConfirm={handleItemRemove(item)}
                    data-cy={dataCySelector(item.id, 'remove')}
                    icon={ICONS.RECYCLE}
                />
            </StyledActions>
        );

        const reserveDisabled = ipBlock.list_type !== IpBlockListType.SET || item.issued_for !== IpIssuedFor.USER;

        return {
            colId: item.id,
            colIp: item.ip,
            colUser: item.user?.email,
            colServer: item.server?.name,
            colActions: actions,
            colComment: item.comment,
            colIssuedFor: issuedForTranslations[item.issued_for],
            colReserved: (
                <Switch
                    checked={item.is_reserved}
                    onChange={handleToggleReserved(item)}
                    loading={item.is_loading}
                    disabled={reserveDisabled}
                    tooltip={reserveDisabled && <Translate content="ip.list.reserveDisabledTooltip" />}
                    data-cy={dataCySelector(item.id, 'reserve')}
                />
            ),
            key: item.id.toString(),
            disabled: !canBeRemoved(item),
        };
    });

    return (
        <Loader isLoading={isLoading}>
            <PageHeader
                title={<Translate
                    content="ip.list.title"
                    params={{ ipBlock: ipBlock.name }}
                />}
                buttonText={ipBlock.list_type === IpBlockListType.RANGE ? 'ip.reserveBtn' : 'ip.addBtn'}
                buttonIcon="ip-addresses"
                onButtonClick={handleToggleDialog}
                isButtonShown={true}
            />
            <StyledTable>
                <Toolbar data-cy={IP_BLOCKS.TOOLBAR}>
                    <ToolbarGroup title="actions">
                        <ButtonWithConfirmation
                            data-cy={IPS.BATCH_DELETE_BTN}
                            disabled={!selection.length}
                            isLoading={false}
                            confirmationButtonGhost={false}
                            confirmationButtonText={<Translate content="ip.removeBtn" />}
                            translations={{
                                title: <Translate content="ip.batchButtonWithConfirmation.title" />,
                                button: <Translate content="ip.batchButtonWithConfirmation.button" />,
                                tooltip: <Translate content="ip.batchButtonWithConfirmation.tooltip" />,
                                text: removeText,
                            }}
                            handleConfirm={handleRemoveSelected}
                            icon={ICONS.RECYCLE}
                        />
                    </ToolbarGroup>
                </Toolbar>
                <InfiniteScroll
                    loadMore={loadIpBlockIpsOnScroll}
                    hasMore={!!ipList.links.next}
                >
                    <Loader isLoading={isLoadingList}>
                        <List
                            emptyView={
                                <EmptyView
                                    title="ip.emptyView.title"
                                    description="ip.emptyView.description"
                                    icon={ICONS.NET}
                                />
                            }
                            columns={columns}
                            selection={selection}
                            onSelectionChange={onSelectionChange}
                            data={generateIpData()}
                        />
                    </Loader>
                </InfiniteScroll>
            </StyledTable>
            <IpAddDialog
                id={ipBlock.id}
                type={ipBlock.type}
                listType={ipBlock.list_type}
                isOpened={isDialogOpened}
                onClose={handleToggleDialog}
                onCreated={handleToggleDialog}
            />
        </Loader>
    );
};

const mapStateToProps = (state: RootState) => ({
    ipBlock: state.ipBlock,
    loadingFlags: {
        isLoading: state.app.loadingFlags.has(LOADING_FLAGS.IPBLOCK_ITEM) || state.app.loadingFlags.has(LOADING_FLAGS.IP_LIST),
        isLoadingList: !state.ipBlock.ipList.data.length && state.app.loadingFlags.has(LOADING_FLAGS.IP_LIST),
    },
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    ipBlockActions: bindActionCreators(ipBlockActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(IpTable);
