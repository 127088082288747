// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { connect } from 'react-redux';
import { RootState } from 'client/core/store';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import * as snapshotActions from 'common/modules/snapshot/actions';
import { SnapshotsList } from 'common/components/ServerTabs/SnapshotTab/SnapshotsList';

const mapStateToProps = (state: RootState) => ({
    view: state.app.viewType,
    snapshots: state.snapshots.list.data,
    server: state.project.servers.item,
    isLoading: state.app.loadingFlags.has(LOADING_FLAGS.SNAPSHOTS_LIST),
    echoCredentials: `${state.auth.authData.token_type} ${state.auth.authData.access_token}`,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    snapshotActions: bindActionCreators(snapshotActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SnapshotsList);
