// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import React from 'react';
import {
    Translate,
    Icon,
    ProgressStep,
} from '@plesk/ui-library';
import {
    StatusText,
    StatusIconContainer,
    StatusSubtext,
    ProgressContainer,
} from 'admin/update/containers/VersionState/Styles';
import {
    INTENT_TYPE,
    PROGRESS_STATUS,
} from 'common/constants';
import {
    RouteComponentProps,
    withRouter,
} from 'react-router';
import { connect } from 'react-redux';
import { RootState } from 'admin/core/store';
import { TASK_ACTION } from 'common/api/resources/Task';
import { formatUpdateDate } from 'common/date';

interface IVersionUpdateCompleteWithErrorsProps {
    finishedAt: string | null;
    failedCount: number;
}

export type VersionUpdateCompleteWithErrorsProps =
    IVersionUpdateCompleteWithErrorsProps &
    ReturnType<typeof mapStateToProps>;

export const VersionUpdateCompleteWithErrors: React.FC<VersionUpdateCompleteWithErrorsProps> = ({
    finishedAt,
    failedCount,
    version,
    push,
}) => {
    const handleClick = () => {
        push(`/admin/tasks?action=${TASK_ACTION.UPGRADE_COMPUTE_RESOURCE}&version=${version}`);
    };

    return (
        <>
            <StatusIconContainer>
                <Icon
                    name="exclamation-mark-circle-filled"
                    intent={INTENT_TYPE.DANGER}
                />
            </StatusIconContainer>
            <div>
                <StatusText>
                    <Translate content="update.status.completeWithErrors" />
                </StatusText>
                <StatusSubtext>
                    <Translate content="update.finishedAt" />
                    &nbsp;
                    {finishedAt && formatUpdateDate(finishedAt)}
                </StatusSubtext>
                <ProgressContainer>
                    <ProgressStep
                        title={<Translate content="update.step.downloadingUpdate" />}
                        status={PROGRESS_STATUS.DONE}
                    />
                    <ProgressStep
                        title={<Translate content="update.step.masterNodeUpdating" />}
                        status={PROGRESS_STATUS.DONE}
                    />
                    <ProgressStep
                        title={<Translate content="update.step.computeResourcesUpgrading" />}
                        status={PROGRESS_STATUS.ERROR}
                    >
                        <Translate
                            content="update.failedToUpdate"
                            // eslint-disable-next-line jsx-a11y/anchor-is-valid
                            params={{ count: <a onClick={handleClick}>{failedCount}</a> }}
                        />
                    </ProgressStep>
                </ProgressContainer>
            </div>
        </>
    );
};

const mapStateToProps = (state: RootState, ownProps: RouteComponentProps) => ({
    version: state.app.meta.version,
    push: ownProps.history.push,
});

export default withRouter(connect(mapStateToProps)(VersionUpdateCompleteWithErrors));
