// Copyright 1999-2023. Plesk International GmbH. All rights reserved.
import Axios,
{ CancelTokenSource } from 'axios';
import {
    DependencyList,
    useEffect,
    useState,
} from 'react';

export const useRequestCancellationEffect = (action: (source: CancelTokenSource) => void, deps?: DependencyList) => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const source = Axios.CancelToken.source();

        const fetch = async () => {
            try {
                await action(source);
            } catch (e) {
                if (Axios.isCancel(e)) {
                    // eslint-disable-next-line no-console
                    console.log('Request has been cancelled');
                    return;
                }
            } finally {
                setIsLoading(false);
            }
        };

        fetch();

        return () => {
            source.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);

    return isLoading;
};
