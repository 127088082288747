// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import React from 'react';
import { INotificationResponse } from 'common/api/resources/Notification';
import {
    Icon,
    Translate,
} from '@plesk/ui-library';
import { COMPUTE_RESOURCE_STATUS } from 'admin/computeResource/constants';
import moment from 'moment';
import NotificationItem from 'admin/notification/containers/Items/NotificationItem';
import { getStatus } from 'common/api/resources/ComputeResource';

export interface IComputeResourceStatusChangeItemProps {
    item: INotificationResponse;
}

export const ComputeResourceStatusChangeItem: React.FC<IComputeResourceStatusChangeItemProps> = ({
    item,
}) => {
    const iconEl = React.useMemo(() => {
        if (getStatus(item.data.status) === COMPUTE_RESOURCE_STATUS.UNAVAILABLE) {
            return <Icon name="exclamation-mark-circle-filled" size="16" intent="danger" />;
        }

        return <Icon name="check-mark-circle-filled" size="16" intent="success" />;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <NotificationItem
            item={item}
            icon={iconEl}
            header={(
                <Translate content="notification.item.computeResourceStatusChange.title" />
            )}
            description={(
                <Translate
                    content="notification.item.computeResourceStatusChange.description"
                    params={{
                        name: <b>{item.data.name}</b>,
                        status: getStatus(item.data.status),
                        date: moment(item.data.date).format('D-M-Y'),
                        time: moment(item.data.date).format('HH:mm:ss'),
                    }}
                />
            )}
        />
    );
};

export default ComputeResourceStatusChangeItem;
