// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import styled from 'styled-components';
import { COLORS } from 'common/theme';
import { Button } from '@plesk/ui-library';

export const Control = styled.div`
    display: flex;
    flex-wrap: unset;
    align-items: center;
    min-height: 28px;
    width: 100%;
    background-color: ${COLORS.WHITE_0};
    border: 1px solid ${COLORS.GREY_60};
    border-radius: 2px;

    &:hover {
        border-color: #259ccc;
    }

    &:focus-within {
        border-color: #259ccc;
        background-color: #f0faff;
        color: #222;
    }

    .pul-form-field--error &:not(:focus):not(:disabled) {
        border-color: #d02d4b;
        background-color: #ffdee0;
    }
`;

export const ValueContainer = styled.div`
    display: flex;
    align-items: center;
    flex: 1;
    flex-wrap: wrap;
    padding: 2px 8px;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
`;

export const Input = styled.input`
    flex: 1;
    margin: 0;
    padding: 2px 0;
    background: transparent;
    border: none;

    &:focus {
        outline: none;
    }
`;

export const Tag = styled.div`
    display: flex;
    margin: 2px;
    max-height: 16px;
    min-width: 0;
    box-sizing: border-box;
    background: ${COLORS.GREY_20};
    border-radius: 2px;
`;

export const TagValue = styled.div`
    overflow: hidden;
    padding: 1px;
    padding-left: 6px;
    border-radius: 2px;
    color: hsl(0, 0%, 20%);
    font-size: 12px;
    line-height: 16px;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const TagAction = styled.button`
    display: flex;
    align-items: center;
    padding: 0 4px;
    margin: 0;
    box-sizing: border-box;
    border: none;
    border-radius: 2px;
    outline: none;
    color: #737373;

    &:focus, &:hover {
        background: ${COLORS.GREY_30};
    }

    &:hover {
        cursor: pointer;
    }
`;

export const ActionsContainer = styled.div`
    display: flex;
    align-items: center;
    align-self: stretch;
`;

export const InputAction = styled(Button)``;
