// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import styled from 'styled-components';

export const LocationsContainer = styled.div`
  display: flex;
  align-items: center;

  button {
    margin-left: 3px;
  }
`;

export const InlineLocationCellContainer = styled.div`
  display: inline-block;
`;

export const PopoverLocationCell = styled.div`
  margin-top: 10px;
`;
