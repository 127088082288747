// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import {
    HeaderContainer,
    TitleContainer,
    MainTitleContainer,
    SubTitleContainer,
    ActionsContainer,
    ActionsBar,
    ActionButton,
} from 'common/components/PageHeader/Styles';

export interface IPageHeaderProps {
    title: JSX.Element | string;
    subtitle?: JSX.Element;
    actions?: JSX.Element;
    actionButton?: JSX.Element | null;
}

const PageHeader: React.FC<IPageHeaderProps> = ({
    title,
    subtitle,
    actions,
    actionButton,
}) => (
    <HeaderContainer>
        <TitleContainer>
            <MainTitleContainer>
                {title}
            </MainTitleContainer>
            <SubTitleContainer>
                {subtitle}
            </SubTitleContainer>
        </TitleContainer>
        <ActionsContainer>
            <ActionsBar>
                {actions}
            </ActionsBar>
            <ActionButton>
                {actionButton}
            </ActionButton>
        </ActionsContainer>
    </HeaderContainer>
);

export default PageHeader;
