// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import styled from 'styled-components';

export const Header = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    z-index: 99999;

    background: white;
`;

export const HeaderToolbar = styled.div`
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: 15px;
`;

export const HeaderSection = styled.div`
    margin-right: 16px;
`;
