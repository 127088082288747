// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import {
    DataUnit,
    convertToDataUnit,
} from 'common/helpers/units';

export interface ISizeProps {
    size: number;
    unit?: DataUnit;
}

export const Size: React.FC<ISizeProps> = ({ size, unit = DataUnit.GiB }) => (
    <>
        {convertToDataUnit(size, unit)}
    </>
);
