// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import styled from 'styled-components';
import { COLORS } from 'common/theme';

export const CopyTexts = styled.div`
  display: flex;
  >div {
    margin-right: 5px;
  }
`;

export const CopyTextContainer = styled.div`
    >span.pul-action {
        display: block;

        >a.pul-action__content {
            display: flex;
            cursor: pointer;
            margin-right: 15px;
            color: ${COLORS.GREY_100};
            text-decoration: none;
            justify-content: flex-start;

            div {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                line-height: 20px;
                font-size: 14px;
            }

            span:last-child {
                width: 20px;
                margin-top: 2px;
                margin-left: 4px;
                flex: 0 0 12px;
            }

            :hover, :visited, :link, :active {
                color: ${COLORS.GREY_100};
                text-decoration: none;
            }
        }
    }
`;

export const CopyTextInlineContainer = styled.div`
    display: inline-block;

    >span.pul-action {
        display: inline;

        >a.pul-action__content {
            display: inline;
            cursor: pointer;
            color: ${COLORS.GREY_100};

            div {
                display: inline;
                font-size: 14px;
            }

            span:last-child {
                width: 20px;
                margin-bottom: 3px;
            }

            :hover, :visited, :link, :active {
                color: ${COLORS.GREY_100};
                text-decoration: none;
            }
        }
    }
`;
