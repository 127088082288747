// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'client/core/store';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import ApiTokenForm from 'client/account/containers/Account/AccountHeader/ApiTokenForm';
import SshKeyForm from 'common/components/SshKeyForm/SshKeyForm';
import { createSshKey } from 'client/account/actions/sshKeys';
import { HeaderButton } from 'client/common/components/HeaderButton/HeaderButton';
import {
    HeaderContainer,
    HeaderContent,
    HeaderTitle,
} from 'client/common/styles/Header';
import {
    Popover,
    Translate,
} from '@plesk/ui-library';
import {
    API_TOKENS_TAB,
    SSH_KEYS_TAB,
} from 'client/account/constants';
import {
    RouteComponentProps,
    withRouter,
} from 'react-router';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';

export type AccountHeaderProps =
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const AccountHeader: React.FC<AccountHeaderProps> = ({
    hash,
    userEmail,
    isCreatingNewToken,
    isCreatingNewSshKey,
    create,
}) => {
    const [isPopoverOpened, setPopoverOpened] = React.useState(false);
    const handleOpen = () => setPopoverOpened(true);
    const handleClose = () => setPopoverOpened(false);

    return (
        <HeaderContainer>
            <HeaderContent>
                <HeaderTitle>
                    <h1><Translate content="account.title" /></h1>
                </HeaderTitle>
            </HeaderContent>
            <div>
                {hash === API_TOKENS_TAB && (
                    <Popover
                        onClose={handleClose}
                        visible={isPopoverOpened}
                        canCloseOnOutsideClick={false}
                        target={(
                            <HeaderButton
                                isMobile={true}
                                icon="hexagons"
                                onClick={handleOpen}
                                disabled={isCreatingNewToken}
                            >
                                <Translate content="account.tokens.addNewToken" />
                            </HeaderButton>
                        )}
                        placement="bottom-left"
                    >
                        <ApiTokenForm onCreated={handleClose} />
                    </Popover>
                )}
                {hash === SSH_KEYS_TAB && (
                    <Popover
                        onClose={handleClose}
                        visible={isPopoverOpened}
                        canCloseOnOutsideClick={false}
                        wide={true}
                        target={(
                            <HeaderButton
                                isMobile={true}
                                icon="key"
                                onClick={handleOpen}
                                disabled={isCreatingNewToken}
                            >
                                <Translate content="account.sshKeys.addNewSshKey" />
                            </HeaderButton>
                        )}
                        placement="bottom-left"
                    >
                        <SshKeyForm
                            userEmail={userEmail}
                            isItemCreating={isCreatingNewSshKey}
                            create={create}
                            onCreated={handleClose}
                        />
                    </Popover>
                )}
            </div>
        </HeaderContainer>
    );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    create: bindActionCreators(createSshKey, dispatch),
});

const mapStateToProps = (state: RootState, ownProps: RouteComponentProps) => ({
    userEmail: state.auth.user.email,
    hash: ownProps.location.hash,
    isCreatingNewToken: state.app.loadingFlags.has(LOADING_FLAGS.SAVE_AUTH_API_TOKEN),
    isCreatingNewSshKey: state.app.loadingFlags.has(LOADING_FLAGS.SAVE_AUTH_SSH_KEY),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountHeader));
