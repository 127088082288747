// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import {
    api,
    IApiResponse,
    IPaginateApiResponse,
} from 'common/api/resources/Response';
import {
    IExternalIntegrationCreateRequest,
    IExternalIntegrationResponse,
} from 'common/api/resources/ExternalIntegration/model';
import { IPaginatedWithSearch } from 'common/api/resources/Request/request';
import { QueryBuilder } from 'common/api/resources/Request/QueryBuilder';
import { CancelTokenSource } from 'axios';

export const externalIntegrations = {
    list: (params?: IPaginatedWithSearch, cancelToken?: CancelTokenSource) => api.get<IPaginateApiResponse<IExternalIntegrationResponse[]>>('external_integrations', {
        params: new QueryBuilder(params).build(),
        cancelToken: cancelToken?.token,
    }),
    item: (id: number) => api.get<IApiResponse<IExternalIntegrationResponse>>(`external_integrations/${id}`),
    create: (data: IExternalIntegrationCreateRequest) => api.post<IApiResponse<IExternalIntegrationResponse>>('external_integrations', data),
    update: (id: number, data: IExternalIntegrationCreateRequest) => api.put<IApiResponse<IExternalIntegrationResponse>>(`external_integrations/${id}`, data),
    remove: (id: number) => api.delete(`external_integrations/${id}`),
};
