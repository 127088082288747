// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import { createCustomAction } from 'typesafe-actions';
import * as types from 'common/modules/app/menu/constants';

export const setActiveItem = createCustomAction(
    types.SET_ACTIVE_ITEM,
    (path: string) => ({ payload: path })
);

export const setAlertedItem = createCustomAction(
    types.SET_ALERTED_ITEM,
    (path: string, alerted: boolean) => ({ payload: { path, alerted } })
);

export const toggleGroup = createCustomAction(
    types.TOGGLE_GROUP,
    (ids: number[], isOpened: boolean) => ({ payload: { ids, isOpened } })
);
