// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'admin/core/store';
import {
    Redirect,
    RouteComponentProps,
    withRouter,
} from 'react-router';
import LoginForm from 'common/modules/auth/containers/LoginForm/LoginForm';
import { AuthContainer } from 'admin/auth/containers/AuthLogin/Styles';
import { ADMIN_ROUTE_PREFIX } from 'admin/core/constants';
import LogoBox from 'common/modules/auth/components/LogoBox/LogoBox';
import { AuthPageHeader } from 'common/components/styles/AuthPageHeader';
import AuthLanguageDropdown from 'common/modules/auth/containers/AuthLanguageDropdown/AuthLanguageDropdown';

export type AuthLoginProps =
    RouteComponentProps &
    ReturnType<typeof mapStateToProps>;

export const AuthLogin: React.FC<AuthLoginProps> = ({ isAuthenticated }) => {
    if (isAuthenticated) {
        return (<Redirect to={ADMIN_ROUTE_PREFIX} />);
    }

    return (
        <AuthContainer>
            <AuthPageHeader>
                <LogoBox />
                <AuthLanguageDropdown />
            </AuthPageHeader>
            <LoginForm />
        </AuthContainer>
    );
};

const mapStateToProps = (state: RootState) => ({
    isAuthenticated: state.auth.isAuth,
});

export default withRouter(connect(mapStateToProps)(AuthLogin));
