// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import styled from 'styled-components';

interface IFixedMultilineFormText {
    height?: string;
}

export const FixedMultilineFormText = styled.div<IFixedMultilineFormText>`
  textarea {
    resize: none;
    ${({ height }) => height && `
        height: ${height} !important;
    `}
  }
`;
