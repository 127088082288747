// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import styled from 'styled-components';
import { COLORS } from 'admin/core/theme';

export const ButtonWrapper = styled.div`
  span {
    color: ${COLORS.PRIMARY} !important;
  }
`;
