// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import { Radio } from '@plesk/ui-library';
import styled from 'styled-components';

export const StyledRadio = styled(Radio)`
  :hover .pul-radio__indicator {
    border-color: ${props => props.theme.primary_color};
  }

  .pul-radio__input:checked:not(:disabled)+.pul-radio__indicator {
    background: ${props => props.theme.primary_color};
    border-color: ${props => props.theme.primary_color};
  }
`;
