// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import SettingsTab from 'common/components/ServerTabs/SettingsTab/SettingsTab';
import { connect } from 'react-redux';
import { RootState } from 'admin/core/store';

const mapStateToProps = (state: RootState) => ({
    serverId: state.computeResourceVm.item.id,
    serverSettings: state.computeResourceVm.item.settings,
    serverStatus: state.computeResourceVm.item.status,
    serverVirtualizationType: state.computeResourceVm.item.virtualization_type,
});

export default connect(mapStateToProps)(SettingsTab);
