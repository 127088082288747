// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

export const SET_STORAGE_LIST = 'storage/SET_STORAGE_LIST';
export const APPEND_STORAGES = 'storage/APPEND_STORAGES';
export const ADD_STORAGE_ITEM = 'storage/ADD_STORAGE_ITEM';
export const SET_STORAGE_ITEM = 'storage/SET_STORAGE_ITEM';
export const UNSET_STORAGE_ITEM = 'storage/UNSET_STORAGE_ITEM';
export const REMOVE_STORAGE_ITEM = 'storage/REMOVE_STORAGE_ITEM';
export const UPDATE_STORAGE_ITEM = 'storage/UPDATE_STORAGE_ITEM';
export const SET_STORAGE_ITEM_IS_LOADING = 'storage/SET_STORAGE_ITEM_IS_LOADING';
export const SET_STORAGE_ITEM_IS_DELETING = 'storage/SET_STORAGE_ITEM_IS_DELETING';
