// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import {
    Form,
    FormFieldText,
    Translate,
} from '@plesk/ui-library';
import { Button } from 'admin/common/components/Button/Button';
import { INTENT_TYPE } from 'common/constants';
import {
    IRoleRequest,
    IRoleResponse,
} from 'common/api/resources/Role';
import { RootState } from 'admin/core/store';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import * as roleActions from 'admin/role/actions';
import * as formErrorsActions from 'common/modules/app/formErrors/actions';
import { connect } from 'react-redux';
import { IPermissionResponse } from 'common/api/resources/Permission';
import isEmpty from 'validator/lib/isEmpty';
import { validate } from 'common/validator';
import PermissionList from 'admin/role/components/PermissionList/PermissionList';
import { ScrollableContent } from 'admin/role/containers/RoleForm/Styles';

interface IRoleFormProps {
    onSubmit: () => void;
}

export type RoleFormProps =
    IRoleFormProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

const permissionsToArrayOfIds = (permissions: IPermissionResponse[]): string[] =>
    permissions.map((permission) => permission.id.toString());

const convertRoleToRequest = (role: IRoleResponse): IRoleRequest => ({
    name: role.name,
    permissions: permissionsToArrayOfIds(role.permissions),
});

export const RoleForm: React.FC<RoleFormProps> = ({
    role,
    permissions,
    isItemSaving,
    errors,
    formErrorsActions: { setFormErrors, clearFormErrors },
    roleActions: { createRole, updateRole },
    onSubmit,
}) => {
    const [ formValues, setFormValues ] = React.useState(convertRoleToRequest(role));

    React.useEffect(() => {
        clearFormErrors();
        setFormValues(convertRoleToRequest(role));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ role ]);

    const handleNameChange = (value: string) => {
        setFormValues((state) => ({
            ...state,
            name: value,
        }));
    };

    const handlePermissionSelectionChange = (ids: string[]) => {
        setFormValues((state) => ({
            ...state,
            permissions: ids,
        }));
    };

    const handleSubmit = async (newValues: IRoleRequest) => {
        const newErrors = validate<IRoleRequest>(newValues, {
            name: {
                validator: isEmpty,
                message: <Translate content="validate.fieldRequired" />,
                comparison: true,
            },
        });

        if (Object.keys(newErrors).length) {
            setFormErrors(newErrors);
            return;
        }

        if (role.id) {
            await updateRole(role.id, newValues);
        } else {
            await createRole(newValues);
        }
        onSubmit();
        clearFormErrors();
    };

    return (
        <>
            <Form
                id="roleForm"
                onSubmit={handleSubmit}
                values={formValues}
                errors={errors}
                footerClassName="hidden"
                hideRequiredLegend={true}
                submitButton={false}
                cancelButton={false}
                applyButton={false}
                vertical={true}
            >
                <FormFieldText
                    name="name"
                    size="fill"
                    label={<Translate content="role.form.name" />}
                    required={true}
                    onChange={handleNameChange}
                />
                <ScrollableContent>
                    <PermissionList
                        permissions={permissions}
                        selected={formValues.permissions}
                        onSelectionChange={handlePermissionSelectionChange}
                    />
                </ScrollableContent>
            </Form>
            <Button
                type="submit"
                form="roleForm"
                fill={true}
                intent={INTENT_TYPE.PRIMARY}
                size="lg"
                isLoading={isItemSaving}
            >
                <Translate content="role.form.saveBtn" />
            </Button>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    role: state.role.item,
    permissions: state.permission.list,
    isItemSaving: state.app.loadingFlags.has(LOADING_FLAGS.SAVE_ROLE_ITEM),
    errors: state.app.formErrors,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    roleActions: bindActionCreators(roleActions, dispatch),
    formErrorsActions: bindActionCreators(formErrorsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(RoleForm);
