// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import { RootState as AdminState } from 'admin/core/store';
import { RootState as ClientState } from 'client/core/store';
import { DNSType } from 'common/api/resources/Settings';

export const isDnsIntegrationEnabled = (state: AdminState | ClientState) =>
    state.settings.dns.type !== DNSType.NULL;

export const getHostnameTemplate = (state: AdminState | ClientState) => {
    if (isDnsIntegrationEnabled(state) && shouldRegisterFqdnOnServerCreate(state)) {
        return state.settings.dns.server_hostname_template;
    }

    return null;
};

export const shouldRegisterFqdnOnServerCreate = (state: AdminState | ClientState) => {
    if (isDnsIntegrationEnabled(state)) {
        return state.settings.dns.register_fqdn_on_server_create;
    }

    return false;
};
