// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import * as sshKeyActions from 'common/modules/sshKey/actions';
import {
    List,
    Translate,
} from '@plesk/ui-library';
import { RootState } from 'admin/core/store';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { PageHeader } from 'admin/common/components/PageHeader/PageHeader';
import { StyledTable } from 'common/components/styles/StyledTable';
import InfiniteScroll from 'common/components/InfinityScroll/InfinityScroll';
import { Loader } from 'common/components';
import SshKeyDialog from 'admin/sshKey/containers/SshKeyDialog';
import { SSH_KEYS } from 'admin/sshKey/constants/tests';
import { EmptyView } from 'common/components/EmptyView/EmptyView';
import ButtonWithConfirmation from 'common/components/ButtonWithConfirmation';
import CopyText from 'common/containers/CopyText/CopyText';
import { dataCySelector } from 'common/tests/selectors';
import { getActionColumnProps } from 'common/helpers/list';

export type SshKeysProps =
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

const columns = [{
    width: '1%',
    key: 'colId',
    title: <Translate content="sshKey.list.id" />,
}, {
    width: '35%',
    key: 'colName',
    title: <Translate content="sshKey.list.name" />,
    cellProps: {
        className: 'cell-bold',
    },
}, {
    key: 'colFingerprint',
    title: <Translate content="sshKey.list.fingerprint" />,
}, getActionColumnProps(),
];

export const SshKeys: React.FC<SshKeysProps> = ({
    userEmail,
    isLoadingList,
    sshKeyActions: {
        getSshKeys,
        removeSshKey,
        loadSshKeysOnScroll,
    },
    list,
}) => {
    const [dialogOpened, setDialogOpened] = React.useState(false);
    const [confirmationDialog, setConfirmationDialog] = React.useState();

    React.useEffect(() => {
        getSshKeys();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleRemove = (id: number) => async () => {
        try {
            await removeSshKey(id);
        } finally {
            closeRemoveDialog();
        }
    };

    const closeRemoveDialog = () => setConfirmationDialog(undefined);
    const handleCreate = () => setDialogOpened(true);
    const handleClose = () => setDialogOpened(false);

    const data = React.useMemo(() => list.data.map((item) => ({
        colId: item.id,
        colName: item.name,
        colFingerprint: (
            <CopyText width="450px" text={item.body}>
                {item.body}
            </CopyText>
        ),
        colActions: (
            <ButtonWithConfirmation
                isLoading={item.is_deleting}
                translations={{
                    title: (
                        <Translate content="sshKey.buttonWithConfirmation.title" />
                    ),
                    text: (
                        <Translate content="sshKey.buttonWithConfirmation.confirmationText" />
                    ),
                    button: (
                        <Translate content="sshKey.buttonWithConfirmation.button" />
                    ),
                    tooltip: (
                        <Translate content="sshKey.buttonWithConfirmation.tooltip" />
                    ),
                }}
                handleConfirm={handleRemove(item.id)}
                data-cy={dataCySelector(item.id, 'remove')}
                icon="recycle"
            />
        ),
        key: item.id.toString(),
        // eslint-disable-next-line react-hooks/exhaustive-deps
    })), [list.data]);

    return (
        <>
            {confirmationDialog}
            <PageHeader
                title={<Translate content="sshKey.title"/>}
                buttonText="sshKey.addBtn"
                buttonIcon="key"
                onButtonClick={handleCreate}
                isButtonShown={data.length > 0}
            />
            <StyledTable>
                <InfiniteScroll
                    loadMore={loadSshKeysOnScroll}
                    hasMore={!!list.links.next}
                >
                    <Loader isLoading={isLoadingList}>
                        <List
                            emptyView={
                                <EmptyView
                                    title="sshKey.emptyView.title"
                                    description="sshKey.emptyView.description"
                                    buttonText="sshKey.emptyView.buttonText"
                                    onButtonClick={handleCreate}
                                    icon="key"
                                />
                            }
                            data-cy={SSH_KEYS.TABLE}
                            columns={columns}
                            data={data}
                        />
                    </Loader>
                </InfiniteScroll>
            </StyledTable>
            <SshKeyDialog
                userEmail={userEmail}
                isOpened={dialogOpened}
                onCreated={handleClose}
                onClose={handleClose}
            />
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    userEmail: state.auth.user.email,
    list: state.sshKey,
    isLoadingList: !state.sshKey.data.length && state.app.loadingFlags.has(LOADING_FLAGS.SSH_KEY_LIST),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    sshKeyActions: bindActionCreators(sshKeyActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SshKeys);
