// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import React from 'react';
import {
    NotificationPaneContainer,
    HeaderTitle,
    NotificationsContent,
    HeaderContainer,
} from 'admin/notification/containers/NotificationPane/Styles';
import NotificationList from 'admin/notification/containers/NotificationList/NotificationList';
import {
    Translate,
    ToolbarExpander,
} from '@plesk/ui-library';
import { Button } from 'common/components/styles/Button';
import * as notificationActions from 'admin/notification/actions';
import {
    Dispatch,
    bindActionCreators,
} from 'redux';
import { connect } from 'react-redux';
import { RootState } from 'admin/core/store';

interface INotificationPaneProps {
    isOpen: boolean;
}

export type NotificationPaneProps =
    INotificationPaneProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const NotificationPane: React.FC<NotificationPaneProps> = ({
    isOpen,
    isClearAllDisabled,
    notificationActions: {
        clearAll,
    },
}) => {
    const handleClearAll = () => clearAll();

    return (
        <NotificationPaneContainer isOpen={isOpen}>
            <HeaderContainer>
                <HeaderTitle content="notification.title" />
                <ToolbarExpander />
                <Button
                    ghost={true}
                    onClick={handleClearAll}
                    disabled={isClearAllDisabled}
                >
                    <Translate content="notification.clearAll" />
                </Button>
            </HeaderContainer>
            <NotificationsContent>
                <NotificationList />
            </NotificationsContent>
        </NotificationPaneContainer>
    );
};

const mapStateToProps = (state: RootState) => ({
    isClearAllDisabled: state.notification.list.meta.total === 0,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    notificationActions: bindActionCreators(notificationActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationPane);
