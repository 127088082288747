// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { connect } from 'react-redux';
import {
    getStatus,
    IComputeResourceResponse,
} from 'common/api/resources/ComputeResource';
import { COMPUTE_RESOURCE_STATUS } from 'admin/computeResource/constants';
import ComputeResourceConfigureNetwork from 'admin/computeResource/list/containers/ComputeResourceConfigureNetwork';
import ComputeResourceInstall from 'admin/computeResource/list/containers/ComputeResourceInstall';
import * as computeResourceActions from 'admin/computeResource/actions';

interface IComputeResourceProps {
    onClose: () => void;
    item: IComputeResourceResponse;
}

export type ComputeResourceProps =
    IComputeResourceProps &
    ReturnType<typeof mapDispatchToProps>;

export const ComputeResource: React.FC<ComputeResourceProps> = ({
    item,
    onClose,
    computeResourceActions: { unsetItem },
}) => {
    React.useEffect(() => () => {
        unsetItem();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (getStatus(item.status) === COMPUTE_RESOURCE_STATUS.CONFIGURE_NETWORK) {
        return (<ComputeResourceConfigureNetwork onConfigured={onClose} />);
    }

    return (<ComputeResourceInstall item={item}/>);
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    computeResourceActions: bindActionCreators(computeResourceActions, dispatch),
});

export default connect(null, mapDispatchToProps)(ComputeResource);
