// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import {
    IPaginateApiResponse,
    paginateInitialState,
} from 'common/api/resources/Response';
import {
    ActionType,
    getType,
} from 'typesafe-actions';
import * as sshKeyActions from 'common/modules/sshKey/actions';
import { addToPaginated } from 'common/reducers/list';
import { ISshKeyResponse } from 'common/api/resources/SshKey';

export type SshKeyState = IPaginateApiResponse<ISshKeyResponse[]>;
export type SshKeyAction = ActionType<typeof sshKeyActions>;

export default (state: SshKeyState = paginateInitialState, action: SshKeyAction): SshKeyState => {
    switch (action.type) {
    case getType(sshKeyActions.setSshKeyList):
        return action.payload;
    case getType(sshKeyActions.appendSshKeyList):
        return {
            links: action.payload.links,
            data: [...state.data, ...action.payload.data],
            meta: action.payload.meta,
        };
    case getType(sshKeyActions.addSshKeyItem):
        return addToPaginated(state, action.payload);
    case getType(sshKeyActions.removeSshKeyItem):
        return {
            ...state,
            data: state.data.filter(item => item.id !== action.payload),
        };
    case getType(sshKeyActions.setSshKeyItemIsDeleting):
        return {
            ...state, data: [...state.data.map(item => {
                if (item.id === action.payload.id) {
                    return { ...item, is_deleting: action.payload.isDeleting };
                }

                return item;
            })],
        };
    default:
        return state;
    }
};
