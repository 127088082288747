// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import styled from 'styled-components';

interface IItemContainerProps {
    isActive: boolean;
    isNested: boolean;
    isHighlighted: boolean;
}

export const ItemContainer = styled.div<IItemContainerProps>`
    font-weight: 600;
    opacity: 0.87;
    line-height: 16px;
    display: flex;
    align-items: center;

    &:hover {
        opacity: 1;
        cursor: pointer;
    }

    ${({ isActive }) => isActive && `
        opacity: 1;
    `}
    
    ${({ isHighlighted }) => isHighlighted && `
        background-color: rgba(255, 255, 255, 0.13);
    `}

    ${({ isNested }) => isNested
        ? `
            font-size: 12px;
            padding: 8px 17px;
        `
        : `
            font-size: 14px;
            padding: 12px 17px;
        `
}
    
`;

export const ItemContent = styled.div`
    margin-left: 8px;
`;

interface IItemContentNestedProps {
    isActive: boolean;
}

export const ItemContentNested = styled.div<IItemContentNestedProps>`
    ${({ isActive }) => isActive
        ? 'margin-left: 10px;'
        : 'margin-left: 25px;'
}
`;

export const NavGroupContainer = styled.div`
    position: relative;
`;

export const NavGroupChevron = styled.span`
    position: absolute;
    right: 8px
`;
