// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import { IToast } from 'common/modules/app/toaster/reducer';
import { ToastIntentType } from '@plesk/ui-library';

export const getForegroundToasts = (toaster: readonly IToast[]) =>
    toaster.filter(toast => !toast.background).map(getToasts);

export const getBackgroundToasts = (toaster: readonly IToast[]) =>
    toaster.filter(toast => toast.background).map(getToasts);

const getToasts = (toast: IToast) => {
    const {
        key,
        intent,
        message,
    } = toast;

    return {
        key,
        intent: intent as ToastIntentType,
        message,
    };
};
