// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import {
    api,
    IApiResponse,
} from 'common/api/resources/Response';
import {
    ISettingsRequest,
    ISettingsResponse,
} from 'common/api/resources/Settings/model';

export const settings = {
    list: () => api.get<IApiResponse<ISettingsResponse>>('settings'),
    save: (data: ISettingsRequest) => api.patch<IApiResponse<ISettingsResponse>>('settings', data),
};
