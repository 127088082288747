// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import { ValueType } from 'react-select';
import {
    ILoadOptions,
    ISelectRequiredOption,
} from 'common/components';
import * as React from 'react';
import { computeResources } from 'common/api/resources/ComputeResource';
import AsyncSelectInput from 'common/components/Select/AsyncSelectInput';
import { FILTERS } from 'admin/common/constants/tests';
import { FixedFormField } from 'admin/common/components/Filters/Styles';
import { Translate } from '@plesk/ui-library';

export interface IComputeResourceFilterProps {
    onChange?: (option: ValueType<ISelectRequiredOption>) => void;
    value?: ValueType<ISelectRequiredOption>;
}

export const ComputeResourceFilter: React.FC<IComputeResourceFilterProps> = ({
    onChange,
    value,
}) => {
    const loadComputeResources: ILoadOptions = async (search, loadedOptions, { page }) => {
        const response = await computeResources.list({
            page,
            filters: {
                search,
            },
        });

        return {
            options: response.data.data.map(computeResource => ({
                label: computeResource.host,
                value: computeResource.id.toString(),
            })),
            hasMore: !!response.data.links.next,
            additional: {
                page: page + 1,
            },
        };
    };

    return (
        <FixedFormField data-cy={FILTERS.COMPUTE_RESOURCE}>
            <AsyncSelectInput
                isClearable={true}
                placeholder={<Translate content="filters.computeResource"/>}
                loadOptions={loadComputeResources}
                onChange={onChange}
                debounceTimeout={1000}
                additional={{ page: 1 }}
                value={value}
            />
        </FixedFormField>
    );
};
