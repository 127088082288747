// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import React from 'react';
import moment from 'moment';
import { LocaleProvider as UILocaleProvider } from '@plesk/ui-library';
import { connect } from 'react-redux';
import { ICommonState } from 'common/store';
import { Dispatch } from 'redux';
import * as localeActions from 'common/modules/app/language/actions';
import { ILanguageResponse } from 'common/api/resources/Language';
import { initialLanguageState } from 'common/modules/app/language/reducer';

export const DEFAULT_LOCALE = 'en_US';

export interface ITranslations {
    [key: string]: string | ITranslations;
}

interface ILocaleProviderProps extends React.HTMLProps<HTMLElement> {
    defaultMessages: ITranslations;
    loadLocales: (locale: string) => Promise<Record<string, unknown> | null>;
}

export type LocaleProviderProps =
    ILocaleProviderProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

// List of available locales should be synced with `LANGUAGES` constant in
// `backend/app/Http/Middleware/DetermineLanguage.php`.
// For list of available moment locales check `frontend/node_modules/moment/language`.
const momentLocales = {
    'en_us': 'en',
    'de_de': 'de',
    'es_es': 'es',
    'fr_fr': 'fr',
    'ja_jp': 'ja',
    'it_it': 'it',
    'tr_tr': 'tr',
    'ru_ru': 'ru',
    'nl_nl': 'nl',
    'pt_br': 'pt-br',
    'zh_tw': 'zh-tw',
    'zh_cn': 'zh-cn',
    'el_gr': 'el',
};

export const configureMoment = async (locale: string) => {
    locale = locale.toLowerCase();
    let momentLocale = locale;
    if (momentLocales.hasOwnProperty(locale)) {
        momentLocale = momentLocales[locale];
    }

    if (!moment.locales().includes(momentLocale)) {
        await import(`moment/locale/${momentLocale}`);
    }

    moment.locale(momentLocale);
};

export const getMessages = (allMessages: ITranslations, translatedMessages: ITranslations): ITranslations => {
    Object.entries(allMessages).forEach(([key, value]) => {
        if (translatedMessages[key] === undefined) {
            translatedMessages[key] = value;
        } else if (value.constructor === Object) {
            translatedMessages[key] = getMessages(value as ITranslations, translatedMessages[key] as ITranslations);
        }
    });

    return translatedMessages;
};

export const LocaleProvider: React.FC<LocaleProviderProps> = ({
    defaultMessages,
    loadLocales,

    appLanguage,
    setAppLanguage,
    children,
}) => {
    const [messages, setMessages] = React.useState(defaultMessages);

    React.useEffect(() => {
        const { locale } = appLanguage;

        (async () => {
            try {
                await Promise.all([
                    configureMoment(locale),

                    // Asynchronously load required translation file from server.
                    // We should not bundle all languages with our application 'cause it may
                    // impact performance.
                    loadLocales(locale).then(m => {
                        if (!m) {
                            throw new Error('Invalid response');
                        }
                        setMessages(getMessages(defaultMessages, m as ITranslations));
                    }),
                ]);
            } catch (e) {
                // Mute this exception 'cause we should not break whole application
                // because we can't find proper translation for it.
                // eslint-disable-next-line no-console
                console.error(`Cannot load locale for "${locale}", fallback to "${initialLanguageState.locale}"`);
                setAppLanguage(initialLanguageState);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appLanguage]);

    return (
        <UILocaleProvider messages={messages}>
            {children}
        </UILocaleProvider>
    );
};

const mapStateToProps = (state: ICommonState) => ({
    appLanguage: state.app.language,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    setAppLanguage: (language: ILanguageResponse) => dispatch(localeActions.setLanguage(language)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LocaleProvider);
