// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import {
    ActionType,
    getType,
} from 'typesafe-actions';
import * as actions from 'common/modules/app/notificationAlert/actions';

export type NotificationAlertAction = ActionType<typeof actions>;
export type NotificationAlertState = Readonly<boolean>;

export default (state: NotificationAlertState = true, action: NotificationAlertAction) => {
    switch (action.type) {
    case getType(actions.setNotificationAlert):
        return action.payload;
    default:
        return state;
    }
};
