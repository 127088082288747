// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

export enum ExternalIntegrationType {
    CPANEL = 'cpanel',
    PLESK = 'plesk',
}

export const DefaultPleskProductConstant = 'PLESK-12-WEB-HOST-1M';
export const DefaultCPanelPackageId = '21159'; // cPanel Admin Cloud
export const DefaultCPanelGroupId = '3520903'; // example group ID

export const TranslationExternalIntegrationTypeMap = {
    [ExternalIntegrationType.PLESK]: 'externalIntegration.types.plesk',
    [ExternalIntegrationType.CPANEL]: 'externalIntegration.types.cPanel',
};

export interface IPleskCredentialsData {
    username: string;
    password?: string;
    product_constant: string;
}

export interface ICPanelCredentialsData {
    user?: string;
    password?: string;
    package_id: string;
    group_id: string;
}

export interface IExternalIntegrationCreateRequest {
    name: string;
    type: ExternalIntegrationType;
    credentials: IPleskCredentialsData | ICPanelCredentialsData;
}

export interface IExternalIntegrationResponse {
    id: number;
    name: string;
    type: ExternalIntegrationType;
    applications_count: number;
    servers_count: number;
    credentials: IPleskCredentialsData | ICPanelCredentialsData;

    // Internal property.
    is_deleting: boolean;
}
