// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as types from 'admin/dashboard/constants/types';
import { createCustomAction } from 'typesafe-actions';
import { Dispatch } from 'redux';
import { CancelTokenSource } from 'axios';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { getTaskList } from 'admin/task/actions';
import {
    setIsLoading,
    unsetIsLoading,
} from 'common/modules/app/loadingFlags/actions';
import {
    dashboard,
    IDashboardResponse,
} from 'common/api/resources/Dashboard';

export const setDashboardData = createCustomAction(
    types.GET_DASHBOARD_DATA,
    (data: IDashboardResponse) => ({ payload: data })
);

export const getDashboardData = (cancelToken?: CancelTokenSource) => async(dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.DASHBOARD_DATA));

    try {
        const [result] = await Promise.all([
            dashboard.data(cancelToken),
            getTaskList({}, cancelToken)(dispatch),
        ]);

        dispatch(setDashboardData(result.data.data));

        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.DASHBOARD_DATA));
    }
};
