// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import SelectInput from 'common/components/Select/SelectInput';
import { ValueType } from 'react-select';
import { Translate } from '@plesk/ui-library';
import AsyncSelectInput from 'common/components/Select/AsyncSelectInput';
import FilterForm from 'admin/common/components/FilterForm/FilterForm';
import { UserStatus } from 'common/api/resources/User';
import {
    loadLimitGroupsOptions,
    loadRolesOptions,
} from 'admin/user/components/helpers';
import { statusMappings } from 'admin/user/constants/translation';
import { IFormProps } from 'common/components/Form/types';
import {
    FilterContainer,
    FixedFormField,
} from 'admin/common/components/Filters/Styles';

export interface ISelectOption {
    label: React.ReactNode;
    value: string;
}

export interface IFilterProps {
    onStatusFilterChange: (option: ValueType<ISelectOption>) => void;
    selectedRole?: ISelectOption;
    onRoleFilterChange: (option: ValueType<ISelectOption>) => void;
    selectedLimitGroup?: ISelectOption;
    onLimitGroupFilterChange: (option: ValueType<ISelectOption>) => void;
}

const statuses: ISelectOption[] = Object.values(UserStatus).map(status => ({
    label: statusMappings[status],
    value: status,
}));

const Filters: React.FC<IFilterProps> = ({
    onStatusFilterChange,
    selectedRole,
    onRoleFilterChange,
    selectedLimitGroup,
    onLimitGroupFilterChange,
}) => (
    <FilterContainer>
        <FilterForm>
            <FixedFormField
                name="status"
            >
                {({ getId }: IFormProps<unknown>) => (
                    <SelectInput
                        inputId={getId()}
                        placeholder={<Translate content="filters.status"/>}
                        options={statuses}
                        isClearable={true}
                        onChange={onStatusFilterChange}
                    />
                )}
            </FixedFormField>
            <FixedFormField
                name="role"
            >
                {({ getId }: IFormProps<unknown>) => (
                    <AsyncSelectInput
                        inputId={getId()}
                        value={selectedRole}
                        isClearable={true}
                        placeholder={<Translate content="filters.role"/>}
                        loadOptions={loadRolesOptions}
                        onChange={onRoleFilterChange}
                        debounceTimeout={1000}
                        additional={{ page: 1 }}
                    />
                )}
            </FixedFormField>
            <FixedFormField
                name="limit_group"
            >
                {({ getId }: IFormProps<unknown>) => (
                    <AsyncSelectInput
                        inputId={getId()}
                        value={selectedLimitGroup}
                        isClearable={true}
                        placeholder={<Translate content="filters.limitGroup"/>}
                        loadOptions={loadLimitGroupsOptions}
                        onChange={onLimitGroupFilterChange}
                        debounceTimeout={1000}
                        additional={{ page: 1 }}
                    />
                )}
            </FixedFormField>
        </FilterForm>
    </FilterContainer>
);

export default Filters;
