// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import { createCustomAction } from 'typesafe-actions';
import * as types from 'admin/license/solusVM/constants/types';
import {
    ISolusVMLicenseResponse,
    license,
    ISolusVMLicenseComputeResources,
    IAssignComputeResourcesRequest,
    IActivateSolusVMLicenseRequest,
    isLicenseActive,
} from 'common/api/resources/License/solusVM';
import { CancelTokenSource } from 'axios';
import { Dispatch } from 'redux';
import {
    setIsLoading,
    unsetIsLoading,
} from 'common/modules/app/loadingFlags/actions';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { HTTP_CODES } from 'common/api/constants';
import { bakeForegroundToast } from 'common/modules/app/toaster/actions';
import { INTENT_TYPE } from 'common/constants';
import { setLicenseIsActive } from 'common/modules/app/meta/actions';

export const setLicenseItem = createCustomAction(
    types.SET_LICENSE_ITEM,
    (data: ISolusVMLicenseResponse) => ({ payload: data })
);

export const setComputeResources = createCustomAction(
    types.SET_COMPUTE_RESOURCES,
    (data: ISolusVMLicenseComputeResources) => ({ payload: data })
);

export const getLicense = (cancelToken?: CancelTokenSource) => async(dispatch: Dispatch) =>  {
    dispatch(setIsLoading(LOADING_FLAGS.SOLUSVM_LICENSE_ITEM));

    try {
        const result = await license.item(cancelToken);

        if (result.status === HTTP_CODES.OK) {
            dispatch(setLicenseItem(result.data.data));
        }

        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.SOLUSVM_LICENSE_ITEM));
    }
};

export const activateLicense = (data: IActivateSolusVMLicenseRequest) => async(dispatch: Dispatch) =>  {
    dispatch(setIsLoading(LOADING_FLAGS.SOLUSVM_LICENSE_ACTIVATION));

    try {
        const result = await license.activate(data);

        if (result.status === HTTP_CODES.OK) {
            dispatch(setLicenseItem(result.data.data));
            dispatch(setLicenseIsActive(isLicenseActive(result.data.data)));

            bakeForegroundToast(INTENT_TYPE.SUCCESS, 'license.toasts.activationSuccess')(dispatch);
        }

        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.SOLUSVM_LICENSE_ACTIVATION));
    }
};

export const refreshLicense = () => async(dispatch: Dispatch) =>  {
    dispatch(setIsLoading(LOADING_FLAGS.SOLUSVM_LICENSE_REFRESH));

    try {
        const result = await license.refresh();

        if (result.status === HTTP_CODES.OK) {
            dispatch(setLicenseItem(result.data.data));
            dispatch(setLicenseIsActive(isLicenseActive(result.data.data)));

            bakeForegroundToast(INTENT_TYPE.SUCCESS, 'license.toasts.refreshSuccess')(dispatch);
        }

        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.SOLUSVM_LICENSE_REFRESH));
    }
};

export const resetLicense = () => async(dispatch: Dispatch) =>  {
    dispatch(setIsLoading(LOADING_FLAGS.SOLUSVM_LICENSE_RESET));

    try {
        const result = await license.reset();

        if (result.status === HTTP_CODES.OK) {
            dispatch(setLicenseItem(result.data.data));
            dispatch(setLicenseIsActive(isLicenseActive(result.data.data)));

            bakeForegroundToast(INTENT_TYPE.SUCCESS, 'license.toasts.removeSuccess')(dispatch);
        }

        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.SOLUSVM_LICENSE_RESET));
    }
};

export const getLicenseComputeResources = () => async(dispatch: Dispatch) =>  {
    dispatch(setIsLoading(LOADING_FLAGS.SOLUSVM_LICENSE_COMPUTE_RESOURCES));

    try {
        const result = await license.computeResources();

        if (result.status === HTTP_CODES.OK) {
            dispatch(setComputeResources(result.data.data));
        }

        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.SOLUSVM_LICENSE_COMPUTE_RESOURCES));
    }
};

export const assignComputeResources = (data: IAssignComputeResourcesRequest) => async(dispatch: Dispatch) =>  {
    dispatch(setIsLoading(LOADING_FLAGS.SOLUSVM_LICENSE_ASSIGN_COMPUTE_RESOURCES));

    try {
        const result = await license.assignComputeResources(data);

        if (result.status === HTTP_CODES.OK) {
            dispatch(setComputeResources(result.data.data));
            bakeForegroundToast(INTENT_TYPE.SUCCESS, 'license.toasts.assignComputeResourcesSuccess')(dispatch);
        }

        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.SOLUSVM_LICENSE_ASSIGN_COMPUTE_RESOURCES));
    }
};
