// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import {
    ItemContainer,
    ItemContent,
    ItemContentNested,
} from 'admin/menu/components/Navigation/Styles';
import {
    Icon,
    Status,
} from '@plesk/ui-library';
import {
    ICONS,
    INTENT_TYPE,
} from 'common/constants';

export interface IItemProps extends React.HTMLProps<HTMLElement> {
    icon?: ICONS;
    isNested?: boolean;
    isActive?: boolean;
    isHighlighted?: boolean;
    isAlerted?: boolean;
}

const Item: React.FC<IItemProps> = ({
    icon,
    isNested,
    isActive,
    isHighlighted,
    isAlerted,
    children,
    onClick,
}) => {
    const renderContent = () => {
        if (isNested) {
            return (
                <>
                    {isActive && <Icon size="12" name="chevron-right" />}
                    <ItemContentNested isActive={!!isActive}>
                        {children}
                    </ItemContentNested>
                </>
            );
        }

        return (
            <>
                {icon && <Icon name={icon} />}
                <ItemContent>
                    {children}
                </ItemContent>
                {isAlerted && (
                    <div style={{ marginLeft: '8px' }}>
                        <Status compact={true} intent={INTENT_TYPE.DANGER} />
                    </div>
                )}
            </>
        );
    };

    return (
        <ItemContainer
            isNested={!!isNested}
            isActive={!!isActive}
            isHighlighted={!!isHighlighted}
            onClick={onClick}
        >
            {renderContent()}
        </ItemContainer>
    );
};

export default Item;
