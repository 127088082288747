// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import {
    Grid,
    GridCol,
    Translate,
} from '@plesk/ui-library';
import { PlanCard } from 'common/components/plan/components/PlanCard';
import { PageSubHeader } from 'client/common/components';
import InfiniteScroll from 'common/components/InfinityScroll/InfinityScroll';
import { RootState } from 'client/core/store';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { loadPlansOnScroll } from 'common/modules/plan/actions';
import { connect } from 'react-redux';
import {
    IPlanResponse,
    IPlanCreateRequest,
} from 'common/api/resources/Plan';
import { SIZE } from 'common/constants';
import { CustomPlanCard } from 'common/components/plan/components/CustomPlanCard';
import { Dialog } from 'admin/common/components/Dialog/Dialog';
import PlanForm,
{
    FIELDS,
    PlanRequest,
} from 'common/components/plan/PlanForm';
import { ComputeResourceVmCustomPlan } from 'common/api/resources/ComputeResourceVm';
import {
    convertToDataUnit,
    DataUnit,
} from 'common/helpers/units';
import { initialCustomPlanState } from 'common/modules/computeResourceVm/reducers';
import { filterPlanFields } from 'common/helpers/PlanFields';

export const CUSTOM_PLAN = 'custom_plan';

export interface IPlanListProps {
    onItemClicked: (plan: IPlanResponse) => void;
    selectedPlan?: number | typeof CUSTOM_PLAN;
    disabledPlanIds?: number[];
    hidePlanName?: boolean;
    customPlan?: IPlanCreateRequest | ComputeResourceVmCustomPlan;
    canCustomizePlan?: boolean;
    onCustomizePlan?: (customPlan: ComputeResourceVmCustomPlan) => void;
    minDisk?: number;
    fields?: string[];
}

export type PlanListProps =
    IPlanListProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const PlanList: React.FC<PlanListProps> = ({
    hasNextPage,
    plans,
    selectedPlan,
    onItemClicked,
    loadOnScroll,
    hidePlanName,
    disabledPlanIds,
    canCustomizePlan,
    customPlan,
    onCustomizePlan,
    minDisk,
    fields = [
        FIELDS.VIRTUALIZATION_TYPE,
        FIELDS.STORAGE_TYPE,
        FIELDS.IMAGE_FORMAT,
        FIELDS.IS_SNAPSHOTS_ENABLED,
        FIELDS.DISK,
        FIELDS.RAM,
        FIELDS.VCPU,
        FIELDS.VCPU_UNITS,
        FIELDS.VCPU_LIMIT,
        FIELDS.IO_PRIORITY,
        FIELDS.SWAP,
        FIELDS.IS_BACKUP_AVAILABLE,
        FIELDS.BACKUP_LIMIT,
        FIELDS.INCREMENTAL_BACKUP,
        FIELDS.IS_ADDITIONAL_IP_AVAILABLE,
        FIELDS.DISK_LIMITS,
        FIELDS.NETWORK_LIMITS,
        FIELDS.VZ_PARAMETERS,
    ],
}) => {
    const [initialPlan, setInitialPlan] = React.useState<IPlanResponse>();
    const [customizingPlan, setCustomizingPlan] = React.useState<ComputeResourceVmCustomPlan>({
        ...initialCustomPlanState,
        params: {
            ...initialCustomPlanState.params,
            ram: convertToDataUnit(initialCustomPlanState.params.ram, DataUnit.MiB),
            swap: initialCustomPlanState.params.swap
                ? convertToDataUnit(initialCustomPlanState.params.swap, DataUnit.MiB)
                : 0,
        },
    });
    const [isOpen, setIsOpen] = React.useState(false);

    const handleCustomize = (plan: IPlanResponse) => {
        setInitialPlan(plan);
        setCustomizingPlan({
            ...plan,
            params: {
                ...plan.params,
                ram: convertToDataUnit(plan.params.ram, DataUnit.MiB),
                swap: plan.params.swap
                    ? convertToDataUnit(plan.params.swap, DataUnit.MiB)
                    : 0,
            },
        });
        setIsOpen(true);
    };

    const handleEditCustomPlan = (plan: ComputeResourceVmCustomPlan) => {
        setInitialPlan(undefined);
        setCustomizingPlan({
            ...plan,
            params: {
                ...plan.params,
                ram: convertToDataUnit(plan.params.ram, DataUnit.MiB),
                swap: plan.params.swap
                    ? convertToDataUnit(plan.params.swap, DataUnit.MiB)
                    : 0,
            },
        });
        setIsOpen(true);
    };

    const handleSubmitCustomization = (plan: PlanRequest) => {
        if (onCustomizePlan !== undefined) {
            onCustomizePlan(plan);
        }
        setIsOpen(false);
    };

    return (
        <>
            <PageSubHeader title="servers.create.package"/>
            <InfiniteScroll
                loadMore={loadOnScroll}
                hasMore={hasNextPage}
            >
                <Grid
                    style={{ marginBottom: '-24px' }}
                    gap={SIZE.LG}
                    xs={1}
                    sm={2}
                    md={3}
                    lg={4}
                    xl={5}
                >
                    {canCustomizePlan && (
                        <GridCol>
                            <CustomPlanCard
                                plan={customPlan}
                                isSelected={selectedPlan === CUSTOM_PLAN}
                                onItemClicked={handleEditCustomPlan}
                            />
                        </GridCol>
                    )}
                    {plans.map((plan, key) => (
                        <GridCol key={key}>
                            <PlanCard
                                onItemClicked={onItemClicked}
                                canCustomizeItem={canCustomizePlan}
                                onCustomize={handleCustomize}
                                isSelected={plan.id === selectedPlan}
                                plan={plan}
                                showName={!hidePlanName}
                                disabled={!!disabledPlanIds?.includes(plan.id)}
                            />
                        </GridCol>
                    ))}
                </Grid>
            </InfiniteScroll>
            <Dialog
                heading={<Translate content="servers.create.plan.dialog.title"/>}
                closeHandler={() => setIsOpen(false)}
                isOpen={isOpen}
                size={SIZE.XS}
            >
                <PlanForm
                    onSubmit={handleSubmitCustomization}
                    initialState={customizingPlan}
                    dataUnit={DataUnit.MiB}
                    submitButton={<Translate content="plan.actionDialog.applyBtn" />}
                    minDisk={minDisk}
                    fields={filterPlanFields(fields, initialPlan)}
                />
            </Dialog>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    hasNextPage: !!state.plan.list.links.next,
    plans: state.plan.list.data,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    loadOnScroll: bindActionCreators(loadPlansOnScroll, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlanList);
