// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'admin/core/store';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { IStorageRequest } from 'common/api/resources/Storage';
import * as storageActions from 'admin/storage/actions';
import * as formErrorsActions from 'common/modules/app/formErrors/actions';
import { Button } from 'admin/common/components/Button/Button';
import {
    STORAGE_FORM_ID,
    TRANSLATED_TYPES,
} from 'admin/storage/constants';
import { StorageType } from 'common/api/resources/StorageType';
import {
    Form,
    FormField,
    FormFieldText,
    Section,
    setIn,
    Switch,
    Translate,
} from '@plesk/ui-library';
import {
    INTENT_TYPE,
    SIZE,
} from 'common/constants';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import {
    requiredRule,
    validate,
} from 'common/validator';
import { SegmentedControl } from 'common/components/SegmentedControl/SegmentedControl';
import { IFormProps } from 'common/components/Form/types';
import { nestStringProperties } from 'common/modules/app/formErrors/selectors';

const typeOptions = Object.values([StorageType.NFS]).map((type) => ({
    value: type.toString(),
    title: TRANSLATED_TYPES[type],
}));

interface IStorageFormProps {
    closeDialog: () => void;
}

export type StorageFormProps =
    IStorageFormProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const StorageForm: React.FC<StorageFormProps> = ({
    closeDialog,
    storage,
    errors,
    isItemSaving,
    formErrorsActions: {
        setFormErrors,
        clearFormErrors,
    },
    storageActions: { unsetStorageItem, updateStorage, createStorage },
}) => {
    React.useEffect(() => () => {
        clearFormErrors();
        unsetStorageItem();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [submitValues, setSubmitValues] = React.useState<IStorageRequest>({
        name: storage.name,
        mount: storage.mount,
        credentials: storage.credentials,
        type: storage.type.name,
        is_available_for_balancing: storage.is_available_for_balancing,
    });

    const handleFieldChange = (field: string, value: string) => setSubmitValues(setIn(submitValues, field, value));
    const handleSubmit = async (values: IStorageRequest) => {
        const rules = validate<IStorageRequest>(values, {
            name: requiredRule(<Translate content="validate.fieldRequired" />),
        });

        if (Object.keys(rules).length) {
            setFormErrors(rules);
            return;
        }

        try {
            storage.id ? await updateStorage(storage.id, values) : await createStorage(values);
            closeDialog();
        } catch (e) {
            throw e;
        }
    };

    const handleToggleIsAvailableForBalancing = () => {
        setSubmitValues({
            ...submitValues,
            is_available_for_balancing: !submitValues.is_available_for_balancing,
        });
    };

    const renderMetadata = () => {
        if (storage.type.name === StorageType.NFS) {
            return (
                <>
                    <FormFieldText
                        name="credentials[remote_path]"
                        placeholder="10.0.0.1:/data/share"
                        size="fill"
                        label={<Translate content="storage.form.nfs.remotePath" />}
                        required={true}
                    />
                    <FormFieldText
                        name="credentials[options]"
                        placeholder="rw,hard,intr,rsize=8192,wsize=8192,timeo=14" // cspell:disable-line
                        size="fill"
                        label={<Translate content="storage.form.nfs.options" />}
                    />
                </>
            );
        }

        return null;
    };

    return (
        <>
            <Form
                id={STORAGE_FORM_ID}
                footerClassName="hidden"
                onSubmit={handleSubmit}
                onFieldChange={handleFieldChange}
                values={submitValues}
                errors={errors}
                hideRequiredLegend={true}
                submitButton={false}
                cancelButton={false}
                applyButton={false}
                vertical={true}
            >
                <Section>
                    <FormFieldText
                        size="fill"
                        name="name"
                        placeholder="My Remote Storage"
                        label={<Translate content="storage.form.name" />}
                        required={true}
                    />
                    <FormField
                        name="type"
                        required={true}
                        value={submitValues.type}
                        label={<Translate content="backupNode.form.type" />}
                    >
                        {({ setValue }: IFormProps<string>) => (
                            <SegmentedControl
                                buttons={typeOptions}
                                selected={submitValues.type}
                                onChange={setValue}
                            />
                        )}
                    </FormField>
                    <FormField
                        label={
                            <Translate content="storage.form.availableForBalancing" />
                        }
                    >
                        <Switch
                            checked={submitValues.is_available_for_balancing}
                            onChange={handleToggleIsAvailableForBalancing}
                        />
                    </FormField>
                    <FormFieldText
                        size="fill"
                        name="mount"
                        placeholder="/mnt/data"
                        label={<Translate content="storage.form.mountPoint" />}
                        required={true}
                    />
                    {renderMetadata()}
                </Section>
            </Form>
            <Button
                type="submit"
                form={STORAGE_FORM_ID}
                fill={true}
                intent={INTENT_TYPE.PRIMARY}
                size={SIZE.LG}
                isLoading={isItemSaving}
            >
                <Translate content="storage.form.saveBtn" />
            </Button>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    storage: state.storage.item,
    isItemSaving: state.app.loadingFlags.has(LOADING_FLAGS.STORAGE_SAVE),
    errors: nestStringProperties(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    storageActions: bindActionCreators(storageActions, dispatch),
    formErrorsActions: bindActionCreators(formErrorsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(StorageForm);
