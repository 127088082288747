// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as PermissionActions from 'common/modules/permission/actions';
import { combineReducers } from 'redux';
import {
    ActionType,
    getType,
} from 'typesafe-actions';
import { IPermissionResponse } from 'common/api/resources/Permission';

interface IPermissions {
    list: IPermissionResponse[];
}

export type PermissionAction = ActionType<typeof PermissionActions>;
export type PermissionState = Readonly<IPermissions>;

export default combineReducers<PermissionState, PermissionAction>({
    list: (state = [], action) => {
        switch (action.type) {
        case getType(PermissionActions.setPermissionList):
            return action.payload;
        default:
            return state;
        }
    },
});
