// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as types from 'admin/managementNodeBackup/constants/types';
import { createCustomAction } from 'typesafe-actions';
import {
    ManagementNodeBackupStatus,
    IManagementNodeBackupResponse,
    IManagementNodeListRequest,
} from 'common/api/resources/ManagementNodeBackup/model';
import { IPaginateApiResponse } from 'common/api/resources/Response';
import { Dispatch } from 'redux';
import {
    setIsLoading,
    unsetIsLoading,
} from 'common/modules/app/loadingFlags/actions';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { managementNodeBackups } from 'common/api/resources/ManagementNodeBackup';
import { HTTP_CODES } from 'common/api/constants';
import {
    removeBatchSilent,
    removeSilent,
} from 'common/actions/actionsWrapper';

export const setManagementNodeBackupList = createCustomAction(
    types.SET_MANAGEMENT_NODE_BACKUP_LIST,
    (data: IPaginateApiResponse<IManagementNodeBackupResponse[]>) => ({ payload: data })
);

export const addManagementNodeBackupItem = createCustomAction(
    types.ADD_MANAGEMENT_NODE_BACKUP_ITEM,
    (data: IManagementNodeBackupResponse) => ({ payload: data })
);

export const updateManagementNodeBackupItem = createCustomAction(
    types.UPDATE_MANAGEMENT_NODE_BACKUP,
    (data: Partial<IManagementNodeBackupResponse>) => ({ payload: data })
);

export const updateManagementNodeBackupItemProgress = createCustomAction(
    types.UPDATE_MANAGEMENT_NODE_BACKUP_PROGRESS,
    (id: number, progress: number) => ( { payload: { id, progress } })
);

export const setManagementNodeBackupItemIsDeleting = createCustomAction(
    types.SET_MANAGEMENT_NODE_BACKUP_IS_DELETING,
    (id: number, isDeleting: boolean) => ({ payload: { id, is_deleting: isDeleting } })
);

export const setNextScheduledManagementNodeBackupDate = createCustomAction(
    types.SET_NEXT_SCHEDULED_MANAGEMENT_NODE_BACKUP_DATE,
    (date: string) => ({ payload: date })
);

export const getManagementNodeBackups = (request?: IManagementNodeListRequest) =>
    async (dispatch: Dispatch) => {
        dispatch(setIsLoading(LOADING_FLAGS.MANAGEMENT_NODE_BACKUP_LIST));

        try {
            const result = await managementNodeBackups.list(request);
            if (result.status === HTTP_CODES.OK) {
                dispatch(setManagementNodeBackupList(result.data));
            }

            return result;
        } finally {
            dispatch(unsetIsLoading(LOADING_FLAGS.MANAGEMENT_NODE_BACKUP_LIST));
        }
    };

export const createBackup = () => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.MANAGEMENT_NODE_BACKUP_CREATE));

    try {
        const result = await managementNodeBackups.create();
        if (result.status === HTTP_CODES.CREATED) {
            dispatch(addManagementNodeBackupItem(result.data.data));
            dispatch(updateManagementNodeBackupItem({
                id: result.data.data.id,
                status: ManagementNodeBackupStatus.CREATING,
                backup_progress: 0,
            }));
        }
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.MANAGEMENT_NODE_BACKUP_CREATE));
    }
};

export const restoreFromBackup = (id: number) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.MANAGEMENT_NODE_BACKUP_RESTORE));

    try {
        const result = await managementNodeBackups.restore(id);
        if (result.status === HTTP_CODES.OK) {
            dispatch(updateManagementNodeBackupItem({
                id,
                status: ManagementNodeBackupStatus.RESTORING,
                backup_progress: 0,
            }));
        }
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.MANAGEMENT_NODE_BACKUP_RESTORE));
    }
};

export const deleteBackup = (id: number) => async (dispatch: Dispatch) => await removeSilent(id, {
    dispatch,
    apiCall: managementNodeBackups.remove,
    setLoadingAction: setManagementNodeBackupItemIsDeleting,
    loadingFlag: LOADING_FLAGS.MANAGEMENT_NODE_BACKUP_REMOVE,
    translations: {
        success: 'managementNodeBackup.toasters.removed',
    },
});

export const deleteBackups = (ids: number[]) => async (dispatch: Dispatch) => await removeBatchSilent(ids, {
    dispatch,
    apiCall: managementNodeBackups.removeBatch,
    loadingFlag: LOADING_FLAGS.MANAGEMENT_NODE_BACKUP_REMOVE,
    translations: {
        success: 'managementNodeBackup.toasters.batchRemoved',
    },
});

export const getNextScheduledDate = () => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.MANAGEMENT_NODE_BACKUP_NEXT_SCHEDULED_DATE));

    try {
        const result = await managementNodeBackups.getNextScheduledDate();
        if (result.status === HTTP_CODES.OK) {
            dispatch(setNextScheduledManagementNodeBackupDate(result.data.data.next_scheduled_date));
        }

        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.MANAGEMENT_NODE_BACKUP_NEXT_SCHEDULED_DATE));
    }
};
