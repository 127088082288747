// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import { connect } from 'react-redux';
import {
    Form,
    Section,
    Translate,
} from '@plesk/ui-library';
import Schedule from 'common/containers/Schedule/Schedule';
import { Limit } from 'common/components/Limit/Limit';
import { Button } from 'admin/common/components/Button/Button';
import {
    INTENT_TYPE,
    SIZE,
} from 'common/constants';
import React from 'react';
import { RootState } from 'admin/core/store';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import * as settingsActions from 'common/modules/settings/actions';
import { ISchedule } from 'common/api/resources/model';
import { ISettingsManagementNodeBackup } from 'common/api/resources/Settings';

interface ISettingsFormProps {
    onSubmit: () => void;
}

export type SettingsFormProps =
    ISettingsFormProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const SettingsForm: React.FC<SettingsFormProps> = ({
    backupSettings,
    isSaving,
    onSubmit,
    settingsActions: { saveSettings },
}) => {
    const [values, setValues] = React.useState<ISettingsManagementNodeBackup>(backupSettings);

    const handleScheduleChange = (schedule: ISchedule) => {
        setValues({
            ...values,
            schedule,
        });
    };

    const handleChangeLimitEnabled = () => {
        setValues({
            ...values,
            limit: {
                ...values.limit,
                is_enabled: !values.limit.is_enabled,
            },
        });
    };

    const handleChangeLimit = (limit: string) => {
        setValues({
            ...values,
            limit: {
                ...values.limit,
                limit: +limit,
            },
        });
    };

    const handleSubmit = async (newBackupSettings: ISettingsManagementNodeBackup) => {
        await saveSettings({
            management_node_backup: newBackupSettings,
        }, LOADING_FLAGS.MANAGEMENT_NODE_BACKUP_SAVE_SETTINGS);
        onSubmit();
    };

    return (
        <>
            <Section>
                <Form
                    id="settingsForm"
                    footerClassName="hidden"
                    onSubmit={handleSubmit}
                    values={values}
                    hideRequiredLegend={true}
                    submitButton={false}
                    cancelButton={false}
                    applyButton={false}
                    vertical={true}
                >
                    <Schedule
                        schedule={values.schedule}
                        fieldName="management_node_backup[schedule]"
                        onChange={handleScheduleChange}
                    />
                    <Limit
                        limit={values.limit}
                        label={<Translate content="managementNodeBackup.settingsForm.limitLabel" />}
                        fieldName="management_node_backup[limit][limit]"
                        onChangeIsEnabled={handleChangeLimitEnabled}
                        onLimitChange={handleChangeLimit}
                        min={1}
                    />
                </Form>
            </Section>
            <Button
                type="submit"
                form="settingsForm"
                fill={true}
                intent={INTENT_TYPE.PRIMARY}
                size={SIZE.LG}
                isLoading={isSaving}
            >
                <Translate content="managementNodeBackup.settingsForm.saveBtn" />
            </Button>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    backupSettings: state.settings.management_node_backup,
    isSaving: state.app.loadingFlags.has(LOADING_FLAGS.MANAGEMENT_NODE_BACKUP_SAVE_SETTINGS),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    settingsActions: bindActionCreators(settingsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsForm);