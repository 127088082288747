// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import { Text } from 'common/modules/auth/components/StyledForm';
import { HTTP_CODES } from 'common/api/constants';
import { Translate } from '@plesk/ui-library';
import { IResponseError } from 'common/modules/app/responseError/reducer';

export interface INetworkErrorTextProps {
    error: IResponseError;
}

const translationMap = {
    [HTTP_CODES.NETWORK_ERROR]: <Translate content="networkError" />,
    [HTTP_CODES.AUTH_LINK_INVALID]: <Translate content="invalidAuthLink" />,
};

export const NetworkErrorText: React.FunctionComponent<INetworkErrorTextProps> = ({
    error: {
        code,
        error,
    },
}) => {
    if (!translationMap.hasOwnProperty(code)) {
        return (
            <Text>
                {error}
            </Text>
        );
    }

    return (
        <Text>
            {translationMap[code]}
        </Text>
    );
};
