// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import * as vmActions from 'common/modules/computeResourceVm/actions';
import { connect } from 'react-redux';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { RootState } from 'admin/core/store';
import VncDialog from 'common/containers/VncDialog/VncDialog';

const mapStateToProps = (state: RootState) => ({
    vm: state.computeResourceVm.item,
    isLoadingVm: state.app.loadingFlags.has(LOADING_FLAGS.COMPUTE_RESOURCE_VM),
    isSettingVncUp: state.app.loadingFlags.has(LOADING_FLAGS.VNC_UP),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getComputeResourceVm: bindActionCreators(vmActions.getComputeResourceVm, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(VncDialog);
