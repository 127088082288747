// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import { StyledTable } from 'common/components/styles/StyledTable';
import CopyText from 'common/containers/CopyText/CopyText';
import { IReverseDnsResponse } from 'common/api/resources/ReverseDns';
import { EditReverseDns } from 'common/components/ServerTabs/NetworkingTab/EditReverseDns';
import PopoverForm from 'common/components/ServerTabs/NetworkingTab/PopoverForm';
import { dataCySelector } from 'common/tests/selectors';
import { TABLE_ACTIONS } from 'common/components/ServerTabs/NetworkingTab/constants/test';
import ButtonWithConfirmation from 'common/components/ButtonWithConfirmation';
import { StyledActions } from 'common/components/Actions/Styles';
import * as computeResourceVmActions from 'common/modules/computeResourceVm/actions';
import { RootState } from 'client/core/store';
import { IpIssuedFor } from 'common/api/resources/Ip';
import TrafficCard from 'common/components/ServerTabs/NetworkingTab/components/TrafficCard/TrafficCard';
import { GridCol } from 'common/components/ServerTabs/NetworkingTab/GridCol';
import { hasPermission } from 'common/modules/permission/selectors';
import { PERMISSION_LIST } from 'common/modules/permission/constants';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { Button } from 'admin/common/components/Button/Button';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import {
    ComputeResourceVmStatus,
    IAdditionalIpAddFailedEvent,
    IAdditionalIpAddSuccessedEvent,
    IAdditionalIpDeletedEvent,
    IIpV4,
    IPrimaryIpChangedSuccessEvent,
    IVmResponse,
} from 'common/api/resources/ComputeResourceVm';
import {
    ICONS,
    INTENT_TYPE,
    SIZE,
    SOCKET_CHANNELS,
    SOCKET_EVENTS,
} from 'common/constants';
import {
    ItemList,
    NetworkingHeader,
} from 'common/components/ServerTabs/NetworkingTab/Styles';
import {
    Grid,
    IListColumn,
    Item,
    List,
    Translate,
} from '@plesk/ui-library';
import { NetworkTrafficLimitTypes } from 'common/api/resources/Plan';
import TotalTrafficCard from 'common/components/ServerTabs/NetworkingTab/components/TrafficCard/TotalTrafficCard';
import { getActionColumnProps } from 'common/helpers/list';
import { Dialog } from 'admin/common/components/Dialog/Dialog';
import AdditionalIPForm from 'common/components/ServerTabs/NetworkingTab/AdditionalIPForm';
import { RadioWithConfirmation } from 'common/components/RadioWithConfirmation/RadioWithConfirmation';
import { IpBlockType } from 'common/api/resources/IpBlock';
import { initEchoConnection } from 'common/services/EchoService';
import { ClusterImportProductType } from 'common/api/resources/ClusterImport';
import { VirtualizationType } from 'common/api/resources/ComputeResource';

const initialItem: IReverseDnsResponse = Object.freeze({
    id: 0,
    is_primary: false,
    ip: '',
    domain: '',
});

interface ITableItem {
    colIp: string | React.ReactNode;
    colDomain: string | React.ReactNode;
    colActions: React.ReactNode;
    key: string;
}

export interface INetworkingTabProps {
    echoCredentials: string;
    server: IVmResponse;
    tooltip: React.ReactNode;
}

export type NetworkingTabProps =
    INetworkingTabProps &
    ReturnType<typeof mapDispatchToProps> &
    ReturnType<typeof mapStateToProps>;

const renderIpColumn = (ip: string) => (
    <CopyText>
        <b>
            {ip}
        </b>
    </CopyText>
);

export const NetworkingTab: React.FC<NetworkingTabProps> = ({
    echoCredentials,
    canResetUsage,
    isResettingUsage,
    resetUsage,
    server,
    deleteReverseDns,
    deleteAdditionalIp,
    isAdditionalIpAdding,
    isAdditionalIpDeleting,
    isPrimaryIpChanging,
    changePrimaryIp,
    commitAdditionalIpCreatingSuccess,
    commitAdditionalIpCreatingFail,
    commitAdditionalIpDeletingSuccess,
    commitAdditionalIpDeletingFail,
    commitPrimaryIpChangingSuccess,
    commitPrimaryIpChangingFail,
    tooltip,
}) => {
    const [openedPopover, setOpenedPopover] = React.useState<number | null>();
    const [dialogOpened, setDialogOpened] = React.useState(false);
    const [reverseDnsIdForDeleting, setReverseDnsIdForDeleting] = React.useState(0);
    const [additionalIpIdForDeleting, setAdditionalIpIdForDeleting] = React.useState(0);
    const [newPrimaryIpId, setNewPrimaryIpId] = React.useState(0);

    // In case the page was reloaded.
    isAdditionalIpAdding = isAdditionalIpAdding || server.status === ComputeResourceVmStatus.ADDITIONAL_IP_ADDING;
    isAdditionalIpDeleting = isAdditionalIpDeleting || server.status === ComputeResourceVmStatus.ADDITIONAL_IP_DELETING;

    const hasRunningTask = isPrimaryIpChanging
        || isAdditionalIpAdding
        || isAdditionalIpDeleting;

    const isActionDisabled = React.useMemo(
        () => server.product === ClusterImportProductType.SOLUS_VM_1
            && server.virtualization_type === VirtualizationType.KVM,
        [server]
    );

    React.useEffect(() => {
        const echo = initEchoConnection(echoCredentials);

        if (server.uuid) {
            const channel = echo.private(`${SOCKET_CHANNELS.VIRTUAL_SERVER}.${server.uuid}`);

            channel.listen(
                SOCKET_EVENTS.ADDITIONAL_IP_ADD_SUCCESSED,
                (data: IAdditionalIpAddSuccessedEvent) => {
                    commitAdditionalIpCreatingSuccess(data.ip);
                }
            );
            channel.listen(
                SOCKET_EVENTS.ADDITIONAL_IP_ADD_FAILED,
                (data: IAdditionalIpAddFailedEvent) => {
                    commitAdditionalIpCreatingFail(data.ipId);
                }
            );
            channel.listen(
                SOCKET_EVENTS.ADDITIONAL_IP_DELETE_SUCCESSED,
                (data: IAdditionalIpDeletedEvent) => {
                    commitAdditionalIpDeletingSuccess(data.deletedIpId);
                    setAdditionalIpIdForDeleting(0);
                }
            );
            channel.listen(
                SOCKET_EVENTS.ADDITIONAL_IP_DELETE_FAILED,
                () => {
                    commitAdditionalIpDeletingFail();
                    setAdditionalIpIdForDeleting(0);
                }
            );
            channel.listen(
                SOCKET_EVENTS.PRIMARY_IP_CHANGE_SUCCESSED,
                (data: IPrimaryIpChangedSuccessEvent) => {
                    commitPrimaryIpChangingSuccess(data.newPrimaryIpId);
                    setNewPrimaryIpId(0);
                }
            );
            channel.listen(
                SOCKET_EVENTS.PRIMARY_IP_CHANGE_FAILED,
                () => {
                    commitPrimaryIpChangingFail();
                    setNewPrimaryIpId(0);
                }
            );
        }

        return () => echo.disconnect();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [server.uuid]);

    const handleDeleteReverseDns = (id: number) => async () => {
        try {
            setReverseDnsIdForDeleting(id);
            await deleteReverseDns(id);
        } finally {
            setReverseDnsIdForDeleting(0);
        }
    };
    const handleDeleteAdditionalIp = (id: number) => () => {
        setAdditionalIpIdForDeleting(id);
        deleteAdditionalIp(server.id, server.status, id);
    };

    const handleOpenPopover = (id: number) => () => setOpenedPopover(id);
    const handleClosePopover = () => setOpenedPopover(null);

    const handleOpenDialog = () => setDialogOpened(true);
    const handleCloseDialog = () => setDialogOpened(false);

    const handleResetUsage = () => resetUsage(server.id);

    const columns = (version: IpBlockType) => {
        const cols: Array<IListColumn<string>> = [
            {
                width: '20%',
                key: 'colIp',
                title: <Translate content="servers.tabs.networking.reverseDns.list.ip" />,
            },
        ];

        const isDnsIntegrationEnabled = server.ip_addresses.ipv6?.is_reverse_dns_enabled ||
            server.ip_addresses.ipv4.some(item => item.is_reverse_dns_enabled);

        if (isDnsIntegrationEnabled) {
            cols.push({
                key: 'colDomain',
                title: <Translate content="servers.tabs.networking.reverseDns.list.domain" />,
            });
        }

        if (version === IpBlockType.IPv4) {
            cols.push({
                key: 'colPrimary',
                title: <Translate content="servers.tabs.networking.reverseDns.list.primary" />,
            });
        }

        cols.push(getActionColumnProps());

        return cols;
    };

    const handlePrimaryIpChange = (ip: IIpV4) => async () => {
        setNewPrimaryIpId(ip.id);
        await changePrimaryIp(server.id, server.status, ip.id);
    };

    const renderIpRows = (ip: IIpV4) => ({
        colIp: renderIpColumn(ip.ip),
        colPrimary: (
            <RadioWithConfirmation
                id={`change-ip-radio-${ip.id}`}
                checked={ip.is_primary}
                name="primary"
                isLoading={(isPrimaryIpChanging && newPrimaryIpId === ip.id)}
                disabled={hasRunningTask || isActionDisabled}
                onConfirm={handlePrimaryIpChange(ip)}
                translations={{
                    button: <Translate content="servers.tabs.networking.additionalIps.changePrimaryIpConfirmationPopover.button"/>,
                    title: <Translate content="servers.tabs.networking.additionalIps.changePrimaryIpConfirmationPopover.title"/>,
                    tooltip,
                }}
                showTooltip={isActionDisabled}
                data-cy={dataCySelector(ip.id, TABLE_ACTIONS.CHANGE_PRIMARY_IP)}
            />
        ),
        colDomain: ip.reverse_dns.domain,
        colActions: (
            <StyledActions>
                <EditReverseDns
                    disabled={!ip.is_reverse_dns_enabled || hasRunningTask}
                    isPopoverOpened={openedPopover === ip.reverse_dns.id}
                    reverseDns={ip.reverse_dns}
                    data-cy={dataCySelector(ip.id, TABLE_ACTIONS.EDIT)}
                    handleOpenPopover={handleOpenPopover}
                    onPopoverClose={handleClosePopover}
                />
                <ButtonWithConfirmation
                    data-cy={dataCySelector(ip.id, TABLE_ACTIONS.REMOVE_ADDITIONAL_IP)}
                    isLoading={additionalIpIdForDeleting === ip.id}
                    disabled={ip.is_primary || hasRunningTask || isActionDisabled}
                    translations={{
                        title: (
                            <Translate content="servers.tabs.networking.additionalIps.removePopover.title" />
                        ),
                        button: (
                            <Translate content="servers.tabs.networking.additionalIps.removePopover.button" />
                        ),
                        tooltip: (
                            isActionDisabled
                                ? tooltip
                                : <Translate content="servers.tabs.networking.additionalIps.removePopover.tooltip" />
                        ),
                        text: (
                            <Translate content="servers.tabs.networking.additionalIps.removePopover.text" />
                        ),
                    }}
                    handleConfirm={handleDeleteAdditionalIp(ip.id)}
                    icon={ICONS.RECYCLE}
                />
            </StyledActions>
        ),
        key: ip.id.toString(),
    });

    const renderReversDnsRows = (id: number, item: IReverseDnsResponse, isReverseDnsEnabled: boolean) => ({
        colIp: renderIpColumn(item.ip),
        colDomain: item.domain,
        colActions: (
            <StyledActions>
                <EditReverseDns
                    disabled={!isReverseDnsEnabled || isPrimaryIpChanging}
                    isPopoverOpened={openedPopover === item.id}
                    reverseDns={item}
                    data-cy={dataCySelector(item.id, TABLE_ACTIONS.EDIT)}
                    handleOpenPopover={handleOpenPopover}
                    onPopoverClose={handleClosePopover}
                />
                <ButtonWithConfirmation
                    data-cy={dataCySelector(item.id, TABLE_ACTIONS.REMOVE_REVERSE_DNS)}
                    isLoading={reverseDnsIdForDeleting === item.id}
                    disabled={item.is_primary || !isReverseDnsEnabled}
                    translations={{
                        title: (
                            <Translate content="servers.tabs.networking.reverseDns.removePopover.title" />
                        ),
                        button: (
                            <Translate content="servers.tabs.networking.reverseDns.removePopover.button" />
                        ),
                        tooltip: (
                            <Translate content="servers.tabs.networking.reverseDns.removePopover.tooltip" />
                        ),
                    }}
                    handleConfirm={handleDeleteReverseDns(item.id)}
                    icon={ICONS.RECYCLE}
                />
            </StyledActions>
        ),
        key: id.toString(),
    });

    const renderRowBody = () => (
        <List
            emptyView={null}
            columns={columns(IpBlockType.IPv6)}
            data={
                server.ip_addresses?.ipv6?.reverse_dns.map(item => renderReversDnsRows(
                    server.ip_addresses.ipv6?.id || 0,
                    item,
                    !!server.ip_addresses?.ipv6?.is_reverse_dns_enabled
                ))
            }
        />
    );

    const getListData = () => {
        const listData: ITableItem[] = server.ip_addresses.ipv4
            .filter(item => item.issued_for === IpIssuedFor.VM)
            .map(item => renderIpRows(item));

        if (server.ip_addresses.ipv6) {
            listData.push({
                colIp: renderIpColumn(server.ip_addresses.ipv6.range),
                colDomain: (
                    <Translate
                        content="servers.tabs.networking.reverseDns.list.entries"
                        params={{ count: server.ip_addresses.ipv6.reverse_dns.length }}
                    />
                ),
                colActions: (
                    <PopoverForm
                        ipId={server.ip_addresses.ipv6.id}
                        isOpened={openedPopover === initialItem.id}
                        onClose={handleClosePopover}
                        target={
                            <Button
                                tooltip={
                                    <Translate content="servers.tabs.networking.reverseDns.list.addReverseDns" />
                                }
                                disabled={!server.ip_addresses.ipv6.is_reverse_dns_enabled || isPrimaryIpChanging}
                                ghost={true}
                                icon={ICONS.PLUS}
                                onClick={handleOpenPopover(initialItem.id)}
                            >
                                <Translate content="servers.tabs.networking.reverseDns.list.addReverseDns" />
                            </Button>
                        }
                        reverseDns={initialItem}
                    />
                ),
                key: server.ip_addresses.ipv6.id.toString(),
            });
        }

        return listData;
    };

    return (
        <Grid gap="md">
            <GridCol>
                <Item
                    title={
                        <NetworkingHeader>
                            <h3>
                                <Translate content="servers.tabs.networking.reverseDns.title" />
                            </h3>
                            {server.plan.is_additional_ips_available && (
                                <Button
                                    size={SIZE.LG}
                                    intent={INTENT_TYPE.PRIMARY}
                                    icon={ICONS.PLUS}
                                    onClick={handleOpenDialog}
                                    isLoading={isAdditionalIpAdding}
                                    disabled={hasRunningTask || isActionDisabled}
                                    tooltip={isActionDisabled && tooltip}
                                >
                                    <Translate content="servers.tabs.networking.additionalIps.button" />
                                </Button>
                            )}
                        </NetworkingHeader>
                    }
                    view="card"
                >
                    <StyledTable>
                        <List
                            renderRowBody={server.ip_addresses.ipv6?.is_reverse_dns_enabled ? renderRowBody : null}
                            emptyView={null}
                            columns={columns(IpBlockType.IPv4)}
                            data={getListData()}
                            notExpandableRows={server.ip_addresses.ipv4.map(item => item.id.toString())}
                        />
                        <Dialog
                            isOpen={dialogOpened}
                            heading={<Translate content="servers.tabs.networking.additionalIps.createDialog.header"/>}
                            size={SIZE.XS}
                            closeHandler={handleCloseDialog}
                        >
                            <AdditionalIPForm
                                server={server}
                                onSubmit={handleCloseDialog}
                            />
                        </Dialog>
                    </StyledTable>
                </Item>
            </GridCol>
            <GridCol>
                {server.plan.network_traffic_limit_type === NetworkTrafficLimitTypes.Total && (
                    <ItemList
                        xs={1}
                        sm={1}
                        md={1}
                        xl={2}
                        lg={2}
                        gap={SIZE.MD}
                        stretchable={true}
                    >
                        <TotalTrafficCard
                            incomingTraffic={server.usage.network.incoming}
                            outgoingTraffic={server.usage.network.outgoing}
                            limit={server.plan.limits.network_total_traffic}
                        />
                    </ItemList>
                )}
                {server.plan.network_traffic_limit_type === NetworkTrafficLimitTypes.Separate && (
                    <ItemList
                        xs={1}
                        sm={1}
                        md={2}
                        xl={2}
                        lg={2}
                        gap={SIZE.MD}
                        stretchable={true}
                    >
                        <TrafficCard
                            networkTraffic={server.usage.network.outgoing}
                            limit={server.plan.limits.network_outgoing_traffic}
                            title={
                                <Translate content="servers.tabs.networking.traffic.outgoing.title" />
                            }
                            description={
                                <Translate content="servers.tabs.networking.traffic.outgoing.description"/>
                            }
                            exceededDescription={
                                <Translate content="servers.tabs.networking.traffic.outgoing.exceededDescription"/>
                            }
                        />
                        <TrafficCard
                            networkTraffic={server.usage.network.incoming}
                            limit={server.plan.limits.network_incoming_traffic}
                            title={
                                <Translate content="servers.tabs.networking.traffic.incoming.title" />
                            }
                            description={
                                <Translate content="servers.tabs.networking.traffic.incoming.description"/>
                            }
                            exceededDescription={
                                <Translate content="servers.tabs.networking.traffic.incoming.exceededDescription"/>
                            }
                        />
                    </ItemList>
                )}
                {canResetUsage && (
                    <ButtonWithConfirmation
                        data-cy={dataCySelector(server.id, TABLE_ACTIONS.RESET_TRAFFIC)}
                        disabled={server.is_processing}
                        confirmationButtonGhost={false}
                        translations={{
                            text: (
                                <Translate content="servers.tabs.networking.traffic.resetPopover.text" />
                            ),
                            button: (
                                <Translate content="servers.tabs.networking.traffic.resetPopover.confirmationButton"/>
                            ),
                            title: (
                                <Translate content="servers.tabs.networking.traffic.resetPopover.title" />
                            ),
                        }}
                        handleConfirm={handleResetUsage}
                        isLoading={isResettingUsage || server.status === ComputeResourceVmStatus.USAGE_RESETTING}
                        confirmationButtonText={
                            <Translate content="servers.tabs.networking.traffic.resetPopover.button"/>
                        }
                        buttonIntent={INTENT_TYPE.PRIMARY}
                        buttonSize={SIZE.LG}
                        withStyledButton={true}
                        placement="top-right"
                    />
                )}
            </GridCol>
        </Grid>
    );
};

const mapStateToProps = (state: RootState) => ({
    canResetUsage: hasPermission(state, PERMISSION_LIST.MANAGE_SERVERS),
    isResettingUsage: state.app.loadingFlags.has(LOADING_FLAGS.COMPUTE_RESOURCE_VM_RESET_USAGE),
    isAdditionalIpAdding: state.app.loadingFlags.has(LOADING_FLAGS.COMPUTE_RESOURCE_VM_ADDITIONAL_IP_ADDING),
    isAdditionalIpDeleting: state.app.loadingFlags.has(LOADING_FLAGS.COMPUTE_RESOURCE_VM_ADDITIONAL_IP_DELETING),
    isPrimaryIpChanging: state.app.loadingFlags.has(LOADING_FLAGS.COMPUTE_RESOURCE_VM_CHANGE_PRIMARY_IP),
    echoCredentials: `${state.auth.authData.token_type} ${state.auth.authData.access_token}`,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    deleteReverseDns: bindActionCreators(computeResourceVmActions.deleteIpV6ReverseDns, dispatch),
    deleteAdditionalIp: bindActionCreators(computeResourceVmActions.deleteAdditionalIp, dispatch),
    changePrimaryIp: bindActionCreators(computeResourceVmActions.changePrimaryIp, dispatch),
    resetUsage: bindActionCreators(computeResourceVmActions.resetComputeResourceVmUsage, dispatch),
    commitAdditionalIpCreatingSuccess: bindActionCreators(computeResourceVmActions.commitAdditionalIpCreatingSuccess, dispatch),
    commitAdditionalIpCreatingFail: bindActionCreators(computeResourceVmActions.commitAdditionalIpCreatingFail, dispatch),
    commitAdditionalIpDeletingSuccess: bindActionCreators(computeResourceVmActions.commitAdditionalIpDeletingSuccess, dispatch),
    commitAdditionalIpDeletingFail: bindActionCreators(computeResourceVmActions.commitAdditionalIpDeletingFail, dispatch),
    commitPrimaryIpChangingSuccess: bindActionCreators(computeResourceVmActions.commitPrimaryIpChangingSuccess, dispatch),
    commitPrimaryIpChangingFail: bindActionCreators(computeResourceVmActions.commitPrimaryIpChangingFail, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(NetworkingTab);
