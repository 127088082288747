// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import { RedocStandalone } from 'redoc';
import { DocumentationContainer } from 'common/containers/documentation/Styles';
import { Loader } from 'common/components';
import {
    documentation,
    IDocumentationResponse,
} from 'common/api/resources/Documentation';

const Documentation: React.FC = () => {
    const [json, setJson] = React.useState<IDocumentationResponse>();

    React.useEffect(() => {
        const fetch = async () => {
            const result = await documentation.get();
            const baseUrl = process.env.REACT_APP_API_URL?.replace(/\/+$/, '');

            for (const path of Object.keys(result.data.paths)) {
                for (const method of Object.keys(result.data.paths[path])) {
                    const sample = result.data.paths[path][method]['x-code-samples'];
                    sample[0].source = sample[0].source.replace('/api/v1', baseUrl);
                }
            }

            setJson(result.data);
        };

        fetch();
    }, []);

    return (
        <Loader isLoading={json === undefined}>
            <DocumentationContainer>
                <RedocStandalone
                    spec={json}
                    options={{
                        theme: { colors: { primary: { main: '#dd5522' } } },
                    }}
                />
            </DocumentationContainer>
        </Loader>
    );
};

export default Documentation;