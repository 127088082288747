// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import { RootState } from 'admin/core/store';
import { ICommonState } from 'common/store';

export const nestedToLinear = (state: RootState) => {
    const errors = {};
    Object.keys(state.app.formErrors).forEach(key => {
        if (key.indexOf('.') === -1) {
            errors[key] = state.app.formErrors[key];
            return;
        }

        const [, objectProperty] = key.split('.');
        errors[objectProperty] = state.app.formErrors[key];
    });

    return errors;
};

export const nestStringProperties = (state: ICommonState) => {
    if (!state.app.formErrors) {
        return {};
    }

    const isPlainObject = (errors: Record<string, unknown>) => !!errors && errors.constructor === {}.constructor;
    const getNestedObject = (errors: Record<string, unknown>) => Object.entries(errors).reduce((result, [prop, val]) => {
        prop.split('.').reduce((nestedResult, item, propIndex, propArray) => {
            const lastProp = propIndex === propArray.length - 1;
            if (lastProp) {
                nestedResult[item] = isPlainObject(val as Record<string, unknown>)
                    ? getNestedObject(val as Record<string, unknown>)
                    : val;
            } else {
                nestedResult[item] = nestedResult[item] || {};
            }
            return nestedResult[item];
        }, result);
        return result;
    }, {});

    return getNestedObject(state.app.formErrors);
};

export const processFormErrors = (formErrors: { [key: string]: string[] }) => {
    const errors = Object.assign({}, formErrors);
    if ('fqdns' in formErrors) {
        if (!('name' in errors)) {
            errors['name'] = [];
        }
        errors['name'] = errors['name'].concat(errors['fqdns'][0]);
    }

    return errors;
};

export const getProcessedErrors = (state: ICommonState) => processFormErrors(nestStringProperties(state));
