// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import {
    adjustHue,
    darken,
    saturate,
} from 'polished';

export const COLORS = {
    PRIMARY: '#1d6a90',
    PRIMARY_HOVER: darken(0.03, saturate(0.04, adjustHue(2, '#1d6a90'))),
    PRIMARY_PRESSED: darken(0.08, saturate(0.08, adjustHue(4, '#1d6a90'))),
    WHITE: '#fff',
    WHITE_10: '#ededed',
    RED: '#be2d45',
    ORANGE: '#a56627',
    GREEN: '#5c7c1d',
    GREY_10: '#fbfbfb',
    GREY_20: '#f5f5f5',
    GREY_30: '#ececec',
    GREY_40: '#dedede',
    GREY_50: '#ccc',
    GREY_60: '#b5b5b5',
    GREY_70: '#979797',
    GREY_80: '#737373',
    GREY_90: '#4b4b4b',
    GREY_100: '#222',
    BLUE_10: '#3c87a5',
};
