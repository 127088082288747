// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import styled from 'styled-components';

export const ConfirmationDialogItem = styled.div`
  margin-top: 15px;
  
  >button {
    min-height: 40px;
    min-width: 100px;
    padding: 12px 24px;
    font-size: 14px;
    margin-right: 15px;
  }
`;