// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as iconActions from 'admin/icon/actions';
import { combineReducers } from 'redux';
import {
    ActionType,
    getType,
} from 'typesafe-actions';
import {
    IPaginateApiResponse,
    paginateInitialState,
} from 'common/api/resources/Response';
import { addToPaginated } from 'common/reducers/list';
import {
    IconType,
    IIconResponse,
} from 'common/api/resources/Icon';

interface IIcon {
    list: IPaginateApiResponse<IIconResponse[]>;
    item: IIconResponse;
}

export type IconAction = ActionType<typeof iconActions>;
export type IconState = IIcon;

export const initialIconState: IIconResponse = {
    id: 0,
    name: '',
    url: '',
    type: IconType.OS,
    is_deleting: false,
};

export default combineReducers<IconState, IconAction>({
    list: (state = paginateInitialState, action: IconAction) => {
        switch (action.type) {
        case getType(iconActions.appendIcons):
            return {
                links: action.payload.links,
                data: [...state.data, ...action.payload.data],
                meta: action.payload.meta,
            };
        case getType(iconActions.addIconItem):
            return addToPaginated(state, action.payload);
        case getType(iconActions.setIconList):
            return action.payload;
        case getType(iconActions.removeIconItem):
            return {
                ...state,
                data: state.data.filter(item => item.id !== action.payload),
            };
        case getType(iconActions.updateIconItem):
            return {
                ...state,
                data: state.data.map(item => {
                    if (item.id === action.payload.id) {
                        return action.payload;
                    }

                    return item;
                }),
            };
        case getType(iconActions.setIconItemIsDeleting):
            return {
                ...state, data: [...state.data.map(item => {
                    if (item.id === action.payload.id) {
                        return { ...item, is_deleting: action.payload.isDeleting };
                    }

                    return item;
                })],
            };
        default:
            return state;
        }
    },
    item: (state = initialIconState, action) => {
        switch (action.type) {
        case getType(iconActions.setIconItem):
            return action.payload;
        case getType(iconActions.unsetIconItem):
            return { ...initialIconState };
        default:
            return state;
        }
    },
});
