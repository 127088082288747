// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

export enum VM_TABS {
    SETTINGS_TAB = '#settings',
    RESCUE_TAB = '#rescue',
    BACKUPS_TAB = '#backups',
    RESIZE_TAB = '#resize',
    TASKS_TAB = '#reinstall',
    REINSTALL_TAB = '#tasks',
    SNAPSHOT_TAB = '#snapshots',
    GRAPH_TAB = '#graphs',
    NETWORKING_TAB = '#networking',
}

export enum VMS_TABS {
    SERVERS_TAB = '#servers',
    MIGRATIONS_TAB = '#migrations',
}

export const TEST = {
    TAB: {
        SETTINGS: 'tab-settings',
        RESCUE: 'tab-rescue',
        BACKUP: 'tab-backup',
        TASK: 'tab-task',
        REINSTALL: 'tab-reinstall',
        RESIZE: 'tab-resize',
        SNAPSHOT: 'tab-snapshot',
        GRAPH: 'tab-graph',
        NETWORKING: 'tab-networking',
    },

    VMS_TAB: {
        SERVERS: 'vms-tab-servers',
        MIGRATIONS: 'vms-tab-migrations',
    },

    VMS: {
        CREATE_BUTTON: 'vms-create',
    },

    VM_CREATE: {
        COMPUTE_RESOURCE_SELECT: 'compute-resource-select',
        SSH_SELECT: 'ssh-select',
        PASSWORD: 'password',
        USER: 'user',
        PROJECT: 'project',
        CREATE_BUTTON: 'create-button',
    },

    SERVERS_MIGRATION_FORM: {
        METRICS: {
            VM: 'migration-form-metrics-vm',
            MEMORY: 'migration-form-metrics-memory',
            DISK: 'migration-form-metrics-disk',
        },
        DESTINATION: 'migration-form-destination',
    },

    DESCRIPTION: {
        IPS: 'vm-description-ips',
    },

    ROW: {
        IP_BLOCKS: 'ip-blocks',
    },
};
