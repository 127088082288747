// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import { IPlanResponse } from 'common/api/resources/Plan';
import { IOsImageResponse } from 'common/api/resources/OsImage';
import { ILocationResponse } from 'common/api/resources/Location';
import { IApplicationResponse } from 'common/api/resources/Application';
import { ComputeResourceVmCustomPlan } from 'common/api/resources/ComputeResourceVm';
import { VirtualizationType } from 'common/api/resources/ComputeResource';

export default class ManagerOfDisabledEntities {
    plans: IPlanResponse[];
    planId?: number;
    osImages: IOsImageResponse[];
    osImageVersionId?: number;
    applications: IApplicationResponse[];
    applicationId?: number;
    locations?: ILocationResponse[];
    location?: ILocationResponse;
    locationId?: number;

    constructor(
        plans: IPlanResponse[],
        osImages: IOsImageResponse[],
        applications: IApplicationResponse[],
        planId?: number,
        osImageVersionId?: number,
        applicationId?: number,
        locations?: ILocationResponse[],
        locationId?: number
    ) {
        this.plans = plans;
        this.planId = planId;
        this.osImages = osImages;
        this.osImageVersionId = osImageVersionId;
        this.applications = applications;
        this.applicationId = applicationId;
        this.locations = locations;
        this.locationId = locationId;
        this.location = locations?.find(location => location.id === this.locationId && location.available_plans !== undefined);
    }

    getDisabledPlanIds = () => this.plans.filter(plan => (
        this.osImageVersionId && !plan.available_os_image_versions?.some(item => item.id === this.osImageVersionId)
    ) || (
        this.applicationId && !plan.available_applications?.some(item => item.id === this.applicationId)
    ) || (
        this.locationId && !plan.available_locations?.some(item => item.id === this.locationId)
    )).map(item => item.id);

    getDisabledOsImageVersionIds = (customPlan?: ComputeResourceVmCustomPlan) =>
        this.osImages.map(item => item.versions).flat(1).filter(osImageVersion => {
            if (customPlan !== undefined) {
                return osImageVersion.virtualization_type !== customPlan.virtualization_type;
            }

            if (this.planId && !osImageVersion.available_plans?.some(item => item.id === this.planId)) {
                return true;
            }

            if (this.location) {
                for (const availablePlan of this.location?.available_plans!) {
                    if (osImageVersion.available_plans?.some(item => item.id === availablePlan.id)) {
                        return false;
                    }
                }

                return true;
            }

            return false;
        }).map(item => item.id);

    getDisabledApplicationIds = (customPlan?: ComputeResourceVmCustomPlan) => this.applications.filter(application => {
        if (customPlan !== undefined) {
            return customPlan.virtualization_type === VirtualizationType.VZ;
        }

        if (this.planId && !application.available_plans?.some(item => item.id === this.planId)) {
            return true;
        }

        if (this.location) {
            for (const availablePlan of this.location?.available_plans!) {
                if (application.available_plans?.some(item => item.id === availablePlan.id)) {
                    return false;
                }
            }

            return true;
        }

        return false;
    }).map(item => item.id);

    getDisabledLocationIds = () => this.locations !== undefined ? this.locations.filter(location => {
        if (this.planId && !location.available_plans?.some(item => item.id === this.planId)) {
            return true;
        }

        if (location.available_plans !== undefined && (this.osImageVersionId || this.applicationId)) {
            for (const availablePlan of location.available_plans) {
                if (this.osImageVersionId && this.plans.find(plan => plan.id === availablePlan.id
                    && plan?.available_os_image_versions?.some(item => item.id === this.osImageVersionId)
                )) {
                    return false;
                }

                if (this.applicationId && this.plans.find(plan => plan.id === availablePlan.id
                    && plan?.available_applications?.some(item => item.id === this.applicationId)
                )) {
                    return false;
                }
            }

            return true;
        }

        return false;
    }).map(item => item.id) : [];
}