// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import * as computeResourceVmActions from 'common/modules/computeResourceVm/actions';
import { Loader } from 'common/components';
import { connect } from 'react-redux';
import { INTENT_TYPE } from 'common/constants';
import CopyText from 'common/containers/CopyText/CopyText';
import ServerInfoBlock from 'admin/computeResourceVm/containers/ComputeResourceVmRow/ServerInfoBlock';
import {
    IPlanLimit,
    NetworkTrafficLimitTypes,
} from 'common/api/resources/Plan';
import ButtonWithConfirmation from 'common/components/ButtonWithConfirmation';
import { TEST } from 'admin/computeResourceVm/constants';
import {
    ServerInfo,
    ServerInfoBlockWrapper,
} from 'admin/computeResourceVm/containers/ComputeResourceVmRow/Styles';
import {
    convertToDataUnit,
    DataUnit,
} from 'common/helpers/units';
import {
    ComputeResourceVmStatus,
    getViewableIps,
    IVmResponse,
} from 'common/api/resources/ComputeResourceVm';
import {
    Status,
    Text,
    Translate,
} from '@plesk/ui-library';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { VIRTUALIZATION_TYPE_TRANSLATION_MAP } from 'common/api/resources/ComputeResource';
import { PlanDescription } from 'common/components/plan/components/PlanDescription/PlanDescription';

interface IComputeResourceVmRowProps {
    isLoading: boolean;
    item: IVmResponse;
}

export type ComputeResourceVmRowProps =
    IComputeResourceVmRowProps &
    ReturnType<typeof mapDispatchToProps>;

export const ComputeResourceVmRow: React.FC<ComputeResourceVmRowProps> = ({
    isLoading,
    item,
    computeResourceVmActions: {
        getComputeResourceVm,
        resetComputeResourceVmUsage,
    },
}) => {
    React.useLayoutEffect(() => {
        getComputeResourceVm(item.id);
    }, [getComputeResourceVm, item.id]);

    const [isResettingUsage, setIsResettingUsage] = React.useState(false);
    const ipBlockNames = getViewableIps(item).map(ip => ip.ip_block?.name);

    const handleResetUsage = async () => {
        try {
            setIsResettingUsage(true);
            await resetComputeResourceVmUsage(item.id);
        } finally {
            setIsResettingUsage(false);
        }
    };

    const renderNetworkUsage = (traffic: number, isExceeded: boolean, limit: IPlanLimit<DataUnit>) => {
        const intent = isExceeded ? INTENT_TYPE.WARNING : undefined;

        return (
            <Status intent={intent}>
                <Text intent={intent} bold={true}>
                    {convertToDataUnit(traffic, limit.unit)} {limit.unit}
                </Text>
                {limit.is_enabled && (
                    <Text>&nbsp;/ {limit.limit} {limit.unit}</Text>
                )}
            </Status>
        );
    };

    return (
        <Loader isLoading={isLoading} center={false}>
            <ServerInfo>
                <ServerInfoBlock
                    title={
                        <Translate content="computeResource.servers.list.info.virtualizationType" />
                    }
                >
                    {VIRTUALIZATION_TYPE_TRANSLATION_MAP[item.virtualization_type]}
                </ServerInfoBlock>
                <ServerInfoBlock
                    title={
                        <Translate content="computeResource.servers.list.info.osImage" />
                    }
                >
                    {item.settings.os_image.name}
                </ServerInfoBlock>
                {ipBlockNames.length > 0 && (
                    <ServerInfoBlock
                        data-cy={TEST.ROW.IP_BLOCKS}
                        title={
                            <Translate content="computeResource.servers.list.info.ipBlock" />
                        }
                    >
                        {ipBlockNames.join(', ')}
                    </ServerInfoBlock>
                )}
                <ServerInfoBlock
                    title={
                        <Translate content="computeResource.servers.list.info.macAddress" />
                    }
                >
                    <CopyText>{item.settings.mac_address}</CopyText>
                </ServerInfoBlock>
                <ServerInfoBlock
                    title={
                        <Translate content="computeResource.servers.list.hypervisorId" />
                    }
                >
                    <CopyText>{item.uuid}</CopyText>
                </ServerInfoBlock>
                {item.project && (
                    <ServerInfoBlock
                        title={
                            <Translate content="computeResource.servers.list.info.project" />
                        }
                    >
                        {item.project.name}
                    </ServerInfoBlock>
                )}
            </ServerInfo>
            <ServerInfo>
                {item.plan.network_traffic_limit_type === NetworkTrafficLimitTypes.Separate && (
                    <>
                        <ServerInfoBlock
                            title={
                                <Translate content="computeResource.servers.list.info.incomingTraffic" />
                            }
                        >
                            {renderNetworkUsage(
                                item.usage.network.incoming.value,
                                item.usage.network.incoming.is_exceeded,
                                item.plan.limits.network_incoming_traffic
                            )}
                        </ServerInfoBlock>
                        <ServerInfoBlock
                            title={
                                <Translate content="computeResource.servers.list.info.outgoingTraffic" />
                            }
                        >
                            {renderNetworkUsage(
                                item.usage.network.outgoing.value,
                                item.usage.network.outgoing.is_exceeded,
                                item.plan.limits.network_outgoing_traffic
                            )}
                        </ServerInfoBlock>
                    </>
                )}
                {item.plan.network_traffic_limit_type === NetworkTrafficLimitTypes.Total && (
                    <ServerInfoBlock
                        title={
                            <Translate content="computeResource.servers.list.info.totalTraffic" />
                        }
                    >
                        {renderNetworkUsage(
                            item.usage.network.incoming.value + item.usage.network.outgoing.value,
                            item.usage.network.incoming.is_exceeded && item.usage.network.outgoing.is_exceeded,
                            item.plan.limits.network_total_traffic
                        )}
                    </ServerInfoBlock>
                )}
                <ServerInfoBlock
                    title={
                        <Translate content="computeResource.servers.list.info.plan" />
                    }
                >
                    <PlanDescription
                        planName={!item.plan.is_custom ? item.plan.name : undefined}
                        vcpu={item.specifications.vcpu}
                        disk={item.specifications.disk}
                        ram={item.specifications.ram}
                    />
                </ServerInfoBlock>
            </ServerInfo>
            <ServerInfoBlockWrapper>
                <ButtonWithConfirmation
                    disabled={item.is_processing}
                    isLoading={isResettingUsage || item.status === ComputeResourceVmStatus.USAGE_RESETTING}
                    confirmationButtonGhost={false}
                    confirmationButtonText={
                        <Translate content="servers.tabs.networking.traffic.resetPopover.button"/>
                    }
                    translations={{
                        text: (
                            <Translate content="servers.tabs.networking.traffic.resetPopover.text" />
                        ),
                        button: (
                            <Translate content="servers.tabs.networking.traffic.resetPopover.confirmationButton"/>
                        ),
                        title: (
                            <Translate content="servers.tabs.networking.traffic.resetPopover.title" />
                        ),
                    }}
                    handleConfirm={handleResetUsage}
                />
            </ServerInfoBlockWrapper>
        </Loader>
    );
};
const mapDispatchToProps = (dispatch: Dispatch) => ({
    computeResourceVmActions: bindActionCreators(computeResourceVmActions, dispatch),
});

export default connect(null, mapDispatchToProps)(ComputeResourceVmRow);
