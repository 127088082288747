// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import RFB,
{ RfbOptions } from '@novnc/novnc/core/rfb';
import { Loader } from 'common/components';
import CopyText from 'common/containers/CopyText/CopyText';
import LocationCell from 'common/components/IconCell/LocationCell';
import {
    ICONS,
    INTENT_TYPE,
} from 'common/constants';
import {
    getViewableIps,
    IVmResponse,
} from 'common/api/resources/ComputeResourceVm';
import {
    AlertContainer,
    VncDialogContainer,
    VncDialogHeader,
    VncDialogHeaderItem,
    VncInstance,
} from 'common/components/VncDialog/Styles';
import {
    Action,
    Alert,
    Translate,
    Icon,
} from '@plesk/ui-library';

export interface IVncDialogProps {
    vm: IVmResponse;
    isSettingVncUp: boolean;
}

export const VncDialog: React.FC<IVncDialogProps> = ({
    vm,
    isSettingVncUp,
}) => {
    const rfbEl = React.useRef<HTMLDivElement>(null);
    const [errorMessage, setErrorMessage] = React.useState<string | React.ReactNode>();
    const [rfbInstance, setRfbInstance] = React.useState<RFB>();

    const initVnc = async () => {
        if (rfbInstance || !vm.id || isSettingVncUp || !vm.vnc_url) {
            return;
        }

        document.title = `${vm.name} - VNC`;

        try {
            const options: RfbOptions = {
                credentials: {
                    password: vm.settings.vnc_password,
                },
                shared: true,
                showDotCursor: true,
            };

            const instance = new RFB(
                rfbEl.current,
                `${process.env.REACT_APP_VNC_PROXY_URL}${vm.vnc_url}`,
                options
            );

            instance.addEventListener('connect', onConnect);
            instance.addEventListener('disconnect', onDisconnect);
            instance.scaleViewport = true;
            instance.resizeSession = true;
            instance.focusOnClick = true;

            setRfbInstance(instance);
        } catch (e) {
            setErrorMessage(e.response.data.message);
        }
    };

    React.useEffect(() => {
        initVnc();

        return () => {
            disconnect();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vm, rfbInstance, isSettingVncUp]);

    const onConnect = () => {
        setErrorMessage('');
    };

    const disconnect = () => {
        if (rfbInstance) {
            rfbInstance.disconnect();
            rfbInstance.removeEventListener('connect', onConnect);
            rfbInstance.removeEventListener('disconnect', onDisconnect);
        }
    };

    const onDisconnect = () => {
        disconnect();
        setErrorMessage(<Translate content="vncDialog.connectionFailed" />);
    };

    const handleSendCtrlAltDel = () => {
        if (rfbInstance) {
            rfbInstance.sendCtrlAltDel();
        }
    };

    return (
        <VncDialogContainer>
            <VncDialogHeader>
                <VncDialogHeaderItem>
                    <img
                        width={16}
                        src={vm.settings.os_image.icon}
                        alt={vm.settings.os_image.name}
                    />
                    {vm.name}
                </VncDialogHeaderItem>
                <VncDialogHeaderItem>
                    <Icon
                        name={ICONS.LOCATION}
                        intent={INTENT_TYPE.INACTIVE}
                    />
                    {getViewableIps(vm).map(ip => <CopyText key={ip.id}>{ip.ip}</CopyText>)}
                </VncDialogHeaderItem>
                {vm.location && (
                    <VncDialogHeaderItem>
                        <LocationCell location={vm.location} />
                    </VncDialogHeaderItem>
                )}
                <VncDialogHeaderItem>
                    <Icon
                        name={ICONS.USER}
                        intent={INTENT_TYPE.INACTIVE}
                    />
                    {vm.settings.user}
                </VncDialogHeaderItem>
                <VncDialogHeaderItem>
                    <Loader
                        isLoading={isSettingVncUp}
                        text={
                            <div>
                                <Translate content="vncDialog.connecting" />
                            </div>
                        }
                    >
                        <Action onClick={handleSendCtrlAltDel}>Ctrl+Alt+Del</Action>
                    </Loader>
                </VncDialogHeaderItem>
            </VncDialogHeader>
            {errorMessage && (
                <AlertContainer>
                    <Alert intent="danger">
                        {errorMessage}
                    </Alert>
                </AlertContainer>
            )}
            {!errorMessage && (
                <VncInstance
                    ref={rfbEl}
                    id="noVNC-canvas"
                />
            )}
        </VncDialogContainer>
    );
};
