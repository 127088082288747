// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as dashboardActions from 'admin/dashboard/actions';
import { combineReducers } from 'redux';
import {
    ActionType,
    getType,
} from 'typesafe-actions';
import { IDashboardResponse } from 'common/api/resources/Dashboard';

interface IDashboard {
    data: IDashboardResponse;
}

export type DashboardAction = ActionType<typeof dashboardActions>;
export type DashboardState = IDashboard;

export const initialDashboardState: IDashboardResponse = {
    compute_resources: {
        total: 0,
        status: {
            active: 0,
            failed: 0,
            unavailable: 0,
        },
    },
    servers: {
        total: 0,
        status: {
            started: 0,
            stopped: 0,
            unavailable: 0,
        },
    },
    users: {
        total: 0,
    },
    ipv4: {
        total: 0,
        used: 0,
    },
    cluster_memory: {
        total: 0,
        used: 0,
    },
    cluster_disk: {
        total: 0,
        used: 0,
    },
};

export default combineReducers<DashboardState, DashboardAction>({
    data: (state = initialDashboardState, action) => {
        switch (action.type) {
        case getType(dashboardActions.setDashboardData):
            return action.payload;
        default:
            return state;
        }
    },
});
