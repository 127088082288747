// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'admin/core/store';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import {
    Alert,
    Form,
    FormField,
    FormFieldSelect,
    ISegmentedControlButton,
    Section,
    Translate,
} from '@plesk/ui-library';
import * as computeResourceActions from 'admin/computeResource/actions';
import * as formErrorsActions from 'common/modules/app/formErrors/actions';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { validate } from 'common/validator';
import isEmpty from 'validator/lib/isEmpty';
import {
    Loader,
    Tooltip,
} from 'common/components';
import {
    INTENT_TYPE,
    SIZE,
} from 'common/constants';
import ConfirmationDialog from 'common/components/ConfirmationDialog/ConfirmationDialog';
import { Button } from 'admin/common/components/Button/Button';
import NetworkFormError from 'common/containers/NetworkFormError/NetworkFormError';
import { SegmentedControl } from 'common/components/SegmentedControl/SegmentedControl';
import { NetworkType } from 'common/api/resources/ComputeResource';
import { humanizeNetworkType } from 'admin/computeResource/constants';

interface IComputeResourceConfigureNetworkProps {
    onConfigured: () => void;
}

export type ComputeResourceConfigureNetworkProps =
    IComputeResourceConfigureNetworkProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

interface IComputeResourceConfigureNetworkState {
    dialog: React.ReactNode | null;
    network_index: string;
    type: NetworkType;
    networkTypeOptions: Array<ISegmentedControlButton<NetworkType>>;
}

export class ComputeResourceConfigureNetwork extends React.Component<ComputeResourceConfigureNetworkProps, IComputeResourceConfigureNetworkState> {
    state: IComputeResourceConfigureNetworkState = {
        dialog: null,
        network_index: '',
        type: NetworkType.BRIDGED,
        networkTypeOptions: Object.values(NetworkType).map((type) => ({
            value: type,
            title: humanizeNetworkType(type),
            disabled: type === NetworkType.VZ_ROUTED || this.props.selectedItem.vms_count > 0,
            tooltip: type === NetworkType.VZ_ROUTED ? (
                <Translate content="computeResource.settings.form.network.vzRoutedIsNotAllowed" />
            ) : null,
        })),
    };

    async componentDidMount() {
        const { getComputeResourceNetworks } = this.props.computeResourceActions;
        const { selectedItem } = this.props;

        await getComputeResourceNetworks(selectedItem.id);
    }

    handleFieldChange = (field: string, value: string) => {
        this.props.formErrorsActions.clearFormErrors();

        this.setState(state => ({
            ...state,
            [field]: value,
        }));
    };

    handleCreate = async () => {
        const { network_index, type } = this.state;
        const {
            selectedItem,
            onConfigured,
            networks,
            computeResourceActions: { setUpNetwork },
            formErrorsActions: { setFormErrors },
        } = this.props;

        const errors = validate<IComputeResourceConfigureNetworkState>(this.state, {
            network_index: {
                validator: isEmpty,
                message: <Translate content="validate.fieldRequired" />,
                comparison: true,
            },
        });

        if (Object.keys(errors).length) {
            setFormErrors(errors);
            return;
        }

        const network = networks[parseInt(network_index, 10)];

        const confirm = async () => {
            try {
                this.handleCloseDialog();
                await setUpNetwork(selectedItem.id, {
                    id: network.id,
                    type,
                });
                onConfigured();
            } catch (e) {
                throw e;
            }
        };

        if (network.type !== 'openvswitch') {
            this.setState({
                dialog: (
                    <ConfirmationDialog
                        isOpen={true}
                        isLoading={!!selectedItem.is_configuring_network}
                        handleClick={confirm}
                        onClose={this.handleCloseDialog}
                        title={<Translate content="computeResource.actionDialog.network.confirmTitle" />}
                        innerText={<Alert intent={INTENT_TYPE.DANGER}><Translate content="computeResource.actionDialog.network.confirmText" /></Alert>}
                    />
                ),
            });
        } else {
            confirm();
        }
    };

    handleCloseDialog = () => {
        this.setState({ dialog: null });
    };

    render() {
        const { errors, loadingFlags, selectedItem, networks } = this.props;
        const { dialog } = this.state;

        return (
            <>
                {dialog}
                <Loader isLoading={loadingFlags.isNetworksLoading} center={false}>
                    <Form
                        id="configureNetworkForm"
                        footerClassName="hidden"
                        onSubmit={this.handleCreate}
                        onFieldChange={this.handleFieldChange}
                        errors={errors}
                        hideRequiredLegend={true}
                        submitButton={false}
                        cancelButton={false}
                        applyButton={false}
                        vertical={true}
                    >
                        <Section>
                            <FormFieldSelect
                                name="network_index"
                                label={<Translate content="computeResource.actionDialog.network.networks" />}
                                size={SIZE.FILL}
                                required={true}
                            >
                                {networks.map((network, index) => (
                                    <option key={index} value={index.toString()}>
                                        {`${network.name}:${network.ip}/${network.mask_size}(${network.type})`}
                                    </option>
                                ))}
                            </FormFieldSelect>
                            <FormField
                                fullDescription={
                                    <Translate
                                        content="computeResource.settings.form.network.fullDescription"
                                        params={{
                                            link: (
                                                <a
                                                    href="https://docs.solusvm.com/v2/administrator-guide/Choosing%2Ba%2BCompute%2BResource%2BNetwork%2BType.html"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <Translate content="computeResource.settings.form.network.fullDescriptionLink" />
                                                </a>
                                            ),
                                        }}
                                    />
                                }
                                name="type"
                                required={true}
                                label={<Translate content="computeResource.settings.form.network.type"/>}
                            >
                                <Tooltip
                                    shown={selectedItem.vms_count > 0}
                                    title={<Translate content="computeResource.settings.form.network.description"/>}>
                                    <SegmentedControl
                                        buttons={this.state.networkTypeOptions}
                                        selected={this.state.type}
                                        onChange={(value: string) => this.handleFieldChange('type', value)}
                                    />
                                </Tooltip>
                            </FormField>
                            <NetworkFormError />
                        </Section>
                    </Form>
                    <Button
                        intent={INTENT_TYPE.PRIMARY}
                        type="submit"
                        fill={true}
                        size="lg"
                        form="configureNetworkForm"
                        state={!!selectedItem.is_configuring_network ? 'loading' : null}
                    >
                        <Translate content="computeResource.actionDialog.network.configure" />
                    </Button>
                </Loader>
            </>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    errors: state.app.formErrors,
    networks: state.computeResource.networks,
    selectedItem: state.computeResource.item,
    loadingFlags: {
        isNetworksLoading: state.app.loadingFlags.has(LOADING_FLAGS.COMPUTE_RESOURCE_NETWORKS),
    },
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    computeResourceActions: bindActionCreators(computeResourceActions, dispatch),
    formErrorsActions: bindActionCreators(formErrorsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ComputeResourceConfigureNetwork);
