// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import { ISSHKeypair } from 'common/services/SSHKeyGenerator';
import moment,
{ Moment } from 'moment';

export const set = (id: number, pair: ISSHKeypair, expiresAt: Moment): void =>
    KeypairCache.getInstance().set(id, pair, expiresAt);

export const get = (id: number): IExpirableSSHKeypair|null =>
    KeypairCache.getInstance().get(id);

export const move = (oldID: number, newID: number): void =>
    KeypairCache.getInstance().move(oldID, newID);

export const remove = (id: number): void =>
    KeypairCache.getInstance().remove(id);

export const runGC = (): void => {
    setInterval(() => KeypairCache.getInstance().gc(), 1000);
};

export interface IExpirableSSHKeypair extends ISSHKeypair{
    expiresAt: Moment;
}

type KeypairCacheStorage = Map<number, IExpirableSSHKeypair>;

export class KeypairCache {
    private static readonly sessionStorageKey = 'onetime-keypair-cache';

    private static instance: KeypairCache|null = null;

    private readonly storage: KeypairCacheStorage;

    constructor(storage: KeypairCacheStorage) {
        this.storage = storage;
    }

    static getInstance(): KeypairCache {
        if (KeypairCache.instance === null) {
            let storage = new Map();
            if (window.sessionStorage !== null) {
                const raw = window.sessionStorage.getItem(KeypairCache.sessionStorageKey);
                if (raw !== null) {
                    const obj = JSON.parse(raw);
                    // eslint-disable-next-line guard-for-in
                    for (let key in obj) {
                        const val = obj[key];
                        val.expiresAt = moment(val.expiresAt);
                        storage.set(+key, val);
                    }
                    if (!isStorageMap(storage)) {
                        // Fallback to in memory if invalid class was stored.
                        window.sessionStorage.removeItem(KeypairCache.sessionStorageKey);
                    }
                }
            }

            KeypairCache.instance = new KeypairCache(storage);
        }
        return KeypairCache.instance;
    }

    private static isExpired(i: IExpirableSSHKeypair): boolean {
        return i.expiresAt.diff(moment()) <= 0;
    }

    set(id: number, pair: ISSHKeypair, expiresAt: Moment): void {
        this.doSet(id, pair, expiresAt);
        this.persist();
    }

    get(id: number): IExpirableSSHKeypair|null {
        const i = this.storage.get(id);
        if (i === undefined) {
            return null;
        }

        if (KeypairCache.isExpired(i)) {
            this.storage.delete(id);
            this.persist();
            return null;
        }
        return i;
    }

    move(oldID: number, newID: number): void {
        if (oldID === newID) {
            return;
        }

        const v = this.get(oldID);
        if (v !== null) {
            this.storage.delete(oldID);
            this.doSet(newID, v, v.expiresAt);
            this.persist();
        }
    }

    remove(id: number): void {
        this.storage.delete(id);
        this.persist();
    }

    gc(): void {
        this.storage.forEach((i, k) => {
            if (KeypairCache.isExpired(i)) {
                this.storage.delete(k);
            }
        });
        this.persist();
    }

    private doSet(id: number, pair: ISSHKeypair, expiresAt: Moment): void {
        const item = {
            ...pair,
            expiresAt,
        };
        this.storage.set(id, item);
    }

    private persist(): void {
        if (window.sessionStorage === null) {
            return;
        }

        let jsonObject = {};
        this.storage.forEach((value, key) => {
            jsonObject[key] = value;
        });

        window.sessionStorage.setItem(
            KeypairCache.sessionStorageKey,
            JSON.stringify(jsonObject)
        );
    }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isStorageMap = (obj: any): obj is KeypairCacheStorage => obj.set !== undefined
    && obj.get !== undefined
    && obj.delete !== undefined;
