// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import { HeaderButtonContainer } from 'client/common/components/HeaderButton/Styles';
import { Button } from '@plesk/ui-library';

export interface IHeaderButtonProps extends React.HTMLProps<HTMLButtonElement> {
    tooltip?: React.ReactNode | string;
    icon?: string;
    isMobile?: boolean;
    isLoading?: boolean;
}

export const HeaderButton: React.FC<IHeaderButtonProps> = ({
    isMobile,
    children,
    icon,
    tooltip,
    isLoading,
    ...rest
}) => (
    <HeaderButtonContainer isMobile={isMobile}>
        <Button
            fill={true}
            intent="primary"
            icon={icon}
            tooltip={tooltip}
            state={isLoading ? 'loading' : undefined}
            {...rest}
        >
            {children}
        </Button>
    </HeaderButtonContainer>
);
