// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import { CancelTokenSource } from 'axios';
import {
    api,
    IPaginateApiResponse,
} from 'common/api/resources/Response';
import {
    ITaskListRequest,
    ITaskResponse,
} from 'common/api/resources/Task/model';
import { QueryBuilder } from 'common/api/resources/Request/QueryBuilder';

export const tasks = {
    list: (params?: ITaskListRequest, cancelToken?: CancelTokenSource) => api.get<IPaginateApiResponse<ITaskResponse[]>>(
        'tasks',
        { params: new QueryBuilder(params).build(), cancelToken: cancelToken?.token }
    ),
    cancel: (id: number) => api.post(`tasks/${id}/cancel`),
    retry: (id: number) => api.post(`tasks/${id}/retry`),
};
