// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import {
    IStorageTypeResponse,
    StorageType,
} from 'common/api/resources/StorageType';
import {
    IPaginated,
    IRequest,
    ISearchable,
} from 'common/api/resources/Request/request';

export interface IStorageResponse {
    id: number;
    name: string;
    mount: string;
    type: IStorageTypeResponse;
    path: string;
    thin_pool: string | null;
    is_available_for_balancing: boolean;
    servers_count: number;
    compute_resources_count: number;
    free_space: number;
    credentials: Record<string, unknown>;

    // virtual fields
    isDeleting: boolean;
    isLoading: boolean;
}

export interface IStorageCreateRequest {
    type: StorageType;
    path: string;
    thin_pool?: string;
    is_available_for_balancing: boolean;
}

export interface IStorageRequest {
    name: string;
    is_available_for_balancing: boolean;
    type: StorageType;
    mount: string;
    credentials: Record<string, unknown>;
}

export interface IStorageMountRequest {
    compute_resource_id: number;
}

export interface IStoragePatchRequest {
    is_available_for_balancing: boolean;
}

export interface IStorageListFilters {
    type?: StorageType;
}

export interface IStorageListRequest extends IRequest<IStorageListFilters>, IPaginated, ISearchable {}

export enum BALANCE_STRATEGY {
    RANDOM = 'random',
    ROUND_ROBIN = 'round-robin',
    MOST_STORAGE_AVAILABLE = 'most-storage-available',
}

export interface IUpdateStorageResource {
    storage: IStorageResponse;
}
