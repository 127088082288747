// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import {
    Action,
    Icon,
    Tooltip,
    Translate,
    useTranslate,
} from '@plesk/ui-library';
import * as React from 'react';
import { connect } from 'react-redux';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import * as externalIntegrationActions from 'admin/externalIntegration/actions';
import List from 'common/components/List/List';
import { PageSection } from 'common/components/PageHeader/Styles';
import { PageHeader } from 'admin/common/components/PageHeader/PageHeader';
import { Dialog } from 'admin/common/components/Dialog/Dialog';
import { Loader } from 'common/components/Loader/Loader';
import ExternalIntegrationForm from 'admin/externalIntegration/containers/ExternalIntegrationForm';
import { RootState } from 'admin/core/store';
import { EmptyView } from 'common/components/EmptyView/EmptyView';
import { StyledActions } from 'common/components/Actions/Styles';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { useRequestCancellationEffect } from 'common/hooks/useRequestCancellationEffect';
import ButtonWithConfirmation from 'common/components/ButtonWithConfirmation';
import {
    getActionColumnProps,
    reloadListData,
} from 'common/helpers/list';
import { TranslationExternalIntegrationTypeMap } from 'common/api/resources/ExternalIntegration';

const columns = [{
    width: '1%',
    key: 'colId',
    title: <Translate content="externalIntegration.list.id" />,
}, {
    width: '15%',
    key: 'colName',
    title: <Translate content="externalIntegration.list.name" />,
}, {
    width: '15%',
    key: 'colType',
    title: <Translate content="externalIntegration.list.type" />,
}, {
    width: '15%',
    key: 'colCountServers',
    title: <Translate content="externalIntegration.list.countServers" />,
}, getActionColumnProps({ width: '5%' }),
];

export type ExternalIntegrationsProps =
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const ExternalIntegrations: React.FC<ExternalIntegrationsProps> = ({
    externalIntegrationItem,
    externalIntegrationList,
    isLoadingItem,
    isLoadingList,
    externalIntegrationActions: {
        getExternalIntegrations,
        getExternalIntegration,
        removeExternalIntegration,
    },
}) => {
    const translate = useTranslate();
    const [isDialogOpened, setIsDialogOpened] = React.useState(false);

    const isFirstLoading = useRequestCancellationEffect(token => getExternalIntegrations({}, token), []);
    const loadPaginated = (page: number) => getExternalIntegrations({ page });

    const handleClose = () => setIsDialogOpened(false);
    const handleCreate = () => setIsDialogOpened(true);
    const handleEdit = (id: number) => () => {
        getExternalIntegration(id);
        setIsDialogOpened(true);
    };

    const handleRemove = (id: number) => async () => {
        await removeExternalIntegration(id);
        reloadListData(externalIntegrationList, loadPaginated);
    };

    const data = externalIntegrationList.data.map((item) => ({
        key: item.id.toString(),
        colId: item.id,
        colName: item.name,
        colType: translate(TranslationExternalIntegrationTypeMap[item.type]),
        colCountServers: item.servers_count,
        colActions: (
            <StyledActions>
                <Tooltip title={<Translate content="location.tooltip.edit"/>}>
                    <Action
                        icon={<Icon name="pencil" />}
                        className="action-icon"
                        onClick={handleEdit(item.id)}
                    />
                </Tooltip>
                <ButtonWithConfirmation
                    isLoading={item.is_deleting}
                    disabled={item.servers_count > 0 || item.applications_count > 0}
                    translations={{
                        title: (
                            <Translate content="externalIntegration.actions.delete.title" />
                        ),
                        button: (
                            <Translate content="externalIntegration.actions.delete.button" />
                        ),
                        tooltip: (
                            <Translate content={item.servers_count > 0 || item.applications_count > 0 ?
                                'externalIntegration.actions.delete.tooltip.disabled' :
                                'externalIntegration.actions.delete.tooltip.enabled'
                            } />
                        ),
                    }}
                    handleConfirm={handleRemove(item.id)}
                    icon="recycle"
                />
            </StyledActions>
        ),
    }));

    return (
        <>
            <PageHeader
                title={(
                    <PageSection>
                        <Translate content="externalIntegration.title" />
                    </PageSection>
                )}
                isButtonShown={externalIntegrationList.data.length > 0}
                buttonText="externalIntegration.createBtn"
                buttonIcon="plugins"
                onButtonClick={handleCreate}
            />
            <List
                isLoading={isLoadingList}
                isFirstLoading={isFirstLoading}
                loadItems={loadPaginated}
                meta={externalIntegrationList.meta}
                emptyView={
                    <EmptyView
                        title="externalIntegration.emptyView.title"
                        description="externalIntegration.emptyView.description"
                        buttonText="externalIntegration.emptyView.buttonText"
                        onButtonClick={handleCreate}
                        icon="plugins"
                    />
                }
                sortColumn="colId"
                sortDirection="DESC"
                columns={columns}
                data={data}
            />
            <Dialog
                heading={<Translate content={externalIntegrationItem.id ? 'externalIntegration.dialog.titleEdit' : 'externalIntegration.dialog.titleAdd'} />}
                closeHandler={handleClose}
                isOpen={isDialogOpened}
                size="xs"
            >
                <Loader isLoading={isLoadingItem} center={false}>
                    <ExternalIntegrationForm onSubmit={handleClose} />
                </Loader>
            </Dialog>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    externalIntegrationItem: state.externalIntegration.item,
    externalIntegrationList: state.externalIntegration.list,
    isLoadingItem: state.app.loadingFlags.has(LOADING_FLAGS.EXTERNAL_INTEGRATION_ITEM),
    isLoadingList: state.app.loadingFlags.has(LOADING_FLAGS.EXTERNAL_INTEGRATION_LIST),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    externalIntegrationActions: bindActionCreators(externalIntegrationActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExternalIntegrations);
