// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import { CardItem } from 'client/common/components/Card';
import { connect } from 'react-redux';
import { popupCenter } from 'common/helpers/popup';
import * as computeResourceVmActions from 'common/modules/computeResourceVm/actions';
import {
    CardItemIcon,
    ToolbarIcons,
} from 'client/project/containers/projectItem/tabs/ProjectServerTab/ProjectServerCard/Styles';
import { HTTP_CODES } from 'common/api/constants';
import ButtonWithConfirmation from 'common/components/ButtonWithConfirmation';
import { VmStatus } from 'common/components/VmStatus';
import { PROJECT_SERVER_PAGE } from 'client/project/constants/tests';
import { dataCySelector } from 'common/tests/selectors';
import { HostnameActionContainer } from 'client/project/containers/projectItem/tabs/ProjectServerTab/ProjectServersList/Styles';
import ApplicationLoginLink from 'client/project/components/ApplicationLoginLink/ApplicationLoginLink';
import Item from 'client/project/containers/projectItem/tabs/ProjectServerTab/ProjectServerCard/Item/Item';
import { CutTitle } from 'common/components';
import {
    RouteComponentProps,
    withRouter,
} from 'react-router';
import {
    IVmResponse,
    ComputeResourceVmStatus,
    updateFqdns,
    IVmUpdateRequest,
} from 'common/api/resources/ComputeResourceVm';
import {
    ICONS,
    INTENT_TYPE,
    KEYBOARD_EVENT_KEYS,
    VNC_DIALOG_SIZE,
} from 'common/constants';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import {
    Action,
    Button,
    Label,
    Popover,
    Text,
    Toolbar,
    ToolbarExpander,
    Translate,
    useTranslate,
    Icon,
} from '@plesk/ui-library';
import {
    DescriptionEditInput,
    NameEditInput,
} from 'client/project/containers/projectCard/Styles';
import { COLORS } from 'client/core/theme';
import ButtonWithConfirmationInput
    from 'common/components/ButtonWithInputConfirmation/ButtonWithInputConfirmation';
import CopyText from 'common/containers/CopyText/CopyText';
import { RootState } from 'client/core/store';
import { shouldRegisterFqdnOnServerCreate } from 'common/modules/settings/selectors';
import {
    domainRule,
    requiredRule,
    validateScalar,
} from 'common/validator';
import { hasPermission } from 'common/modules/permission/selectors';
import { PERMISSION_LIST } from 'common/modules/permission/constants';

interface IProjectServerCardProps extends RouteComponentProps {
    vm: IVmResponse;
    isDisabled?: boolean;
}

export type ProjectServerCardProps =
    IProjectServerCardProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const ProjectServerCard: React.FC<ProjectServerCardProps> = ({
    registerFqdn,
    vm,
    isDisabled,
    history: { push },
    pathname,
    computeResourceVmActions: {
        restartComputeResourceVm,
        deleteComputeResourceVm,
        stopComputeResourceVm,
        startComputeResourceVm,
        updateComputeResourceVm,
    },
    canDeleteComputeResourceVm,
}) => {
    const translate = useTranslate();
    const [startError, setStartError] = React.useState<string>();
    const [nameError, setNameError] = React.useState<React.ReactNode | string>();
    const [isEdit, setIsEdit] = React.useState(false);
    const { name, description } = vm;
    const [updateRequest, setUpdateRequest] = React.useState<IVmUpdateRequest>({ name, description });
    const isStartProhibited = vm.is_processing
        || vm.is_suspended
        || [ComputeResourceVmStatus.STARTED, ComputeResourceVmStatus.NOT_EXIST].includes(vm.status);

    React.useEffect(() => {
        setUpdateRequest({ name, description });
    }, [name, description]);

    const handleCloseErrorPopover = () => setStartError(undefined);

    const onDescriptionRefSet = React.useCallback((node) => {
        if (node) {
            node.focus();
        }
    }, []);

    const handleShowVnc = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.stopPropagation();
        popupCenter(`/vnc_client/${vm.id}`, VNC_DIALOG_SIZE.WIDTH, VNC_DIALOG_SIZE.HEIGHT);
    };
    const handleReboot = () => restartComputeResourceVm(vm.id, { force: false });
    const handleDelete = () => deleteComputeResourceVm(vm.id);
    const handleShutdown = () => stopComputeResourceVm(vm.id, { force: false });
    const handleGoToServer = () => {
        if (![ComputeResourceVmStatus.UNAVAILABLE.toString(), ComputeResourceVmStatus.NOT_EXIST.toString()].includes(vm.status)) {
            push(`${pathname}/servers/${vm.id}#overview`);
        }
    };
    const handleStart = async (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.stopPropagation();
        setStartError('');

        try {
            await startComputeResourceVm(vm.id);
        } catch (err) {
            if (err.response.status === HTTP_CODES.BAD_REQUEST) {
                setStartError(err.response.data.message);
            }
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNameError(undefined);
        setUpdateRequest({ ...updateRequest, [e.target.name]: e.target.value });
    };

    const handleEdit = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.stopPropagation();
        setIsEdit(true);
    };

    const handleCancel = () => {
        setUpdateRequest({ name: vm.name, description: vm.description });
        setIsEdit(false);
    };

    const handleSave = async () => {
        const rule = registerFqdn && vm.name !== updateRequest.name
            ? domainRule(<Translate content="validate.badDomain" />)
            : requiredRule(<Translate content="validate.fieldRequired" />);

        const error = validateScalar(updateRequest.name!, rule);
        if (error) {
            setNameError(error);
            return;
        }

        if (registerFqdn && vm.name !== updateRequest.name) {
            updateRequest.fqdns = updateFqdns(vm, updateRequest.name!);
        }

        try {
            await updateComputeResourceVm(vm.id, updateRequest);
        } finally {
            setIsEdit(false);
        }
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === KEYBOARD_EVENT_KEYS.ENTER) {
            handleSave();
        }
    };

    if (isEdit) {
        return (
            <CardItem
                onKeyPress={handleKeyPress}
                isLoading={vm.isLoading}
                icon={
                    <CardItemIcon>
                        {vm.settings.os_image.icon && (
                            <img
                                width={24}
                                src={vm.settings.os_image.icon}
                                alt=""
                            />
                        )}
                    </CardItemIcon>
                }
                cardTitle={(
                    <NameEditInput
                        name="name"
                        ref={onDescriptionRefSet}
                        onChange={handleChange}
                        value={updateRequest.name}
                        placeholder={translate('projects.form.name') as string}
                        type="text"
                        maxLength={63}
                    />
                )}
                item={
                    <span>
                        <DescriptionEditInput
                            name="description"
                            ref={onDescriptionRefSet}
                            onChange={handleChange}
                            value={updateRequest.description}
                            placeholder={translate('projects.form.description') as string}
                            type="text"
                            maxLength={48}
                        />
                    </span>
                }
                footer={
                    <Toolbar>
                        <Popover
                            placement="bottom-right"
                            visible={!!nameError}
                            intent="danger"
                            onClose={handleCloseErrorPopover}
                            target={
                                <Button
                                    onClick={handleSave}
                                    ghost={true}
                                    icon="check-mark"
                                >
                                    <Translate content="projects.form.save" />
                                </Button>
                            }
                        >
                            {nameError}
                        </Popover>
                        <Button
                            onClick={handleCancel}
                            ghost={true}
                            icon="remove"
                        >
                            <Translate content="projects.form.cancel" />
                        </Button>
                    </Toolbar>
                }
            />
        );
    }

    return (
        <CardItem
            isDisabled={isDisabled}
            isLoading={vm.isLoading}
            icon={
                <CardItemIcon data-cy={PROJECT_SERVER_PAGE.HEADER}>
                    {vm.settings.os_image.icon && (
                        <img
                            width={24}
                            src={vm.settings.os_image.icon}
                            alt=""
                        />
                    )}
                </CardItemIcon>
            }
            cardTitle={
                <>
                    <HostnameActionContainer>
                        <Action onClick={handleGoToServer}>
                            <CutTitle title={vm.name} />
                        </Action>
                        {vm.is_suspended && (
                            <>
                                &nbsp;
                                <Label intent={INTENT_TYPE.WARNING}><Translate content="servers.status.suspended" /></Label>
                            </>
                        )}
                    </HostnameActionContainer>
                    {!vm.isLoading && !vm.is_processing && (
                        <Action
                            style={{ color: COLORS.GREY_100 }}
                            onClick={handleEdit}
                            className="item__edit-action"
                            icon={
                                <Icon
                                    className="pul-icon pul-action__icon"
                                    name={ICONS.PENCIL}
                                />
                            }
                            data-cy={dataCySelector(vm.id, 'edit')}
                        />
                    )}
                </>
            }
            item={
                <Item vm={vm} />
            }
            footer={
                <Toolbar>
                    <VmStatus
                        status={vm.status}
                        compact={true}
                        isProcessing={vm.is_processing}
                        progress={vm.progress}
                    />
                    <ToolbarExpander/>
                    <ToolbarIcons>
                        <Popover
                            placement="bottom-right"
                            visible={!!startError}
                            intent="danger"
                            onClose={handleCloseErrorPopover}
                            target={
                                <Button
                                    ghost={true}
                                    icon={<Icon name={ICONS.START_CIRCLE} />}
                                    disabled={isStartProhibited}
                                    onClick={handleStart}
                                    data-cy={dataCySelector(vm.id, 'start')}
                                    tooltip={<Translate content="projects.server.links.start" />}
                                />
                            }
                        >
                            {startError}
                        </Popover>
                        <Button
                            ghost={true}
                            disabled={vm.is_processing || vm.status !== ComputeResourceVmStatus.STARTED}
                            icon={<Icon name={ICONS.CONSOLE_FILLED} />}
                            onClick={handleShowVnc}
                            tooltip={<Translate content="projects.server.links.vnc" />}
                        />
                        <ButtonWithConfirmation
                            icon={ICONS.STOP_CIRCLE}
                            disabled={vm.is_processing || vm.status !== ComputeResourceVmStatus.STARTED}
                            translations={{
                                text: (
                                    <Translate content="projects.server.shutdownConfirmation" />
                                ),
                                button: (
                                    <Translate content="projects.server.confirmStop" />
                                ),
                                title: (
                                    <Translate content="projects.server.stopTitle" />
                                ),
                                tooltip: (
                                    <Translate content="projects.server.shutdown" />
                                ),
                            }}
                            handleConfirm={handleShutdown}
                            data-cy={dataCySelector(vm.id, 'stop')}
                        />
                        <ButtonWithConfirmation
                            icon={ICONS.RESET}
                            disabled={vm.is_processing || vm.status !== ComputeResourceVmStatus.STARTED}
                            translations={{
                                text: (
                                    <Translate content="projects.server.restartConfirmation" />
                                ),
                                button: (
                                    <Translate content="projects.server.confirmRestart" />
                                ),
                                title: (
                                    <Translate content="projects.server.restartTitle" />
                                ),
                                tooltip: (
                                    <Translate content="projects.server.restart" />
                                ),
                            }}
                            handleConfirm={handleReboot}
                            data-cy={dataCySelector(vm.id, 'reset')}
                        />
                        <ApplicationLoginLink settings={vm.settings}/>
                    </ToolbarIcons>
                    <ToolbarExpander/>
                    <ButtonWithConfirmationInput
                        isLoading={vm.is_deleting}
                        disabled={vm.is_processing || !canDeleteComputeResourceVm}
                        translations={{
                            text: (
                                <Translate
                                    content='projects.server.removeServerText'
                                    params={{ name: <CopyText isInline={true}><Text bold>{vm.name}</Text></CopyText> }}
                                />
                            ),
                            confirmationButton: (
                                <Translate content="projects.server.confirmRemove" />
                            ),
                            title: (
                                <Translate content="projects.server.removeTitle" />
                            ),
                            label: (
                                <Translate content="projects.server.removeServerLabel" />
                            ),
                            tooltip: (canDeleteComputeResourceVm
                                ? <Translate content="projects.server.remove" />
                                : <Translate content="servers.delete.cannotDelete" />
                            ),
                        }}
                        confirmation={vm.name}
                        handleConfirm={handleDelete}
                        icon={ICONS.RECYCLE}
                        placement={'left-bottom'}
                        data-cy={dataCySelector(vm.id, 'delete')}
                    />
                </Toolbar>
            }
        />
    );
};

const mapStateToProps = (state: RootState) => ({
    registerFqdn: shouldRegisterFqdnOnServerCreate(state),
    canDeleteComputeResourceVm: hasPermission(state, PERMISSION_LIST.DELETE_SERVERS) && (
        state.auth.user.id === state.project.servers.item.user.id
        || hasPermission(state, PERMISSION_LIST.MANAGE_SERVERS)
    ),
});

const mapDispatchToProps = (dispatch: Dispatch, ownProps: RouteComponentProps) => ({
    pathname: ownProps.location.pathname,
    computeResourceVmActions: bindActionCreators(computeResourceVmActions, dispatch),
    history: ownProps.history,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProjectServerCard));
