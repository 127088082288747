// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import styled from 'styled-components';
import { COLORS } from 'common/theme';

interface IWrapperProps {
    color?: string;
    backgroundColor?: string;
}

export const Wrapper = styled.div<IWrapperProps>`
    padding: 12px 20px;
    color: ${COLORS.GREY_100};
    background: ${props => props.backgroundColor ? props.backgroundColor : COLORS.WHITE_0};
    border: 1px solid ${COLORS.GREY_20};
    box-sizing: border-box;
    box-shadow: inset 3px 0 0 ${props => props.color ? props.color : props.theme.primary_color};
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
    
    span {
        font-size: 14px;
        align-self: center;
    }
    
    button {
        color: ${props => props.theme.primary_color};
        &:hover {
           color: ${props => props.theme.primary_color};
        }
    }

    ul {
        list-style: initial;
        padding-left: 12px;
    }
`;
