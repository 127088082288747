// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import * as projectMemberActions from 'client/project/actions/member';
import { IProjectMemberResponse } from 'common/api/resources/Project';
import { CardItem } from 'client/common/components/Card';
import ButtonWithConfirmation from 'common/components/ButtonWithConfirmation';
import {
    STATUS_INTENT_MAP,
    STATUS_INVITED,
} from 'common/modules/project/constants';
import { Figure } from 'client/project/components/Figure/Figure';
import { RootState } from 'client/core/store';
import { HTTP_CODES } from 'common/api/constants';
import {
    RouteComponentProps,
    withRouter,
} from 'react-router';
import {
    Button,
    FormFieldText,
    Icon,
    Status,
    Toolbar,
    ToolbarExpander,
    Translate,
} from '@plesk/ui-library';
import {
    ICONS,
    KEYBOARD_EVENT_KEYS,
} from 'common/constants';
import { ToolbarAction } from 'client/project/containers/projectCard/Styles';
import TimeoutLink from 'client/project/containers/projectItem/tabs/ProjectMembersTab/TimeoutLink';
import { statusMapping } from 'client/project/constants/translation';
import { dataCySelector } from 'common/tests/selectors';

interface IProjectMembersCardProps extends RouteComponentProps {
    projectId: number;
    member: IProjectMemberResponse;
}

export type ProjectMembersCardProps =
    IProjectMembersCardProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const ProjectMembersCard: React.FC<ProjectMembersCardProps> = ({
    projectMembersActions: {
        createMember,
        removeProjectMember,
        removeMemberItem,
        updateMemberItem,
        leaveProject,
        resendInvite,
    },
    history,
    authId,
    project,
    projectId,
    member,
}) => {
    const [formErrors, setFormErrors] = React.useState();
    const handleChange = (email: string) => updateMemberItem({ ...member, email });
    const isCurrentUser = !member.is_owner && member.user_id === authId;
    const handleSave = async () => {
        if (!member.email.trim().length) {
            return;
        }

        try {
            await createMember(projectId, member);
        } catch (e) {
            if (e.response.status === HTTP_CODES.VALIDATION_ERROR) {
                setFormErrors(e.response.data.errors);
            }
        }
    };

    const handleLeave = (data: IProjectMemberResponse) => async () => {
        await leaveProject(data, projectId);

        history.push('/');
    };

    const handleRemove = () => {
        if (!member.id) {
            removeMemberItem(member.id);
            return;
        }

        removeProjectMember(projectId, member);
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === KEYBOARD_EVENT_KEYS.ENTER) {
            handleSave();
        }
    };

    const handleResendInvite = () => resendInvite(projectId, member);

    if (member.isEdit) {
        return (
            <CardItem
                onKeyPress={handleKeyPress}
                icon={member.is_owner ? <Icon size="32" name="star-circle-filled" intent="info" /> : <Figure title={member.email} />}
                isLoading={member.isLoading}
                item={
                    <FormFieldText
                        placeholder="Enter email..."
                        errors={formErrors}
                        onChange={handleChange}
                        autoFocus={true}
                        size="fill"
                        label={null}
                    />
                }
                footer={
                    <>
                        <Button
                            onClick={handleSave}
                            ghost={true}
                            icon="check-mark"
                            disabled={!member.email.trim().length}
                        >
                            <Translate content="projects.form.save" />
                        </Button>
                        <ToolbarExpander />
                        <Button onClick={handleRemove} ghost={true} icon="recycle" />
                    </>
                }
            />
        );
    }

    return (
        <CardItem
            isLoading={member.isLoading}
            icon={member.is_owner ? <Icon size="32" name="star-circle-filled" intent="info" /> : <Figure title={member.email} />}
            cardTitle={member.email}
            item={<Translate content={`projects.members.${member.is_owner ? 'owner' : 'user'}`} />}
            footer={
                <Toolbar>
                    <Status intent={STATUS_INTENT_MAP[member.status]} compact={true} flip={true}>
                        {statusMapping[member.status]}
                    </Status>
                    <ToolbarExpander/>
                    {member.status === STATUS_INVITED && (
                        <TimeoutLink
                            from={member.invite_sent_at}
                            text={<Translate content="projects.members.timeoutText" />}
                        >
                            <ToolbarAction onClick={handleResendInvite}>
                                <Icon name={ICONS.MAIL} />
                                <Translate content="projects.members.resendInvite" />
                            </ToolbarAction>
                        </TimeoutLink>
                    )}
                    <ToolbarExpander/>
                    <ButtonWithConfirmation
                        icon={ICONS.ARROW_TRAY_OUT}
                        translations={{
                            text: (
                                <Translate content="projects.members.confirmationLeave.text" />
                            ),
                            button: (
                                <Translate content="projects.members.confirmationLeave.button" />
                            ),
                            tooltip: (
                                <Translate content="projects.members.confirmationLeave.button" />
                            ),
                        }}
                        handleConfirm={handleLeave(member)}
                        disabled={!isCurrentUser}
                        data-cy={dataCySelector(member.id, 'leave')}
                    />
                    <ButtonWithConfirmation
                        translations={{
                            text: (
                                <Translate content="projects.members.confirmationRemove.text" />
                            ),
                            button: (
                                <Translate content="projects.members.confirmationRemove.button" />
                            ),
                            tooltip: (
                                <Translate content="projects.members.confirmationRemove.button" />
                            ),
                        }}
                        handleConfirm={handleRemove}
                        icon={ICONS.RECYCLE}
                        disabled={!project.is_owner || member.is_owner}
                        data-cy={dataCySelector(member.id, 'remove')}
                    />
                </Toolbar>
            }
        />
    );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    projectMembersActions: bindActionCreators(projectMemberActions, dispatch),
});

const mapStateToProps = (state: RootState) => ({
    authId: state.auth.user.id,
    formErrors: state.app.formErrors,
    project: state.project.projects.item,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProjectMembersCard));
