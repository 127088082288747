// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { connect } from 'react-redux';
import {
    RouteComponentProps,
    withRouter,
} from 'react-router';
import * as authActions from 'common/modules/auth/actions';
import { Loader } from 'common/components';
import { Translate } from '@plesk/ui-library';

export type AuthByTokenProps =
    RouteComponentProps<{ token: string }> &
    ReturnType<typeof mapDispatchToProps>;

export const AuthByToken: React.FC<AuthByTokenProps> = ({
    history,
    authActions: { authByToken },
    match: { params },
}) => {
    React.useLayoutEffect(() => {
        const authRequest = async () => {
            try {
                await authByToken(params.token);
            } finally {
                history.push('/');
            }
        };

        if (params.token) {
            authRequest();
            return;
        }

        history.push('/');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <Loader text={<Translate content="auth.authorizing" />} isLoading={true} />;
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    authActions: bindActionCreators(authActions, dispatch),
});

export default withRouter(connect(null, mapDispatchToProps)(AuthByToken));
