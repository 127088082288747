// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import { createCustomAction } from 'typesafe-actions';
import { Dispatch } from 'redux';
import {
    create,
    get,
    loadOnScroll,
    paginateList,
    remove,
    update,
} from 'common/actions/actionsWrapper';
import { IPaginateApiResponse } from 'common/api/resources/Response';
import { IAppState } from 'admin/core/store';
import * as types from 'admin/storage/constants/types';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import {
    IStorageListRequest,
    IStoragePatchRequest,
    IStorageRequest,
    IStorageResponse,
    storages,
} from 'common/api/resources/Storage';

export const setStorageList = createCustomAction(
    types.SET_STORAGE_LIST,
    (data: IPaginateApiResponse<IStorageResponse[]>) => ({ payload: data })
);
export const setStorageItem = createCustomAction(
    types.SET_STORAGE_ITEM,
    (data: IStorageResponse) => ({ payload: data })
);
export const unsetStorageItem = createCustomAction(types.UNSET_STORAGE_ITEM);
export const addStorageItem = createCustomAction(
    types.ADD_STORAGE_ITEM,
    (data: IStorageResponse) => ({ payload: data })
);
export const updateStorageItem = createCustomAction(
    types.UPDATE_STORAGE_ITEM,
    (data: IStorageResponse) => ({ payload: data })
);
export const removeStorageItem = createCustomAction(
    types.REMOVE_STORAGE_ITEM,
    (id: number) => ({ payload: id })
);
export const appendStorages = createCustomAction(
    types.APPEND_STORAGES,
    (data: IPaginateApiResponse<IStorageResponse[]>) => ({ payload: data })
);
export const setStorageItemIsLoading = createCustomAction(
    types.SET_STORAGE_ITEM_IS_LOADING,
    (id: number, isLoading: boolean) => ({ payload: { id, isLoading } })
);
export const setStorageItemIsDeleting = createCustomAction(
    types.SET_STORAGE_ITEM_IS_DELETING,
    (id: number, isDeleting: boolean) => ({ payload: { id, isDeleting } })
);

export const getStorages = (request?: IStorageListRequest) => async (dispatch: Dispatch) => await paginateList({
    dispatch,
    loadingFlag: LOADING_FLAGS.STORAGE_LIST,
    action: setStorageList,
    apiCall: () => storages.list(request),
});

export const createStorage = (data: IStorageRequest) => async (dispatch: Dispatch) => await create({
    data,
    dispatch,
    loadingFlag: LOADING_FLAGS.STORAGE_SAVE,
    action: addStorageItem,
    apiCall: storages.create,
    translations: {
        success: 'storage.toasts.storageSaved',
    },
});

export const getStorage = (id: number) => async (dispatch: Dispatch) => await get(id, {
    dispatch,
    apiCall: storages.item,
    action: setStorageItem,
    loadingFlag: LOADING_FLAGS.STORAGE_GET,
});

export const updateStorage = (id: number, data: IStorageRequest) => async (dispatch: Dispatch) => await update(id, {
    data,
    dispatch,
    apiCall: storages.update,
    action: updateStorageItem,
    loadingFlag: LOADING_FLAGS.STORAGE_SAVE,
    translations: {
        success: 'storage.toasts.storageSaved',
    },
});

export const removeStorage = (id: number) => async (dispatch: Dispatch) => await remove(id, {
    dispatch,
    apiCall: storages.remove,
    setLoadingAction: setStorageItemIsDeleting,
    action: removeStorageItem,
    loadingFlag: LOADING_FLAGS.STORAGE_REMOVE,
    translations: {
        success: 'storage.toasts.storageDeleted',
    },
});

export const loadStoragesOnScroll = ()  => async(dispatch: Dispatch, getState: () => IAppState) => {
    const state = getState();
    const nextPage = state.storage.list.links.next;
    const isLoading = state.app.loadingFlags.has(LOADING_FLAGS.STORAGE_LIST_INFINITY_SCROLL);

    return await loadOnScroll({
        nextPage,
        isLoading,
        dispatch,
        action: appendStorages,
        loadingFlag: LOADING_FLAGS.STORAGE_LIST_INFINITY_SCROLL,
    });
};

export const patchComputeResourceStorage = (id: number, data: IStoragePatchRequest) => async (dispatch: Dispatch) => {
    dispatch(setStorageItemIsLoading(id, true));

    try {
        const result = await storages.patch(id, data);
        dispatch(updateStorageItem(result.data.data));

        return result;
    } finally {
        dispatch(setStorageItemIsLoading(id, false));
    }
};
