// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import styled from 'styled-components';

export const BackupOperationContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  min-width: 985px;
`;

export const BatchActionContainer = styled.div`
  margin-bottom: 10px;
  display: inline-block;

  .pul-button {
    margin-right: 8px;

    :last-of-type {
      margin-right: 0;
    }
  }
`;
