// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

export const LIMIT_GROUPS = {
    TABLE: 'limitGroups-table',
};

export const TABLE_ACTIONS = {
    EDIT: 'edit',
    REMOVE: 'remove',
    FILTER: 'filter',
};

export const FORM = {
    VMS: 'vms',
    RUNNING_VMS: 'running_vms',
    ADDITIONAL_IPS: 'additional_ips',
};
