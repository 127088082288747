// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import * as dashboardActions from 'admin/dashboard/actions';
import TaskItem from 'admin/dashboard/containers/items/TaskItem';
import ComputeResourceItem from 'admin/dashboard/containers/items/ComputeResourceItem';
import ServerItem from 'admin/dashboard/containers/items/ServerItem';
import UserItem from 'admin/dashboard/containers/items/UserItem';
import Ipv4Item from 'admin/dashboard/containers/items/Ipv4Item';
import { RootState } from 'admin/core/store';
import { Loader } from 'common/components';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import ClusterMemoryItem from 'admin/dashboard/containers/items/ClusterMemoryItem';
import ClusterDiskItem from 'admin/dashboard/containers/items/ClusterDiskItem';
import { useRequestCancellationEffect } from 'common/hooks/useRequestCancellationEffect';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import {
    Grid,
    GridCol,
} from '@plesk/ui-library';
import { SIZE } from 'common/constants';

export type DashboardProps =
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const Dashboard: React.FC<DashboardProps> = ({
    isLoadingDashboard,
    getDashboardData,
}) => {
    useRequestCancellationEffect(token => getDashboardData(token), []);

    return (
        <Loader isLoading={isLoadingDashboard}>
            <Grid gap={SIZE.LG} style={{ marginTop: '20px' }}>
                <GridCol lg={3}>
                    <ComputeResourceItem />
                </GridCol>
                <GridCol lg={3}>
                    <ServerItem />
                </GridCol>
                <GridCol lg={3}>
                    <Grid gap={SIZE.LG}>
                        <GridCol>
                            <ClusterMemoryItem />
                        </GridCol>
                        <GridCol style={{ padding: 0 }} />
                        <GridCol>
                            <Ipv4Item />
                        </GridCol>
                    </Grid>
                </GridCol>
                <GridCol lg={3}>
                    <Grid gap={SIZE.LG}>
                        <GridCol>
                            <ClusterDiskItem />
                        </GridCol>
                        <GridCol style={{ padding: 0 }} />
                        <GridCol>
                            <UserItem />
                        </GridCol>
                    </Grid>
                </GridCol>
                <GridCol lg={12}>
                    <TaskItem />
                </GridCol>
            </Grid>
        </Loader>
    );
};

const mapStateToProps = (state: RootState) => ({
    isLoadingDashboard: state.app.loadingFlags.has(LOADING_FLAGS.DASHBOARD_DATA),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getDashboardData: bindActionCreators(dashboardActions.getDashboardData, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
