// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import styled from 'styled-components';
import { COLORS } from 'admin/core/theme';

interface IUsageProgressProps {
    moreThanHalf: boolean;
}
interface IUsageProgressHalfProps {
    angle: number;
}

export const UsageItem = styled.div`
    position: relative;
    display: inline-block;
    min-width: 120px;
    height: 120px;
    border-radius: 50%;
    border: 2px solid ${COLORS.GREY_40};
    margin: 8px 40px 12px 0;
    text-align: center;
    flex: 0;
`;

export const UsageProgress = styled.div<IUsageProgressProps>`
    clip: ${props => props.moreThanHalf ? 'inherit' : 'rect(0, 124px, 124px, 60px)'};
    height: 120px;
    width: 120px;
    margin: -2px 0 0 -2px;
    position: absolute;
`;

export const UsageProgressHalf = styled.div<IUsageProgressHalfProps>`
    border: 2px solid ${COLORS.GREEN};
    clip: rect(0, 62px, 124px, 0);
    height: 120px;
    width: 120px;
    position: absolute;
    border-radius: 50%;
    transform: rotate(${props => props.angle}deg);
`;

export const UsageDetail = styled.div`
  width: 100px;
  position: absolute;
  top: 50%; left: 50%;
  transform: translate(-50%,-50%);
  div {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
  }
`;
