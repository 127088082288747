// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import { ILocationResponse } from 'common/api/resources/Location';
import { Popover } from '@plesk/ui-library';
import LocationCell from 'common/components/IconCell/LocationCell';
import {
    InlineLocationCellContainer,
    PopoverLocationCell,
    LocationsContainer,
} from 'admin/computeResource/list/components/Locations/Styles';
import { Button } from 'admin/common/components/Button/Button';
import { INTENT_TYPE } from 'common/constants';

export interface ILocationsProps {
    locations: ILocationResponse[];
}

const MAX_SHOWN_LOCATIONS = 1;

const renderMoreButton = (locations: ILocationResponse[]) => {
    if (locations.length <= MAX_SHOWN_LOCATIONS) {
        return undefined;
    }

    return (
        <Popover
            placement="bottom-left"
            target={
                <Button ghost={true} intent={INTENT_TYPE.PRIMARY}>
                    +{locations.length - MAX_SHOWN_LOCATIONS}
                </Button>
            }
        >
            {locations.slice(MAX_SHOWN_LOCATIONS).map((location) => (
                <PopoverLocationCell key={location.id}>
                    <LocationCell location={location}/>
                </PopoverLocationCell>
            ))}
        </Popover>
    );
};

const renderShownLocations = (locations: ILocationResponse[]) =>
    locations.slice(0, MAX_SHOWN_LOCATIONS).map((location) => (
        <InlineLocationCellContainer key={location.id}>
            <LocationCell location={location}/>
        </InlineLocationCellContainer>
    ));

const Locations: React.FC<ILocationsProps> = ({ locations }) => (
    <LocationsContainer>
        {renderShownLocations(locations)}
        {renderMoreButton(locations)}
    </LocationsContainer>
);

export default Locations;
