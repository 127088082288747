// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import styled from 'styled-components';
import {
    FormField,
    ItemList,
    Select,
} from '@plesk/ui-library';
import { COLORS } from 'admin/core/theme';

export const ErrorForIsEnabled = styled.div`
    .pul-form-field__error {
        width: 330px;
        margin-right: -330px;
    }
`;

export const ErrorForLimit = styled.div`
    margin-top: 4px;

    .pul-form-field__error {
        width: 330px;
        margin-left: -150px;
        margin-right: -100px;
    }
`;

export const StyledSelect = styled(Select)`
    padding: 4px 0;
`;

export const StyledItemList = styled(ItemList)`
    .pul-item__title {
        font-weight: 600;
        margin: 6px auto;
    }

    .pul-item-list__item {
        background-color: ${COLORS.GREY_20};

        &:hover {
            background-color: ${COLORS.GREY_30};
            box-shadow: inset 0px -1px 0px ${COLORS.GREY_30};
        }

        &:active, &:focus {
            background-color: ${COLORS.GREY_20};
        }
    }

    .pul-item-list__item--selected {
        background-color: ${COLORS.PRIMARY};

        &:hover {
            background-color: ${COLORS.PRIMARY_HOVER};
        }

        &:active, &:focus {
            background-color: ${COLORS.PRIMARY};
        }

        .pul-item__title {
            color: ${COLORS.WHITE};
        }
    }
`;

export const OneLineFormField = styled(FormField)`
    display: flex;
    align-items: center;

    .pul-section-item__title {
        margin-bottom: 0;
        margin-right: 10px;
    }
`;

export const VZParametersSectionFormFieldContainer = styled(FormField)`
    display: flex;
    flex-direction: column;

    .pul-section-item__value .pul-section-item {
        margin: 0;
        padding-top: 0;
        padding-bottom: 0;
    }

    .pul-section-item__title {
        margin-bottom: 0;
    }
`;

export const LabelText = styled.span`
  display: block;
  width: 51px;
`;