// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import { HTTP_CODES } from 'common/api/constants';
import NotFound from 'common/components/ErrorPages/NotFound/NotFound';

export interface IWithErrorPagesProps extends React.HTMLProps<IWithErrorPagesProps> {
    onMount: () => void;
    deps?: React.DependencyList;
}

export const WithErrorPages: React.FC<IWithErrorPagesProps> = ({
    children,
    onMount,
    deps,
}) => {
    const [response, setResponse] = React.useState({
        status: HTTP_CODES.OK,
        data: {},
    });

    const fetchData = async() => {
        try {
            await onMount();
        } catch (e) {
            if (e.response) {
                setResponse(e.response);
                return;
            }

            throw e;
        }
    };

    React.useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);

    if (response.status === HTTP_CODES.NOT_FOUND) {
        return (<NotFound />);
    }

    return (<>{children}</>);
};
