// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';

import { COLORS } from 'admin/core/theme';
import CopyText from 'common/containers/CopyText/CopyText';
import { Button } from 'admin/common/components/Button/Button';
import { ButtonWrapper } from 'admin/common/components/SystemVariablesPopover/Styles';
import {
    Popover,
    Translate,
} from '@plesk/ui-library';

export interface IVariable {
    variable: string;
    description: React.ReactNode;
}

export interface ISystemVariablesPopoverProps {
    variables: IVariable[];
}

export const SystemVariablesPopover: React.FC<ISystemVariablesPopoverProps> = ({ variables }) => (
    <Popover
        target={(
            <ButtonWrapper>
                <Button
                    ghost={true}
                    icon="info-circle"
                >
                    <Translate content="application.variables.btnInfo" />
                </Button>
            </ButtonWrapper>
        )}
        placement="right"
    >
        <h3>
            <Translate content="application.variables.title" />
        </h3>
        {variables.map((variable, key) => (
            <p key={key}>
                <b style={{ color: COLORS.PRIMARY }}>
                    <div style={{ float: 'left' }}>
                        <CopyText>{`{{ ${variable.variable} }}`}</CopyText>
                    </div>
                </b>  &nbsp;— {variable.description}
            </p>
        ))}
    </Popover>
);
