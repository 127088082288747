// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import { LOADER } from 'common/components/Loader/test';
import {
    ContentLoader,
    GridCol,
} from '@plesk/ui-library';
import {
    StyledCardItem,
    ItemContent,
    ToolbarContainer,
    ItemToolbar,
    ItemInfo,
    ItemTitle,
} from 'client/common/components/Card/Styles';

interface ICardItemProps extends React.HTMLProps<HTMLDivElement> {
    icon?: React.ReactNode;
    item?: React.ReactNode;
    footer: React.ReactNode;
    cardTitle?: React.ReactNode | string;
    isLoading?: boolean;
    isDisabled?: boolean;
}

export const CardItem: React.FC<ICardItemProps> = props => {
    const {
        item,
        footer,
        isLoading,
        isDisabled,
        icon,
        cardTitle,
        onClick,
        onKeyPress,
    } = props;
    return (
        <GridCol onClick={onClick}>
            <StyledCardItem onKeyPress={onKeyPress} isDisabled={!!isDisabled}>
                {isLoading && <ContentLoader data-cy={LOADER} />}
                <ItemContent>
                    {icon}
                    <ItemInfo>
                        {cardTitle && (
                            <ItemTitle>
                                {cardTitle}
                            </ItemTitle>
                        )}
                        {item}
                    </ItemInfo>
                </ItemContent>
                <ToolbarContainer>
                    <ItemToolbar>
                        {footer}
                    </ItemToolbar>
                </ToolbarContainer>
            </StyledCardItem>
        </GridCol>
    );
};
