// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

// should be synced with backend/api/v1/Permission/Models/PermissionList.php
export const PERMISSION_LIST = {
    MANAGE_COMPUTE_RESOURCES: 'manage compute resources',
    MANAGE_SERVERS: 'manage servers',
    MANAGE_STORAGE: 'manage storage',
    MANAGE_ROLES: 'manage roles',
    MANAGE_USERS: 'manage users',
    MANAGE_IP_BLOCKS: 'manage IP blocks',
    MANAGE_PLANS: 'manage plans',
    MANAGE_OS_IMAGES: 'manage os images',
    MANAGE_APPLICATIONS: 'manage applications',
    MANAGE_LOCATIONS: 'manage locations',
    MANAGE_APP_SETTINGS: 'manage app settings',
    MANAGE_SSH_KEYS: 'manage ssh keys',
    MANAGE_API_TOKENS: 'manage api tokens',
    MANAGE_LIMIT_GROUPS: 'manage limit groups',
    MANAGE_NOTIFICATIONS: 'manage notifications',
    MANAGE_LICENSES: 'manage licenses',
    MANAGE_BACKUP_NODES: 'manage backup nodes',
    MANAGE_BACKUPS: 'manage backups',
    MANAGE_MANAGEMENT_NODE_BACKUPS: 'manage management node backups',
    MANAGE_ICONS: 'manage icons',
    MANAGE_TASKS: 'manage tasks',
    MANAGE_SERVERS_MIGRATION: 'manage servers migration',
    MANAGE_ACTIVITY_LOGS: 'manage activity logs',
    MANAGE_CLUSTER_IMPORTS: 'manage cluster imports',
    GET_COMPUTE_RESOURCES: 'get compute resources',
    GET_SERVERS: 'get servers',
    GET_LOCATIONS: 'get locations',
    GET_OS_IMAGES: 'get os images',
    GET_APPLICATIONS: 'get applications',
    GET_DASHBOARD: 'get dashboard',
    GET_LICENSES: 'get licenses',
    GET_APP_SETTINGS: 'get app settings',
    GET_PLANS: 'get plans',
    GET_SSH_KEYS: 'get ssh keys',
    GET_USERS: 'get users',
    GET_IP_BLOCKS: 'get IP blocks',
    GET_ICONS: 'get icons',
    GET_TASKS: 'get tasks',
    GET_BACKUP_NODES: 'get backup nodes',
    GET_BACKUPS: 'get backups',
    GET_MANAGEMENT_NODE_BACKUPS: 'get management node backups',
    GET_ROLES: 'get roles',
    GET_LIMIT_GROUPS: 'get limit groups',
    GET_CLUSTER_IMPORTS: 'get cluster imports',
    USAGE: 'usage statistic',
    VIEW_HELP: 'view help',
    VIEW_ADMIN_AREA: 'view admin area',
    ACCESS_BILLING_BYPASS: 'access billing bypass',
    RESIZE_SERVERS: 'resize servers',
    DELETE_SERVERS: 'delete servers',
};
