// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import { CancelTokenSource } from 'axios';
import {
    api,
    IApiResponse,
} from 'common/api/resources/Response';
import { IUpdateResponse } from 'common/api/resources/Update/model';

export const updates = {
    data: (cancelToken?: CancelTokenSource) => api.get<IApiResponse<IUpdateResponse>>('updates', {
        cancelToken: cancelToken?.token,
    }),
    initUpdate: () => api.post('updates'),
};
