// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import { VZNetfilterStatus } from 'common/api/resources/ComputeResourceVm';
import { Translate } from '@plesk/ui-library';
import {
    ISegmentedControlProps,
    SegmentedControl,
} from 'common/components/SegmentedControl/SegmentedControl';

export interface IVZNetfilterStatusSegmentedControlProps  extends Pick<ISegmentedControlProps<VZNetfilterStatus>, 'withConfirmation' | 'selected' | 'onChange'> {
    disabled?: boolean;
}

const VZNetfilterStatusSegmentedControl: React.FC<IVZNetfilterStatusSegmentedControlProps> = ({
    disabled = false,
    ...rest
}) => {
    const buttons = Object.keys(VZNetfilterStatus).map(status => {
        const value = VZNetfilterStatus[status];
        let title = value.charAt(0).toUpperCase() + value.slice(1);

        return ({
            title,
            value,
            disabled,
            tooltip: <Translate content={tooltips[value]}/>,
        });
    });

    return (
        <SegmentedControl
            buttons={buttons}
            confirmationPopoverTranslations={{
                title: <Translate content="components.vzNetfilterStatusSegmentedControl.confirmationPopoverTitle"/>,
                button: <Translate content="change"/>,
                text: <Translate content="components.vzNetfilterStatusSegmentedControl.confirmText" />,
            }}
            {...rest}
        />
    );
};

const tooltips = {
    [VZNetfilterStatus.DISABLED]: 'components.vzNetfilterStatusSegmentedControl.tooltips.disabled',
    [VZNetfilterStatus.STATELESS]: 'components.vzNetfilterStatusSegmentedControl.tooltips.stateless',
    [VZNetfilterStatus.STATEFUL]: 'components.vzNetfilterStatusSegmentedControl.tooltips.stateful',
    [VZNetfilterStatus.FULL]: 'components.vzNetfilterStatusSegmentedControl.tooltips.full',
};

export default VZNetfilterStatusSegmentedControl;