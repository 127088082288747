// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import { Dispatch } from 'redux';
import {
    setIsLoading,
    unsetIsLoading,
} from 'common/modules/app/loadingFlags/actions';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { HTTP_CODES } from 'common/api/constants';
import { setProjectList } from 'common/modules/project/actions';
import {
    IUserProjectsListRequest,
    users,
} from 'common/api/resources/User';

export const getProjects = (id: number, request?: IUserProjectsListRequest) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.PROJECT_LIST));

    try {
        const result = await users.projects.list(id, request);

        if (result.status === HTTP_CODES.OK) {
            dispatch(setProjectList({
                ...result.data,
                data: result.data.data.map(item => ({
                    ...item,
                    isEdit: false,
                    isLoading: false,
                })),
            }));
        }

        return result;
    } catch (e) {
        throw e;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.PROJECT_LIST));
    }
};
