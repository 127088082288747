// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import { Dispatch } from 'redux';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { createCustomAction } from 'typesafe-actions';
import * as types from 'admin/activityLog/constants/types';
import {
    activityLogs,
    IActivityLogListRequest,
    IActivityLogResponse,
} from 'common/api/resources/ActivityLog';
import { IAppState } from 'admin/core/store';
import {
    loadOnScroll,
    paginateList,
} from 'common/actions/actionsWrapper';
import { IPaginateApiResponse } from 'common/api/resources/Response';
import { CancelTokenSource } from 'axios';

export const setActivityLogsList = createCustomAction(
    types.SET_ACTIVITY_LOG_LIST,
    (data: IPaginateApiResponse<IActivityLogResponse[]>) => ({ payload: data })
);
export const appendActivityLogs = createCustomAction(
    types.APPEND_ACTIVITY_LOGS,
    (data: IPaginateApiResponse<IActivityLogResponse[]>) => ({ payload: data })
);

export const getActivityLogs = (request?: IActivityLogListRequest, cancelToken?: CancelTokenSource) => async(dispatch: Dispatch) => {
    const apiCall = () => activityLogs.list(request, cancelToken);

    return await paginateList({
        dispatch,
        loadingFlag: LOADING_FLAGS.ACTIVITY_LOG_LIST,
        action: setActivityLogsList,
        apiCall,
    });
};

export const loadActivityLogsOnScroll = ()  => async(dispatch: Dispatch, getState: () => IAppState) => {
    const state = getState();
    const nextPage = state.activityLog.list.links.next;
    const isLoading = state.app.loadingFlags.has(LOADING_FLAGS.ACTIVITY_LOG_LIST);

    return await loadOnScroll({
        nextPage,
        isLoading,
        dispatch,
        action: appendActivityLogs,
        loadingFlag: LOADING_FLAGS.ACTIVITY_LOG_LIST_INFINITY_SCROLL,
    });
};
