// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import ComputeResourceVmTable from 'admin/computeResourceVm/containers/ComputeResourceVmTable/ComputeResourceVmTable';
import { EmptyView } from 'common/components/EmptyView/EmptyView';
import {
    Button,
    Translate,
} from '@plesk/ui-library';
import PageHeader from 'common/components/PageHeader/PageHeader';
import { PageSection } from 'common/components/PageHeader/Styles';
import {
    RouteComponentProps,
    withRouter,
} from 'react-router';
import { ADMIN_ROUTE_PREFIX } from 'admin/core/constants';
import {
    Tab,
    TabList,
    TabPanel,
    Tabs,
} from 'react-tabs';
import {
    TEST,
    VMS_TABS,
} from 'admin/computeResourceVm/constants';
import ServersMigrationTable from 'admin/serversMigration/Table/ServersMigrationTable';

export interface IComputeResourceVmsProps extends RouteComponentProps {}

export const ComputeResourceVms: React.FC<IComputeResourceVmsProps> = ({
    history,
    location: {
        hash,
    },
}) => {
    if (!hash) {
        history.replace(VMS_TABS.SERVERS_TAB);
    }

    const tabs = Object.values(VMS_TABS);
    const handleCreate = () => history.push(`${ADMIN_ROUTE_PREFIX}/servers/create`);
    const handleTabSelect = (index: number) => history.replace(tabs[index]);

    const renderHeaderButton = () => {
        if (hash !== VMS_TABS.SERVERS_TAB) {
            return null;
        }
        return (
            <Button
                intent="primary"
                size="lg"
                icon="screen"
                onClick={handleCreate}
                data-cy={TEST.VMS.CREATE_BUTTON}
            >
                <Translate content="computeResourceVm.createBtn" />
            </Button>
        );
    };

    return (
        <>
            <PageHeader
                title={(
                    <PageSection>
                        <Translate content="computeResourceVm.header" />
                    </PageSection>
                )}
                actionButton={renderHeaderButton()}
            />
            <Tabs selectedIndex={tabs.findIndex(tab => tab === hash)} onSelect={handleTabSelect}>
                <TabList>
                    <Tab data-cy={TEST.VMS_TAB.SERVERS}>
                        <Translate content="computeResourceVms.serversTab.title"/>
                    </Tab>
                    <Tab data-cy={TEST.VMS_TAB.MIGRATIONS}>
                        <Translate content="computeResourceVms.migrationsTab.title"/>
                    </Tab>
                </TabList>
                <TabPanel>
                    <ComputeResourceVmTable
                        withFilters={true}
                        emptyView={<EmptyView
                            title="computeResource.servers.emptyView.title"
                            description="computeResource.servers.emptyView.description"
                            icon="screen"
                        />}
                    />
                </TabPanel>
                <TabPanel>
                    <ServersMigrationTable/>
                </TabPanel>
            </Tabs>
        </>
    );
};

export default withRouter(ComputeResourceVms);
