// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { RootState } from 'admin/core/store';
import { connect } from 'react-redux';
import { ThemedStyles } from 'common/containers/ThemeProvider/Styles';
import { StyledTabs } from 'client/common/styles/Tabs';

export type ThemeProviderProps =
    React.HTMLProps<HTMLElement> &
    ReturnType<typeof mapStateToProps>;

const ThemeProvider: React.FC<ThemeProviderProps> = ({
    children,
    settings,
}) => {
    React.useEffect(() => {
        document.title = settings.theme.brand_name;
    }, [settings.theme.brand_name]);

    return (
        <StyledThemeProvider theme={settings.theme}>
            <>
                <ThemedStyles theme={settings.theme} />
                <StyledTabs theme={settings.theme} />
                {children}
            </>
        </StyledThemeProvider>
    );
};

const mapStateToProps = (state: RootState) => ({
    settings: state.settings,
});

export default connect(mapStateToProps)(ThemeProvider);
