// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import { IComputeResourceResponse } from 'common/api/resources/ComputeResource';

// Should be synced with `backend/api/v1/BackupNode/Enums/BackupNodeType.php`
export enum BackupNodeType {
    SSH_RSYNC = 'ssh_rsync',
    HETZNER_STORAGE_BOX = 'hetzner_storage_box',
}

export interface IShortBackupNodeResponse {
    id: number;
    name: string;
}

export interface IBackupNodeResponse {
    id: number;
    name: string;
    type: BackupNodeType;
    credentials: ISSHRsyncCredentials | IHetznerStorageBoxCredentials;
    compute_resources_count: number;
    backups_count: number;
    total_backups_size: number;
    compute_resources: IComputeResourceResponse[];
    can_delete: boolean;

    is_deleting: boolean;
}

export interface IBackupNodeCreateRequest {
    name: string;
    type: BackupNodeType;
    credentials: ISSHRsyncCredentials | IHetznerStorageBoxCredentials;
    compute_resources: number[];
}

export interface ISSHRsyncCredentials {
    host: string;
    port: number;
    login: string;
    key: string;
    storage_path: string;
}

export interface IHetznerStorageBoxCredentials {
    host: string;
    login: string;
    key: string;
}
