// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import styled from 'styled-components';
import { COLORS } from 'admin/core/theme';
import { Columns as UILibColumns } from '@plesk/ui-library';

export const CodeEditorWrapper = styled.div`
    .CodeMirror {
        height: 400px;
        border: 1px solid ${COLORS.GREY_50};
        box-sizing: border-box;
        border-radius: 2px;
        z-index: 0;
    }
`;

export const Label = styled.div`
  display: flex; 
  align-items: center;
  justify-content: space-between;
`;

export const Columns = styled(UILibColumns)`
  align-items: center;
  height: 40px;
`;
