// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import {
    IconWrapper,
    IconCellContainer,
} from 'common/components/IconCell/Styles';
import {
    Translate,
    Icon,
} from '@plesk/ui-library';

export interface IIPv6AvailableCellProps {
    available: boolean;
}

const IPv6AvailableCell: React.FC<IIPv6AvailableCellProps> = ({
    available,
}) => {
    const icon = available ? 'check-mark' : 'cross-mark';
    const text = available ? 'computeResource.metrics.ipv6Available' : 'computeResource.metrics.ipv6NotAvailable';

    return (
        <IconCellContainer>
            <IconWrapper>
                <Icon name={icon} />
                <Translate content={text} />
            </IconWrapper>
        </IconCellContainer>
    );
};

export default IPv6AvailableCell;
