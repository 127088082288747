// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import { ICONS } from 'common/constants';

export interface IMenuItem {
    path: string;
    icon: ICONS;
    value: string;
    permission?: string;
}

export const menuItems: IMenuItem[] = [{
    path: '/projects',
    icon: ICONS.PROJECTS,
    value: 'menu.projects',
}, {
    path: '/account',
    icon: ICONS.USER,
    value: 'menu.account',
}, {
    path: '/documentation',
    icon: ICONS.GLOBE,
    value: 'menu.documentation',
}];
