// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import {
    Action as UIAction,
    IAction,
} from '@plesk/ui-library';
import { DisabledContainer } from 'common/components/Action/Styles';

export interface IActionProps extends IAction {
    disabled?: boolean;
}

const Action: React.FC<IActionProps> = ({
    disabled,
    onClick,
    ...remain
}) => {
    if (disabled) {
        return (
            <DisabledContainer>
                <UIAction {...remain}/>
            </DisabledContainer>
        );
    }

    return <UIAction onClick={onClick} {...remain}/>;
};

export default Action;
