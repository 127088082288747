// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import {
    api,
    IApiResponse,
    IPaginateApiResponse,
} from 'common/api/resources/Response';
import {
    IComputeResourceCreateRequest,
    IComputeResourceDeleteRequest,
    IComputeResourceInstallStepResponse,
    IComputeResourceResponse,
    IComputeResourceSettingsResponse,
    IComputeResourcePatchRequest,
    ILogicalVolumeResponse,
    IMetricResponse,
    INetworkResponse,
    IPhysicalVolumeResponse,
    ISetUpNetworkRequest,
    IVmCreateRequest,
    IUsageResponse,
} from 'common/api/resources/ComputeResource/model';
import {
    IStorageCreateRequest,
    IStorageResponse,
} from 'common/api/resources/Storage';
import { IVmResponse } from 'common/api/resources/ComputeResourceVm';
import { CancelTokenSource } from 'axios';
import { IIpBlockResponse } from 'common/api/resources/IpBlock';
import { QueryBuilder } from 'common/api/resources/Request/QueryBuilder';
import { IPaginatedWithSearch } from 'common/api/resources/Request/request';

export const computeResources = {
    create: (data: IComputeResourceCreateRequest) => api.post<IApiResponse<IComputeResourceResponse>>('compute_resources', data),
    patch: (id: number, data: IComputeResourcePatchRequest) => api.patch<IApiResponse<IComputeResourceResponse>>(`compute_resources/${id}`, data),
    retryInstallation: (id: number, data: IComputeResourceCreateRequest) => api.post<IApiResponse<IComputeResourceResponse>>(`compute_resources/${id}/retry_installation`, data),
    remove: (id: number, data: IComputeResourceDeleteRequest) => api.delete(`compute_resources/${id}`, { data }),
    list: (params?: IPaginatedWithSearch) => api.get<IPaginateApiResponse<IComputeResourceResponse[]>>('compute_resources', {
        params: new QueryBuilder(params).build(),
    }),
    item: (id: number, cancelToken?: CancelTokenSource) => api.get<IApiResponse<IComputeResourceResponse>>(
        `compute_resources/${id}`, { cancelToken: cancelToken ? cancelToken.token : undefined }),
    usage: (id: number) => api.get<IApiResponse<IUsageResponse>>(`compute_resources/${id}/usage`),

    api: (id: number) => ({
        metrics: () => api.get<IApiResponse<IMetricResponse[]>>(`compute_resources/${id}/metrics`),
        networks: () => api.get<IApiResponse<INetworkResponse[]>>(`compute_resources/${id}/networks`),
        physicalVolumes: () => api.get<IApiResponse<IPhysicalVolumeResponse[]>>(`compute_resources/${id}/physical_volumes`),
        thinPools: () => api.get<IApiResponse<ILogicalVolumeResponse[]>>(`compute_resources/${id}/thin_pools`),
        setupNetwork: (data: ISetUpNetworkRequest) => api.post<IApiResponse<IComputeResourceResponse>>(`compute_resources/${id}/setup_network`, data),
    }),

    installSteps: {
        list: (id: number, cancelToken?: CancelTokenSource) => api.get<IApiResponse<IComputeResourceInstallStepResponse[]>>(
            `compute_resources/${id}/install_steps`, { cancelToken: cancelToken ? cancelToken.token : undefined }),
    },

    ipBlocks: {
        list: (id: number) => api.get<IApiResponse<IIpBlockResponse[]>>(`compute_resources/${id}/ip_blocks`),
    },

    settings: {
        item: (id: number) => api.get<IApiResponse<IComputeResourceSettingsResponse>>(`compute_resources/${id}/settings`),
        update: (
            id: number, data: IComputeResourceSettingsResponse
        ) => api.put<IApiResponse<IComputeResourceSettingsResponse>>(`compute_resources/${id}/settings`, data),
    },

    storages: {
        list: (id: number) => api.get<IApiResponse<IStorageResponse[]>>(`compute_resources/${id}/storages`),
        create: (
            id: number, data: IStorageCreateRequest
        ) => api.post<IApiResponse<IStorageResponse>>(`compute_resources/${id}/storages`, data),
    },

    vms: {
        list: (id: number) => api.get<IPaginateApiResponse<IVmResponse[]>>(`compute_resources/${id}/servers`),
        create: (
            id: number, data: IVmCreateRequest
        ) => api.post<IApiResponse<IVmResponse>>(`compute_resources/${id}/servers`, data),
    },
};
