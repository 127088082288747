// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import {
    FormFieldCheckbox,
    Section,
    Translate,
} from '@plesk/ui-library';
import SettingsWrapper from 'admin/settings/containers/Settings/SettingsWrapper';
import * as settingsActions from 'common/modules/settings/actions';
import { connect } from 'react-redux';
import {
    Dispatch,
    bindActionCreators,
} from 'redux';
import { ISettingsResponse } from 'common/api/resources/Settings';

export type NetworkRulesSettingsProps =
    ReturnType<typeof mapDispatchToProps>;

export const NetworkRulesSettings: React.FC<NetworkRulesSettingsProps> = ({
    settingsActions: { saveSettings },
}) => {
    const handleSubmit = (values: ISettingsResponse) => {
        saveSettings({
            network_rules: values.network_rules,
        });
    };

    return (
        <SettingsWrapper
            title="settings.titles.networkRules"
            onSubmit={handleSubmit}
        >
            <Section>
                <FormFieldCheckbox
                    name="network_rules[arp]"
                    label={
                        <Translate content="settings.networkRules.arp" />
                    }
                />
                <FormFieldCheckbox
                    name="network_rules[dhcp]"
                    label={
                        <Translate content="settings.networkRules.dhcp" />
                    }
                />
                <FormFieldCheckbox
                    name="network_rules[cloud_init]"
                    label={
                        <Translate content="settings.networkRules.cloudInit" />
                    }
                />
                <FormFieldCheckbox
                    name="network_rules[smtp]"
                    label={
                        <Translate content="settings.networkRules.smtp" />
                    }
                />
                <FormFieldCheckbox
                    name="network_rules[icmp]"
                    label={
                        <Translate content="settings.networkRules.icmp" />
                    }
                />
                <FormFieldCheckbox
                    name="network_rules[icmp_reply]"
                    label={
                        <Translate content="settings.networkRules.icmpReply" />
                    }
                />
                <FormFieldCheckbox
                    name="network_rules[portmapper]"
                    label={
                        <Translate content="settings.networkRules.portmapper" />
                    }
                />
            </Section>
        </SettingsWrapper>
    );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    settingsActions: bindActionCreators(settingsActions, dispatch),
});

export default connect(null, mapDispatchToProps)(NetworkRulesSettings);
