// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'client/core/store';
import Menu from 'client/menu/containers/Menu/Menu';
import {
    Content,
    StyledClientLayout,
} from 'client/common/containers/Layout/Styles';
import {
    Redirect,
    RouteComponentProps,
    withRouter,
} from 'react-router';
import TopBar from 'common/components/TopBar/TopBar';
import {
    Toaster,
    Translate,
} from '@plesk/ui-library';
import { isUserLockedOrSuspended } from 'common/modules/auth/selectors/user';
import { COLORS } from 'client/core/theme';
import {
    getBackgroundToasts,
    getForegroundToasts,
} from 'common/helpers/toaster';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import * as toasterActions from 'common/modules/app/toaster/actions';

interface ILayoutProps extends React.HTMLProps<HTMLElement>, RouteComponentProps {}

export type LayoutProps =
    ILayoutProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const Layout: React.FC<LayoutProps> = ({
    meta: { isLicenseActive },
    isUserLockedOrSuspended: userLockedOrSuspended,
    children,
    auth: { isAuth, user },
    toaster,
    toasterActions: { dropToast },
    history,
}) => {
    if (!isAuth) {
        return (<Redirect to="/login" />);
    }

    if (history.location.pathname !== '/account' && user.id && !user.has_verified_email) {
        return (<Redirect to="/account" />);
    }

    const handleMenuItemClicked = (path: string) => () => {
        if (path) {
            history.push(path);
        }
    };

    const isMenuItemActive = (current: string) => history.location.pathname.startsWith(current);

    const handleToastClose = (key: string) => dropToast(key);

    return (
        <StyledClientLayout>
            <Menu handleClick={handleMenuItemClicked} isActive={isMenuItemActive} />
            <Content>
                {!isLicenseActive && (
                    <TopBar>
                        <Translate content="license.topInfoPanel.description" />
                    </TopBar>
                )}
                {userLockedOrSuspended && (
                    <TopBar color={COLORS.RED_0}>
                        <Translate
                            content="account.topInfoPanel.lockedOrSuspended"
                            params={{ status: user.status }}
                        />
                    </TopBar>
                )}
                <Toaster
                    className="toaster"
                    toasts={getForegroundToasts(toaster)}
                    onToastClose={handleToastClose}
                />
                <Toaster
                    className="toaster"
                    position="bottom-end"
                    toasts={getBackgroundToasts(toaster)}
                    onToastClose={handleToastClose}
                />
                {children}
            </Content>
        </StyledClientLayout>
    );
};

const mapStateToProps = (state: RootState) => ({
    meta: state.app.meta,
    auth: state.auth,
    isUserLockedOrSuspended: isUserLockedOrSuspended(state),
    toaster: state.app.toaster,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    toasterActions: bindActionCreators(toasterActions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout));
