// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import styled from 'styled-components';
import { COLORS } from 'common/theme';
import { CardItem } from 'common/components/styles/CardItem';

export const ItemPlan = styled(CardItem)`
    position: relative;
    padding: 16px 20px;
    min-height: 150px;

    .plan-param {
        opacity: ${props => props.isSelected ? 0.8 : 1};
    }
`;

export const TitleContainer = styled.div`
  display: flex;
`;

export const Title = styled.div`
    margin-bottom: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: ${COLORS.GREY_100};
    white-space: nowrap;
    width: 100%;
`;

export const ItemCost = styled.div`
    margin-bottom: 16px;

    div:first-child {
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 4px;
        overflow: hidden;
        line-break: anywhere;
    }

    div:last-child {
        font-size: 14px;
        line-height: 20px;
    }
`;

export const ItemPlanParams = styled.div`
  display: flex;
`;


export const PlanValuesList = styled.div`
    font-size: 14px;
    line-height: 20px;
    margin-left: 16px;
    padding: 0;
    
    div:not(:last-child) {
      margin-bottom: 8px;
    }
`;

export const PlanLabels = styled.div`
    font-size: 14px;
    line-height: 20px;
    color: ${COLORS.GREY_80};
    margin: 0;
    padding: 0;
`;

export const PlanLabelItem = styled.div`
    &:not(:last-child) {
        margin-bottom: 8px;
    }
`;
