// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import {
    RouteComponentProps,
    withRouter,
} from 'react-router';
import { Link } from '@plesk/ui-library';
import { ADMIN_ROUTE_PREFIX } from 'admin/core/constants';
import { VM_TABS } from 'admin/computeResourceVm/constants';

export interface IComputeResourceVmLinkProps extends RouteComponentProps {
    id: number;
    children?: React.ReactElement | string;
    tab?: string;
}

export const ComputeResourceVmLink: React.FC<IComputeResourceVmLinkProps> = ({
    id,
    history,
    children,
    tab,
}) => {
    tab = tab ?? VM_TABS.RESCUE_TAB;

    // Remove leading '#'.
    if (tab[0] === '#') {
        tab = tab.slice(1);
    }

    const handleClick = () => {
        history.push(`${ADMIN_ROUTE_PREFIX}/servers/${id}#${tab}`);
    };

    return (
        <Link onClick={handleClick}>
            {children}
        </Link>
    );
};

export default withRouter(ComputeResourceVmLink);
