// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import styled from 'styled-components';

export const FormErrorsWrapper = styled.div`
  margin-bottom: 10px;
`;

export const FormErrorsTitle = styled.div`
  color: #d02d4b;
`;
