// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'admin/core/store';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import * as formErrorsActions from 'common/modules/app/formErrors/actions';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import {
    ipRule,
    validate,
    requiredRule,
} from 'common/validator';
import {
    Form,
    FormFieldText,
    Section,
    Translate,
} from '@plesk/ui-library';
import { INTENT_TYPE } from 'common/constants';
import { Button } from 'admin/common/components/Button/Button';
import { Dialog } from 'admin/common/components/Dialog/Dialog';
import { nestStringProperties } from 'common/modules/app/formErrors/selectors';
import {
    IpBlockType,
    IpBlockListType,
} from 'common/api/resources/IpBlock';
import { DialogHeadingContainer } from 'admin/sshKey/containers/styles';
import * as ipBlockActions from 'admin/ipBlock/actions';
import {
    IReserveIpRequest,
    IAddIpRequest,
} from 'common/api/resources/Ip';

interface IIPAddDialogProps {
    id: number;
    type: IpBlockType;
    listType: IpBlockListType;
    isOpened: boolean;
    onCreated: () => void;
    onClose: () => void;
}

export type IPAddDialogProps =
    IIPAddDialogProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

const defaultValues: IAddIpRequest = Object.freeze({
    ip: '',
    comment: '',
});

export const IpAddDialog: React.FC<IPAddDialogProps> = ({
    id,
    type,
    listType,
    onCreated,
    isOpened,
    onClose,
    isItemCreating,
    formErrors,
    ipBlockActions: {
        reserveIpInBlock,
        addIpToBlock,
    },
    formErrorsActions: {
        setFormErrors,
        clearFormErrors,
    },
}) => {
    const [submitValues, setSubmitValues] = React.useState({ ...defaultValues });

    React.useEffect(() => () => {
        clearFormErrors();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleCreate = async (values: IReserveIpRequest|IAddIpRequest) => {
        const version = type === IpBlockType.IPv4 ? 4 : 6;
        const rules = {
            ip: [
                requiredRule(<Translate content="validate.fieldRequired" />),
                ipRule(<Translate content="validate.badIpAddress" />, version),
            ],
        };

        const errors = validate<IReserveIpRequest|IAddIpRequest>(values, rules);

        if (Object.keys(errors).length) {
            setFormErrors(errors);
            return;
        }

        // We have to perform different actions depending on the list type.
        // 1. In the case of the Range list type: we must reserve ip in the ip block
        // 2. In the case of the Set list: type, we must add ip to the ip list of the ip block.
        const action = listType === IpBlockListType.RANGE
            ? reserveIpInBlock
            : addIpToBlock;

        try {
            await action(id, values);
            setSubmitValues({ ...defaultValues });
            onCreated();
        } catch (e) {
            throw e;
        }
    };

    const handleClose = () => {
        setSubmitValues({ ...defaultValues });
        onClose();
    };

    return (
        <Dialog
            heading={(
                <DialogHeadingContainer>
                    {listType === IpBlockListType.RANGE ? (
                        <Translate content="ipBlock.addForm.titleReserve" />
                    ) : (
                        <Translate content="ipBlock.addForm.title" />
                    )}
                </DialogHeadingContainer>
            )}
            closeHandler={handleClose}
            isOpen={isOpened}
            size="xs"
        >
            <Form
                id="ipAddForm"
                footerClassName="hidden"
                onSubmit={handleCreate}
                values={submitValues}
                errors={formErrors}
                hideRequiredLegend={true}
                submitButton={false}
                cancelButton={false}
                applyButton={false}
                vertical={true}
            >
                <Section>
                    <FormFieldText
                        name="ip"
                        size="fill"
                        label={<Translate content="ipBlock.addForm.ip" />}
                        required={true}
                    />
                    <FormFieldText
                        name="comment"
                        size="fill"
                        label={<Translate content="ipBlock.addForm.comment" />}
                    />
                </Section>
            </Form>
            <Button
                type="submit"
                form="ipAddForm"
                fill={true}
                intent={INTENT_TYPE.PRIMARY}
                size="lg"
                isLoading={isItemCreating}
            >
                <Translate content="ipBlock.form.saveBtn" />
            </Button>
        </Dialog>
    );
};

const mapStateToProps = (state: RootState) => ({
    formErrors: nestStringProperties(state),
    isItemCreating: state.app.loadingFlags.has(LOADING_FLAGS.ADD_IP_TO_IPBLOCK),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    ipBlockActions: bindActionCreators(ipBlockActions, dispatch),
    formErrorsActions: bindActionCreators(formErrorsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(IpAddDialog);
