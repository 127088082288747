// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import {
    Action,
    Paragraph,
    Section,
    Translate,
} from '@plesk/ui-library';
import { PageHeader } from 'admin/common/components/PageHeader/PageHeader';
import { HelpContent } from 'admin/help/components/Styles';

export const Help: React.FC = () => (
    <>
        <PageHeader title={<Translate content="help.title"/>} isButtonShown={false}/>
        <HelpContent>
            <Section vertical={true} title={<Translate content="help.apiDocSection" />} >
                <Paragraph>
                    <Translate content="help.apiDocumentationDescription" />
                </Paragraph>
                <Action href="/admin/documentation" target="_blank">
                    <Translate content="help.apiDocumentationUrl" />
                </Action>
            </Section>
        </HelpContent>
    </>
);

export default Help;
