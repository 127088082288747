// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import {
    FormField,
    Translate,
    IFormField,
} from '@plesk/ui-library';
import { InlineFormField } from 'common/components/Limit/Styles';
import { SegmentedControl } from 'common/components/SegmentedControl/SegmentedControl';
import { FormFieldNumber } from 'common/components/Form/FormFieldNumber/FormFieldNumber';
import { ILimit } from 'common/api/resources/model';

export interface ILimitProps extends IFormField {
    limit: ILimit;
    fieldName: string;
    onChangeIsEnabled: (option: string) => void;
    onLimitChange?: (limit: string) => void;
    fieldLabel?: React.ReactNode;
    min?: number;
}

export const LIMIT_STATES = {
    LIMIT: 'limit',
    UNLIMITED: 'unlimited',
};

export const LIMIT_BUTTONS = [
    { title: <Translate content="limitForm.unlimited" />, value: LIMIT_STATES.UNLIMITED },
    { title: <Translate content="limitForm.limit" />, value: LIMIT_STATES.LIMIT },
];

export const Limit: React.FC<ILimitProps> = ({
    limit,
    fieldName,
    onChangeIsEnabled,
    fieldLabel,
    min,
    onLimitChange,
    ...remain
}) => (
    <FormField name={fieldName} {...remain}>
        <InlineFormField>
            <SegmentedControl
                buttons={LIMIT_BUTTONS}
                selected={limit.is_enabled ? LIMIT_STATES.LIMIT : LIMIT_STATES.UNLIMITED}
                onChange={onChangeIsEnabled}
            />
            <FormFieldNumber
                label={null}
                name={fieldName}
                min={min !== undefined ? min : 0}
                value={limit.limit}
                defaultValue={limit.limit}
                disabled={!limit.is_enabled}
                onChange={onLimitChange}
            />
            {fieldLabel}
        </InlineFormField>
    </FormField>
);
