// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import {
    api,
    IApiResponse,
} from 'common/api/resources/Response';
import {
    IAuthLinkRequest,
    ILoginRequest,
    ILoginResponse,
    IRegisterRequest,
    IResetPasswordRequest,
    IUpdatePasswordRequest,
    IVerifyEmailRequest,
} from 'common/api/resources/Auth/model';

export const auth = {
    login: (data: ILoginRequest) => api.post<IApiResponse<ILoginResponse>>('auth/login', data),
    register: (data: IRegisterRequest) => api.post<IApiResponse<ILoginResponse>>('auth/register', data),
    verify: (id: number, data: IVerifyEmailRequest) => api.post<IApiResponse<ILoginResponse>>(`auth/verify/${id}`, data),
    byLink: (data: IAuthLinkRequest) => api.post<IApiResponse<ILoginResponse>>('auth/by_link', data),
    resetPassword: (data: IResetPasswordRequest) => api.post('auth/reset_password', data),
    updatePassword: (data: IUpdatePasswordRequest) => api.put('auth/reset_password', data),
};
