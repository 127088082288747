// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import styled from 'styled-components';

export const StyledForm = styled.div`
  .pul-input {
    margin-top: 4px;
  }

  .pul-input__input {
    height: 40px;
  }

  .pul-section-item__title {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    padding: 0 !important;
    margin: 0 !important;
    color: #737373;
  }

  .pul-input__suffix {
    padding: 6px;
  }

  .pul-form__footer {
    height: 0;
    margin: 0 !important;

    .pul-form-field {
      min-height: unset;
      padding: 0;
    }
  }
`;
