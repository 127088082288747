// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

export default {
    menu: {
        dashboard: 'Dashboard',
        externalIntegrations: 'External Licensing Integrations',
        computeResources: 'Compute Resources',
        locations: 'Locations',
        plans: 'Plans',
        servers: 'Virtual Servers',
        backups: 'Backups',
        serverBackups: 'Virtual Server Backups',
        managementNodeBackup: 'Management Node Backups',
        backupNodes: 'Backup Nodes',
        storage: 'Storage',
        images: 'Images',
        oses: 'Operating Systems',
        applications: 'Applications',
        network: 'Network',
        ipv4Blocks: 'IPv4 Blocks',
        ipv6Blocks: 'IPv6 Blocks',
        updates: 'System Updates',
        settings: 'Settings',
        generalSettings: 'General',
        dnsSettings: 'DNS',
        billingIntegrationSettings: 'Billing Integration',
        updatesSettings: 'System Updates',
        license: 'Licensing',
        mailSettings: 'Mail',
        networkRulesSettings: 'Network Rules',
        notificationsSettings: 'Notifications',
        userAreaSettings: 'User Area',
        computeResourceSettings: 'Compute Resources',
        icons: 'Icons',
        languages: 'Languages',
        access: 'Access',
        users: 'Users',
        roles: 'Roles',
        limitGroups: 'Limit Groups',
        sshKeys: 'SSH Keys',
        tasks: 'Tasks',
        help: 'Help & Support',
        api_tokens: 'API Tokens',
        activity_logs: 'Activity Logs',
        imports: 'Cluster Imports',
    },
    accessDenied: 'The access is denied.',
    networkError: 'Can\'t connect to the API service.',
    notImplementedTitle: 'Under construction',
    notImplemented: 'This feature is on its way. Stay tuned!',
    gatewayTimeout: 'The server didn\'t respond in time. Please try again.',
    license: {
        topInfoPanel: {
            invalidCoresAmount: 'The number of cores in the cluster is higher than the amount specified in the license: %%current%% / %%available%%.',
            activateBtn: 'I have activation code',
            description: 'This installation is running unlicensed.',
            goToLicensePage: 'Go to the License page',
            crsOnManagementNodeLimitExceeded: 'We\'ve detected that the management server is used as a compute resource. Your license doesn\'t support this configuration. Install the Enterprise license or delete the existing compute resources (%%crs%%), get one or more individual servers, and then connect them as compute resources.',
            crLimitExceeded: 'The actual number of \'%%licenseType%%\' compute resources (%%count%%) exceeds the limit (%%limit%%).',
            crVsLimitExceeded: 'Found the following \'%%licenseType%%\' compute resources whose number of virtual servers exceeds the limit of %%limit%%: %%crs%%.',
            crVsLimitExceededItem: '%%name%% has %%count%% servers',
        },
        model: 'Licensing model',
        title: 'Licensing',
        activateBtn: 'Activate License',
        refreshBtn: 'Refresh',
        key: 'License key',
        expirationDate: 'Expiration date',
        nextUpdateDate: 'Next update date',
        cpuCores: 'Number of CPU cores',
        cpuCoresProgress: '%%current%% of %%available%%',
        crProgress: '%%current%% of %%available%%',
        emptyView: {
            buttonText: 'Activate License',
            title: 'No active license found!',
            description: 'Activate a new license to unlock all product functionality',
        },
        form: {
            title: 'Activate License',
            activateBtn: 'Activate',
            activationCode: 'Activation code',
        },
        toasts: {
            refreshSuccess: 'The license has been refreshed',
            refreshFailed: 'The license refreshing failed',
            activationSuccess: 'The license has been activated',
            activationFailed: 'The license activation has been failed',
            removeSuccess: 'The license has been removed.',
            assignComputeResourcesSuccess: 'The compute resources have been updated.',
        },
        statuses: {
            active: 'Active',
            expired: 'Expired',
        },
        removeConfirmation: {
            btn: 'Remove license',
            text: 'Remove the license? You can always bring it back using the activation code.',
        },
        table: {
            micro: 'Number of Micro compute resources',
            microLimit: 'Max: 2 servers per compute resource',
            mini: 'Number of Mini compute Resources',
            miniLimit: 'Max: 5 servers per compute resource',
            standard: 'Number of Standard compute Resources',
            standardLimit: 'Unlimited number of servers per compute resource',
        },
        computeResourcesForm: {
            microLabel: 'Micro compute resources',
            miniLabel: 'Mini compute resources',
            standardLabel: 'Standard compute resources',
            selectAllBtn: 'Select All',
            updateBtn: 'Update compute resources',
            notListed: 'The following compute resources are not listed: %%crs%%. Select the following compute resources in any of the fields.',
        },
    },
    dashboard: {
        header: 'Dashboard',
        of: 'of',
        cards: {
            tasks: 'Tasks',
            computeResources: 'Compute Resources',
            servers: 'Servers',
            users: 'Users',
            ipv4: 'IPv4 usage',
            clusterMemory: 'Cluster RAM usage',
            clusterDisk: 'Cluster Disk usage',
            usage: '%%used%% of %%total%% %%unit%%',
        },
    },
    notification: {
        title: 'Notifications',
        emptyList: 'You don\'t have any Notifications',
        remove: 'Delete',
        clearAll: 'Clear all',
        viewDetails: 'View Details',
        showMore: 'Show more',
        item: {
            computeResourceStatusChange: {
                title: 'Compute Resource status changed',
                description: '%%name%% has become %%status%% %%date%% at %%time%%',
            },
            newVersionAvailable: {
                title: 'Update to version %%version%% is now available',
                description: 'Install this update as soon as possible',
            },
            versionUpdated: {
                title: 'Updated the system to version %%version%%',
                description: 'The update was installed on %%date%% at %%time%%',
            },
            backupCreated: {
                title: 'The server %%computeResourceVm%% backup was created',
                description: 'Completed %%date%% at %%time%%',
            },
            backupRestored: {
                title: 'The server %%computeResourceVm%% was restored from backup',
                description: 'Completed %%date%% at %%time%%',
            },
            incomingTrafficExceeded: {
                title: 'Incoming traffic limit excess',
                description: 'The incoming traffic limit of the server %%name%% was exceeded on %%date%% at %%time%%',
            },
            outgoingTrafficExceeded: {
                title: 'Outgoing traffic limit excess',
                description: 'The outgoing traffic limit of the server %%name%% was exceeded on %%date%% at %%time%%',
            },
            managementNodeBackupCreated: {
                title: 'Management node backup was created',
                description: 'Completed %%date%% at %%time%%',
            },
            managementNodeBackupRestored: {
                title: 'Management node backup was restored',
                description: 'Completed %%date%% at %%time%%',
            },
            managementNodeBackupRecovered: {
                title: 'One or more management node backups were recovered',
                description: 'Recovered %%mnbCount%% management node backup(s) on "%%backupNodeName%%" on %%date%% at %%time%%',
            },
        },
    },
    limitGroup: {
        emptyView: {
            buttonText: 'Create Limit Group',
            title: 'No limit groups yet.',
            description: 'Create a limit group to limit the compute resources consumption and to prevent the resources overuse.',
        },
        addBtn: 'Add Limit Group',
        title: 'Limit groups',
        toasts: {
            limitGroupDeleted: 'The limit group was deleted.',
            limitGroupSaved: 'The limit group was saved.',
        },
        form: {
            titleAdd: 'Add Limit Group',
            titleEdit: 'Edit Limit Group',
            name: 'Name',
            vms: 'Servers',
            runningVms: 'Running servers',
            additionalIps: 'Additional IPs',
            saveBtn: 'Save',
        },
        list: {
            id: 'ID',
            name: 'Name',
            servers: 'Servers',
            users: 'Users',
            running: 'Running',
            additionalIps: 'Additional IPs',
        },
        buttonWithConfirmation: {
            title: 'Delete the limit group',
            button: 'Delete',
            tooltip: 'Delete limit group',
            confirmationText: 'The limit group will be permanently deleted.',
        },
        limit: {
            limit: 'Limit',
            unlimited: 'Unlimited',
        },
        tooltip: {
            edit: 'Edit limit group',
        },
    },
    auth: {
        login: 'Log in',
        loginBtn: 'Log In',
        notRegisteredMessage: 'Not registered yet?',
        password: 'Password',
        registerLink: 'Register!',
        title: 'Login form',
        authorizing: 'Authorizing...',
    },
    externalIntegration: {
        title: 'External Licensing Integrations',
        createBtn: 'Add Integration',
        emptyView: {
            buttonText: 'Add External Licensing Integration',
            title: 'No external licensing integrations yet.',
            description: 'Go ahead and create your first external licensing integration now - it only takes a few seconds.',
        },
        list: {
            id: 'ID',
            name: 'Name',
            type: 'Type',
            countServers: 'Number of servers',
        },
        actions: {
            delete: {
                title: 'Delete External Licensing Integration',
                button: 'Delete',
                tooltip: {
                    enabled: 'Delete the external licensing integration.',
                    disabled: 'You cannot delete External Integration while it has related Applications or Servers.',
                },
            },
        },
        types: {
            plesk: 'Plesk KA',
            cPanel: 'cPanel Manage2',
        },
        form: {
            name: 'Name',
            type: 'Type',
            credentials: {
                username: 'Username',
                password: 'Password',
                plesk: {
                    productConstant: 'Product Constant',
                    productConstantDescription: 'The product constant defines which product license will be installed. %%url%%.',
                    seeAllConstants: 'See all available Plesk constants',
                },
                cPanel: {
                    packageId: 'Package ID',
                    packageIdLc: 'package ID',
                    packageIdDescription: 'The %%url%% defines which product license will be installed.',
                    groupId: 'Group ID',
                    groupIdLc: 'group ID',
                    groupIdDescription: 'The %%url%% defines the group a product license will be assigned to.',
                },
            },
            saveBtn: 'Save',
        },
        dialog: {
            titleAdd: 'Add External Licensing Integration',
            titleEdit: 'Edit External Licensing Integration',
        },
        toasts: {
            deleted: 'The External Licensing Integration was deleted.',
            externalIntegrationSaved: 'The External Licensing Integration was saved.',
        },
    },
    help: {
        title: 'Help',
        description: '',
        apiDocumentationUrl: 'Documentation link',
        apiDocSection: 'API integration',
        apiDocumentationDescription: 'Learn how to integrate with Solus using our RESTful API.',
    },
    settings: {
        title: 'General settings',
        description: 'Here you can set up Solus.',
        hostname: 'Hostname',
        hostnameDescription: 'After the hostname is changed, the service will reboot automatically for the changes to take effect. To access the server after that, use the new hostname. Make sure you don\'t have any compute resources being installed or they\'ll fail.',
        sendStatistic: 'Help improve Solus by sending feature usage information',
        titles: {
            mail: 'Mail Settings',
            userArea: 'User Area settings',
            computeResource: 'Compute Resource Settings',
            dns: 'DNS Settings',
            networkRules: 'Network rules',
            notifications: 'Notifications',
            update: 'System update settings',
            billingIntegration: 'Billing Integration Settings',
        },
        networkRules: {
            arp: 'Enable ARP flood protection',
            dhcp: 'Disable DHCP traffic',
            cloudInit: 'Disable cloud-init server access',
            smtp: 'Disable SMTP traffic',
            icmp: 'Disable ICMP traffic',
            icmpReply: 'Disable ICMP reply traffic',
            portmapper: 'Disable RPC Portmapper ports',
        },
        notifications : {
            tabs: {
                email: 'Email',
            },
            table: {
                event: 'Email Event',
                send: 'Send notification',
                custom: 'Custom template',
            },
            variables: {
                hostname: 'The server hostname',
                username: 'A server login',
                password: 'A server password',
                email: 'A user\'s email address',
                ipv4: 'The assigned IPv4 list',
                ipv6: 'The assigned IPv6 list',
                passwordResetUrl: 'A password reset URL',
                urlExpiresIn: 'A URL expiration time in hours',
                verifyEmailUrl: 'Verify email URL',
                project: 'A project name',
                projectInviteUrl: 'A user\'s project invitation URL',
            },
            editNotification: 'Edit notification',
            subjectTemplate: 'Subject template',
            bodyTemplate: 'Body template',
            overrideTemplate: 'Use custom template',
            serverCreate: 'The server has been created',
            serverResetPassword: 'The server password has been reset',
            userResetPassword: 'The user password reset confirmation has been sent',
            userVerifyEmail: 'The user email verification has been sent',
            projectUserInvite: 'The user has been invited to the project',
            projectUserLeft: 'The user has left the project',
            serverIncomingTrafficExceeded: 'The server incoming traffic has been exceeded',
            serverOutgoingTrafficExceeded: 'The server outgoing traffic has been exceeded',
            useCustomTemplateDescription: 'Selecting this option will overwrite the default email template with the custom one you specify below.',
            templatesDescription: 'The templates use the %%twig%% template engine.',
            templatesSaveDescription: 'Changes for all languages will be saved.',
            resetToDefault: 'Reset the templates to default?',
            default: 'Reset to default',
        },
        branding: {
            resetToDefaults: 'reset to default',
            logo: 'Logo',
            logoDescription: 'The maximum aspect ratio is 4:1. The image size must be 400x100 pixels or larger.',
            favicon: 'Favicon',
            title: 'Branding',
            brandName: 'Brand name',
            primaryColor: 'Primary color',
            secondaryColor: 'Secondary color',
            termsAndConditions: 'Terms and conditions URL',
        },
        features: {
            serverCreation: {
                title: 'Server Creation',
                description: 'Select the features users will not see when creating servers.',
                hidePlanSection: 'Hide plan section',
                hideLocationSection: 'Hide location section',
                hidePlanName: 'Hide plan name',
                hideUserData: 'Hide user data',
            },
            authorization: {
                title: 'Authorization',
                registration: {
                    label: 'Enable Registration',
                    description: 'Select to enable registration for new users.',
                },
                passwordRecovery: {
                    label: 'Enable Password Recovery',
                    description: 'If enabled, you and users will be able to reset your passwords.',
                },
            },
        },
        tokenApi: {
            url: 'Token API URL',
            enable: 'Enable',
            label: 'Token API',
        },
        limitGroup: {
            limitGroup: 'The default limit group for new users',
            unlimited: 'Unlimited',
        },
        registration: {
            clientRole: 'The default role for new users',
        },
        toasts: {
            settingsSaved: 'The settings were saved.',
            testEmailSent: 'A test email was sent.',
        },
        computeResource: {
            rescueImageUrl: 'Rescue Image URL',
            rescueImageUrlDescription: 'The link must lead to the rescue ISO image that supports cloud-init v2.',
            label: 'Compute Resource Settings',
            vmCreateStrategy: 'Balancing algorithm',
            vmCreateStrategyDescription: 'Defines how Solus chooses compute resources for new servers deployed within the same location',
            kvm: {
                title: 'KVM settings',
                diskCacheMode: 'Virtual server disk cache mode',
                diskCacheModeDescription: 'Defines disk cache mode for new servers.',
            },
        },
        nonExistentVmsRemover: {
            enabled: 'Automatically remove faulty servers that have the \'Unknown\' status.',
            interval: 'How often (in minutes) faulty servers will be removed',
        },
        mail: {
            title: 'Mail Settings',
            encryption: 'Use TLS',
            from: {
                email: 'From email',
                name: 'From name',
            },
            host: 'Host',
            password: 'Password',
            port: 'Port',
            username: 'Username',
            saveSettings: 'Save',
            testMail: 'Send test email',
        },
        save: 'Save',
        dns: {
            type: 'Type',
            ttl: 'TTL',
            hostname: {
                domain: 'Default hostname template for new servers',
                randomPrefix: 'Random prefix',
            },
            registerFqdnOnServerCreate: 'Create DNS records for hostnames of new servers',
            reverseDns: {
                domain: 'Default reverse DNS record template',
                hostname: 'Server hostname',
                ip: 'Server IP',
                ipReversed: 'Reversed Server IP',
                ipDashed: 'Server IP (dash-separated)',
                ipDashedReversed: 'Reversed Server IP (dash-separated)',
            },
            powerdns: {
                host: 'Host',
                apiKey: 'API key',
                port: 'Port',
                https: 'Use HTTPS',
            },
        },
        billingIntegration: {
            types: {
                none: 'None',
                custom: 'Custom billing',
                whmcs: 'WHMCS',
            },
            type: 'Type',
            whmcs: {
                url: 'URL',
                token: 'Authorization Token',
            },
        },
        update: {
            channels: 'Available source channels',
            channel: 'Source channel',
            method: 'Update Method',
            schedule: 'Update schedule',
            weekDays: 'Days of the week',
            time: 'Time',
            updateMethods: {
                auto: 'Autoupdate',
                manual: 'Manually',
            },
        },
    },
    update: {
        header: 'Updates',
        version: 'Version',
        readyToInstall: 'Ready to install',
        current: 'Current',
        install: 'Install Now',
        retry: 'Retry',
        changeSettings: 'Change Update Settings',
        changeLog: 'Change Log',
        startedAt: 'Started at',
        finishedAt: 'Finished at',
        failedToUpdate: 'Failed to Update %%count%% Nodes',
        step: {
            downloadingUpdate: 'Downloading Update',
            masterNodeUpdating: 'Updating Management Server',
            computeResourcesUpgrading: 'Updating Compute Resources',
        },
        status: {
            upToDate: 'Up to date',
            downloadingUpdate: 'Downloading Update',
            updateDownloadFailed: 'Failed to download Update',
            readyForUpdate: 'Ready for Update',
            updating: 'Updating',
            updateFailed: 'Failed to Update',
            completeWithErrors: 'Complete with errors',
        },
        changeType: {
            attention: 'attention',
            feature: 'feature',
            improvement: 'improvement',
            bugfix: 'bugfix',
        },
    },
    plan: {
        batchButtonWithConfirmation: {
            title: 'Delete plans',
            button: 'Delete',
            tooltip: 'Delete plans',
        },
        buttonWithConfirmation: {
            title: 'Delete plan',
            button: 'Delete',
            tooltip: '',
        },
        tooltip: {
            setAsDefault: 'Set the plan as default',
            edit: 'Edit the plan',
            remove: 'Delete the plan',
        },
        emptyView: {
            buttonText: 'Create Plan',
            title: 'No plans yet.',
            description: 'Time to create a plan! Plans define which servers and with what amount of resources users can deploy.',
        },
        title: 'Plans',
        removeBtn: 'Delete',
        setDefaultBtn: 'Set as default',
        addBtn: 'Add Plan',
        removeConfirmation: {
            title: 'Confirm the delete operation?',
            innerText: 'The selected data will be deleted.',
        },
        list: {
            id: 'ID',
            name: 'Name',
            disk: 'Storage, GiB',
            ram: 'Memory, MiB',
            cpu: 'Virtual CPUs',
            tokenPerHour: 'Tokens per hour',
            tokenPerMonth: 'Tokens per month',
            storageType: 'Storage type',
            imageFormat: 'Image format',
            bytes: 'Bandwidth limit',
            iops: 'IOPS limit',
            backups: 'Backups',
            snapshots: 'Snapshots',
            visibility: 'Visible',
            virtualizationType: 'Virtualization type',
            actions: {
                edit: 'Edit',
                remove: 'Delete',
            },
        },
        actionDialog: {
            applyBtn: 'Apply',
            saveBtn: 'Save',
            titleAdd: 'Add Plan',
            titleEdit: 'Edit Plan',
            createBtn: 'Create Plan',
            snapshots: 'Snapshots',
            planName: 'Name',
            planCpu: 'Virtual CPUs',
            documentation: 'Documentation.',
            planCpuUnits: 'CPU Units',
            planCpuLimits: 'CPU Limits',
            planIOPriority: 'I/O Priority',
            planSwap: 'Burstable to RAM / Swap',
            planTokenPerHour: 'Tokens per hour',
            planTokenPerMonth: 'Tokens per month',
            planCpuDescription: 'The number of virtual cores',
            planCpuUnitsDescription: 'CPU units define how much CPU time one virtual server can receive in comparison with other virtual servers on a compute resource if all the CPUs of the compute resource are fully used. Available for VZ plans only.',
            planCpuLimitDescription: 'A CPU limit indicates the maximum CPU power a virtual server can get for its running processes. Available for VZ plans only.',
            planIOPriorityDescription: 'The disk I/O priority level of a virtual server. Available for VZ plans only.',
            planTokenPerHourDescription: 'The number of tokens to calculate price per hour',
            planTokenPerMonthDescription: 'The number of tokens to calculate price per month',
            whenLimitsAreReset: 'When limits are reset',
            resetLimitPolicy: {
                firstDayOfMonth: 'On the 1st of each month',
                vmCreatedDay: 'On the date a server was created',
                never: 'No automated reset',
            },
            networkTrafficLimitType: 'Traffic limit type',
            networkTrafficLimitTypes: {
                separate: 'Separate',
                total: 'Total',
            },
            limits: {
                disk: {
                    bandwidth: 'Disk I/O bytes per second (bandwidth)',
                    iops: 'Disk I/O operations per second (IOPS)',
                },
                network: {
                    incomingBandwidth: 'Incoming network bandwidth',
                    outgoingBandwidth: 'Outgoing network bandwidth',
                    incomingTraffic: 'Incoming traffic limit (monthly)',
                    outgoingTraffic: 'Outgoing traffic limit (monthly)',
                    totalTraffic: 'Total traffic limit (monthly)',
                    reduceBandwidth: 'Reduce network bandwidth if the virtual server reached the traffic limit',
                },
                backupsNumber: {
                    label: 'Backup limit',
                    description: 'Maximum number of backup files to store (including full, incremental, scheduled, and manual backups)',
                },
            },
            planDisk: 'Storage, GiB',
            planRam: 'Memory',
            planRamUnit: 'Memory unit',
            planVirtualizationType: 'Virtualization type',
            planStorageType: 'Storage type',
            planImageFormat: 'Image format',
            default: 'Default',
            bytes: 'bytes',
            operations: 'operations',
            visibility: 'Visible',
            isBackupAvailable: 'Offer backups',
            isIncrementalBackupEnabled: 'Enable incremental backups',
            backupLimitOnly: 'The Unlimited option isn\'t available when incremental backups are enabled.',
            incrementalBackupCantBeEnabled: 'Incremental backups are only available for the ThinLVM storage type with a set backup limit.',
            incrementalCount: 'Number of incremental backups for each full backup',
            backupPrice: 'Backup price in %',
            backupPriceDescription: 'Percentage of server price charged additionally for enabling backups',
            isAdditionalIpsAvailable: 'Offer additional IP addresses',
            ipTokensPerMonth: 'Tokens per month',
            ipTokensPerHour: 'Tokens per hour',
            diskLimits: 'Disk limits',
            networkLimits: 'Network limits',
            additionalOffers: 'Additional offers',
            availableEntities: {
                locations: 'Locations',
                addAllLocations: 'Add all locations',
                osImageVersions: 'Operating systems',
                addAllOsImageVersions: 'Add all compatible operating systems',
                applications: 'Applications',
                addAllApplications: 'Add all compatible applications',
            },
            vzParameters: {
                title: 'VZ Parameters',
                isEditable: 'Allow users to change the setting per virtual server',
            },
        },
        validation: {
            ramMin: 'Provide a number starting from %%min%%',
            diskMin: 'Provide a number starting from %%min%%',
            cpuMinMax: 'Provide a number between %%min%% and %%max%%',
            tokenMin: 'Provide a positive number',
            totalBytesMin: 'Provide a number starting from %%min%%',
            totalIopsMin: 'Provide a number starting from %%min%%',
            backupPricePercent: 'Provide a valid percentage.',
        },
        toasts: {
            planSaved: 'The plan was saved.',
            planDeleted: 'The plan was deleted.',
            plansBatchDeleted: '%%count%% plans were deleted.',
        },
    },
    role: {
        emptyView: {
            buttonText: 'Create Role',
            title: 'No roles yet.',
            description: 'To create users with various permissions, create roles first. Permissions are contained within roles that you then assign when creating users.',
        },
        title: 'Roles',
        addBtn: 'Add Role',
        buttonWithConfirmation: {
            title: 'Delete the role?',
            confirmationText: 'The role will be permanently deleted.',
            button: 'Delete role',
            tooltip: 'Delete role',
        },
        list: {
            id: 'ID',
            name: 'Name',
            users: 'Users',
            actions: {
                edit: 'Edit',
                remove: 'Delete',
            },
        },
        form: {
            titleAdd: 'Add role',
            titleEdit: 'Edit role',
            saveBtn: 'Save',
            name: 'Name',
            permissions: {
                name: 'Permissions',
            },
        },
        toasts: {
            roleSaved: 'The role was saved.',
            roleDeleted: 'The role was deleted.',
        },
        tooltip: {
            edit: 'Edit role',
        },
    },
    user: {
        emptyView: {
            buttonText: 'Create User',
            title: 'No users yet.',
            description: 'You may want to have users with various permissions. To get this, create users and assign them roles, which contain a set of permissions.',
        },
        title: 'Users',
        addBtn: 'Add User',
        list: {
            id: 'ID',
            language: 'Language',
            name: 'Name',
            email: 'Email',
            verified: 'Email Verified',
            status: 'Status',
            role: 'Roles',
            limitGroup: 'Limit Group',
            servers: 'Created Servers',
            running: 'Running Servers',
            additionalIps: 'Additional IPs',
            created: 'Created',
        },
        limitsList: {
            name: 'Name',
            used: 'Used',
            limit: 'Limit',
            unlimited: 'Unlimited',
        },
        form: {
            titleAdd: 'Add User',
            titleEdit: 'Edit User',
            saveBtn: 'Save',
            limitGroupUnlimited: 'Unlimited',
            limitGroup: 'Limit Group',
            roles: 'Role',
            status: 'Status',
            userName: 'User name',
            language: 'Language',
            email: 'Email',
            password: 'Password',
            billingUserId: 'Billing User ID',
            billingToken: 'Billing Token',
            passwordDescription: 'From 8 to 30 characters, 1 letter and 1 number',
            allowedIPs: 'Allowed IPs',
        },
        toasts: {
            userSaved: 'The user was saved.',
            userDeleted: 'The user was deleted.',
            batchDeleted: '%%count%% users were deleted.',
            userDeleteError: 'An error occurred while deleting the user.',
        },
        buttonWithConfirmation: {
            title: 'Delete the user?',
            button: 'Delete',
            confirmationText: 'Delete the user with the %%email%% email? The user has %%count%% assigned servers. Deleting the user will also irrevocably delete the servers.',
            label: 'Enter the user email to confirm its deletion',
            tooltips: {
                enabled: 'Delete user',
                disabled: {
                    mainAccount: 'The main user can\'t be deleted.',
                },
            },
        },
        batchDelete: 'Delete',
        confirmationBatchRemove: {
            tooltip: 'Delete users',
            title: 'Delete selected users',
            text: '%%userCount%% selected user(s) who do not have servers will be deleted',
            label: 'Type \'%%confirmation%%\' to confirm deleting users',
            forceLabel: 'Delete all the selected users (%%userCount%%) and %%serverCount%% server(s)',
            button: 'Delete',
        },
        filters: {
            status: 'Status',
            role: 'Role',
            limitGroup: 'Limit Group',
        },
        info: {
            role: {
                title: 'Role: %%name%%',
                empty: 'This role do not have any permissions',
            },
            limitGroup: {
                title: 'Limit group: %%name%%',
            },
        },
        usage: '%%current%% of %%max%%',
        usageUnlimited: '%%current%% of Unlimited',
        tooltips: {
            edit: 'Edit',
        },
        status: {
            active: 'Active',
            locked: 'Locked',
            suspended: 'Suspended',
        },
    },
    task: {
        emptyView: {
            title: 'The tasks were not found.',
            description: 'Change the search criteria.',
        },
        filters: {
            actions: 'Actions',
            status: 'Status',
            computeResource: 'Compute Resource',
        },
        dialogTitle: 'Task: %%task%%',
        cancelPopover: {
            tooltip: 'Cancel task',
            title: 'Cancel Task',
            button: 'Cancel',
        },
        tooltip: {
            retry: 'Retry the current task.',
        },
        title: 'Tasks',
        cancelSuccess: 'The task was canceled.',
        retrySuccess: 'The task was retried.',
        cancelConfirmation: {
            title: 'Cancel the task?',
            innerText: 'The task will be permanently canceled.',
        },
        list: {
            id: 'ID',
            queue: 'Action',
            computeResource: 'Compute Resource',
            user: 'User',
            serverId: 'Server ID',
            serverUuid: 'Server UUID',
            status: 'Status',
            duration: 'Duration, min',
            progress: 'Progress',
            created: 'Started',
            finished: 'Finished',
        },
        status: {
            notStarted: 'Not started',
            pending: 'Pending',
            queued: 'Queued',
            running: 'Running',
            canceled: 'Canceled',
            done: 'Done',
            failed: 'Failed',
        },
    },
    computeResource: {
        emptyView: {
            buttonText: 'Create Compute Resource',
            title: 'No compute resources yet.',
            description: 'It all starts here. Create your first compute resource to provide CPU, RAM, and disk space for users so that they could create servers.',
        },
        actions: 'Actions',
        addBtn: 'Add Compute Resource',
        createVm: 'Add Server',
        itemCreateFail: 'Failed to create a resource.',
        itemCreateSuccess: 'The compute resource was created.',
        itemUpdateSuccess: 'The compute resource was updated.',
        itemUpdateFail: 'Failed to update the compute resource.',
        itemRetrySuccess: 'Retrying agent installation process',
        itemRetryFail: 'Retry install failed',
        itemDeleteSuccess: 'The compute resource was deleted.',
        title: 'Compute Resources',
        listLoadSuccess: 'Failed to load the compute resources.',
        list: {
            id: 'ID',
            name: 'Name',
            status: 'Status',
            host: 'Host Address',
            port: 'Port',
            vmCount: 'Servers',
            locations: 'Locations',
            version: 'Version',
            virtualizationTypes: 'Virtualization types',
            isLocked: 'Locked',
        },
        storage: {
            removeConfirmation: {
                title: 'Delete the storage?',
                innerText: 'The storage will be permanently deleted.',
            },
            updateSuccess: 'The compute resource storage was updated.',
            updateFail: 'Failed to update the compute resource storage.',
            deletedSuccessfully: 'The storage was deleted.',
            mountedSuccessfully: 'The storage was mounted',
            unmountedSuccessfully: 'The storage was unmounted',
        },
        buttonWithConfirmation: {
            title: 'Delete Compute Resource',
            button: 'Delete',
            tooltip: 'Delete Compute Resource',
        },
        actionDialog: {
            createBtn: 'Save',
            updateBtn: 'Save',
            retryBtn: 'Retry installation',
            processingBtn: 'Processing',
            editBtn: 'Edit',
            closeBtn: 'Close',
            createSuccess: 'The compute resource was added.',
            titleAdd: 'Add Compute Resource',
            titleEdit: 'Edit Compute Resource',
            titleView: 'Compute resource',
            host: 'Host Address',
            hostDescription: 'IP Address or Domain Name',
            name: 'Name',
            login: 'SSH Login',
            agentPort: 'Agent Port',
            sshPort: 'SSH Port',
            sshKey: 'SSH Private Key',
            password: 'Password',
            passwordDescription: 'From 8 to 30 characters, 1 letter and 1 number',
            locations: 'Locations',
            ipBlocks: 'IP Block',
            title: 'Fill in compute resource details',
            ipv6NotAvailable: 'IPv6 isn\'t available on this Compute Resource.',
            authType: 'Auth Type',
            sshGenerate: {
                tooltip: 'Generate new SSH key pair',
                title: 'Generate a new key pair',
            },
            network: {
                networkConfiguringStarted: 'The network configuring was started.',
                networks: 'Select network interface',
                configure: 'Configure Network',
                confirmTitle: 'Configure network?',
                confirmText: 'Warning: You run the risk of permanently losing network access to the server. Proceed only if you have physical access to the server, access to the server’s serial console, or 24/7/365 server support.',
            },
            oneTimePair: {
                type: 'One-time SSH Keys',
                generating: 'Generating SSH keys. Please wait.',
                help: 'To set up the public key, copy the script below, connect to the compute resource server via SSH, and run the copied script.',
                validTo: 'One-time keys are valid for:',
                failedToGenerate: 'Failed to generate one-time SSH keys.',
            },
            licenseType: {
                label: 'License type',
                description: 'The license type imposes limitations on the number of virtual servers a compute resource can have. The maximum number of servers for the license type: \'Micro\' - %%micro%%, \'Mini\' - %%mini%%, \'Standard\' - %%standard%%.',
                standardLimit: 'Unlimited',
            },
            isLocked: 'Locked',
        },
        computeResourceInstallSteps: {
            title: 'Installing agent',
        },
        servers: {
            addBtn: 'Add Server',
            editBtn: 'Edit Server',
            emptyView: {
                buttonText: 'Add Server',
                title: 'You don\'t have any servers yet.',
                description: 'Go ahead and create your first Server now - it only takes a few seconds.',
            },
            batchActions: {
                restart: 'Restart',
                batchRestartPopover: {
                    title: 'Restart servers',
                    button: 'Restart',
                    tooltip: 'Restart servers',
                },
                start: 'Start',
                batchStartPopover: {
                    title: 'Start servers',
                    button: 'Start',
                    tooltip: 'Start servers',
                },
                stop: 'Stop',
                batchStopPopover: {
                    title: 'Stop servers',
                    button: 'Stop',
                    tooltip: 'Stop servers',
                },
                migrate: 'Migrate',
                migrateTooltip: {
                    vzIsNotSupported: 'Migration of VZ virtual servers is not supported.',
                },
                delete: 'Delete',
                batchDeletePopover: {
                    title: 'Delete servers',
                    text: 'Do you really want to delete those servers? Backups and Snapshots will be deleted as well.',
                    button: 'Delete',
                    tooltip: 'Delete servers',
                },
                restarted: '%%count%% servers were restarted.',
                started: '%%count%% servers were started.',
                stopped: '%%count%% servers were stopped.',
                deleted: '%%count%% servers were deleted.',
                deleteConfirmation: {
                    title: 'Delete Selected Servers',
                    text: 'Do you really want to delete %%count%% server(s)? All their backups and snapshots will be deleted as well.',
                    label: 'Type \'%%confirmation%%\' to confirm deletion of selected servers',
                },
            },
            removeConfirmation: {
                title: 'Delete the server?',
                innerText: 'The server will be permanently deleted.',
            },
            list: {
                id: 'ID',
                name: 'Hostname',
                status: 'Status',
                ip: 'IP',
                computeResource: 'Compute Resource',
                hypervisorId: 'Hypervisor ID',
                cpu: 'CPU',
                owner: 'Owner',
                created: 'Created',
                info: {
                    virtualizationType: 'Virtualization type',
                    osImage: 'OS Image',
                    ipBlock: 'IP Blocks',
                    macAddress: 'MAC address',
                    project: 'Project',
                    plan: 'Plan',
                    outgoingTraffic: 'Outgoing traffic',
                    incomingTraffic: 'Incoming traffic',
                    totalTraffic: 'Total traffic',
                    resetTraffic: 'Reset traffic',
                },
            },
            form: {
                title: 'Add Server',
                titleEdit: 'Edit Server',
                name: 'Hostname',
                nameDescription: 'The hostname will change the next time the server is restarted.',
                tooltip: 'This action is not supported yet for the imported %%product%% server.',
                description: 'Description',
                osImageVersion: 'OS Image version',
                computeResource: 'Compute Resource',
                ipBlocks: 'IP Block',
                plans: 'Plans',
                password: 'Password',
                sshKeys: 'SSH Keys',
                saveBtn: 'Save',
                createBtn: 'Add',
                user: 'User',
                project: 'Project',
                additionalIpCount: 'Additional IPv4 count',
            },
            statuses: {
                started: 'Started',
                stopped: 'Stopped',
                unavailable: 'Unavailable',
            },
            itemLoadFail: 'Failed to load the compute resource\'s server.',
            itemCreateFail: 'Failed to create a server.',
            itemCreateSuccess: 'The server was created.',
            itemReinstallSuccess: 'The server was reinstalled.',
            vnc: 'Open VNC window',
            start: 'Start server',
            stop: 'Stop server',
            buttonWithConfirmation: {
                title: 'Delete server',
                label: 'Enter the server name to confirm its deletion',
                text: 'Do you really want to delete %%name%% server? Backups and snapshots will be deleted as well.',
                button: 'Delete',
                tooltip: 'Delete the server',
            },
            restartPopover: {
                title: 'Restart server',
                button: 'Restart',
                tooltip: 'Restart the server',
            },
            stopPopover: {
                title: 'Stop server',
                button: 'Stop',
                tooltip: 'Stop the server',
            },
            powerOffPopover: {
                title: 'Power Off Server',
                button: 'Power Off',
                text: 'Powering Off a server removes the power instantly and could potentially cause data loss. Would you like to continue?',
                tooltip: 'Power Off',
            },
            migrationForm: {
                title: 'Migration',
                documentationLinkTitle: 'Documentation',
                totalServers: 'Selected servers',
                totalMemory: 'Total Memory',
                totalDisk: 'Total Disk',
                destination: 'Destination Compute Resource',
                live: 'Live migrate',
                preserveIPs: 'Preserve original IP',
                submit: 'Start',
                errors: 'Errors',
            },
            confirmationPopover: {
                cancel: 'Cancel',
                notConfirmed: 'Strings do not match.',
            },
            toasts: {
                serverUpdated: 'Server successfully updated',
            },
        },
        tasks: {
            title: 'Tasks',
        },
        settings: {
            settingsSaveFail: 'Failed to save the compute resource settings.',
            settingsSaveSuccess: 'The compute resource settings were saved.',
            title: 'Settings',
            form: {
                title: 'Settings',
                cachePath: 'Cache path',
                backupTmpPath: 'Path to temporary backup directory',
                isoPath: 'Path to Cloud-init ISO files',
                vncProxyPort: 'VNC proxy port',
                balanceStrategy: 'Storage Balancing Algorithm',
                balanceStrategyDescription: 'Defines how Solus chooses compute resource\'s storage for new servers deployed within the same storage type.',
                limits: {
                    title: 'Usage limits',
                    description: '%%used%% in use',
                    unlimited: 'unlimited',
                    vm: 'Servers',
                    hdd: 'Storage',
                    ram: 'Virtual memory',
                    vcpu: 'Virtual CPUs',
                    gib: 'GiB',
                    units: 'units',
                },
                network: {
                    fullDescription: '%%link%%. You can change a network type later in the compute resource settings.',
                    fullDescriptionLink: 'Learn more about network types',
                    description: 'It\'s not possible to change the network type if at least one server exists.',
                    type: 'Network Type',
                    vzRoutedIsNotAllowed: 'The "vz routed" network can only be used for imported VZ compute resources.',
                    vzRoutedIsOnlyAllowed: 'Only the "vz routed" network can be used for imported VZ compute resources.',
                },
                virtualizationTypes: {
                    title: 'Virtualization types',
                    tooltip: 'It\'s not possible to choose another virtualization type because the compute resource supports only the selected one.',
                },
                kvm: {
                    title: 'KVM settings',
                    diskCacheMode: 'Virtual server disk cache mode',
                    diskCacheModeDescription: 'Defines disk cache mode for new servers. If not specified then global setting is applied.',
                },
                saveBtn: 'Save',
            },
        },
        metrics: {
            ipv6Available: 'IPv6 is available',
            ipv6NotAvailable: 'IPv6 isn\'t available',
        },
        storages: {
            emptyView: {
                title: 'No storage points yet.',
                description: 'Go ahead and create your first storage now - it only takes a few seconds.',
            },
            addBtn: 'Add Storage',
            itemCreateFail: 'Failed to create a storage.',
            itemCreateSuccess: 'A new storage was created.',
            colId: 'ID',
            colType: 'Type',
            colPath: 'Path',
            colAvailableForBalancing: 'Use for New Servers',
            colFreeSpace: 'Free Space, GiB',
            title: 'Storages',
            buttonWithConfirmation: {
                title: 'Delete Storage',
                button: 'Delete',
                tooltip: 'Delete the storage',
                tooltipServerExists: 'It\'s not possible to delete the storage if at least one server exists.',
                tooltipVZStorage: 'It\'s not possible to delete the VZ containers storage.',
            },
            form: {
                title: 'Add Compute Resource Storage',
                createBtn: 'Save',
                type: 'Type',
                path: 'Path',
                volume: 'Volume',
                availableForBalancing: 'Use for New Servers',
            },
            selectors: {
                lvm: 'Select LVM Storage',
                nfs: 'Select NFS Storage',
                thinLvm: 'Select ThinLVM Storage',
            },
            availableForBalancing: {
                allow: 'Allow',
                forbid: 'Forbid',
            },
            tooltip: {
                useForNewServersDisabled: 'You can manage this option in the storage section.',
                vzStorageAlreadyExists: 'The VZ containers storage already exists.',
                vzIsNotSupportedByCR: 'The compute resource doesn\'t support the VZ virtualization type.',
            },
        },
        removeConfirmation: {
            title: 'Delete the compute resource?',
            innerText: 'The compute resource will be permanently deleted.',
            innerTextCannotDelete: 'Delete all associated servers before deleting a compute resource.',
        },
        statuses: {
            configureNetwork: 'Configure Network',
            configuringNetwork: 'Configuring Network',
            commissioning: 'Commissioning',
            active: 'Active',
            failed: 'Failed',
            unavailable: 'Unavailable',
        },
        tooltip: {
            details: 'View details',
        },
        page: {
            title: 'Compute Resource %%name%%',
            tabs: {
                servers: 'Servers',
                storages: 'Storage',
                tasks: 'Tasks',
                usageAndLimits: 'Usage',
            },
        },
        usageAndLimits: {
            allocatedUsage: 'Allocated resources',
            physicalUsage: 'Physical usage',
            virtualCPU: 'Virtual CPUs',
            virtualMemory: 'Virtual Memory',
            storage: 'Storage',
            addBtn: 'Set Limits',
            servers: 'Servers',
            cpu: 'cPUs',
            disk: 'Disk',
            memory: 'Memory',
            of: 'of',
            unlimited: 'Unlimited',
            diskTooltip: 'Disk usage of root partition.',
        },
    },
    computeResourceVm: {
        header: 'Virtual Servers',
        hypervisorId: 'Hypervisor ID',
        macAddress: 'MAC Address',
        create: 'Add Server',
        createBtn: 'Add Server',
        tabs: {
            overview: 'Overview',
        },
        settingsTab: {
            title: 'Settings',
        },
        rescueTab: {
            title: 'Rescue',
        },
        tasksTab: {
            title: 'Tasks',
        },
        snapshotsTab: {
            title: 'Snapshots',
        },
        graphTab: {
            title: 'Graph',
        },
        backupsTab: {
            title: 'Backups',
        },
        networkingTab: {
            title: 'Networking',
        },
        backups: {
            createBtn: {
                title: 'Create Backup',
                text: 'The new manual backup task will be added to the queue and will be processed as long as the backup node is ready to handle the backup.',
                button: 'Create',
                tooltip: 'Create Backup',
                disabledInRescueModeTooltip: 'You can\'t back up a server in Rescue Mode.',
            },
            toasters: {
                createSuccess: 'The server %%computeResourceVm%% backup was created.',
                createFail: 'Could not create the server backup.',
            },
        },
        reinstallTab: {
            title: 'Reinstall',
        },
        resizeTab: {
            title: 'Resize',
        },
        locationList: {
            noComputeResources: 'This location has no compute resources.',
        },
    },
    computeResourceVms: {
        serversTab: {
            title: 'Servers',
        },
        migrationsTab: {
            title: 'Migrations',
        },
    },
    osImage: {
        emptyView: {
            buttonText: 'Add Operating System',
            title: 'You don\'t have any Operating Systems yet.',
            description: 'Go ahead and create your first Operating System now - it only takes a few seconds.',
        },
        title: 'Operating Systems',
        addBtn: 'Add Operating System',
        addVersion: '+ Add version',
        editVersions: 'Edit versions',
        saveVersions: 'Save versions',
        removeBtn: 'Delete',
        setDefaultBtn: 'Set as default',
        tooltip: {
            setAsDefault: 'Set Operating System as default',
            edit: 'Edit Operating System',
        },
        batchButtonWithConfirmation: {
            tooltip: 'Delete Operating Systems',
            title: 'Delete Operating Systems',
            button: 'Delete',
        },
        list: {
            id: 'ID',
            name: 'Name',
            versions: 'Versions',
            add: 'Add version',
            visibility: 'Visible',
            actions: {
                edit: 'Edit',
                remove: 'Delete',
            },
        },
        form: {
            name: 'Name',
            description: 'Description',
            icon: 'Icon',
            titleAdd: 'Add Operating System',
            titleEdit: 'Edit Operating System',
            saveBtn: 'Save',
            id: 'ID',
            visibility: 'Visible',
        },
        toasts: {
            osImageDeleted: 'Operating System successfully deleted',
            osImageSaved: 'Operating System successfully saved',
            osImageBatchDeleted: '%%count%% images successfully deleted',
        },
        buttonWithConfirmation: {
            title: 'Delete Operating System',
            button: 'Delete',
            tooltip: 'Delete Operating System',
        },
    },
    osImageVersion: {
        id: 'ID',
        version: 'Name',
        cloudInitVersion: 'Cloud-init version',
        virtualizationType: 'Virtualization type',
        availablePlans: 'Plans',
        addAllPlans: 'Add all compatible plans',
        url: 'URL',
        template: 'Template',
        visibility: 'Visible',
        saveBtn: 'Save',
        titleAdd: 'Add Operating System Version',
        titleEdit: 'Edit Version: %%version%%',
        itemCreateSuccess: 'The OS version was created',
        tooltip: {
            download: 'Download Operating System Image',
            edit: 'Edit Operating System Version',
            remove: 'Delete Operating System Version',
        },
        buttonWithConfirmation: {
            title: 'Delete Operating System Version',
            button: 'Delete',
            tooltip: 'Delete Operating System Version',
        },
    },
    application: {
        loginLink: {
            type: {
                'none': 'None',
                'url': 'URL',
                'jsCode': 'Script (JS)',
                'info': 'Info (HTML)',
            },
            help: {
                jsCode: {
                    btn: 'More info about JS',
                    title: 'Information about JS Code',
                    description: 'You can specify valid JS code here. It will be executed as it is. On caniuse.com, you can check if JS features in the script are available. The script’s maximum execution time is 30 seconds. The script must call end() or openWindow() at the end of execution.',
                    symbols: {
                        openWindow: 'This function acts the same way as `window.open` but accepts only location.',
                        end: 'Informs that the script is finished.',
                    },
                },
            },
        },
        variables: {
            btnInfo: 'System Variables',
            title: 'Available system variables',
            hostname: 'Server hostname',
            ip: 'Server IP',
            uuid: 'Server UUID',
            address: 'The first available FQDN (if any). Otherwise, the IP.',
            fqdns: 'Array of server FQDN',
            plesk: {
                externalIntegrationResponse: 'A response from the license server to Plesk installation',
                activationCode: 'A Plesk license activation code',
            },
        },
        prefilledProperties: {
            btnInfo: 'Prefilled Fields',
            title: 'Prefilled Fields',
            description: 'The following fields in the application form will be prefilled:',
            email: 'user email',
            domain: 'server hostname',
            password: 'randomly generated password',
        },
        batchButtonWithConfirmation: {
            tooltip: 'Delete the Applications',
            title: 'Delete Applications',
            button: 'Delete',
        },
        buttonWithConfirmation: {
            title: 'Delete Application',
            button: 'Delete',
        },
        tooltip: {
            setAsDefault: 'Set the application as default.',
            edit: 'Edit the application.',
            remove: {
                enabled: 'Delete the application.',
                disabled: 'You cannot delete a built-in application.',
            },
            download: 'Download application',
        },
        emptyView: {
            buttonText: 'Add Application',
            title: 'No applications yet.',
            description: 'Go ahead and create your first Application now - it only takes a few seconds.',
        },
        title: 'Applications',
        addBtn: 'Add Application',
        removeBtn: 'Delete',
        setDefaultBtn: 'Set as default',
        list: {
            id: 'ID',
            name: 'Name',
            cloudInitVersion: 'Cloud-init Version',
            icon: 'Icon',
            url: 'URL',
            visibility: 'Visible',
            actions: {
                edit: 'Edit',
                remove: 'Delete',
            },
        },
        form: {
            name: 'Name',
            icon: 'Icon',
            selectionIcon: 'Select Icon',
            user_data_template: 'User data template',
            cloud_init_version: 'Cloud-init version',
            externalIntegration: 'External Licensing Integration',
            loginLink: {
                type: 'Login link Type',
                url: 'Login link URL',
                jsCode: 'Login link script',
                info: 'Login link info',
            },
            json_schema: 'JSON schema',
            url: 'Application image URL',
            visibility: 'Visible',
            availablePlans: 'Plans',
            addAllPlans: 'Add all compatible plans',
            titleAdd: 'Add Application',
            titleEdit: 'Edit Application',
            saveBtn: 'Save',
            buildin_notice: 'You cannot edit a built-in application.',
        },
        toasts: {
            applicationDeleted: 'The application was deleted.',
            applicationSaved: 'The application was saved.',
            applicationBatchDeleted: 'The applications were deleted',
        },
        removeConfirmation: {
            title: 'Delete the application?',
            innerText: 'The application will be permanently deleted.',
        },
    },
    apiToken: {
        title: 'API Tokens',
        addNewToken: 'Generate API Token',
        generateBtn: 'Generate',
        copyAndClose: 'Copy & Close',
        tokenAdded: 'API Token was added',
        tokenAddedText: 'Copy and securely store your API token. It won\'t be shown again for security reasons.',
        list: {
            name: 'Name',
            user: 'User',
            created: 'Created',
        },
        confirmationRemove: {
            text: 'Do you really want to remove this API Token?',
            button: 'Remove',
        },
        emptyView: {
            title: 'You don\'t have any API tokens yet.',
        },
    },
    icon: {
        title: 'Icons',
        addBtn: 'Add Icon',
        selectIcon: 'Select icon',
        type: 'Type',
        list: {
            id: 'ID',
            name: 'Name',
            type: 'Type',
        },
        form: {
            name: 'Name',
            url: 'URL',
            type: 'Icon Type',
            saveBtn: 'Save',
        },
        dialog: {
            createTitle: 'Create Icon',
            editTitle: 'Edit Icon',
        },
        removePopover: {
            title: 'Delete Icon',
            button: 'Delete',
            tooltip: 'Delete Icon',
        },
        tooltip: {
            edit: 'Edit Icon',
        },
        toasts: {
            iconDeleted: 'Icon successfully deleted',
            iconSaved: 'Icon successfully saved',
        },
        emptyView: {
            buttonText: 'Add Icon',
            title: 'No icons added yet.',
            description: 'Go ahead and create your first Icon now - it only takes a few seconds.',
        },
        types: {
            os: 'Operating System',
            application: 'Application',
            location: 'Location',
        },
    },
    storage: {
        buttonWithConfirmation: {
            title: 'Delete Storage',
            button: 'Delete',
            tooltip: 'Delete Storage',
        },
        tooltip: {
            edit: 'Edit the storage.',
            remove: 'Delete the storage.',
        },
        emptyView: {
            buttonText: 'Create Storage',
            title: 'No storage points yet.',
            description: 'Go ahead and create your first storage now - it only takes a few seconds.',
        },
        title: 'Storage',
        addBtn: 'Add Storage',
        removeBtn: 'Delete',
        setDefaultBtn: 'Set as default',
        list: {
            id: 'ID',
            availableForBalancing: 'Use For New Servers',
            name: 'Name',
            servers: 'Servers',
            crs: 'Compute Resources',
            type: 'Storage Type',
            actions: {
                edit: 'Edit',
                remove: 'Delete',
            },
        },
        form: {
            name: 'Name',
            titleAdd: 'Add Storage',
            titleEdit: 'Edit Storage',
            saveBtn: 'Save',
            availableForBalancing: 'Use for New Servers',
            mountPoint: 'Mount Point',
            nfs: {
                remotePath: 'Remote Path',
                options: 'Options',
            },
        },
        toasts: {
            storageDeleted: 'The storage was deleted.',
            storageSaved: 'The storage was saved.',
        },
        removeConfirmation: {
            title: 'Delete the storage?',
            innerText: 'The storage will be permanently deleted.',
        },
    },
    location: {
        buttonWithConfirmation: {
            title: 'Delete Location',
            button: 'Delete',
            tooltip: 'Delete Location',
        },
        tooltip: {
            setAsDefault: 'Set the location as default.',
            edit: 'Edit the location.',
            remove: 'Delete the location.',
        },
        emptyView: {
            buttonText: 'Create Location',
            title: 'No locations yet.',
            description: 'Create the first shared storage.',
        },
        default: 'Default',
        title: 'Locations',
        addBtn: 'Add Location',
        removeBtn: 'Delete',
        setDefaultBtn: 'Set as default',
        list: {
            id: 'ID',
            name: 'Name',
            description: 'Description',
            icon: 'Icon',
            visibility: 'Visible',
            actions: {
                edit: 'Edit',
                remove: 'Delete',
            },
        },
        form: {
            visibility: 'Visible',
            name: 'Name',
            icon: 'Icon',
            description: 'Description',
            computeResources: 'Compute Resources',
            availablePlans: 'Plans',
            addAllPlans: 'Add all plans',
            titleAdd: 'Add Location',
            titleEdit: 'Edit Location',
            saveBtn: 'Save',
        },
        toasts: {
            locationDeleted: 'The location was deleted.',
            locationSaved: 'The location was saved.',
        },
        removeConfirmation: {
            title: 'Delete the location?',
            innerText: 'The location will be permanently deleted.',
        },
    },
    sshKey: {
        emptyView: {
            buttonText: 'Add SSH Key',
            title: 'No SSH keys yet.',
            description: 'To log in to a server using public key authentication, create an SSH key.',
        },
        title: 'SSH Keys',
        addBtn: 'Add SSH Key',
        toasts: {
            itemDeleted: 'The SSH key was deleted.',
            itemSaved: 'The SSH key was saved.',
        },
        form: {
            saveBtn: 'Save',
            titleAdd: 'Add SSH Key',
            name: 'Name',
            body: 'Public key',
            generate: {
                title: 'Generate a new key pair',
                tooltip: 'Your key pair will be saved and downloaded.',
            },
        },
        list: {
            id: 'ID',
            name: 'Name',
            fingerprint: 'Fingerprint',
            actions: {
                remove: 'delete',
            },
        },
        buttonWithConfirmation: {
            title: 'Delete the SSH Key?',
            confirmationText: 'The SSH key will be permanently deleted.',
            button: 'Delete',
            tooltip: 'Delete SSH Key',
        },
    },
    ipBlock: {
        emptyView: {
            buttonText: 'Create IP Block',
            title: 'No IP blocks yet.',
            description: 'Even a toaster can have an IP address let alone a server. Create an IP block: a range of IP addresses from which servers will take their IPs.',
        },
        title: 'IP Addresses',
        addBtn: 'Add IP Block',
        removeBtn: 'Delete',
        listTypeRange: 'Range',
        listTypeSet: 'Set',
        list: {
            id: 'ID',
            name: 'Name',
            type: 'Type',
            listType: 'List type',
            totalIps: 'Total IPs',
            reservedIps: 'Reserved IPs',
            actions: {
                edit: 'Edit',
                remove: 'Delete',
                manageIps: 'Assigned IPs',
            },
        },
        add: 'Add',
        reserve: 'Reserve',
        addForm: {
            title: 'Add IP',
            titleReserve: 'Reserve IP',
            ip: 'IP',
            isReserved: 'Reserved',
            comment: 'Comment',
        },
        form: {
            sectionIpBlock: 'Fill the IP block details',
            sectionComputeResource: 'Select compute resource',
            titleAdd: 'Add IP Block',
            titleEdit: 'Edit IP Block',
            computeResources: 'Compute resources',
            ipBlockName: 'Name',
            listType: 'List type',
            type: 'Type',
            gateway: 'Gateway',
            netmask: 'Netmask',
            ns_1: 'Primary Name Server',
            ns_2: 'Secondary Name Server',
            to: 'To IP',
            from: 'From IP',
            range: 'Range',
            subnet: 'Subnet',
            createBtn: 'Add IP Block',
            saveBtn: 'Save',
            ipv6NotAvailable: 'IPv6 isn\'t available on one of the selected compute resources.',
            reverseDns: {
                enabled: 'Enable reverse DNS management',
                zone: 'Reverse DNS Zone',
            },
        },
        toasts: {
            ipBlockIpCreated: 'The IP block was added.',
            ipBlockIpDeleted: 'The IP block was deleted.',
            ipAdded: 'The IP address was added.',
            ipReserved: 'The IP address was reserved.',
            ipBlockSaved: 'The IP block was saved.',
            ipBlockDeleted: 'The IP block was deleted.',
            ipDeleted: 'The IP address was deleted.',
            ipBlockIpCreateFailed: 'An error occurred while adding the IP block.',
            ipBlockIpDeleteFailed: 'An error occurred while deleting the IP block.',
            ipBlockBatchDeleted: '%%count%% IP blocks were deleted.',
        },
        batchButtonWithConfirmation: {
            tooltip: 'Delete the IP blocks.',
            title: 'Delete IP Blocks',
            button: 'Delete',
        },
        buttonWithConfirmation: {
            tooltip: 'Delete the IP block.',
            title: 'Delete IP Block',
            button: 'Delete',
        },
        tooltip: {
            edit: 'Edit ip block',
        },
        validate: {
            subnet: 'The subnet must be from 1 to 128.',
        },
    },
    ip: {
        removeBtn: 'Delete',
        addBtn: 'Add',
        reserveBtn: 'Reserve',
        list: {
            id: 'ID',
            ip: 'IP',
            server: 'Server',
            user: 'User',
            title: '%%ipBlock%%: IP Table',
            comment: 'Comment',
            issuedFor: 'Created for',
            reserved: 'Reserved',
            reserveDisabledTooltip: 'It is possible to reserve only IP addresses from an IP Block with the "Set" list type and created for a user.',
        },
        buttonWithConfirmation: {
            title: 'Delete IP',
            button: 'Delete',
            tooltip: 'Delete the IP address.',
        },
        batchButtonWithConfirmation: {
            title: 'Delete IPs',
            button: 'Delete',
            tooltip: 'Delete the IP addresses.',
            textRange: 'After the IP address is deleted from the IP Block with the "Range" type, it will be available for further use. Delete the IP address?',
            textSet: 'After the IP address is deleted from the IP Block with the "Set" type, it will be unavailable for further use. Delete the IP address?',
        },
        emptyView: {
            title: 'No IPs in these IP blocks yet.',
            description: 'Go ahead and reserve one.',
        },
        issuedFor: {
            vm: 'Virtual Server',
            user: 'User',
            gateway: 'Gateway',
            serversMigration: 'Servers Migration',
        },
        toasts: {
            ipSaved: 'The IP was saved.',
        },
    },
    register: {
        company: 'Company',
        email: 'Email',
        firstName: 'First name',
        lastName: 'Last name',
        password: 'Password',
        registerBtn: 'Register',
        title: 'Register form',
    },
    progressStatesStep: {
        statusNotStarted: 'Queued',
        statusDone: 'Done',
        statusWarning: 'Warning',
        statusError: 'Error',
        statusCanceled: 'Canceled',
    },
    backups: {
        header: 'Virtual Server Backups',
        batchDelete: 'Delete',
        emptyView: {
            title: 'No backups yet.',
            description: 'To start backing up customers’ servers, you need to add a Backup Node to store them.',
        },
    },
    managementNodeBackup: {
        header: 'Management Node Backups',
        createBtn: {
            title: 'Create Backup',
            disableTooltip: 'To create backups, specify the backup node.',
        },
        batchDelete: 'Delete',
        list: {
            id: 'ID',
            status: 'Status',
            version: 'Version',
            created: 'Created',
            creationMethod: 'Creation method',
            size: 'Backup size, GiB',
            backupNode: 'Backup node',
            creator: 'User',
        },
        emptyView: {
            title: 'No backups yet.',
        },
        status: {
            creating: 'Creating',
            restoring: 'Restoring',
            created: 'Created',
            failed: 'Failed',
            garbage: 'Garbage',
        },
        type: {
            auto: 'Auto',
            manual: 'Manual',
        },
        detailsDialog: {
            title: 'Backup fail reason',
        },
        confirmationRestore: {
            confirmationText: 'Restoring the backup will overwrite all current management node data. This operation cannot be undone.',
            button: 'Restore',
            title: 'Restore from backup',
            tooltip: 'Restore from backup.',
        },
        confirmationRemove: {
            button: 'Delete',
            title: 'Delete Backup',
            tooltip: 'Delete the backup.',
        },
        toasters: {
            removed: 'The backup was deleted.',
            batchRemoved: 'The backups were deleted.',
            recovered: 'Recovered %%mnbCount%% management node backup(s).',
        },
        settingsCard: {
            title: 'Automatic backups',
            settingsTitle: 'Backup settings',
            content: {
                disabled: 'To use backups, enable this feature.',
                enabled: 'Next backup %%schedule_date%%',
                limit: {
                    limited: 'Backup limit: %%limit%%',
                    unlimited: 'Backup limit: Unlimited',
                    description: 'Maximum number of backups to store (including scheduled and manual backups)',
                },
                settings: 'Backup settings',
                backupNodeNotSpecified: 'To enable automatic backups, specify the backup node.',
                safeDisable: 'Your existing backups will not be removed and you will be able create manual backups.',
            },
        },
        settingsForm: {
            saveBtn: 'Save Changes',
            limitLabel: 'Maximum number of backups to store (including scheduled and manual backups)',
        },
        backupNodeCard: {
            title: 'Backup node',
            hint: 'Stores management node backups.',
            confirmationDialog: {
                title: 'Change backup node',
                content: 'This will change the backup node to store new management node backups. Existing management node backups remain available for restoration on the previous backup node. Continue?',
                button: 'Change backup node',
            },
            resetDialog: {
                title: 'Detach backup node',
                content: 'You won\'t be able to create management node backups until you select a new backup node to store them. Existing backups stored on the detached node will remain available for restoration. Continue?',
                button: 'Detach backup node',
            },
        },
    },
    backupNode: {
        list: {
            id: 'ID',
            name: 'Name',
            type: 'Type',
            host: 'Host',
            computeResources: 'CRs',
            backups: 'Backups',
            usage: 'Used, Gib',
            usedForManagementNode: 'Used for management node',
        },
        title: 'Backup Nodes',
        addBtn: 'Add Backup Node',
        saveBtn: 'Save',
        emptyView: {
            title: 'Add Backup Node',
            description: 'To start backing up client servers, you first need to add a place to store them.',
            buttonText: 'Add Backup Node',
        },
        nodeNotAccessible: 'This backup node is not accessible:',
        toasts: {
            createSuccess: 'The backup node was added.',
            createFail: 'Failed to add a backup node.',
            deleted: 'The backup node was deleted.',
            updatedSuccess: 'The backup node was updated.',
            updateFail: 'Cannot update the backup node.',
        },
        dialog: {
            createTitle: 'Add Backup Node',
            editTitle: 'Edit Backup Node',
        },
        form: {
            name: 'Name',
            host: 'Host',
            type: 'Type',
            computeResources: 'Compute Resources',
            login: 'SSH Login',
            key: 'SSH Private Key',
            port: 'SSH Port',
            storagePath: 'Storage Path',
        },
        buttonWithConfirmation: {
            tooltip: {
                enabled: 'Delete the backup node.',
                disabled: 'You cannot delete backup nodes while it has backups or it is used for management node backups.',
            },
            title: 'Delete Backup Node',
            button: 'Delete',
        },
        tooltip: {
            edit: 'Edit the backup node.',
        },
    },
    error: {
        title: 'Something went wrong...',
        description: 'Sorry, it seems we have an issue here. Please refresh the page and try again.',
        buttonText: 'Refresh Page',
    },
    language: {
        title: 'Languages',
        list: {
            id: 'ID',
            name: 'Name',
            locale: 'Locale',
            country: 'Country',
            visible: 'Visible',
            usersCount: 'Users',
        },
        tooltip: {
            setAsDefault: 'Set the language as default',
        },
    },
    filters: {
        backupNode: 'All Backup Nodes',
        computeResource: 'All CRs',
        computeResourceVm: 'All Servers',
        computeResourceVmStatus: 'All Statuses',
        status: 'All Statuses',
        role: 'All Roles',
        limitGroup: 'All Limit Groups',
        icon: 'All Icons',
        user: 'All Users',
        all: 'All',
        virtualizationType: 'Virtualization',
    },
    serversMigration: {
        status: {
            pending: 'Pending',
            processing: 'Processing - %%curr%% of %%total%%',
            done: 'Done',
            failed: 'Failed',
        },
        emptyView: {
            title: 'No server migrations found!',
            description: 'Create a new server migration from the Virtual Server list.',
        },
        list: {
            id: 'ID',
            destination: 'Destination Compute Resource',
            status: 'Status',
            started: 'Started',
            finished: 'Finished',
            duration: 'Duration, min',
            row: {
                id: 'Server ID',
                name: 'Server Name',
                status: 'Status',
                source: 'Source Compute Resource',
            },
        },
        toasts: {
            deleted: 'The server migration was deleted.',
            batchDelete: 'The server migrations were deleted.',
        },
        actions: {
            delete: {
                title: 'Delete Server Migration',
                text: 'This won\'t however cancel the migration. The deleted migration will be completed until the end.',
                button: 'Delete',
                tooltip: 'Delete the server migration.',
            },
            batchDelete: {
                title: 'Delete Server Migrations',
                text: 'This won\'t however cancel the migrations. The deleted migrations will be completed until the end.',
                button: 'Delete',
                tooltip: 'Delete the selected server migrations.',
            },
        },
    },
    activityLog: {
        title: 'Activity Logs',
        events: {
            userCreateRequested: 'User creation requested',
            userCreateSuccess: 'User creation succeeded',
            userCreateFailed: 'User creation failed',
            userDeleteRequested: 'User deletion requested',
            usersDeleted: 'Users deleted',
            userAuthorizedSuccess: 'User authorization succeeded',
            userAllowedIpAdded: 'Allowed IP addresses for user added',
            userAllowedIpRemoved: 'Allowed IP addresses for user removed',
            computeResourceCreateRequested: 'Compute resource creation requested',
            computeResourceDeleteRequested: 'Compute resource deletion requested',
            serverCreateRequested: 'Server creation requested',
            serverCreateSuccess: 'Server creation succeeded',
            serverCreateFailed: 'Server creation failed',
            serverReinstallRequested: 'Server reinstallation requested',
            serverReinstalled: 'Server reinstalled',
            serverDeleteRequested: 'Server deletion requested',
            serversDeleted: 'Servers deleted',
            backupsDeleted: 'Backups deleted',
            locationCreateSuccess: 'Location creation succeeded',
            locationDeleteSuccess: 'Location deletion succeeded',
            locationChangeSuccess: 'Location editing succeeded',
            ipBlockChangeSuccess: 'IP block editing succeeded',
            additionalIpCreateRequested: 'Requested to create an additional IP',
            additionalIpCreateSuccess: 'Created an additional IP',
            additionalIpCreateFailed: 'Failed to create an additional IP',
            additionalIpDeleteRequested: 'Requested to delete an additional IP',
            additionalIpsDeleteRequested: 'Requested to delete additional IPs',
            additionalIpDeleteSuccess: 'Deleted the additional IP',
            additionalIpDeleteFailed: 'Failed to delete the additional IP',
            additionalIpsDeleteFailed: 'Failed to delete additional IPs',
            externalIntegrationRequested: 'External licensing integration requested',
            failedToSendEmailNotification: 'Failed to send the user email notification',
            managementNodeBackupsEnabled: 'Enabled automatic backups of the management node',
            managementNodeBackupsDisabled: 'Disabled automatic backups of the management node',
            managementNodeBackupRequested: 'Requested to back up the management node',
            managementNodeBackupRestoreRequested: 'Requested to restore the management node backup',
            managementNodeBackupRestored: 'Restored the management node from a backup',
            managementNodeBackupDeleted: 'Deleted the management node backup',
        },
        emptyView: {
            title: 'No activity logs found!',
        },
        list: {
            event: 'Event',
            user: 'User',
            ip: 'User IP',
            created: 'Created',
        },
        data: {
            list: {
                key: 'Key',
                value: 'Value',
            },
        },
    },
    clusterImport: {
        title: 'Cluster imports',
        addBtn: 'Add',
        emptyView: {
            buttonText: 'Add cluster import',
            title: 'No active cluster import found',
            description: 'Add cluster import to start importing servers from the cluster.',
        },
        buttonWithConfirmation: {
            title: 'Delete the cluster import',
            button: 'Delete',
            tooltip: 'Delete cluster import',
            confirmationText: 'The cluster import will be permanently deleted.',
        },
        toasters: {
            removed: 'The cluster import was removed.',
        },
        tooltip: {
            run: 'Run the cluster import.',
            refresh: 'Refresh the list of compute resources to be imported.',
            edit: 'Edit the cluster import.',
        },
        list: {
            id: 'ID',
            name: 'Name',
            host: 'Hostname',
            error: {
                title: 'Import errors',
                noError: 'No errors',
                details: 'Details',
            },
            status: 'Status',
            failReason: 'Cluster import %%name%%',
        },
        form: {
            title: 'Add Cluster Import',
            name: 'Name',
            nameDescription: 'Name of the cluster import',
            configuration: {
                host: 'Host Address',
                hostDescription: 'Imported cluster\'s management node IP Address or hostname',
                port: 'Port',
            },
            credentials: {
                login: 'SSH Login',
                port: 'SSH Port',
                type: 'Type',
                password: 'Password',
                key: 'SSH Private Key',
            },
            sshGenerate: {
                tooltip: 'Generate new SSH key pair',
                title: 'Generate a new key pair',
            },
            addBtn: 'Add',
            editBtn: 'Save',
        },
        status: {
            preparing: 'Preparing',
            pre_checking: 'Pre-checking',
            importing: 'Importing',
            refreshing: 'Refreshing',
            prepared: 'Prepared',
            failed_prepare: 'Failed to prepare',
            failed_pre_check: 'Failed to pre-check',
            failed_import: 'Failed to import',
            failed_refresh: 'Failed to refresh',
        },
        computeResources: {
            title: 'Compute Resources of cluster import %%name%%',
            runButton: {
                title: 'Run',
                tooltip: 'Run cluster import for the selected compute resources',
                confirmation: {
                    button: 'Run',
                    title: 'Run cluster import',
                },
            },
            emptyView: {
                title: 'No compute resources found',
                description: 'Try to refresh cluster import data.',
            },
            list: {
                id: 'ID',
                name: 'Name',
                type: 'Type',
                sourceId: 'Source ID',
                serverCount: 'Number of servers',
                importable: 'Can be imported',
                isImported: 'Is imported',
            },
        },
        errorDialog: {
            title: 'Cluster import %%name%%',
            list: {
                id: 'ID',
                name: 'Name',
                reason: 'Reason',
            },
        },
    },
};
