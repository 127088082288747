// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import styled from 'styled-components';

export const DialogHeadingContainer = styled.div`
    display: flex;
    justify-content: space-between;
    /* Because of dialog close button */
    margin-right: 20px;
`;
