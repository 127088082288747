// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import { createGlobalStyle } from 'styled-components';
import { COLORS } from 'admin/core/theme';

export const AdminGlobalStyle = createGlobalStyle`
    .infinity-scroll {
        height: 100%;
    }

    .pul-drawer-header, .pul-button--primary {
        background-color: ${COLORS.PRIMARY}!important;
    }

    .pul-button--primary {
        background-color: ${COLORS.PRIMARY}!important;

        &:hover {
            background-color: ${COLORS.PRIMARY_HOVER}!important;
        }
    }

    .pul-button--ghost {
        color: ${COLORS.PRIMARY}!important;
    }
`;
