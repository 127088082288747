// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import {
    api,
    IPaginateApiResponse,
} from 'common/api/resources/Response';
import {
    IActivityLogListRequest,
    IActivityLogResponse,
} from 'common/api/resources/ActivityLog/model';
import { QueryBuilder } from 'common/api/resources/Request/QueryBuilder';
import { CancelTokenSource } from 'axios';

export const activityLogs = {
    list: (params?: IActivityLogListRequest, cancelToken?: CancelTokenSource) => api.get<IPaginateApiResponse<IActivityLogResponse[]>>('activity_logs', {
        params: new QueryBuilder(params).build(),
        cancelToken: cancelToken?.token,
    }),
};
