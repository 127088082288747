// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import {
    ActionType,
    getType,
} from 'typesafe-actions';
import * as loadingFlagsActions from 'common/modules/app/loadingFlags/actions';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';

export type LoadingFlagsAction = ActionType<typeof loadingFlagsActions>;
export type LoadingFlagsState = Set<LOADING_FLAGS>;

export default (state = new Set([LOADING_FLAGS.NONE]), action: LoadingFlagsAction) => {
    switch (action.type) {
    case getType(loadingFlagsActions.setIsLoading): {
        const setFlags = new Set(state.values());
        setFlags.add(action.payload);
        return setFlags;
    }
    case getType(loadingFlagsActions.unsetIsLoading): {
        const setFlags = new Set(state.values());
        setFlags.delete(action.payload);
        return setFlags;
    }
    default:
        return state;
    }
};
