// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

export const hexToRGB = (hex: string): number[] => {
    const components = hex.replace(/^#/, '').match(/.{2}/g);
    if (components?.length !== 3) {
        throw new Error(`Cannot convert ${hex} to RGB`);
    }

    return components.map((component) => parseInt(component, 16));
};

const padToTwo = (str: string): string => {
    if (str.length < 2) {
        return `0${str}`;
    }

    return str;
};

export const colorBetween = (start: string, end: string, percentage: number): string => {
    const startRGB = hexToRGB(start);
    const endRGB = hexToRGB(end);

    return [0, 0, 0]
        .map((_, idx) => Math.round(startRGB[idx] + (endRGB[idx] - startRGB[idx]) * percentage / 100))
        .reduce((out, component) => out + padToTwo(component.toString(16)), '#');
};
