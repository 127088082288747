// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import { Translate } from '@plesk/ui-library';
import {
    Header,
    Title,
    Action,
} from 'client/common/components/PageSubHeader/Styles';

export interface IPageSubHeaderProps {
    title: string;
    action?: JSX.Element;
}

export const PageSubHeader: React.FC<IPageSubHeaderProps> = ({
    title,
    action,
}) => (
    <Header>
        <Title>
            <Translate content={title} />
        </Title>
        {action && (
            <Action>
                {action}
            </Action>
        )}
    </Header>
);
