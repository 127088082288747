// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import React from 'react';
import { RootState } from 'admin/core/store';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import * as backupActions from 'common/modules/backup/actions';
import { connect } from 'react-redux';
import BackupsTable,
{ BackupsTableColumns } from 'common/modules/backup/containers/BackupsTable';
import { ADMIN_ROUTE_PREFIX } from 'admin/core/constants';
import {
    RouteComponentProps,
    withRouter,
} from 'react-router';
import { PageHeader } from 'admin/common/components/PageHeader/PageHeader';
import { IBackupListFilters } from 'common/api/resources/Backup';
import { ValueType } from 'react-select';
import { ISelectRequiredOption } from 'common/components';
import {
    BackupOperationContainer,
    BatchActionContainer,
} from 'admin/backup/containers/Backups/Styles';
import { Translate } from '@plesk/ui-library';
import ButtonWithConfirmation from 'common/components/ButtonWithConfirmation';
import { ACTIONS } from 'common/modules/backup/constants/tests';
import { EmptyView } from 'common/components/EmptyView/EmptyView';
import { ComputeResourceFilter } from 'admin/common/components/Filters/ComputeResourceFilter/ComputeResourceFilter';
import { BackupNodeFilter } from 'admin/common/components/Filters/BackupNodeFilter/BackupNodeFilter';
import { FilterContainer } from 'admin/common/components/Filters/Styles';
import FilterForm from 'admin/common/components/FilterForm/FilterForm';
import { UserFilter } from 'admin/common/components/Filters/UserFilter/UserFilter';
import { ComputeResourceVmFilter } from 'admin/common/components/Filters/ComputeResourceVmFilter/ComputeResourceVmFilter';
import { useRequestCancellationEffect } from 'common/hooks/useRequestCancellationEffect';
import { CancelTokenSource } from 'axios';

export type BackupsProps =
    RouteComponentProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const Backups: React.FC<BackupsProps> = ({
    isServerProcessing,
    backupActions: {
        getBackups,
        removeBackups,
    },
    isBackupBatchRemoving,
    history,
}) => {
    const [filters, setFilters] = React.useState<IBackupListFilters>({
        creator_id: 0,
        compute_resource_id: 0,
        compute_resource_vm_id: 0,
        backup_node_id: 0,
    });
    const [selection, setSelection] = React.useState<string[]>([]);

    const handleSelectionChange = (indexes: string[]) => {
        setSelection(indexes);
    };

    const handleBatchDelete = async () => {
        await removeBackups(selection.map(id => parseInt(id, 10)));
        setSelection([]);
    };

    const handleComputeResourceClick = (computeResourceId: number) =>
        history.push(`${ADMIN_ROUTE_PREFIX}/compute_resources/${computeResourceId}`);

    const handleComputeResourceVmClick = (computeResourceVmId: number) =>
        history.push(`${ADMIN_ROUTE_PREFIX}/servers/${computeResourceVmId}`);

    const handleComputeResourceFilterChange = async (option: ValueType<ISelectRequiredOption>) => {
        setFilters({
            ...filters,
            compute_resource_id: option ? parseFloat((option as ISelectRequiredOption).value) : 0,
        });
    };

    const handleComputeResourceVmFilterChange = async (option: ValueType<ISelectRequiredOption>) => {
        setFilters({
            ...filters,
            compute_resource_vm_id: option ? parseFloat((option as ISelectRequiredOption).value) : 0,
        });
    };

    const handleUserFilterChange = async (option: ValueType<ISelectRequiredOption>) => {
        setFilters({
            ...filters,
            creator_id: option ? parseFloat((option as ISelectRequiredOption).value) : 0,
        });
    };

    const handleBackupNodeFilterChange = async (option: ValueType<ISelectRequiredOption>) => {
        setFilters({
            ...filters,
            backup_node_id: option ? parseFloat((option as ISelectRequiredOption).value) : 0,
        });
    };

    const loadPaginated = React.useCallback(
        (page: number, cancelToken?: CancelTokenSource) => getBackups({ filters, page }, cancelToken),
        [getBackups, filters]
    );

    const isFirstLoading = useRequestCancellationEffect(
        token => getBackups({ filters }, token),
        [filters]
    );

    return (
        <>
            <PageHeader
                isButtonShown={false}
                title={<Translate content="backups.header"/>}
            />
            <BackupsTable
                columns={{
                    [BackupsTableColumns.ID]: { width: '1%' },
                    [BackupsTableColumns.STATUS]: { width: '10%' },
                    [BackupsTableColumns.CREATED_AT]: { width: '10%' },
                    [BackupsTableColumns.CREATION_METHOD]: { width: '1%' },
                    [BackupsTableColumns.SIZE]: { width: '1%' },
                    [BackupsTableColumns.DISK]: { width: '1%' },
                    [BackupsTableColumns.COMPUTE_RESOURCE]: { width: '15%' },
                    [BackupsTableColumns.COMPUTE_RESOURCE_VM]: { width: '15%' },
                    [BackupsTableColumns.BACKUP_NODE]: { width: '15%' },
                    [BackupsTableColumns.BACKUP_TYPE]: { width: '15%' },
                    [BackupsTableColumns.CREATOR]: { width: '15%' },
                    [BackupsTableColumns.ACTIONS]: { width: '1%' },
                }}
                isFirstLoading={isFirstLoading}
                loadPaginated={loadPaginated}
                filters={filters}
                toolbar={<BackupOperationContainer>
                    <BatchActionContainer>
                        <ButtonWithConfirmation
                            data-cy={ACTIONS.DELETE}
                            disabled={selection.length === 0}
                            isLoading={isBackupBatchRemoving}
                            confirmationButtonGhost={false}
                            confirmationButtonText={<Translate content="backups.batchDelete" />}
                            translations={{
                                text: (
                                    <Translate content="backup.buttonWithConfirmation.confirmationText" />
                                ),
                                button: (
                                    <Translate content="backup.buttonWithConfirmation.button" />
                                ),
                                title: (
                                    <Translate content="backup.buttonWithConfirmation.title" />
                                ),
                                tooltip: (
                                    <Translate content="backup.buttonWithConfirmation.tooltip" />
                                ),
                            }}
                            handleConfirm={handleBatchDelete}
                            icon="recycle"
                        />
                    </BatchActionContainer>
                    <FilterContainer>
                        <FilterForm alignRight={true}>
                            <ComputeResourceFilter onChange={handleComputeResourceFilterChange} />
                            <ComputeResourceVmFilter onChange={handleComputeResourceVmFilterChange} />
                            <BackupNodeFilter onChange={handleBackupNodeFilterChange} />
                            <UserFilter onChange={handleUserFilterChange} />
                        </FilterForm>
                    </FilterContainer>
                </BackupOperationContainer>}
                isServerProcessing={isServerProcessing}
                onComputeResourceClick={handleComputeResourceClick}
                onComputeResourceVmClick={handleComputeResourceVmClick}
                withSelection={true}
                selection={selection}
                onSelectionChange={handleSelectionChange}
                emptyView={<EmptyView
                    title="backups.emptyView.title"
                    description="backups.emptyView.description"
                    icon="backup"
                />}
            />
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    isServerProcessing: state.computeResourceVm.item.is_processing,
    isBackupBatchRemoving: state.app.loadingFlags.has(LOADING_FLAGS.BACKUP_BATCH_REMOVE),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    backupActions: bindActionCreators(backupActions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Backups));
