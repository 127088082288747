// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import styled from 'styled-components';

export const PasswordLabelContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const ResetPasswordContainer = styled.div`
    text-decoration: none;
    a {
        span {
            color: #28AADE!important;
        }

        &:hover {
            text-decoration: none;

            span {
                color: #259CCC!important;
            }
        }
    }
`;

export const ResendLinkContainer = styled.div`
    display: flex;
    font-size: 12px;
`;

export const ResendLinkText = styled.div`
    color: #737373;
    margin-right: 4px;
    line-height: 16px;
`;

export const ElapsedTimeText = styled.span`
    color: #222;
    font-weight: 600;
    line-height: 16px;
`;
