// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import { createCustomAction } from 'typesafe-actions';
import { Dispatch } from 'redux';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import {
    create,
    get,
    loadOnScroll,
    paginateList,
    remove,
    update,
} from 'common/actions/actionsWrapper';
import { IPaginateApiResponse } from 'common/api/resources/Response';
import { IAppState } from 'admin/core/store';
import * as types from 'common/modules/location/constants/types';
import {
    ILocationCreateRequest,
    ILocationResponse,
    ILocationUpdateRequest,
    locations,
} from 'common/api/resources/Location';
import {
    setIsLoading,
    unsetIsLoading,
} from 'common/modules/app/loadingFlags/actions';
import { bakeForegroundToast } from 'common/modules/app/toaster/actions';
import { INTENT_TYPE } from 'common/constants';
import { IPaginatedWithSearch } from 'common/api/resources/Request/request';

export const setLocationList = createCustomAction(
    types.SET_LOCATION_LIST,
    (data: IPaginateApiResponse<ILocationResponse[]>) => ({ payload: data })
);
export const setLocationItem = createCustomAction(
    types.SET_LOCATION_ITEM,
    (data: ILocationResponse) => ({ payload: data })
);
export const unsetLocationItem = createCustomAction(types.UNSET_LOCATION_ITEM);
export const addLocationItem = createCustomAction(
    types.ADD_LOCATION_ITEM,
    (data: ILocationResponse) => ({ payload: data })
);
export const updateLocationItem = createCustomAction(
    types.UPDATE_LOCATION_ITEM,
    (data: ILocationResponse) => ({ payload: data })
);
export const updateLocationItemPosition = createCustomAction(
    types.UPDATE_LOCATION_ITEM_POSITION,
    (id: number, position: number) => ({ payload: { id, position } })
);
export const removeLocationItem = createCustomAction(
    types.REMOVE_LOCATION_ITEM,
    (id: number) => ({ payload: id })
);
export const appendLocations = createCustomAction(
    types.APPEND_LOCATIONS,
    (data: IPaginateApiResponse<ILocationResponse[]>) => ({ payload: data })
);
export const clearDefault = createCustomAction(types.CLEAR_DEFAULT);
export const setLocationItemIsLoading = createCustomAction(
    types.SET_LOCATION_ITEM_IS_LOADING,
    (index: number) => ({ payload: index })
);
export const unsetLocationItemIsLoading = createCustomAction(
    types.UNSET_LOCATION_ITEM_IS_LOADING,
    (index: number) => ({ payload: index })
);
export const setLocationItemIsDeleting = createCustomAction(
    types.SET_LOCATION_ITEM_IS_DELETING,
    (id: number, isDeleting: boolean) => ({ payload: { id, isDeleting } })
);

export const getLocations = (params?: IPaginatedWithSearch) => async (dispatch: Dispatch) => await paginateList({
    dispatch,
    loadingFlag: LOADING_FLAGS.LOCATION_LIST,
    action: setLocationList,
    apiCall: () => locations.list(params),
});

export const createLocation = (data: ILocationCreateRequest) => async (dispatch: Dispatch) => await create({
    data,
    dispatch,
    loadingFlag: LOADING_FLAGS.SAVE_LOCATION_ITEM,
    action: addLocationItem,
    apiCall: locations.create,
    translations: {
        success: 'location.toasts.locationSaved',
    },
});

export const getLocation = (id: number) => async (dispatch: Dispatch) => await get(id, {
    dispatch,
    apiCall: locations.item,
    action: setLocationItem,
    loadingFlag: LOADING_FLAGS.LOCATION_ITEM,
});

export const updateLocation = (id: number, data: ILocationCreateRequest) => async (dispatch: Dispatch) => await update(id, {
    data,
    dispatch,
    apiCall: locations.update,
    action: updateLocationItem,
    loadingFlag: LOADING_FLAGS.SAVE_LOCATION_ITEM,
    translations: {
        success: 'location.toasts.locationSaved',
    },
});

export const updateDefaultLocation = (id: number, data: ILocationUpdateRequest) => async(dispatch: Dispatch) => {
    dispatch(setLocationItemIsLoading(id));

    try {
        const result = await locations.patch(id, data);

        dispatch(clearDefault());
        dispatch(updateLocationItem(result.data.data));

        return result;
    } finally {
        dispatch(unsetLocationItemIsLoading(id));
    }
};

export const updateLocationVisibility = (id: number, data: ILocationUpdateRequest) => async(dispatch: Dispatch) => {
    dispatch(setLocationItemIsLoading(id));

    try {
        const result = await locations.patch(id, data);

        dispatch(updateLocationItem(result.data.data));

        return result;
    } finally {
        dispatch(unsetLocationItemIsLoading(id));
    }
};

export const removeLocation = (id: number) => async (dispatch: Dispatch) => await remove(id, {
    dispatch,
    apiCall: locations.remove,
    setLoadingAction: setLocationItemIsDeleting,
    action: removeLocationItem,
    loadingFlag: LOADING_FLAGS.REMOVE_LOCATION_ITEM,
    translations: {
        success: 'location.toasts.locationDeleted',
    },
});

export const loadLocationsOnScroll = ()  => async(dispatch: Dispatch, getState: () => IAppState) => {
    const state = getState();
    const nextPage = state.location.list.links.next;
    const isLoading = state.app.loadingFlags.has(LOADING_FLAGS.LOCATION_LIST);

    return await loadOnScroll({
        nextPage,
        isLoading,
        dispatch,
        action: appendLocations,
        loadingFlag: LOADING_FLAGS.LOCATION_LIST,
    });
};

export const updateLocationPosition = (source_id: number, data: ILocationUpdateRequest) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.SAVE_LOCATION_ITEM));

    if (data.position) {
        dispatch(updateLocationItemPosition(source_id, data.position));
    }

    try {
        const result = await locations.patch(source_id, data);

        bakeForegroundToast(INTENT_TYPE.SUCCESS, 'location.toasts.locationSaved')(dispatch);
        dispatch(updateLocationItem(result.data.data));

        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.SAVE_LOCATION_ITEM));
    }
};
