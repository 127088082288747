// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as ProjectActions from 'common/modules/project/actions';
import * as ProjectMemberActions from 'client/project/actions/member';
import { combineReducers } from 'redux';
import {
    ActionType,
    getType,
} from 'typesafe-actions';
import {
    IPaginateApiResponse,
    paginateInitialState,
} from 'common/api/resources/Response';
import { initialUserState as user } from 'admin/user/reducer';
import { IProjectResponse } from 'common/api/resources/Project';

interface IProjects {
    list: IPaginateApiResponse<IProjectResponse[]>;
    item: IProjectResponse;
}

export type ProjectAction = ActionType<typeof ProjectActions | typeof ProjectMemberActions>;
export type ProjectState = Readonly<IProjects>;

export const initialProjectState: IProjectResponse = {
    id: 0,
    name: '',
    description: '',
    is_default: false,
    isEdit: true,
    isLoading: false,
    members: 0,
    servers: 0,
    owner: user,
    is_owner: true,
    ssh_keys: [],
};

export default combineReducers<ProjectState, ProjectAction>({
    list: (state = paginateInitialState, action: ProjectAction) => {
        switch (action.type) {
        case getType(ProjectActions.setProjectList):
            return action.payload;
        case getType(ProjectActions.updateProjectItem):
            return {
                ...state, data: [...state.data.map(item => {
                    if (item.id === action.payload.id) {
                        return action.payload;
                    }

                    return item;
                })],
            };
        case getType(ProjectActions.removeProjectItem):
            return {
                ...state,
                data: state.data.filter(item => item.id !== action.payload),
            };
        case getType(ProjectActions.addProject):
            return { ...state, data: [action.payload, ...state.data] };
        case getType(ProjectActions.setItemIsLoading):
            return {
                ...state, data: [...state.data.map(item => {
                    if (item.id === action.payload) {
                        return { ...item, isLoading: true };
                    }

                    return item;
                })],
            };
        case getType(ProjectActions.unsetItemIsLoading):
            return {
                ...state, data: [...state.data.map(item => {
                    if (item.id === action.payload) {
                        return { ...item, isLoading: false };
                    }

                    return item;
                })],
            };
        default:
            return state;
        }
    },
    item: (state = initialProjectState, action) => {
        switch (action.type) {
        case getType(ProjectActions.setProjectItem):
            return { ...action.payload, ssh_keys: [] };
        case getType(ProjectMemberActions.removeMemberItem):
            return { ...state, members: --state.members };
        case getType(ProjectMemberActions.addMember):
            return { ...state, members: ++state.members };
        case getType(ProjectActions.setProjectSshKeys):
            if (state.id === action.payload.id) {
                return { ...state, ssh_keys: action.payload.data };
            }
            return state;
        case getType(ProjectActions.appendProjectSshKeyItem):
            const { id, data } = action.payload;
            if (state.id === id) {
                return { ...state, ssh_keys: [...state.ssh_keys, data] };
            }
            return state;
        default:
            return state;
        }
    },
});
