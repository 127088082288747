// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

export const SET_ICON_LIST = 'icon/SET_ICON_LIST';
export const APPEND_ICONS = 'icon/APPEND_ICONS';
export const ADD_ICON_ITEM = 'icon/ADD_ICON_ITEM';
export const SET_ICON_ITEM = 'icon/SET_ICON_ITEM';
export const UNSET_ICON_ITEM = 'icon/UNSET_ICON_ITEM';
export const REMOVE_ICON_ITEM = 'icon/REMOVE_ICON_ITEM';
export const UPDATE_ICON_ITEM = 'icon/UPDATE_ICON_ITEM';
export const SET_ICON_ITEM_IS_DELETING = 'icon/SET_ICON_ITEM_IS_DELETING';
