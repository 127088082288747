// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import styled from 'styled-components';
import { Dropdown as UIDropdown } from '@plesk/ui-library';
import { COLORS } from 'common/theme';

export const Dropdown = styled(UIDropdown)`
    .pul-button {
        padding-left: 10px;
        border-radius: 16px;
        color: ${COLORS.GREY_70} !important;

        .pul-button__inner {
            flex-direction: row-reverse;
    
            img {
                min-width: 15px;
            }
        }

        .pul-button__icon {
            color: ${COLORS.GREY_70} !important;
            margin: 0 0 0 8px !important;
        }

        :hover {
            color: ${COLORS.GREY_100} !important;

            .pul-button__icon {
                color: ${COLORS.GREY_100} !important
            }
        }
    }
`;
