// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import styled from 'styled-components';
import { COLORS } from 'admin/core/theme';

export const Title = styled.div`
    display: flex;
    align-items: flex-end;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color: ${COLORS.GREY_100};

    .pul-label {
        margin-left: 12px;
        margin-bottom: 7px;
    }
`;
