// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import styled from 'styled-components';
import { COLORS } from 'admin/core/theme';
import { Action } from '@plesk/ui-library';

export const AuthTypeContainer = styled.div`
    margin-top: 8px;
    margin-bottom: 8px;
`;

export const TypeContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: -5px;
`;

export const OneTimeKeyPairGenerating = styled(Action)`
    color: ${COLORS.GREY_100};
`;

export const OneTimeKeyPairCountdown = styled.span`
    display: inline-block;
    margin-left: 5px;
`;
