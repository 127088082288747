// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import styled from 'styled-components';
import { COLORS } from 'client/core/theme';
import { CardItem } from 'common/components/styles/CardItem';

interface IOsVersionItemProps {
    isDisabled?: boolean;
}

export const WrappedApplicationForm = styled.div`
  .field-description {
     margin-bottom: 0;
  }

  legend {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: ${COLORS.GREY_100};
    margin-bottom: 8px;
  }

  label.control-label {
    display: block;
    margin-bottom: .5rem;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    align-items: center;
    color: ${COLORS.GREY_80};

    .required {
        display: inline-block;
        padding: 0 2px;
        color: red;
    }
  }

  .field-error {
      .form-control {
        border-color: ${COLORS.RED_0};
        background-color: ${COLORS.RED_10};

        &:focus {
            border-color: ${COLORS.RED_0};
            background-color: ${COLORS.RED_10};
        }
      }
  }

  .form-group {
    margin-bottom: 0;
    vertical-align: middle;
    padding-top: 20px;

    .error-detail {
       color: ${COLORS.RED_0};
       margin: 4px 0 0;
       padding: 0;
       font-size: 12px;
    }
  }

  input.form-control {
    background: ${COLORS.WHITE_0};
    border: 1px solid ${COLORS.GREY_50};
    box-sizing: border-box;
    border-radius: 2px;
    width: 100%;
    padding: 10px 30px 10px 10px;
    font-size: 14px;
    line-height: 20px;
    color: ${COLORS.GREY_100};

    &:focus {
        outline: none;
        background: rgba(40,170,222,0.05);
        border: 1px solid ${COLORS.PRIMARY};
        box-sizing: border-box;
        box-shadow: inset 0 3px 2px rgba(40,170,222,0.09);
    }
  }
`;

export const ApplicationItem = styled(CardItem)`
    flex-direction: column;
    height: 66px;
    padding: 12px 20px;

  .application-description {
     opacity: ${props => props.isSelected ? 0.8 : 1};
  }
`;

export const ItemContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`;

export const LogoContainer = styled.div`
    width: 24px;
    height: 30px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
        height: 24px !important;
        width: 24px !important;
    }
`;

export const OsVersionList = styled.ul`
    padding: 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    border-radius: 2px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    top: 5px;
    z-index: 1;
`;

export const OsVersionItem = styled.li<IOsVersionItemProps>`
    background: ${props => props.isDisabled ? COLORS.GREY_20 : COLORS.WHITE_0};
    ${props => props.isDisabled && `
        p {
            color: ${COLORS.GREY_40};
        }
        svg {
            opacity: 0.3;
        }
    `}
    padding: 6px 20px;
    cursor: pointer;
    height: 16px;
    display: flex;
    justify-content: space-between;

    .pul-icon {
        padding: 0 0 3px 5px;
        width: 16px;
        min-width: 16px;
    }

    &:hover {
        background: #F5F5F5;
    }
`;

export const OsVersionItemParagraph = styled.p`
    text-overflow: ellipsis;
    font-size: 12px;
    line-height: 16px;
    color: ${COLORS.GREY_100};
    white-space: nowrap;
    overflow: hidden;
    margin-bottom: 0 !important;
`;

export const ItemContent = styled.div`
    margin-left: 12px;
    flex: 2 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;

export const ItemContentName = styled.div`
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #222;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const ItemDescription = styled.span`
    font-size: 12px;
    line-height: 16px;
    color: #737373;
    display: flex;
    align-content: center;
    opacity: 0.87;
    svg {
      fill: rgba(115, 115, 115, 0.87) !important;
    }
`;
