// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as projectMemberActions from 'client/project/actions/member';
import { combineReducers } from 'redux';
import {
    ActionType,
    getType,
} from 'typesafe-actions';
import {
    IPaginateApiResponse,
    paginateInitialState,
} from 'common/api/resources/Response';
import { STATUS_ACTIVE } from 'common/modules/project/constants';
import { IProjectMemberResponse } from 'common/api/resources/Project';

interface IMembers {
    list: IPaginateApiResponse<IProjectMemberResponse[]>;
}

export const initialProjectMemberState = {
    id: 0,
    email: 'test@test.com',
    status: STATUS_ACTIVE,
    is_owner: true,
    user_id: null,
    isEdit: false,
    isLoading: false,
};

export type MemberAction = ActionType<typeof projectMemberActions>;
export type MemberState = Readonly<IMembers>;

export default combineReducers<MemberState, MemberAction>({
    list: (state = paginateInitialState, action: MemberAction) => {
        switch (action.type) {
        case getType(projectMemberActions.setProjectMembers):
            return action.payload;
        case getType(projectMemberActions.removeMemberItem):
            return {
                ...state,
                data: state.data.filter(item => item.id !== action.payload),
            };
        case getType(projectMemberActions.updateMemberItem):
            return {
                ...state, data: [...state.data.map(item => {
                    if (item.id === action.payload.id) {
                        return action.payload;
                    }

                    return item;
                })],
            };
        case getType(projectMemberActions.addMember):
            return { ...state, data: [action.payload, ...state.data] };
        case getType(projectMemberActions.setItemIsLoading):
            return {
                ...state, data: [...state.data.map(item => {
                    if (item.id === action.payload) {
                        return { ...item, isLoading: true };
                    }

                    return item;
                })],
            };
        case getType(projectMemberActions.unsetItemIsLoading):
            return {
                ...state, data: [...state.data.map(item => {
                    if (item.id === action.payload) {
                        return { ...item, isLoading: false };
                    }

                    return item;
                })],
            };
        default:
            return state;
        }
    },
});
