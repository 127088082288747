// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'admin/core/store';
import {
    Icon,
    Item,
    Translate,
} from '@plesk/ui-library';
import {
    DashboardItemContainer,
    ItemDescription,
} from 'admin/dashboard/containers/items/Styles';

export type UserItemProps =
    ReturnType<typeof mapStateToProps>;

export const UserItem: React.FC<UserItemProps> = ({
    stats,
}) => (
    <DashboardItemContainer>
        <Item
            icon={<Icon name="user" intent="info" />}
            description={<ItemDescription>{stats.total}</ItemDescription>}
            view="card"
            title={<Translate content="dashboard.cards.users" />}
        />
    </DashboardItemContainer>
);

const mapStateToProps = (state: RootState) => ({
    stats: state.dashboard.data.users,
});

export default connect(mapStateToProps)(UserItem);
