// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import styled from 'styled-components';
import { Grid as UIGrid } from '@plesk/ui-library';

export const Grid = styled(UIGrid)`
  max-width: 50%;

  @media (max-width: 1800px) {
    max-width: 60%;
  }

  @media (max-width: 1400px) {
    max-width: 80%;
  }

  @media (max-width: 1200px) {
    max-width: 100%;
  }
`;
