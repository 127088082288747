// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'admin/core/store';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import {
    requiredRule,
    validate,
} from 'common/validator';
import * as iconActions from 'admin/icon/actions';
import * as formErrorsActions from 'common/modules/app/formErrors/actions';
import {
    Form,
    FormField,
    FormFieldText,
    Section,
    Translate,
} from '@plesk/ui-library';
import { INTENT_TYPE } from 'common/constants';
import { Button } from 'admin/common/components/Button/Button';
import {
    IconType,
    IIconRequest,
} from 'common/api/resources/Icon';
import { nestStringProperties } from 'common/modules/app/formErrors/selectors';
import { SegmentedControl } from 'common/components/SegmentedControl/SegmentedControl';
import { IFormProps } from 'common/components/Form/types';
import { IconTypeText } from 'admin/icon/components/IconTypeText';

interface IIconFormProps {
    onSubmit: () => void;
}

export type IconFormProps =
    IIconFormProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

const typeOptions = Object.values(IconType).map((type) => ({
    value: type.toString(),
    title: <IconTypeText type={type} />,
}));

export const IconForm: React.FC<IconFormProps> = ({
    item,
    isItemSaving,
    formErrors,
    formErrorsActions: {
        setFormErrors,
        clearFormErrors,
    },
    iconActions: {
        unsetIconItem,
        createOrUpdateIcon,
    },
    onSubmit,
}) => {
    React.useEffect(() => () => {
        clearFormErrors();
        unsetIconItem();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSubmit = async (values: IIconRequest) => {
        const errors = validate<IIconRequest>(values, {
            name: requiredRule(<Translate content="validate.fieldRequired" />),
            url: requiredRule(<Translate content="validate.fieldRequired" />),
            type: requiredRule(<Translate content="validate.fieldRequired" />),
        });

        if (Object.keys(errors).length) {
            setFormErrors(errors);
            return;
        }

        try {
            await createOrUpdateIcon(values);
            onSubmit();
        } catch (e) {
            throw e;
        }
    };

    return (
        <>
            <Form
                id="iconForm"
                onSubmit={handleSubmit}
                values={item}
                footerClassName="hidden"
                errors={formErrors}
                hideRequiredLegend={true}
                submitButton={false}
                cancelButton={false}
                applyButton={false}
                vertical={true}
            >
                <Section>
                    <FormField
                        name="type"
                        required={true}
                        label={<Translate content="icon.form.type" />}
                    >
                        {({ setValue }: IFormProps<string>) => (
                            <SegmentedControl
                                buttons={typeOptions}
                                selected={item.type}
                                onChange={setValue}
                            />
                        )}
                    </FormField>
                    <FormFieldText
                        name="name"
                        size="fill"
                        label={<Translate content="icon.form.name" />}
                        required={true}
                    />
                    <FormFieldText
                        name="url"
                        size="fill"
                        label={<Translate content="icon.form.url" />}
                        required={true}
                    />
                </Section>
            </Form>
            <Button
                type="submit"
                form="iconForm"
                fill={true}
                intent={INTENT_TYPE.PRIMARY}
                size="lg"
                isLoading={isItemSaving}
            >
                <Translate content="icon.form.saveBtn" />
            </Button>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    item: state.icon.item,
    isItemSaving: state.app.loadingFlags.has(LOADING_FLAGS.ICON_ITEM_SAVE),
    formErrors: nestStringProperties(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    iconActions: bindActionCreators(iconActions, dispatch),
    formErrorsActions: bindActionCreators(formErrorsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(IconForm);
