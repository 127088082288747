// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import * as toasterActions from 'common/modules/app/toaster/actions';
import { connect } from 'react-redux';
import Menu from 'admin/menu/containers/Menu/Menu';
import Header from 'admin/common/containers/Header/Header';
import { LayoutContainer } from 'admin/common/containers/Styles';
import { StyledTabs } from 'client/common/styles/Tabs';
import NotificationPane from 'admin/notification/containers/NotificationPane/NotificationPane';
import { ErrorBoundary } from 'admin/common/components/ErrorBoundary/ErrorBoundary';
import { hasPermission } from 'common/modules/permission/selectors';
import { PERMISSION_LIST } from 'common/modules/permission/constants';
import AccessForbidden from 'common/components/ErrorPages/AccessForbidden/AccessForbidden';
import { Redirect } from 'react-router';
import { RootState } from 'admin/core/store';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { Toaster } from '@plesk/ui-library';
import {
    getBackgroundToasts,
    getForegroundToasts,
} from 'common/helpers/toaster';
import LicenseTopBar from 'admin/common/components/LicenseTopBar/LicenseTopBar';

export type AdminLayoutProps =
    React.HTMLProps<HTMLElement> &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const AdminLayout: React.FC<AdminLayoutProps> = ({
    isAuth,
    children,
    toaster,
    toasterActions: { dropToast },
    canViewAdminArea,
}) => {
    const [isOpen, setIsOpen] = React.useState(false);

    const toggleOpen = () => setIsOpen(!isOpen);

    if (!isAuth) {
        return (<Redirect to="/admin/login" />);
    }

    if (!canViewAdminArea) {
        return (<AccessForbidden/>);
    }

    const handleToastClose = (key: string) => dropToast(key);

    return (
        <LayoutContainer
            header={<Header isOpen={isOpen} onOpen={toggleOpen} />}
            sidebar={<Menu />}
        >
            <StyledTabs theme={{ primary_color: '#28aade' }} />
            <LicenseTopBar />
            <Toaster className="toaster" toasts={getForegroundToasts(toaster)} onToastClose={handleToastClose} />
            <Toaster
                className="toaster"
                position="bottom-end"
                toasts={getBackgroundToasts(toaster)}
                onToastClose={handleToastClose}
            />
            <ErrorBoundary>
                {children}
            </ErrorBoundary>
            <NotificationPane isOpen={isOpen} />
        </LayoutContainer>
    );
};

const mapStateToProps = (state: RootState) => ({
    isAuth: state.auth.isAuth,
    toaster: state.app.toaster,
    canViewAdminArea: hasPermission(state, PERMISSION_LIST.VIEW_ADMIN_AREA),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    toasterActions: bindActionCreators(toasterActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminLayout);
