// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import styled from 'styled-components';
import { FormFieldSelect } from '@plesk/ui-library';
import { COLORS } from 'admin/core/theme';
import { StyledSelector } from 'common/containers/LanguageSelector/Styles';

export const StyledSettings = styled.div`
  max-width: 1200px;
  margin-top: 25px;
`;

export const Logo = styled.div`
  position: relative;

  img {
    max-height: 150px;
  }
`;

export const StrategySelector = styled(FormFieldSelect)`
  .pul-form-field__description {
    max-width: 600px;
  }
`;

export const SectionDescription = styled.div`
  margin-bottom: 10px;
  color: ${ COLORS.GREY_80 };
`;

export const Label = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 500px;
`;

export const LanguageSelectorContainer = styled.div`
  display: flex;

  .pul-form-field-checkbox {
    margin-left: 16px;

    .pul-checkbox__text {
      white-space: nowrap;
    }
  }

  ${StyledSelector} {
    width: 250px;
  }
`;

export const SettingsDialogToolbar = styled.div`
  margin-top: 24px;
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;

  .pul-button {
    margin-left: 16px;
  }
`;

export const SettingsSubmitToolbar = styled.div`
  margin-top: 16px;
  margin-bottom: 8px;

  .pul-button:not(:first-child) {
    margin-left: 16px;
  }
`;
