// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import * as taskActions from 'admin/task/actions';
import { RootState } from 'admin/core/store';
import TaskTable from 'admin/task/containers/TaskTable';
import { useRequestCancellationEffect } from 'common/hooks/useRequestCancellationEffect';

export type TasksTabProps =
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const TasksTab: React.FC<TasksTabProps> = ({
    taskActions: {
        getTaskList,
    },
    computeResourceId,
}) => {
    const filters = {
        action: '',
        status: '',
        compute_resource_id: computeResourceId,
    };

    const isFirstLoading = useRequestCancellationEffect(
        token => getTaskList({ filters }, token),
        []
    );

    return (
        <TaskTable
            excludedColumns={['colComputeResource']}
            isFirstLoading={isFirstLoading}
            filters={filters}
        />
    );
};

const mapStateToProps = (state: RootState) => ({
    tasks: state.task.list.data,
    computeResourceId: state.computeResource.item.id,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    taskActions: bindActionCreators(taskActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TasksTab);

