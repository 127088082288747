// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import {
    IPaginated,
    IRequest,
} from 'common/api/resources/Request/request';

export enum IconType {
    OS = 'os',
    APPLICATION = 'application',
    FLAGS = 'flags'
}

export interface IIconRequest {
    name: string;
    url: string;
    type: IconType;
}

export interface IIconResponse {
    id: number;
    name: string;
    url: string;
    is_deleting: boolean;
    type: IconType;
}

export interface IIconListFilters {
    type?: IconType;
    search?: string;
}

export interface IIconListRequest extends IRequest<IIconListFilters>, IPaginated {}
