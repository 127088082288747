// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

export const PROJECT_SERVER_PAGE = {
    LOCATION_CARD: 'location-card',
    OS_IMAGE_CARD: 'os-image-card',
    APPLICATION_CARD: 'application-card',
    APPLICATION_TAB: 'application-tab',
    PLAN_CARD: 'plan-card',
    CREATE_BUTTON: 'create-button',
    HEADER: 'header',
};

export const OVERVIEW_TAB = {
    CPU: 'cpu-div',
};

export const APPLICATION_LOGIN_LINK = {
    TEST_BUTTON: 'login-link-button',
    IFRAME: 'iframe',
};

export const PROJECT_SERVER_ITEM = {
    TAB_STATUS: 'tab-status',
};
