// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as RootActions from 'common/modules/root/actions';
import {
    Action,
    AnyAction,
    Reducer,
} from 'redux';
import { getType } from 'typesafe-actions';

/**
 * Decorator for Admin and Client root reducers.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const rootReducerDecorator = <S = any, A extends Action = AnyAction>(reducer: Reducer<S, A>) => (state: S | undefined, action: A): S => {
    if (action.type === getType(RootActions.resetState)) {
        state = undefined;
    }

    return reducer(state, action);
};
