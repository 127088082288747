// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import {
    IPlanLimits,
    IPlanUpdateLimits,
    NetworkTrafficLimitTypes,
} from 'common/api/resources/Plan';
import PlanLimit from 'common/components/plan/components/PlanLimit';
import { FORM } from 'admin/plan/constants/tests';
import ResetLimitField from 'common/components/plan/components/ResetLimitField';
import {
    FormField,
    Heading,
    Item,
    Section,
    Translate,
    useTranslate,
} from '@plesk/ui-library';
import {
    BandwidthUnit,
    DataUnit,
} from 'common/helpers/units';
import { IFormProps } from 'common/components/Form/types';
import { StyledItemList } from 'common/components/plan/components/Styles';

export interface INetworkLimitsSectionProps {
    limits: IPlanLimits | IPlanUpdateLimits;
    limitType: NetworkTrafficLimitTypes;
}

const NetworkLimitsSection: React.FC<INetworkLimitsSectionProps> = ({
    limits,
    limitType,
}) => {
    const translate = useTranslate();
    const localizationMap = React.useMemo(() => ({
        [NetworkTrafficLimitTypes.Separate]: translate('plan.actionDialog.networkTrafficLimitTypes.separate'),
        [NetworkTrafficLimitTypes.Total]: translate('plan.actionDialog.networkTrafficLimitTypes.total'),
    }), [translate]);

    return (
        <Section
            title={
                <Translate content="plan.actionDialog.networkLimits"/>
            }
            collapsible={true}
            collapsed={true}
            data-cy={FORM.NETWORK_LIMITS_SECTION}
        >
            <PlanLimit
                data-cy={FORM.NETWORK_LIMITS.INCOMING_BANDWIDTH}
                limit={limits.network_incoming_bandwidth}
                limitName="network_incoming_bandwidth"
                units={BandwidthUnit}
                label={
                    <Translate content="plan.actionDialog.limits.network.incomingBandwidth"/>
                }
            />
            <PlanLimit
                data-cy={FORM.NETWORK_LIMITS.OUTGOING_BANDWIDTH}
                limit={limits.network_outgoing_bandwidth}
                limitName="network_outgoing_bandwidth"
                units={BandwidthUnit}
                label={
                    <Translate content="plan.actionDialog.limits.network.outgoingBandwidth"/>
                }
            />
            <FormField
                name="network_traffic_limit_type"
                value={limitType}
            >
                {({ setValue, getValue }: IFormProps<NetworkTrafficLimitTypes>) => (
                    <>
                        <Heading level={4}>
                            <Translate content="plan.actionDialog.networkTrafficLimitType"/>
                        </Heading>
                        <StyledItemList
                            selectable={true}
                            minColumnWidth={205}
                            value={getValue()}
                            onSelect={setValue}
                            gap="md"
                            md="auto"
                        >
                            {Object.values(NetworkTrafficLimitTypes).map((networkTrafficLimitType) => (
                                <Item
                                    title={localizationMap[networkTrafficLimitType]}
                                    key={networkTrafficLimitType}
                                    value={networkTrafficLimitType}
                                />
                            ))}
                        </StyledItemList>
                    </>
                )}
            </FormField>
            {limitType === NetworkTrafficLimitTypes.Separate && (
                <>
                    <PlanLimit
                        data-cy={FORM.NETWORK_LIMITS.INCOMING_TRAFFIC}
                        limit={limits.network_incoming_traffic}
                        limitName="network_incoming_traffic"
                        units={DataUnit}
                        label={
                            <Translate content="plan.actionDialog.limits.network.incomingTraffic"/>
                        }
                    />
                    <PlanLimit
                        data-cy={FORM.NETWORK_LIMITS.OUTGOING_TRAFFIC}
                        limit={limits.network_outgoing_traffic}
                        limitName="network_outgoing_traffic"
                        units={DataUnit}
                        label={
                            <Translate content="plan.actionDialog.limits.network.outgoingTraffic"/>
                        }
                    />
                </>
            )}
            {limitType === NetworkTrafficLimitTypes.Total && (
                <PlanLimit
                    data-cy={FORM.NETWORK_LIMITS.TOTAL_TRAFFIC}
                    limit={limits.network_total_traffic}
                    limitName="network_total_traffic"
                    units={DataUnit}
                    label={
                        <Translate content="plan.actionDialog.limits.network.totalTraffic"/>
                    }
                />
            )}
            <ResetLimitField />
            <PlanLimit
                data-cy={FORM.NETWORK_LIMITS.REDUCE_BANDWIDTH}
                limit={limits.network_reduce_bandwidth}
                limitName="network_reduce_bandwidth"
                units={BandwidthUnit}
                label={
                    <Translate content="plan.actionDialog.limits.network.reduceBandwidth"/>
                }
            />
        </Section>
    );
};

export default NetworkLimitsSection;
