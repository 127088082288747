// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import React from 'react';
import {
    Icon,
    Link,
    Popover,
    Translate,
} from '@plesk/ui-library';
import moment from 'moment';
import { Dialog } from 'admin/common/components/Dialog/Dialog';
import { IBackupSettings } from 'common/api/resources/ComputeResourceVm';
import { IConfirmOn } from 'common/components/SwitchWithConfirmation';
import { ACTIONS } from 'common/modules/backup/constants/tests';
import { IPlanLimit } from 'common/api/resources/Plan';
import {
    ICONS,
    SIZE,
} from 'common/constants';
import { CardWithSwitch } from 'common/components/CardWithSwitch/CardWithSwitch';
import {
    CardWithSwitchLink,
    CardWithSwitchLinkIcon,
} from 'common/components/CardWithSwitch/Styles';
import { connect } from 'react-redux';
import { hasPermission } from 'common/modules/permission/selectors';
import { PERMISSION_LIST } from 'common/modules/permission/constants';
import { ICommonState } from 'common/store';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { SETTINGS } from 'common/containers/BackupCard/constants/tests';
import { getProcessedErrors } from 'common/modules/app/formErrors/selectors';
import BackupSettingsForm from 'common/containers/BackupSettingsForm/BackupSettingsForm';

export interface IBackupCardProps {
    backupSettings: IBackupSettings;
    setBackupSettings: (newBackupSettings: IBackupSettings) => void;
    isBackupSettingsUpdating: boolean;
    nextScheduledBackupAt: string | null;
    shouldConfirmOnDisable?: boolean;
    disabled?: boolean;
    backupPrice: number;
    additionalConfirmTextOnEnable?: React.ReactNode;
    backupsNumber?: IPlanLimit;
}

export type BackupCardProps =
    IBackupCardProps &
    ReturnType<typeof mapStateToProps>;

export const BackupCard: React.FC<BackupCardProps> = ({
    nextScheduledBackupAt,
    backupSettings,
    setBackupSettings,
    isBackupSettingsUpdating,
    shouldConfirmOnDisable,
    disabled,
    backupPrice,
    additionalConfirmTextOnEnable,
    backupsNumber,
}) => {
    const [isDialogOpened, setIsDialogOpened] = React.useState(false);

    const confirmOnEnable: IConfirmOn = {
        enabled: backupPrice > 0 || additionalConfirmTextOnEnable !== undefined,
        translations: {
            title: (
                <Translate content="backupCard.enableConfirm.default.title" />
            ),
            text: (
                <>
                    {backupPrice > 0 && (
                        <Translate
                            content="backupCard.enableConfirm.default.text"
                            params={{ percent: backupPrice }}
                        />
                    )}
                    {additionalConfirmTextOnEnable}
                </>
            ),
            button: (
                <Translate content="backupCard.enableConfirm.default.button" />
            ),
        },
    };

    const confirmOnDisable: IConfirmOn = {
        enabled: !!shouldConfirmOnDisable,
        translations: {
            title: (
                <Translate content='backupCard.disableConfirm.title' />
            ),
            text: (
                <Translate content='backupCard.disableConfirm.text' />
            ),
            button: (
                <Translate content='backupCard.disableConfirm.button' />
            ),
        },
    };

    const handleBackupsEnabledChange = (isEnabled: boolean) => {
        setBackupSettings({
            ...backupSettings,
            enabled: isEnabled,
        });
    };

    return (
        <CardWithSwitch
            title={<Translate content='backupCard.title' />}
            isEnabled={backupSettings.enabled}
            isLoading={isBackupSettingsUpdating}
            isDisabled={disabled}
            handleChange={handleBackupsEnabledChange}
            confirmOnEnable={confirmOnEnable}
            confirmOnDisable={confirmOnDisable}
            switch-data-cy={ACTIONS.ENABLE_SWITCH}
        >
            {backupSettings.enabled ? (
                <>
                    {nextScheduledBackupAt && (
                        <div>
                            <Translate
                                content="backupCard.content.enabled"
                                params={{
                                    schedule_date: moment(nextScheduledBackupAt).format('DD-MM-YYYY HH:mm'),
                                }}
                                data-cy={ACTIONS.NEXT_BACKUP_AT}
                            />
                        </div>
                    )}
                    {backupsNumber !== undefined && (
                        <>
                            {backupsNumber.is_enabled ? (
                                <Translate content="backupCard.backupsNumber.limited" params={{
                                    limit: backupsNumber.limit,
                                }}/>
                            ) : (
                                <Translate content="backupCard.backupsNumber.unlimited"/>
                            )}
                            <Popover
                                target={(
                                    <Icon name={ICONS.INFO_CIRCLE} size="12" className="pul-form-field__full-description"/>
                                )}>
                                <Translate content="backupCard.backupsNumber.description" />
                            </Popover>
                        </>
                    )}
                    <CardWithSwitchLink>
                        <CardWithSwitchLinkIcon name="calendar-clock"/>
                        <Link onClick={() => setIsDialogOpened(true)} disabled={disabled}>
                            <Translate content="backupCard.scheduleSettings"/>
                        </Link>
                    </CardWithSwitchLink>
                    <Dialog
                        heading={<Translate content="backupCard.settingsTitle"/>}
                        closeHandler={() => setIsDialogOpened(false)}
                        isOpen={isDialogOpened}
                        size={SIZE.XS}
                        data-cy={SETTINGS.DIALOG}
                    >
                        <BackupSettingsForm
                            backupSettings={backupSettings}
                            setBackupSettings={setBackupSettings}
                            onSubmit={() => setIsDialogOpened(false)}
                        />
                    </Dialog>
                </>
            ) : (
                <Translate content="backupCard.content.disabled"/>
            )}
        </CardWithSwitch>
    );
};

const mapStateToProps = (state: ICommonState) => ({
    canManageBackups: hasPermission(state, PERMISSION_LIST.MANAGE_BACKUPS),
    isSaving: state.app.loadingFlags.has(LOADING_FLAGS.COMPUTE_RESOURCE_VM_BACKUP_SETTINGS_UPDATE),
    formErrors: getProcessedErrors(state),
});

export default connect(mapStateToProps)(BackupCard);
