// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import styled from 'styled-components';

export const AuthContainer = styled.div`
    background: #ffffff;
    min-width: 400px;
    max-width: 450px;
    padding: 48px 60px 24px;
    box-shadow: 0 1px 3px rgba(60, 64, 67, 0.16), 0 1px 1px rgba(60, 64, 67, 0.08);
    border-radius: 2px;
    
    @media (max-width: 560px) {
      min-width: 0;
      border-radius: 0;
      padding: 36px 20px 24px;
      width: 100%;
    }

    .react-tabs__tab-list {
        margin-bottom: 30px;
    }

    .react-tabs__tab {
        font-weight: 600;
        font-size: 20px;
        line-height: 26px;
        padding-bottom: 4px;
    }
`;
