// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import {
    ActionType,
    getType,
} from 'typesafe-actions';
import * as responseErrorActions from 'common/modules/app/responseError/actions';

export interface IResponseError {
    code: number;
    error: string;
}

export type ResponseErrorAction = ActionType<typeof responseErrorActions>;
export type ResponseErrorState = Readonly<IResponseError>;

const initialItemState: IResponseError = {
    code: 0,
    error: '',
};

export default (state: IResponseError = initialItemState, action: ResponseErrorAction) => {
    switch (action.type) {
    case getType(responseErrorActions.setResponseError):
        return action.payload;
    case getType(responseErrorActions.clearResponseError):
        return { ...initialItemState };
    default:
        return state;
    }
};
