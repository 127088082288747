// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import styled from 'styled-components';

interface IHeaderButtonContainer {
    isMobile?: boolean;
}

export const HeaderButtonContainer = styled.div<IHeaderButtonContainer>`
    .pul-button {
        height: 40px;
        font-size: 14px;
        line-height: 20px;
        padding: 10px 32px;
        margin-top: 10px;

        .pul-button__icon {
            margin-right: 8px!important;
            opacity: unset!important;
        }
    }

    ${({ isMobile }) => isMobile && `
        @media (max-width: 560px) {
            .pul-button {
                width: unset;
                padding: 12px;
                margin-top: 8px;

                .pul-button__inner {
                    span:last-child {
                        display: none;
                    }
                }

                .pul-button__icon {
                    opacity: unset!important;
                    margin: 0!important;
                }
            }
        }
    `}
`;