// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import styled from 'styled-components';
import { COLORS } from 'admin/core/theme';

export const DashboardItemContainer = styled.div`
    .pul-media__header > span {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .pul-media__figure {
        .pul-icon--info {
            color: ${COLORS.PRIMARY}
        }
    }
`;

export const ItemWithTableContainer = styled(DashboardItemContainer)`
    .pul-item__content {
        margin-left: -40px;
        margin-top: 22px;
    }
`;

export const ItemDescription = styled.span`
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    padding-right: 6px;
   
    color: ${COLORS.GREY_100};
`;

export const ItemAdditionalDescription = styled.span`
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    display: inline-block;

    color: ${COLORS.GREY_80};

    span:last-child {
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

export const StatusContainer = styled.div`
    display: flex;

    .pul-action {
        a span {
            color: ${COLORS.PRIMARY};
        }
    }
`;

export const StatusLinks = styled.div`
    margin-right: 12px;

    .pul-action {
        display: block;
        margin-top: 8px;
    }

    .pul-status {
        span:last-child {
            font-size: 14px;
            line-height: 20px;
        }
    }

    .pul-status--compact:not(.pul-action--icon) .pul-status:before {
        margin-top: 6px;
    }
`;

export const StatusCount = styled.div`
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: end;
    margin-top: 8px;
`;
