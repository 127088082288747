// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import {
    api,
    IApiResponse,
} from 'common/api/resources/Response';
import {
    IReverseDnsPatchRequest,
    IReverseDnsCreateRequest,
    IReverseDnsResponse,
} from 'common/api/resources/ReverseDns/model';

export const reverseDns = {
    create: (data: IReverseDnsCreateRequest) => api.post<IApiResponse<IReverseDnsResponse>>('reverse_dns', data),
    patch: (id: number, data: Partial<IReverseDnsPatchRequest>) => api.patch<IApiResponse<IReverseDnsResponse>>(`reverse_dns/${id}`, data),
    remove: (id: number) => api.delete(`reverse_dns/${id}`),
};
