// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as graphActions from 'common/modules/graph/actions';
import { combineReducers } from 'redux';
import {
    ActionType,
    getType,
} from 'typesafe-actions';
import {
    ICpuItem,
    IDisksItem,
    IGraphResponse,
    IMemoryItem,
    INetworkItem,
} from 'common/api/resources/Graph';

interface IGraph {
    cpu: IGraphResponse<ICpuItem[]>;
    network: IGraphResponse<INetworkItem[]>;
    disks: IGraphResponse<IDisksItem[]>;
    memory: IGraphResponse<IMemoryItem[]>;
}

export const cpuGraphInitialState: IGraphResponse<ICpuItem[]> = {
    name: 'cpu',
    uuid: '',
    interval: {
        value: 5,
        measurement: 'minute',
    },
    items: [],
};

export const memoryGraphInitialState: IGraphResponse<IMemoryItem[]> = {
    name: 'memory',
    uuid: '',
    interval: {
        value: 5,
        measurement: 'minute',
    },
    items: [],
};

export const networkGraphInitialState: IGraphResponse<INetworkItem[]> = {
    name: 'network',
    uuid: '',
    interval: {
        value: 5,
        measurement: 'minute',
    },
    items: [],
};

export const disksGraphInitialState: IGraphResponse<IDisksItem[]> = {
    name: 'disks',
    uuid: '',
    interval: {
        value: 5,
        measurement: 'minute',
    },
    items: [],
};

export type GraphAction = ActionType<typeof graphActions>;
export type GraphState = Readonly<IGraph>;

export default combineReducers<GraphState, GraphAction>({
    cpu: (state = cpuGraphInitialState, action: GraphAction) => {
        switch (action.type) {
        case getType(graphActions.setCpu):
            return action.payload;
        case getType(graphActions.updateChart):
            if (state.items.length === 0) {
                return state;
            }

            const lastElement = state.items[state.items.length - 1];
            const cpuTimeDerivative = action.payload.cpu.cpu_time -  lastElement.cpu_time;
            const loadAverage = (cpuTimeDerivative / action.payload.cpu.cpu_count / state.interval.value) * 100;

            return {
                ...state,
                items: [
                    ...state.items.slice(1),
                    {
                        cpu_time: action.payload.cpu.cpu_time,
                        cpu_count: action.payload.cpu.cpu_count,
                        load_average: loadAverage > 0 && lastElement.cpu_time > 0 ? loadAverage * state.interval.value : 0,
                        time: action.payload.time,
                    },
                ],
            };
        default:
            return state;
        }
    },
    network: (state = networkGraphInitialState, action: GraphAction) => {
        switch (action.type) {
        case getType(graphActions.setNetwork):
            return action.payload;
        case getType(graphActions.updateChart):
            if (state.items.length === 0) {
                return state;
            }

            const lastElement = state.items[state.items.length - 1];
            const readKb = action.payload.network.read_kb - lastElement.raw.read_kb;
            const writeKb = action.payload.network.write_kb - lastElement.raw.write_kb;

            return {
                ...state,
                items: [
                    ...state.items.slice(1),
                    {
                        raw: {
                            write_kb: action.payload.network.write_kb,
                            read_kb: action.payload.network.read_kb,
                        },
                        derivative: {
                            write_kb: writeKb > 0 && lastElement.raw.write_kb ? writeKb : 0,
                            read_kb: readKb > 0 && lastElement.raw.read_kb ? readKb : 0,
                        },
                        time: action.payload.time,
                    },
                ],
            };
        default:
            return state;
        }
    },
    disks: (state = disksGraphInitialState, action: GraphAction) => {
        switch (action.type) {
        case getType(graphActions.setDisks):
            return action.payload;
        case getType(graphActions.updateChart):
            if (state.items.length === 0) {
                return state;
            }

            const lastElement = state.items[state.items.length - 1];
            const writeKb = action.payload.disks.write_kb - lastElement.raw.write_kb;
            const readKb = action.payload.disks.read_kb - lastElement.raw.read_kb;

            return {
                ...state,
                items: [
                    ...state.items.slice(1),
                    {
                        raw: {
                            write_kb: action.payload.disks.write_kb,
                            read_kb: action.payload.disks.read_kb,
                        },
                        derivative: {
                            write_kb: writeKb > 0 && lastElement.raw.write_kb > 0 ? writeKb : 0,
                            read_kb: readKb > 0 && lastElement.raw.read_kb > 0 ? readKb : 0,
                        },
                        time: action.payload.time,
                    },
                ],
            };
        default:
            return state;
        }
    },
    memory: (state = memoryGraphInitialState, action: GraphAction) => {
        switch (action.type) {
        case getType(graphActions.setMemory):
            return action.payload;
        case getType(graphActions.updateChart):
            return {
                ...state,
                items: [
                    ...state.items.slice(1),
                    {
                        memory: action.payload.memory,
                        time: action.payload.time,
                    },
                ],
            };
        default:
            return state;
        }
    },
});
