// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import { AxiosResponse } from 'axios';

export type IAxiosApiResponse<I> = AxiosResponse<IApiResponse<I>>;

export interface IApiResponse<I> {
    data: I;
}

export interface ILinks {
    first: string;
    last: string;
    next: string;
    prev: string;
}

export interface IMeta {
    total: number;
    to: number;
    last_page: number;
    from: number;
    per_page: number;
    current_page: number;
}

export interface IPaginateApiResponse<I> {
    data: I;
    links: ILinks;
    meta: IMeta;
}

export const paginateInitialState = {
    data: [],
    links: {
        first: '',
        last: '',
        next: '',
        prev: '',
    },
    meta: {
        total: 0,
        to: 0,
        last_page: 0,
        from: 0,
        per_page: 0,
        current_page: 0,
    },
};
