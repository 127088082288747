// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import { PROJECT_SERVER_PAGE } from 'client/project/constants/tests';
import { IApplicationResponse } from 'common/api/resources/Application';
import { ServerIcon } from 'common/components/ServerIcon/ServerIcon';
import { COLORS } from 'common/theme';
import { Icon } from '@plesk/ui-library';
import { CutTitle } from 'common/components';
import { ICONS } from 'common/constants';
import {
    ApplicationItem,
    ItemContainer,
    ItemContent,
    ItemContentName,
    LogoContainer,
} from 'common/components/serverType/Styles';
import { dataCySelector } from 'common/tests/selectors';

export interface IApplicationCardProps {
    application: IApplicationResponse;
    isSelected: boolean;
    onApplicationSelected: (id: number) => void;
    disabled?: boolean;
}

export const ApplicationCard: React.FC<IApplicationCardProps> = ({
    application,
    onApplicationSelected,
    isSelected,
    disabled,
}) => {
    const handleClick = () => !disabled && onApplicationSelected(application.id);

    return (
        <ApplicationItem onClick={handleClick} isSelected={isSelected} isDisabled={disabled}>
            <ItemContainer data-cy={dataCySelector(application.id, PROJECT_SERVER_PAGE.APPLICATION_CARD)}>
                <div>
                    <LogoContainer>
                        {isSelected ? <Icon name={ICONS.CHECK_MARK} /> : <ServerIcon width={25} icon={application.icon} />}
                    </LogoContainer>
                </div>
                <ItemContent>
                    <ItemContentName data-cy={PROJECT_SERVER_PAGE.APPLICATION_CARD}>
                        <CutTitle title={application.name} />
                    </ItemContentName>
                </ItemContent>
                {!application.is_visible && (
                    <Icon name="eye-closed" size="16" style={{ color: isSelected ? COLORS.WHITE_0 : COLORS.GREY_60 }}/>
                )}
            </ItemContainer>
        </ApplicationItem>
    );
};
