// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import {
    Form,
    FormFieldText,
    Translate,
} from '@plesk/ui-library';
import { Button } from 'admin/common/components/Button/Button';
import { INTENT_TYPE } from 'common/constants';
import { RootState } from 'admin/core/store';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import * as formErrorsActions from 'common/modules/app/formErrors/actions';
import { connect } from 'react-redux';
import {
    requiredRule,
    validate,
} from 'common/validator';
import * as licenseActions from 'admin/license/solusVM/actions';
import * as responseErrorActions from 'common/modules/app/responseError/actions';

import { IActivateSolusVMLicenseRequest } from 'common/api/resources/License/solusVM';
import FormErrors from 'common/components/Form/FormErrors/FormErrors';

interface ILicenseActivationFormProps {
    onSubmit: () => void;
}

export type LicenseActivationFormProps =
    ILicenseActivationFormProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const LicenseActivationForm: React.FC<LicenseActivationFormProps> = ({
    isActivating,
    errors,
    responseError,
    formErrorsActions: { setFormErrors, clearFormErrors },
    responseErrorActions: { clearResponseError },
    licenseActions: { activateLicense },
    onSubmit,
}) => {
    React.useEffect(() => {
        clearFormErrors();
        clearResponseError();
    }, [clearFormErrors, clearResponseError]);

    const handleSubmit = async (values: IActivateSolusVMLicenseRequest) => {
        clearResponseError();

        const validationErrors = validate<IActivateSolusVMLicenseRequest>(values, {
            activation_code: requiredRule(<Translate content="validate.fieldRequired" />),
        });

        if (Object.keys(validationErrors).length) {
            setFormErrors(validationErrors);
            return;
        }

        try {
            await activateLicense(values);
            onSubmit();
        } catch (e) {
            throw e;
        }
    };

    return (
        <>
            <Form
                id="licenseForm"
                onSubmit={handleSubmit}
                errors={errors}
                footerClassName="hidden"
                hideRequiredLegend={true}
                submitButton={false}
                cancelButton={false}
                applyButton={false}
                vertical={true}
            >
                <FormFieldText
                    name="activation_code"
                    size="fill"
                    label={<Translate content="license.form.activationCode" />}
                    required={true}
                />
                <Button
                    type="submit"
                    form="licenseForm"
                    fill={true}
                    intent={INTENT_TYPE.PRIMARY}
                    size="lg"
                    isLoading={isActivating}
                >
                    <Translate content="license.form.activateBtn" />
                </Button>
                {responseError && (<FormErrors errors={[responseError]} />)}
            </Form>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    isActivating: state.app.loadingFlags.has(LOADING_FLAGS.SOLUSVM_LICENSE_ACTIVATION),
    errors: state.app.formErrors,
    responseError: state.app.responseError.error,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    licenseActions: bindActionCreators(licenseActions, dispatch),
    formErrorsActions: bindActionCreators(formErrorsActions, dispatch),
    responseErrorActions: bindActionCreators(responseErrorActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(LicenseActivationForm);
