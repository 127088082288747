// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

export const SETTINGS_TAB = '#settings';
export const API_TOKENS_TAB = '#api-tokens';
export const SSH_KEYS_TAB = '#ssh-keys';

export const ACCOUNT_TABS = {
    [SETTINGS_TAB]: 0,
    [API_TOKENS_TAB]: 1,
    [SSH_KEYS_TAB]: 2,
};
