// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import { COMPUTE_RESOURCE_STATUS } from 'admin/computeResource/constants';

export enum NOTIFICATION_TYPE {
    COMPUTE_RESOURCE_STATUS_CHANGE = 'ComputeResourceStatusChange',
    NEW_VERSION_AVAILABLE = 'NewVersionAvailable',
    VERSION_UPDATED = 'VersionUpdated',
    BACKUP_CREATED = 'BackupCreated',
    BACKUP_RESTORED = 'BackupRestored',
    INCOMING_TRAFFIC_EXCEEDED = 'IncomingTrafficExceeded',
    OUTGOING_TRAFFIC_EXCEEDED = 'OutgoingTrafficExceeded',
    MANAGEMENT_NODE_BACKUP_CREATED = 'ManagementNodeBackupCreated',
    MANAGEMENT_NODE_BACKUP_RESTORED = 'ManagementNodeBackupRestored',
    MANAGEMENT_NODE_BACKUP_RECOVERED = 'ManagementNodeBackupRecovered',
}

interface IComputeResourceStatusChange {
    id: number;
    name: string;
    date: string;
    status: COMPUTE_RESOURCE_STATUS;
}

interface IVersionUpdateAvailable {
    version: string;
    date: string;
}

interface IBackupNotification {
    compute_resource_vm: {
        id: number;
        name: string;
    };
}

interface ITrafficExceeded {
    name: string;
    date: string;
}

export interface INotificationResponse {
    id: number;
    data:
        IComputeResourceStatusChange &
        IVersionUpdateAvailable &
        IBackupNotification &
        ITrafficExceeded &
        IManagementNodeBackupNotification &
        IManagementNodeBackupRecoveryNotification;
    type: NOTIFICATION_TYPE;
}

export interface IEmailNotification {
    default_subject: string;
    default_body: string;
}

export interface IManagementNodeBackupNotification {
    id: number;
    date: string;
}

export interface IManagementNodeBackupRecoveryNotification {
    backup_node_name: string;
    management_node_backup_count: number;
}
