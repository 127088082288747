// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import {
    Action,
    Translate,
} from '@plesk/ui-library';
import { INTENT_TYPE } from 'common/constants';
import { Status } from 'common/components/styles/Status';
import { ComputeResourceVmStatus } from 'common/api/resources/ComputeResourceVm';

export interface IVMStatusProps {
    status: string;
    isProcessing: boolean;
    progress?: number;
    compact?: boolean;
}

export const statusMappings = {
    [ComputeResourceVmStatus.CREATING]: <Translate content="servers.status.creating" />,
    [ComputeResourceVmStatus.RESIZING]: <Translate content="servers.status.resizing" />,
    [ComputeResourceVmStatus.RESTARTING]: <Translate content="servers.status.restarting" />,
    [ComputeResourceVmStatus.STOPPING]: <Translate content="servers.status.stopping" />,
    [ComputeResourceVmStatus.STARTING]: <Translate content="servers.status.starting" />,
    [ComputeResourceVmStatus.SUSPENDING]: <Translate content="servers.status.suspending" />,
    [ComputeResourceVmStatus.RESUMING]: <Translate content="servers.status.resuming" />,
    [ComputeResourceVmStatus.REINSTALLING]: <Translate content="servers.status.reinstalling" />,
    [ComputeResourceVmStatus.MIGRATING]: <Translate content="servers.status.migrating" />,
    [ComputeResourceVmStatus.BACKUP_RESTORING]: <Translate content="servers.status.backupRestoring" />,
    [ComputeResourceVmStatus.BACKUP_CREATING]: <Translate content="servers.status.backupCreating" />,
    [ComputeResourceVmStatus.SNAPSHOT_CREATING]: <Translate content="servers.status.snapshotCreating" />,
    [ComputeResourceVmStatus.SNAPSHOT_REVERTING]: <Translate content="servers.status.snapshotReverting" />,
    [ComputeResourceVmStatus.SNAPSHOT_DELETING]: <Translate content="servers.status.snapshotDeleting" />,
    [ComputeResourceVmStatus.USAGE_RESETTING]: <Translate content="servers.status.usageResetting" />,
    [ComputeResourceVmStatus.CHANGING_PASSWORD]: <Translate content="servers.status.passwordChanging" />,
    [ComputeResourceVmStatus.UPDATING]: <Translate content="servers.status.updating" />,
    [ComputeResourceVmStatus.DELETING]: <Translate content="servers.status.deleting" />,
    [ComputeResourceVmStatus.NOT_EXIST]: <Translate content="servers.status.unknown" />,
    [ComputeResourceVmStatus.UNAVAILABLE]: <Translate content="servers.status.unavailable" />,
    [ComputeResourceVmStatus.STARTED]: <Translate content="servers.status.running" />,
    [ComputeResourceVmStatus.STOPPED]: <Translate content="servers.status.stopped" />,
    [ComputeResourceVmStatus.PAUSED]: <Translate content="servers.status.paused" />,
    [ComputeResourceVmStatus.NO_STATE]: <Translate content="servers.status.noState" />,
    [ComputeResourceVmStatus.ADDITIONAL_IP_ADDING]: <Translate content="servers.status.additionalIpAdding" />,
    [ComputeResourceVmStatus.ADDITIONAL_IP_DELETING]: <Translate content="servers.status.additionalIpDeleting" />,
    [ComputeResourceVmStatus.PRIMARY_IP_CHANGING]: <Translate content="servers.status.primaryIpChanging" />,
};

const intentMappings = {
    [ComputeResourceVmStatus.NOT_EXIST]: INTENT_TYPE.MUTED,
    [ComputeResourceVmStatus.UNAVAILABLE]: INTENT_TYPE.WARNING,
    [ComputeResourceVmStatus.PAUSED]: INTENT_TYPE.WARNING,
    [ComputeResourceVmStatus.STARTED]: INTENT_TYPE.SUCCESS,
    [ComputeResourceVmStatus.STOPPED]: INTENT_TYPE.DANGER,
    [ComputeResourceVmStatus.NO_STATE]: INTENT_TYPE.DANGER,
};

const statusWithProgress = [
    ComputeResourceVmStatus.CREATING,
    ComputeResourceVmStatus.REINSTALLING,
    ComputeResourceVmStatus.MIGRATING,
    ComputeResourceVmStatus.BACKUP_CREATING,
    ComputeResourceVmStatus.BACKUP_RESTORING,
];

export const VmStatus: React.FC<IVMStatusProps> = ({
    status,
    isProcessing,
    progress,
    compact,
}) => {
    if (isProcessing) {
        const processingProgress = statusWithProgress.includes(status as ComputeResourceVmStatus) ? `${progress ? progress : 0}%` : '';
        return (
            <Action progress={true}>
                {statusMappings[status]} {processingProgress}
            </Action>
        );
    }

    if (!intentMappings.hasOwnProperty(status)) {
        return null;
    }

    return (
        <Status
            intent={intentMappings[status]}
            compact={compact}
        >
            {statusMappings[status]}
        </Status>
    );
};
