// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import { ILanguageResponse } from 'common/api/resources/Language';
import { RootState } from 'admin/core/store';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { connect } from 'react-redux';
import * as languageActions from 'common/modules/language/actions';
import { PageHeader } from 'admin/common/components/PageHeader/PageHeader';
import {
    List,
    Switch,
    Translate,
} from '@plesk/ui-library';
import { StyledTable } from 'common/components/styles/StyledTable';
import InfiniteScroll from 'common/components/InfinityScroll/InfinityScroll';
import { Loader } from 'common/components';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { StyledActions } from 'common/components/Actions/Styles';
import { SetAsDefaultAction } from 'admin/common/components/SetAsDefaultAction';
import { dataCySelector } from 'common/tests/selectors';
import { TABLE_ACTIONS } from 'admin/language/constants/tests';
import { LanguageName } from 'common/components/LanguageName';
import { getActionColumnProps } from 'common/helpers/list';

export type LanguageProps =
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

const columns = [{
    key: 'colId',
    width: '1%',
    title: <Translate content="language.list.id" />,
}, {
    width: '15%',
    key: 'colName',
    title: <Translate content="language.list.name" />,
    cellProps: {
        className: 'cell-bold',
    },
}, {
    width: '15%',
    key: 'colLocale',
    title: <Translate content="language.list.locale" />,
}, {
    width: '15%',
    key: 'colCountry',
    title: <Translate content="language.list.country" />,
}, {
    width: '1%',
    key: 'colVisible',
    title: <Translate content="language.list.visible" />,
}, {
    key: 'colUsersCount',
    title: <Translate content="language.list.usersCount" />,
}, getActionColumnProps(),
];

export const Language: React.FC<LanguageProps> = ({
    languages,
    isLoadingList,
    languageActions: {
        getLanguages,
        loadLanguagesOnScroll,
        setLanguageDefault,
        setLanguageVisible,
    },
}) => {
    const [defaultChanging, setDefaultChanging] = React.useState<number | null>(null);
    const [visibilityChanging, setBlockVisibility] = React.useState<number | null>(null);

    React.useEffect(() => {
        getLanguages();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSetDefault = (language: ILanguageResponse) => async() => {
        if (defaultChanging) {
            return;
        }

        try {
            setDefaultChanging(language.id);
            await setLanguageDefault(language.id, !language.is_default);
        } finally {
            setDefaultChanging(null);
        }
    };

    const handleToggleVisibility = (language: ILanguageResponse) => async() => {
        if (visibilityChanging) {
            return;
        }

        try {
            setBlockVisibility(language.id);
            await setLanguageVisible(language.id, !language.is_visible);
        } finally {
            setBlockVisibility(null);
        }
    };

    const data = React.useMemo(() => languages.data.map((language) => {
        const actionsEl = (
            <StyledActions>
                <SetAsDefaultAction
                    title={<Translate content="language.tooltip.setAsDefault"/>}
                    isDefault={language.is_default}
                    isLoading={defaultChanging === language.id}
                    onClick={handleSetDefault(language)}
                    data-cy={dataCySelector(language.id, TABLE_ACTIONS.DEFAULT)}
                />
            </StyledActions>
        );

        return {
            colId: language.id,
            colName: <LanguageName language={language}/>,
            colLocale: language.locale,
            colCountry: language.country,
            colVisible: <Switch
                disabled={language.is_visible && language.users_count > 0}
                checked={language.is_visible}
                loading={visibilityChanging === language.id}
                onChange={handleToggleVisibility(language)}
                data-cy={dataCySelector(language.id, TABLE_ACTIONS.VISIBILITY)}
            />,
            colUsersCount: language.users_count,
            colActions: actionsEl,
            key: language.id.toString(),
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [languages.data, defaultChanging, visibilityChanging]);

    return (
        <>
            <PageHeader
                title={<Translate content="language.title"/>}
                isButtonShown={false}
            />
            <StyledTable>
                <InfiniteScroll
                    loadMore={loadLanguagesOnScroll}
                    hasMore={!!languages.links.next}
                >
                    <Loader isLoading={isLoadingList}>
                        {/* We can skip empty view here 'cause for now we always will have languages */}
                        <List
                            emptyView={null}
                            columns={columns}
                            data={data}
                        />
                    </Loader>
                </InfiniteScroll>
            </StyledTable>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    languages: state.language.list,
    isLoadingList: !state.language.list.data.length && state.app.loadingFlags.has(LOADING_FLAGS.LANGUAGE_LIST),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    languageActions: bindActionCreators(languageActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Language);
