// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import { IComputeResourceResponse } from 'common/api/resources/ComputeResource';

export enum IpBlockType {
    IPv4 = 'IPv4',
    IPv6 = 'IPv6',
}

export enum IpBlockListType {
    RANGE = 'range',
    SET = 'set',
}

export const IpBlockListTypeTranslations = {
    [IpBlockListType.RANGE]: 'ipBlock.listTypeRange',
    [IpBlockListType.SET]: 'ipBlock.listTypeSet',
};

export interface IReverseDns {
    zone: string;
    enabled: boolean;
}

export interface IIpBlockCreateRequest {
    name: string;
    list_type: IpBlockListType;
    type: IpBlockType;
    gateway: string;
    netmask?: string;
    ns_1: string;
    ns_2: string;
    compute_resources: string[];
    from: string;
    to: string;
    range: string;
    subnet: string;
    reverse_dns: IReverseDns;
}

export interface IIpBlockResponse {
    id: number;
    name: string;
    type: IpBlockType;
    list_type: IpBlockListType;
    gateway: string;
    netmask: string;
    ns_1: string;
    ns_2: string;
    compute_resources: IComputeResourceResponse[];
    from: string;
    to: string;
    is_deleting: boolean;
    range: string;
    subnet: string;
    reverse_dns: IReverseDns;
    reserved_ips_count: number;
    total_ips_count: string;
}
