// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import { Button } from 'admin/common/components/Button/Button';
import {
    Box,
    Container,
    MessageBox,
} from 'common/components/EmptyView/Styles';
import {
    Icon,
    Translate,
} from '@plesk/ui-library';
import { INTENT_TYPE } from 'common/constants';

export interface IEmptyViewProps {
    icon?: string;
    onButtonClick?: () => void;
    buttonText?: string;
    title: string;
    description?: string;
    customIcon?: React.ReactNode;
}

export const EmptyView: React.FC<IEmptyViewProps> = ({
    onButtonClick,
    buttonText,
    title,
    description,
    icon,
    customIcon,
}) => (
    <Container>
        <Box>
            {customIcon ? customIcon : <Icon size="128" name={icon} />}
            <MessageBox>
                <h3>
                    <Translate content={title}/>
                </h3>
                {description && (
                    <p>
                        <Translate content={description}/>
                    </p>
                )}
            </MessageBox>
            {buttonText && (
                <Button onClick={onButtonClick} size="lg" type="submit" intent={INTENT_TYPE.PRIMARY}>
                    <Translate content={buttonText}/>
                </Button>
            )}
        </Box>
    </Container>
);
