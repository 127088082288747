// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

export const cloudInitVersions = [
    { label: 'v0', value: 'v0' },
    { label: 'v0-centos6', value: 'v0-centos6' },
    { label: 'v0-debian9', value: 'v0-debian9' },
    { label: 'v2', value: 'v2' },
    { label: 'v2-centos', value: 'v2-centos' },
    { label: 'v2-alpine', value: 'v2-alpine' },
    { label: 'v2-debian10', value: 'v2-debian10' },
    { label: 'cloudbase', value: 'cloudbase' },
];
