// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

export const MANAGEMENT_NODE_BACKUP_SCHEDULE_CARD = {
    SWITCH: 'management-node-backup-schedule-switch',
};

export const TABLE_ACTIONS = {
    RESTORE: 'restore',
    REMOVE: 'remove',
    BATCH_REMOVE_BTN: 'batch-remove',
};

