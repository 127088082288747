// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import styled from 'styled-components';

export const StyledCardList = styled.div`
    @media (max-width: 560px) {
        padding-right: 2px;
    }
`;

export const ItemInfo = styled.div`
    margin-left: 12px;
    flex: 2;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .pul-action:first-child {
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

export const ItemTitle = styled.span`
    display: flex;
    color: #222;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 4px;
    overflow: hidden;
    text-overflow: ellipsis;

    .pul-action a {
        color: #222;
    }

    .item__edit-action {
        margin-left: 8px;
        position: relative;
        bottom: 3px;
    }

    span:first-child {
        flex: 1;
    }
`;

interface IStyledCardItemProps {
    isDisabled: boolean;
}

export const StyledCardItem = styled.div<IStyledCardItemProps>`
    position: relative;
    height: 160px;
    box-shadow: 0 0 4px rgba(34, 34, 34, 0.24);
    border-radius: 2px;
    padding: 15px 20px 0;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    ${props => props.isDisabled ? `
        opacity: 0.3;
        pointer-events: none;
    ` : ''}

    .pul-content-loader {
        z-index: 1;
        width: 100%;
        height: 100%;
        position: absolute !important;
        top: 0;
        background: #fff;
        left: 0;
        opacity: 0.8;
        justify-content: center;
        display: flex;
    }
`;

export const ItemContent = styled.div`
    padding-bottom: 5px;
    display: flex;
    flex-direction: row;
`;

export const ToolbarContainer = styled.div`
    height: 40px;
    border-top: 1px solid #ececec;
    display: flex;
`;

export const ItemToolbar = styled.div`
    display: flex;
    align-items: center;
    flex: 1;

    .pul-toolbar {
        flex: 1;
    }

    .pul-toolbar__group {
        flex: 0;
    }

    svg {
      color: #737373;
    }
`;
