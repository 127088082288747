// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import {
    ISolusVMLicenseResponse,
    LicenseStatus,
    ISolusVMLicenseComputeResources,
} from 'common/api/resources/License/solusVM';
import {
    ActionType,
    getType,
} from 'typesafe-actions';
import * as solusVMLicenseActions from 'admin/license/solusVM/actions';
import { combineReducers } from 'redux';

interface ISolusVMLicenseReducer {
    item: ISolusVMLicenseResponse;
    computeResources: ISolusVMLicenseComputeResources;
}

export type SolusVMLicenseAction = ActionType<typeof solusVMLicenseActions>;
export type SolusVMLicenseState = Readonly<ISolusVMLicenseReducer>;

export const initialSolusVMLicenseState: ISolusVMLicenseResponse = {
    state: {
        status: LicenseStatus.INVALID,
        registered_name: '',
        company_name: '',
        registered_email: '',
        product_id: 0,
        product_name: '',
        registration_date: '',
        ips: [],
        standard_cr_limit: 0,
        mini_cr_limit: 0,
        micro_cr_limit: 0,
        expiration_date: '0000-00-00',
        soluslabs_key: '',
        activation_code: '',
        ka_key: '',
    },
    exceeded_limits: {
        crs_on_management_node: [],
        cr_limit: [],
        vs_per_cr_limit: [],
    },
};

export const initialSolusVMLicenseComputeResources: ISolusVMLicenseComputeResources = {
    standard: [],
    mini: [],
    micro: [],
};

export const initialSolusVMLicenseReducer: ISolusVMLicenseReducer = {
    item: initialSolusVMLicenseState,
    computeResources: initialSolusVMLicenseComputeResources,
};

export default combineReducers<SolusVMLicenseState, SolusVMLicenseAction>({
    item: (state = initialSolusVMLicenseState, action: SolusVMLicenseAction) => {
        switch (action.type) {
        case getType(solusVMLicenseActions.setLicenseItem):
            return action.payload;
        default:
            return state;
        }
    },
    computeResources: (state = initialSolusVMLicenseComputeResources, action: SolusVMLicenseAction) => {
        switch (action.type) {
        case getType(solusVMLicenseActions.setComputeResources):
            return action.payload;
        default:
            return state;
        }
    },
});
