// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import { initialComputeResourceState } from 'admin/computeResource/reducers';
import { RootState } from 'admin/core/store';
import {
    IComputeResourceResponse,
    ILogicalVolumeResponse,
    IPhysicalVolumeResponse,
} from 'common/api/resources/ComputeResource';
import { IStorageResponse } from 'common/api/resources/Storage';
import { NOT_SELECTED_ID } from 'common/api/constants';
import { StorageType } from 'common/api/resources/StorageType';

export const getAvailablePhysicalVolumes = (state: RootState) =>
    state.computeResource.physicalVolumes.reduce((prev: IPhysicalVolumeResponse[], current: IPhysicalVolumeResponse) => {
        const item = state.computeResource.storages.find((storage: IStorageResponse) => current.vg_name === storage.path && storage.type.name !== StorageType.THIN_LVM);

        if (!item && parseFloat(current.vg_free) > 0) {
            prev = [...prev, current];
        }

        return prev;
    }, []);

export const getAvailableLogicalVolumes = (state: RootState) =>
    state.computeResource.thinPools.reduce((prev: ILogicalVolumeResponse[], current: ILogicalVolumeResponse) => {
        const item = state.computeResource.storages.find((storage: IStorageResponse) => current.vg_name === storage.path && storage.thin_pool === current.lv_name);

        if (!item && parseInt(current.data_percent, 10) < 100) {
            prev = [...prev, current];
        }

        return prev;
    }, []);

export const getComputeResourceInstallSteps = (state: RootState, crId: number) => {
    const computeResource = state.computeResource.list.data.find(item => item.id === crId);

    if (computeResource && computeResource.installSteps) {
        return computeResource.installSteps.sort((x, y) => x.id - y.id);
    }

    return [];
};

export const getSelectedComputeResource = (state: RootState, id?: number) => {
    const itemId = id ? id : state.computeResource.item.id;
    const computeResource = state.computeResource.list.data.find((item: IComputeResourceResponse) => item.id === itemId);

    return computeResource ? computeResource : initialComputeResourceState;
};

export const isComputeResourceSelected = (state: RootState) => state.computeResource.item.id > NOT_SELECTED_ID;
export const availableStorages = (state: RootState) => state.storage.list.data.filter(storage => !state.computeResource.storages.map(item => item.id).includes(storage.id));
