// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import SelectInput from 'common/components/Select/SelectInput';
import { ValueType } from 'react-select';
import {
    FormField,
    Translate,
} from '@plesk/ui-library';
import FilterForm from 'admin/common/components/FilterForm/FilterForm';
import { IconType } from 'common/api/resources/Icon';
import { ICONS } from 'admin/icon/constants/tests';
import { typeMappings } from 'admin/icon/constants/translation';
import { ISelectOption } from 'common/components';

export interface IFilterProps {
    onTypeFilterChange: (option: ValueType<ISelectOption>) => void;
}

const types: ISelectOption[] = Object.values(IconType).map(type => ({
    label: typeMappings[type],
    value: type,
}));

const IconFilter: React.FC<IFilterProps> = ({
    onTypeFilterChange,
}) => (
    <FilterForm>
        <FormField label={<Translate content="icon.type" />}>
            <SelectInput<ISelectOption>
                placeholder={<Translate content="filters.icon"/>}
                options={types}
                isClearable={true}
                onChange={onTypeFilterChange}
                data-cy={ICONS.TYPE_FILTER}
            />
        </FormField>
    </FilterForm>
);

export default IconFilter;
