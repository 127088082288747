// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import { CutTitle } from 'common/components';
import { IOsImageResponse } from 'common/api/resources/OsImage';
import { IOsImageVersionResponse } from 'common/api/resources/OsImageVersion';
import { PROJECT_SERVER_PAGE } from 'client/project/constants/tests';
import { ServerIcon } from 'common/components/ServerIcon/ServerIcon';
import { COLORS } from 'common/theme';
import { ICONS } from 'common/constants';
import {
    Icon,
    Tooltip,
    Translate,
} from '@plesk/ui-library';
import {
    ApplicationItem,
    ItemContainer,
    ItemContent,
    ItemContentName,
    ItemDescription,
    LogoContainer,
    OsVersionItem,
    OsVersionItemParagraph,
    OsVersionList,
} from 'common/components/serverType/Styles';
import { dataCySelector } from 'common/tests/selectors';
import { OS_IMAGE_VERSION } from 'common/components/serverType/constants/test';

type IOsImageCard = {
    osImage: IOsImageResponse;
    selectedId: number;
    onVersionSelected: (id: number) => void;
    disabledOsImageVersionIds?: number[];
} & React.HTMLProps<HTMLDivElement>;

export const OsImageCard: React.FC<IOsImageCard> = ({
    osImage,
    onVersionSelected,
    selectedId,
    disabledOsImageVersionIds,
}) => {
    const [isOpenList, setIsOpenList] = React.useState(false);
    const [osImageVersionId, setOsImageVersionId] = React.useState(osImage.versions.length ? osImage.versions[0].id : 0);
    const isSelected = osImage.versions.map(item => item.id).includes(selectedId);
    const itemElement = React.useRef<HTMLDivElement>(null);

    React.useLayoutEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const selectedVersion = () => {
        const version = osImage.versions.find(item => item.id === osImageVersionId);

        if (version) {
            return version.version;
        }

        return 'no version found';
    };

    const handleClickOutside = (e: MouseEvent) => {
        if (itemElement.current && !itemElement.current.contains(e.target as Node)) {
            setIsOpenList(false);
        }
    };
    const handleClick = () => {
        if (!disabledOsImageVersionIds?.includes(osImageVersionId)) {
            onVersionSelected(osImageVersionId);
            setIsOpenList(false);
        }
    };

    const handleOpenList = (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        if (disabledOsImageVersionIds?.length !== osImage.versions.length) {
            setIsOpenList(!isOpenList);
        }
    };
    const handleSelectVersion = (item: IOsImageVersionResponse) => (e: React.MouseEvent<HTMLLIElement>) => {
        e.stopPropagation();

        if (!disabledOsImageVersionIds?.includes(item.id)) {
            setOsImageVersionId(item.id);
            onVersionSelected(item.id);
            setIsOpenList(false);
        }
    };

    return (
        <ApplicationItem
            ref={itemElement}
            onClick={handleClick}
            isSelected={isSelected}
            isDisabled={disabledOsImageVersionIds?.length === osImage.versions.length}
        >
            <ItemContainer>
                <LogoContainer>
                    {isSelected ? <Icon name={ICONS.CHECK_MARK} /> : <ServerIcon icon={osImage.icon} />}
                </LogoContainer>
                <ItemContent>
                    <ItemContentName data-cy={PROJECT_SERVER_PAGE.OS_IMAGE_CARD}>
                        <CutTitle title={`${osImage.name} ${selectedVersion()}`} />
                    </ItemContentName>
                    {osImage.versions.length > 1 && (
                        <div onClick={handleOpenList}>
                            <ItemDescription className="application-description">
                                <Translate content="servers.create.otherVersions" />
                                <Icon
                                    name={ICONS.CHEVRON_DOWN}
                                    style={{ marginLeft: 5, width: 8 }}
                                />
                            </ItemDescription>
                        </div>
                    )}
                </ItemContent>
                {!osImage.is_visible && (
                    <Icon
                        name={ICONS.EYE_CLOSED}
                        size="16"
                        style={{ color: isSelected ? COLORS.WHITE_0 : COLORS.GREY_60 }}
                    />
                )}
            </ItemContainer>
            {isOpenList && (
                <OsVersionList>
                    {osImage.versions.map((item, key) => (
                        <Tooltip title={item.version} key={key}>
                            <OsVersionItem
                                onClick={handleSelectVersion(item)}
                                isDisabled={!!disabledOsImageVersionIds?.includes(item.id)}
                            >
                                <OsVersionItemParagraph>{item.version}</OsVersionItemParagraph>
                                {!item.is_visible && (
                                    <Icon
                                        name={ICONS.EYE_CLOSED}
                                        size="10"
                                        style={{ color: COLORS.GREY_60 }}
                                        data-cy={dataCySelector(item.id, OS_IMAGE_VERSION.VISIBILITY)}
                                    />
                                )}
                            </OsVersionItem>
                        </Tooltip>
                    ))}
                </OsVersionList>
            )}
        </ApplicationItem>
    );
};
