// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import { Translate } from '@plesk/ui-library';
import {
    Header,
    Title,
} from 'admin/common/components/PageHeader/Styles';
import { Button } from 'admin/common/components/Button/Button';
import { INTENT_TYPE } from 'common/constants';

export interface IPageHeaderProps {
    title: React.ReactNode;
    onButtonClick?: () => void;
    buttonText?: string;
    buttonIcon?: string;
    isButtonShown: boolean;
}

export const PageHeader: React.FC<IPageHeaderProps> = ({
    title,
    onButtonClick,
    buttonText,
    buttonIcon,
    isButtonShown,
}) => (
    <Header>
        <Title>{title}</Title>
        {isButtonShown && (
            <Button onClick={onButtonClick} size="lg" type="submit" intent={INTENT_TYPE.PRIMARY} icon={buttonIcon}>
                {buttonText && (<Translate content={buttonText}/>)}
            </Button>
        )}
    </Header>
);
