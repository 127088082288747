// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import {
    ActionType,
    getType,
} from 'typesafe-actions';
import * as mailConfirmationActions from 'client/project/actions/mailConfirmation';

export type MailConfirmationAction = ActionType<typeof mailConfirmationActions>;

export default (state = false, action: MailConfirmationAction) => {
    switch (action.type) {
    case getType(mailConfirmationActions.setIsShown): {
        return action.payload;
    }
    default:
        return state;
    }
};
