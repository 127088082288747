// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import {
    api,
    IPaginateApiResponse,
} from 'common/api/resources/Response';
import {
    IApiTokenListRequest,
    IApiTokenRequest,
    IApiTokenResponse,
} from 'common/api/resources/ApiToken/model';
import { QueryBuilder } from 'common/api/resources/Request/QueryBuilder';

export const apiTokens = {
    list: (params?: IApiTokenListRequest) => api.get<IPaginateApiResponse<IApiTokenResponse[]>>('api_tokens', {
        params: new QueryBuilder(params).build(),
    }),
    create: (data: IApiTokenRequest) => api.post('api_tokens', data),
    remove: (id: string) => api.delete(`api_tokens/${id}`),
};
