// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import React from 'react';
import { formatUpdateDate } from 'common/date';
import {
    ICONS,
    INTENT_TYPE,
} from 'common/constants';
import {
    StatusIconContainer,
    StatusText,
    StatusSubtext,
    ErrorText,
    InstallErrorContainer,
} from 'admin/update/containers/VersionState/Styles';
import {
    Icon,
    Translate,
    Button,
} from '@plesk/ui-library';

interface IVersionReadyForUpdateProps {
    scheduledDate: string | null;
    isLoading: boolean;
    onInitUpdate: () => void;
}

export type VersionReadyForUpdateProps =
    IVersionReadyForUpdateProps;

export const VersionReadyForUpdate: React.FC<VersionReadyForUpdateProps> = ({
    scheduledDate,
    isLoading,
    onInitUpdate,
}) => {
    const [errorMessage, setErrorMessage] = React.useState(null);

    const handleInitUpdate = async () => {
        try {
            await onInitUpdate();
        } catch (e) {
            setErrorMessage(e.response.data.message);
        }
    };

    return (
        <>
            <StatusIconContainer>
                <Icon
                    name="check-mark-circle-filled"
                    intent={INTENT_TYPE.INFO}
                />
            </StatusIconContainer>
            <div>
                <StatusText>
                    <Translate content="update.status.readyForUpdate" />
                </StatusText>
                <StatusSubtext>
                    {scheduledDate && (
                        <>
                            <span style={{ marginRight: '8px' }}>
                                {formatUpdateDate(scheduledDate, true)}
                            </span>
                            <Icon name={ICONS.CALENDAR_CLOCK} />
                        </>
                    )}
                </StatusSubtext>
                <Button
                    onClick={handleInitUpdate}
                    state={isLoading ? 'loading' : null}
                    style={{ marginTop: '20px' }}
                >
                    <Translate content="update.install" />
                </Button>
                {errorMessage && (
                    <InstallErrorContainer>
                        <ErrorText>
                            {errorMessage}
                        </ErrorText>
                    </InstallErrorContainer>
                )}
            </div>
        </>
    );
};

export default VersionReadyForUpdate;
