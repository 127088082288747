// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import { ILanguageResponse } from 'common/api/resources/Language';
import { Translate } from '@plesk/ui-library';

export interface ILanguageNameProps {
    language: ILanguageResponse;
    checked?: boolean;
}

export const LanguageName: React.FC<ILanguageNameProps> = ({
    language,
    checked,
}) => (
    <>
        {language.icon && (
            <img
                width={15}
                src={language.icon.url}
                style={{ marginRight: '8px' }}
                alt={language.icon.name}
            />
        )}
        {language.name}
        {checked && (
            <>
                &nbsp;
                <Translate content="languages.customized" />
            </>
        )}
    </>
);
