// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import {
    GridCol,
    Item,
} from '@plesk/ui-library';

export interface ISettingsPropertyCardProps {
    title: React.ReactNode;
    text: React.ReactNode;

    'data-cy'?: string;
}

const SettingsPropertyCard: React.FC<ISettingsPropertyCardProps> = ({
    title,
    text,

    'data-cy': dataCy,

    children,
}) => (
    <GridCol data-cy={dataCy}>
        <Item
            title={title}
            view="card"
        >
            {children}
            <div>
                {text}
            </div>
        </Item>
    </GridCol>
);

export default SettingsPropertyCard;
